import { withStyles } from '@material-ui/core';

export default withStyles(({ palette, shadows }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  wrapperTrie: {
    display: 'flex',
    alignItems: 'center',
  },
  listTri: {

  },
  textTrie: {
    fontSize: '1.4rem',
    color: palette.grey[500],
    fontWeight: 'bold',
  },
  btnTrie: {
    background: 'transparent',
    width: 'auto',
    color: palette.secondary.main,
  },
  subTextTrie: {
    color: palette.secondary.main,
    fontWeight: 'bold',
    position: 'relative',
    fontSize: '1rem',
  },
}));
