import React from 'react';
import { Redirect } from 'react-router-dom';
import Proptypes from 'prop-types';

const WrapperPageUser = ({
  children, user, fallback,
}) => (
  <>
    {
      user.isAuthenticated
        ? (
          <>
            { children }
          </>
        ) : (
          <>
            {
            fallback ? (
              <>
                {fallback}
              </>
            ) : <Redirect to="/" />
          }
          </>
        )
    }
  </>
);

WrapperPageUser.propTypes = {
  user: Proptypes.shape({
    token: Proptypes.string,
    fetching: Proptypes.bool,
    fetched: Proptypes.bool,
    isLoading: Proptypes.bool,
    isAuthenticated: Proptypes.bool,
    isAdmin: Proptypes.oneOfType([
      Proptypes.bool,
      Proptypes.number,
    ]),
    user: Proptypes.object,
    msg: Proptypes.string,
  }),
  children: Proptypes.node,
  fallback: Proptypes.node,
};

WrapperPageUser.defaultProps = {
  fallback: null,
};

export default WrapperPageUser;
