import { withStyles } from '@material-ui/core';

export default withStyles(({ breakpoints, palette }) => ({
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    margin: '5rem 0',
  },
  paperChildren: {
    padding: '0 2rem',
    paddingBottom: '5rem',
    justifyContent: 'center',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  title: {
    color: palette.secondary.main,
    fontWeight: 'bold',
    fontSize: '4rem',
    [breakpoints.down(620)]: {
      fontSize: '2.5rem',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
  },
  textField: {
    width: '100%',
    marginTop: '2rem',
  },
  btn: {
    marginTop: '2rem',
  },
}));
