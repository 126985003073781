import { withStyles } from '@material-ui/styles';

export default withStyles(({ palette, breakpoints }) => ({
  root: {
    width: '80%',
    margin: '3rem auto',
    [breakpoints.down(500)]: { // 3 cards
      width: '100%',
    },
  },
  list: {
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapperToggle: {
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%',
    justifyContent: 'space-around',
    marginTop: '1rem',
    [breakpoints.down(800)]: { // 3 cards
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  toggleButtons: {
    [breakpoints.down(500)]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  switch: {
    [breakpoints.down(800)]: { // 3 cards
      marginTop: '2rem',
    },
  },
  labelSwitch: {
    fontSize: '1.2rem',
    color: palette.grey[800],
    textAlign: 'center',
  },
  listItem: {
    borderBottom: '0.5px solid rgba(0, 0, 0, .125)',
    padding: '2rem',
    width: '100%',
  },
  rootListItem: {
    display: 'block',
    borderBottom: '2px solid rgba(0, 0, 0, .125)',
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
  },
  avatarImg: {
    width: '100%',
  },
  wrapperInfo: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  wrapperTextLeft: {
    width: '100%',
  },
  titleText: {
    fontWeight: 'bold',
    fontSize: '1.4rem',
    color: palette.grey[800],
    textAlign: 'center',
  },
  companyNameText: {
    textAlign: 'left',
    fontSize: '1.2rem',
    margin: 0,
  },
  text: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    color: palette.grey[500],
    margin: 0,
    padding: 0,
    wordBreak: 'break-word',
  },
  textRight: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    color: palette.grey[500],
    // alignSelf: 'baseline',
  },
}));
