import React from 'react';
import Proptypes from 'prop-types';
import { Box } from '@material-ui/core';

const TabPanel = ({
  children, value, index, ...rest
}) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    {...rest}
  >
    {value === index && (
    <Box p={3} style={{ paddingTop: 0 }}>
      {children}
    </Box>
    )}
  </div>
);

TabPanel.propTypes = {
  children: Proptypes.node,
  index: Proptypes.any.isRequired,
  value: Proptypes.any.isRequired,
};

export default TabPanel;
