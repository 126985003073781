import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import { Typography } from '@material-ui/core';
import ModalInformation from '../ModalInformation/modal-information';

import withTileTutoriel from './tile-tutoriel.style';

const TileTutoriel = ({
  classes,
  classNamePaper,
  classNameBtnSubmit,
  classNameTitle,
  illustrationRight,
  open,
  onClose,
  title,
  textButton,
  onClickSubmit,
  detailsText,
  textIllustration,
  illustration,
}) => (
  <ModalInformation
    classNameBtnSubmit={cx(classes.btnSubmit, classNameBtnSubmit)}
    classNamePaper={cx(classes.paper, classNamePaper)}
    classNameTitle={cx(classes.title, classNameTitle)}
    open={open}
    onClose={onClose}
    button
    title={title}
    textButton={textButton}
    onClickSubmit={onClickSubmit}
  >
    <div className={classes.container}>
      <Typography variant="body1" className={classes.detailsText}>{detailsText}</Typography>
      <div className={classes.containerIllustration}>
        <div className={classes.wrapperImg}>
          <img src={illustration} alt="#" className={classes.img} />
        </div>
        <Typography variant="body2" className={classes.textIllustration}>{textIllustration}</Typography>
      </div>
    </div>
  </ModalInformation>
);

TileTutoriel.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  classNamePaper: Proptypes.string,
  classNameBtnSubmit: Proptypes.oneOfType([Proptypes.string, Proptypes.bool]),
  classNameTitle: Proptypes.string,
  open: Proptypes.bool,
  onClickSubmit: Proptypes.func,
  onClose: Proptypes.func,
  title: Proptypes.string,
  textButton: Proptypes.string,
  detailsText: Proptypes.string,
  textIllustration: Proptypes.string,
  illustration: Proptypes.node,
  illustrationRight: Proptypes.bool,
};

TileTutoriel.defaultProps = {
  illustrationRight: false,
  onClose: () => {},
  onClickSubmit: () => {},
};

export default withTileTutoriel(TileTutoriel);
