import React from 'react';
import Proptypes from 'prop-types';

import CardInformation from '../../_molecules/CardInformationProject/card-information-project';
import withListCardInformation from './list-card-information-project.style';

// Refacto this component

const ListCardInformation = ({ classes, list }) => (
  <ul className={classes.list}>
    {
      list.map((element, index) => (
        <li className={(index + 1 !== list.length ? classes.card : '')} key={element.key}>
          <CardInformation
            id={element.key}
            title={element.title}
            picture={element.picture}
            pictureHover={element.pictureActive}
            detailsText={element.text}
          />
        </li>
      ))
    }
  </ul>
);

ListCardInformation.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  list: Proptypes.array,
};

export default withListCardInformation(ListCardInformation);
