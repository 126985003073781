import { GET_ERRORS, CLEAR_ERRORS } from '../constants/actionTypes';

// CLEAR ERRORS
export const clearErrors = () => ({
  type: CLEAR_ERRORS,
});

// RETURN ERRORS
export const returnErrors = (msg, status, id = null) => {
  if (status === 500) {
    return ({
      type: GET_ERRORS,
      payload: { msg: 'Une erreur est survenu, veuillez réessayer !', status, id },
    });
  }
  return ({
    type: GET_ERRORS,
    payload: { msg, status, id },
  });
};
