import { withStyles } from '@material-ui/core';

export default withStyles(({ breakpoints, palette }) => ({
  root: {
    width: '100%',
    overflow: 'hidden',
    transition: 'all .3s ease-in-out',
  },
  listCategories: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  btnCategory: {
    background: 'none',
    border: 'none',
    '&:focus': {
      outline: 'none',
    },
    borderBottom: '2px solid white',
  },
  categoryHover: {
    borderBottom: `2px solid ${palette.secondary.main}`,
    background: 'none',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
    border: 'none',
  },
  categoryActive: {
    borderBottom: `2px solid ${palette.secondary.main}`,
    background: 'none',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
    border: 'none',
  },
  categoryLeave: {
    borderBottom: '2px solid white',
    border: 'none',
    background: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  titleCategory: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },

}));
