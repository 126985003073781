import { withStyles } from '@material-ui/styles';
import { mobileSectionHeader, sizeForSmallerSmartphone } from '../../../constants/size';

export default withStyles(({ palette, breakpoints, transitions }) => ({
  appBarSticky: {
    background: palette.common.white,
    animation: 'moveDown 1s ease-in-out',
    position: 'fixed',
    zIndex: 10,
  },
  appBar: {
    background: 'transparent',
    boxShadow: 'none',
    transition: transitions.create(['margin', 'width'], {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen,
    }),
  },
  toolbar: {
    [breakpoints.down(mobileSectionHeader)]: {
      paddingRight: 0,
      paddingLeft: 0,
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  demo: {
    color: 'red',
  },
  logoWrapper: {
    marginRight: '4rem',
  },
  logo1: {
    color: (props) => (props.sticky ? '#212529' : palette.common.white),
    paddingLeft: '2rem',
    [breakpoints.down(sizeForSmallerSmartphone)]: {
      fontSize: '2.4rem',
    },
  },
  logo2: {
    color: (props) => (props.sticky ? palette.secondary.main : '#fab9af'),
    [breakpoints.down(sizeForSmallerSmartphone)]: {
      fontSize: '2.4rem',
    },
  },
  sectionDesktop: {
    display: 'none',
    [breakpoints.up(mobileSectionHeader)]: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
  },
  mobileSection: {
    display: 'block',
    marginTop: '0.5rem',
    [breakpoints.up(mobileSectionHeader)]: {
      display: 'none',
    },
  },
  navRef: {
    marginTop: '1rem',
  },
  link: {
    height: 0,
    textDecoration: 'none',
  },
  textNavBar: {
    color: (props) => (props.sticky ? '#212529' : palette.common.white),
    fontWeight: 'bold',
    fontSize: '1.4rem',
    marginRight: '1rem',
    transition: '0.2s all ease-in-out',
    '&:hover': {
      color: palette.secondary.dark,
    },
  },
  btn: {
    transition: '0.2s background-color ease-in-out',
    margin: '0 1rem',
  },
  btnConcours: {
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.15)',
    },
  },
  btnConcoursText: {
    fontSize: '1.4rem',
  },
  btnConnexion: {
    background: 'transparent',
    border: 'transparent',
    marginTop: '-1rem',
    transition: '0.2s all ease-in-out',
    '&:hover': {
      color: palette.secondary.dark,
      background: 'transparent',
      opacity: 1,
    },
  },
  activeLink: {
    color: palette.secondary.dark,
  },
  labelConnexion: {
    color: (props) => (props.sticky ? '#212529' : palette.common.white),
    transition: '0.2s all ease-in-out',
    fontSize: '1.8rem',
    marginTop: '1.5rem',
    '&:hover': {
      color: palette.secondary.dark,
    },
  },
  btnInscription: {
    color: (props) => (props.sticky ? palette.common.white : palette.secondary.main),
    backgroundColor: (props) => (props.sticky ? palette.secondary.main : palette.common.white),
    height: '3.4rem',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.85)',
    },
  },
  auth: {
    display: 'flex',
    marginTop: '0.3rem',
  },
  linkMobile: {
    textDecoration: 'none',
    color: palette.common.black,
  },
  listText: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
}));
