import { withStyles } from '@material-ui/styles';

export default withStyles(({ breakpoints, palette, transitions }) => ({
  headerGuest: {
    height: '7rem',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);',
    background: palette.gradient.main,
    transition: transitions.create(['margin', 'width'], {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen,
    }),
  },
}));
