import { withStyles } from '@material-ui/core';

export default withStyles(({ breakpoints, palette }) => ({
  list: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    display: 'inline-flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  card: {
    marginRight: '2rem',
  },
  contentTextInformation: {
    margin: '5rem auto',
    width: '50%',
    [breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  textInformation: {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: palette.grey[800],
    textAlign: 'center',
    whiteSpace: 'pre-wrap', // Permet de faire /n
  },
}));
