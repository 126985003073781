import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import Rating from '@material-ui/lab/Rating';
import { Typography } from '@material-ui/core';
import CardParticipant from '../../_atoms/CardParticipant/card-participant';

import withCardParticipantRating from './card-participant-rating.style';
import CarousselCardParticipant from '../../_organisms/CarousselCardParticipant/caroussel-card-participant';
import { statusConcoursPaid } from '../../../constants/concours';

const CardParticipantRating = ({
  classes,
  subCategory,
  list,
  participation,
  valueStars,
  onChangeStars,
  className,
  classNameCard,
  nameStars,
  number,
  statusConcours,
  isWinner,
  onClickSubmitMessage,
  onClickReport,
  onClickAddWinner,
}) => (
  <div className={cx(classes.root, className)}>
    {
      subCategory.format === 5 ? (
        <CardParticipant
          className={classNameCard}
          format={5}
          onClickSubmitMessage={onClickSubmitMessage}
          onClickReport={onClickReport}
          onClickAddWinner={onClickAddWinner}
          text={participation.inputText}
          statusConcours={statusConcours}
          isWinner={isWinner}
        />
      ) : (
        <CarousselCardParticipant
          subCategory={subCategory}
          list={list}
          participation={participation}
          className={classNameCard}
          onClickSubmitMessage={onClickSubmitMessage}
          onClickReport={onClickReport}
          onClickAddWinner={onClickAddWinner}
          isWinner={isWinner}
          statusConcours={statusConcours}
        />
      )
    }
    <div className={classes.rating}>
      <Typography variant="body2" className={classes.number} display="inline">{number}</Typography>
      <Rating
        name={nameStars}
        value={valueStars}
        onChange={onChangeStars}
        size="large"
      />
    </div>
  </div>
);

CardParticipantRating.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  background: Proptypes.node,
  valueStars: Proptypes.oneOfType([
    Proptypes.string,
    Proptypes.number,
  ]),
  subCategory: Proptypes.object,
  participation: Proptypes.object,
  list: Proptypes.array,
  onChangeStars: Proptypes.func,
  className: Proptypes.string,
  classNameCard: Proptypes.string,
  text: Proptypes.string,
  nameStars: Proptypes.string,
  number: Proptypes.oneOfType([
    Proptypes.string,
    Proptypes.number,
  ]),
  refMessage: Proptypes.func,
  onClickSubmitMessage: Proptypes.func,
  onClickReport: Proptypes.func,
  onClickAddWinner: Proptypes.func,
  statusConcours: Proptypes.number,
  isWinner: Proptypes.oneOfType([
    Proptypes.bool, Proptypes.number,
  ]),
};

CardParticipantRating.defaultProps = {
  valueStars: undefined,
  statusConcours: statusConcoursPaid,
  isWinner: false,
  onChangeStars: () => {},
  onClickSubmitMessage: () => {},
  onClickReport: () => {},
  onClickAddWinner: () => {},
};

export default withCardParticipantRating(CardParticipantRating);
