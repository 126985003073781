import { withStyles } from '@material-ui/core';

export default withStyles(({ breakpoints, palette }) => ({
  textStatus: {
    color: palette.secondary.main,
    marginTop: '4rem',
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '2.4rem',
  },
  filter: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: '3rem',
  },
  btn: {
    width: '12rem',
    height: '3rem',
    margin: '1rem',
    background: 'transparent',
    '&:hover': {
      background: palette.secondary.main,
      opacity: 1,
      '@global': {
        span: {
          color: 'white',
        },
      },
    },
  },
  btnActive: {
    background: palette.secondary.main,
    '@global': {
      span: {
        color: 'white',
      },
    },
  },
  textBtn: {
    fontSize: '1.2rem',
    color: 'black',
  },
  wrapperInformations: {
    margin: '2rem 6rem',
    marginBottom: 0,
    [breakpoints.down(1100)]: {
      textAlign: 'center',
    },
  },
  text: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: palette.grey[800],
  },
  content: {
    display: 'flex',
    [breakpoints.down(1100)]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  titlePaper: {
    [breakpoints.up(1300)]: {
      marginTop: '-12rem',
    },
  },
  left: {
    flex: 3,
    [breakpoints.down(1100)]: {
      justifyContent: 'center',
      padding: 0,
    },
  },
  right: {
    height: '100%',
    flex: 1,
    marginRight: '2rem',
    marginTop: '3rem',
    [breakpoints.down(1000)]: {
      margin: 0,
    },
    maxWidth: '32rem',
  },
}));
