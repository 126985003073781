import React from 'react';
import Proptypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { Hidden, Typography } from '@material-ui/core';
import imgInscription from '../../../assets/SVG/Inscription.svg';

import Header from '../../_organisms/Header/header';

import withHeader from './inscription.style';
import SnackBarBottom from '../../_atoms/SnackBarBottom/snack-bar-bottom';
import InscriptionForm from './inscription.form';

class Inscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openHeader: false,
      openSnack: false,
      errorClient: '',
      radioCGU: false,
    };
  }

  componentDidMount() {
    const { clearSuccessMsg } = this.props;
    clearSuccessMsg();
  }

  handleOpenSnack = () => {
    this.setState({
      openSnack: true,
    });
  };

  handleChangeRadioCGU = () => {
    const { radioCGU } = this.state;
    if (radioCGU) {
      this.setState({
        radioCGU: false,
      });
    } else {
      this.setState({
        radioCGU: true,
      });
    }
  }

  handleCloseSnack = () => {
    this.setState({
      openSnack: false,
    });
  };

  handleSubmit = (data) => {
    this.setState({
      errorClient: '',
    });
    const { registerUser, history } = this.props;
    const { radioCGU } = this.state;
    if (radioCGU === false) {
      this.setState({
        errorClient: 'Les CGU n\'ont pas été accepté !',
      });
      this.handleOpenSnack();
    } else if (data.password !== data.confirmPassword) {
      this.setState({
        errorClient: 'Les deux mots de passes ne sont pas égaux ! (client)',
      });
      this.handleOpenSnack();
    } else {
      registerUser({ ...data }, (msgSuccess) => {
        if (msgSuccess) {
          history.push({
            pathname: '/signup/success',
            state: {
              title: 'Inscription réussi',
              text: msgSuccess,
            },
          });
        }
      });
    }
  };

  handleOpenHeader = () => {
    this.setState({
      openHeader: true,
    });
  };

  handleCloseHeader = () => {
    this.setState({
      openHeader: false,
    });
  };

  render() {
    const {
      classes, isAuthenticated,
    } = this.props;

    const {
      openSnack, openHeader, errorClient, radioCGU,
    } = this.state;

    return (
      <>
        {
          !!errorClient && <SnackBarBottom variant="error" open={openSnack} onClose={this.handleCloseSnack} text={errorClient} />
        }
        {
          isAuthenticated && (
            <Redirect to="/" />
          )
        }
        <Header onConnexion={this.handleOpenHeader} open={openHeader} onClose={this.handleCloseHeader} allLinks={false} />
        <section className={classes.paper}>
          <Hidden xsDown>
            <div className={classes.wrapperImg}>
              <img src={imgInscription} alt="Inscription" className={classes.img} />
            </div>
          </Hidden>
          <div className={classes.wrapperForm}>
            <Typography variant="h3" display="block" className={classes.title}>Inscrivez-vous maintenant !</Typography>
            <InscriptionForm onSubmit={this.handleSubmit} radioCGU={radioCGU} onChangeRadioCGU={this.handleChangeRadioCGU} />
          </div>
        </section>
      </>
    );
  }
}

Inscription.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  registerUser: Proptypes.func,
  history: Proptypes.objectOf(
    Proptypes.oneOfType([
      Proptypes.number,
      Proptypes.func,
      Proptypes.object,
      Proptypes.string,
    ]),
  ),
  clearSuccessMsg: Proptypes.func,
  isAuthenticated: Proptypes.bool,
  error: Proptypes.shape({
    msg: Proptypes.string,
    status: Proptypes.number,
    id: Proptypes.string,
  }),
};

export default withHeader(Inscription);
