import { withStyles } from '@material-ui/styles';

export default withStyles(({ palette }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    // eslint-disable-next-line no-nested-ternary
    alignItems: (props) => {
      if (props.typeMessage === 2) return 'center';
      if (props.myMessage) return 'flex-end';
      return 'flex-start';
    },
    textAlign: (props) => (props.typeMessage === 2) && 'center',
    marginBottom: (props) => (props.typeMessage === 2) && '2rem',
  },
  wrapperPaper: {
    width: '40vw',
    padding: '1rem',
    background: (props) => {
      if (props.typeMessage === 2) return palette.secondary.main;
      if (props.myMessage) return '#ebebeb';
      return palette.common.white;
    },
    borderRadius: '1rem',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    borderBottomRightRadius: (props) => (props.myMessage ? '0.5rem' : '1rem'),
    borderBottomLeftRadius: (props) => (props.myMessage ? '1rem' : '0.5rem'),
  },
  text: {
    whiteSpace: 'pre-wrap',
    fontWeight: 'bold',
    fontSize: '1.2rem',
    color: (props) => ((props.typeMessage === 2) ? palette.common.white : palette.grey[800]),
  },
  hour: {
    margin: '0.5rem 0',
    color: palette.grey[500],
    fontWeight: 'bold',
    fontSize: '1rem',
    display: (props) => (props.typeMessage === 2) && 'none',
  },
}));
