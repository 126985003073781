import { parseStatutConcours } from './parse';
import { sortById, sortByIdForParticipationObject } from './sort';

// idConcours est que dans le tableau des participations

export const gainForWinner = (cost) => (
  cost - 0.2 * cost
);

export const concoursInProgress = (status) => {
  let result = true;
  if (status === -1 || status === 5) result = false;
  return result;
};

export const parseTabsMyConcours = (concoursCreated, concoursParticiped) => [
  {
    key: 0,
    headers: [
      {
        key: 0,
        text: 'Catégorie',
      },
      {
        key: 1,
        text: 'Coût',
      },
      {
        key: 2,
        text: 'Statut',
      },
      {
        key: 3,
        text: 'Debut du concours',
      },
      {
        key: 4,
        text: 'Fin du concours',
      },
      {
        key: 5,
        text: 'Solution proposées',
      },
      {
        key: 6,
        text: 'Édition',
      },
      {
        key: 7,
        text: 'Concours',
      },
    ],
    body: concoursCreated.sort(sortById).map((concoursSingle) => {
      const createdArr = concoursSingle.createdAt.split('-');
      const endDateArr = concoursSingle.endDate.split('-');
      return (
        {
          key: concoursSingle.id,
          content: {
            category: concoursSingle.subCategory.name,
            coût: concoursSingle.cost,
            statut: parseStatutConcours(concoursSingle.status),
            debut_concours: `${createdArr[2].split('T')[0]}/${createdArr[1]}/${createdArr[0]}`,
            fin_concours: `${endDateArr[2]}/${endDateArr[1]}/${endDateArr[0]}`,
            solution_proposer: concoursSingle.participations.length,
          },
        }
      );
    }),
  },
  {
    key: 1,
    headers: [
      {
        key: 10,
        text: 'Catégorie',
      },
      {
        key: 11,
        text: 'Gain',
      },
      {
        key: 12,
        text: 'Statut',
      },
      {
        key: 13,
        text: 'Debut du concours',
      },
      {
        key: 14,
        text: 'Fin du concours',
      },
      {
        key: 15,
        text: 'Participation',
      },
    ],
    body: concoursParticiped.sort(sortByIdForParticipationObject).map((participation) => {
      const createdArr = participation.concour.createdAt.split('-');
      const endDateArr = participation.concour.endDate.split('-');
      return (
        {
          key: participation.id,
          idConcours: participation.idConcours,
          content: {
            category: participation.concour.subCategory.name,
            gain: gainForWinner(participation.concour.cost),
            statut: parseStatutConcours(participation.concour.status),
            debut_concours: `${createdArr[2].split('T')[0]}/${createdArr[1]}/${createdArr[0]}`,
            fin_concours: `${endDateArr[2]}/${endDateArr[1]}/${endDateArr[0]}`,
          },
        }
      );
    }),
  }];
