import React, { useEffect, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import Proptypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import WrapperPageUser from '../../../containers/WrapperPageUser/wrapper-page-user.connect';
import HomeGuest from '../HomeGuest/home-guest';
import HomeUser from '../HomeUser/home-user';
import withHome from './home.style';
import { loadUser } from '../../../actions/user';

const Home = ({
  getConcoursWithFilter, location, concours, history, finishTutorial,
}) => {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    if (isAuthenticated) {
      getConcoursWithFilter({
        selector: 'createdAt',
        orderedBy: 'DESC',
      });
      if (!user) {
        dispatch(loadUser());
      }
    }
  }, [isAuthenticated]);

  const isFirstTime = useMemo(() => {
    if (isAuthenticated) {
      return user?.isFirstTime;
    }
    return false;
  }, [user]);
  return (
    <>
      {
        location.pathname !== '/' && (
          <Redirect to="/" />
        )
      }
      <WrapperPageUser fallback={<HomeGuest />}>
        <HomeUser
          concours={concours}
          history={history}
          user={user}
          getConcoursWithFilter={getConcoursWithFilter}
          finishTutorial={finishTutorial}
          isFirstTime={isFirstTime}
        />
      </WrapperPageUser>
    </>
  );
};

Home.propTypes = {
  location: Proptypes.objectOf(
    Proptypes.oneOfType([
      Proptypes.string, Proptypes.object,
    ]),
  ),
  history: Proptypes.objectOf(
    Proptypes.oneOfType([
      Proptypes.number,
      Proptypes.func,
      Proptypes.object,
      Proptypes.string,
    ]),
  ),
  getAllConcours: Proptypes.func,
  getConcoursWithFilter: Proptypes.func,
  finishTutorial: Proptypes.func,
  isAuthenticated: Proptypes.bool,
  concours: Proptypes.oneOfType([
    Proptypes.array,
    Proptypes.object,
  ]),
};

export default withHome(Home);
