import React, { useState } from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { Typography, Divider } from '@material-ui/core';
import useForm from 'react-hook-form';
import WrapperPageUser from '../../../containers/WrapperPageUser/wrapper-page-user.connect';
import Header from '../../_organisms/Header/header.connect';
import InputForm from '../../_atoms/InputForm/input-form';
import TextareaForm from '../../_atoms/TextareaForm/textarea-form';
import SliderWithInfo from '../../_molecules/SliderWithInfo/slider-with-info';
import Button from '../../_atoms/Button/button';
import SnackBarBottom from '../../_atoms/SnackBarBottom/snack-bar-bottom';

import withStep2 from './oragniserConcours.style';

const Step2Concours = ({ classes, location, history }) => {
  const [openSnack, setOpenSnack] = useState(false);
  const [cursorModern, setCursorModern] = useState(2);
  const [cursorYoung, setCursorYoung] = useState(2);
  const [cursorFun, setCursorFun] = useState(2);
  const [cursorLuxury, setCursorLuxury] = useState(2);

  const {
    register, handleSubmit,
  } = useForm();

  const handleOpenSnack = () => {
    setOpenSnack(true);
  };

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  const onSubmit = (data) => {
    const {
      companyName, companySkills, target, message,
    } = data;

    if (companyName && companySkills && target && message) {
      history.push({
        pathname: '/organiser-concours/etape3',
        state: {
          concours: {
            ...location.state,
            ...data,
            cursorModern,
            cursorFun,
            cursorYoung,
            cursorLuxury,
          },
        },
      });
    } else {
      handleOpenSnack();
    }
  };

  const handleChangeModern = (event, newValue) => {
    console.log(location.state);
    setCursorModern(newValue);
  };

  const handleChangeYoung = (event, newValue) => {
    setCursorYoung(newValue);
  };

  const handleChangeFun = (event, newValue) => {
    setCursorFun(newValue);
  };

  const handleChangeLuxury = (event, newValue) => {
    setCursorLuxury(newValue);
  };

  return (
    <>
      {
        !!openSnack && (
          <SnackBarBottom variant="error" open={openSnack} onClose={handleCloseSnack} text="Tout les champs doivent être remplis pour continuer !" />
        )
      }
      {
        location.state ? (
          <WrapperPageUser>
            <Header title="Etape 2 : Parlez-nous de vous" history={history} />
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={cx(classes.wrapBodyStep2, classes.wrapBodyStep3)}>
                <div className={classes.step3Left}>
                  <Typography variant="h3" display="block" className={classes.title}>Nom de votre entreprise ou projet</Typography>
                  <InputForm
                    name="companyName"
                    id="companyName"
                    autoComplete="off"
                    type="text"
                    label="Nom de l'entreprise ou projet"
                    className={classes.fieldsetInput}
                    classNameInput={classes.input}
                    refInput={register}
                    fullWidth
                  />
                  <Typography variant="h3" display="block" className={cx(classes.title, classes.titleInput)}>Vous êtes réputé pour</Typography>
                  <TextareaForm
                    name="companySkills"
                    id="companySkills"
                    label="Decrivez brièvement votre activité"
                    className={classes.fieldsetInput}
                    refTextarea={register}
                    width="100%"
                    classNameArea={classes.input}
                  />
                  <Typography variant="h3" display="block" className={cx(classes.title, classes.titleInput)}>Vous ciblez</Typography>
                  <TextareaForm
                    name="target"
                    id="target"
                    label="Parlez-nous des personnes que vous voulez cibler"
                    className={classes.fieldsetInput}
                    refTextarea={register}
                    width="100%"
                    classNameArea={classes.input}
                  />
                  <Typography variant="h3" display="block" className={cx(classes.title, classes.titleInput)}>Votre site internet</Typography>
                  <TextareaForm
                    name="site"
                    id="site"
                    label="Indiquez l'URL de votre site si vous en possédez un"
                    className={classes.fieldsetInput}
                    refTextarea={register}
                    width="100%"
                    classNameArea={classes.input}
                  />
                </div>
                <div className={classes.wrapperDivider}>
                  <Divider className={classes.divider} orientation="vertical" />
                </div>
                <div className={classes.step3Right}>
                  <Typography variant="h3" display="block" className={cx(classes.title, classes.titleInput)}>Image de marque</Typography>
                  <SliderWithInfo name="cursorModern" onChange={handleChangeModern} value={cursorModern} infoLeft="Classique" infoRight="Moderne" className={classes.sliderInfo} />
                  <SliderWithInfo infoLeft="Jeune" name="cursorYoung" value={cursorYoung} onChange={handleChangeYoung} infoRight="Mature" className={classes.sliderInfo} />
                  <SliderWithInfo infoLeft="Drôle" name="cursorFun" value={cursorFun} onChange={handleChangeFun} infoRight="Serieuse" className={classes.sliderInfo} />
                  <SliderWithInfo infoLeft="Discount" name="cursorLuxury" value={cursorLuxury} onChange={handleChangeLuxury} infoRight="Luxueux" className={classes.sliderInfo} />
                  <Typography variant="h3" display="block" className={cx(classes.title, classes.titleInput)}>Message aux participants</Typography>
                  <TextareaForm
                    name="message"
                    id="message"
                    label="Message que vous voulez adresser aux participants de votre concours"
                    className={classes.fieldsetInput}
                    refTextarea={register}
                    width="100%"
                    classNameArea={classes.input}
                  />
                </div>
              </div>
              <div className={classes.formBottom} style={{ marginTop: '2rem' }}>
                <Button className={classes.btnContinuer} type="submit">Continuer</Button>
              </div>
            </form>
          </WrapperPageUser>
        ) : (
          <Redirect to="/organiser-concours/etape1" />
        )

      }
    </>
  );
};

Step2Concours.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  location: Proptypes.objectOf(
    Proptypes.oneOfType([
      Proptypes.string, Proptypes.object,
    ]),
  ),
  history: Proptypes.objectOf(
    Proptypes.oneOfType([
      Proptypes.number,
      Proptypes.func,
      Proptypes.object,
      Proptypes.string,
    ]),
  ),
};

export default withStep2(Step2Concours);
