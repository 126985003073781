import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import SendIcon from '@material-ui/icons/Send';
import {
  IconButton, List, ListItem, ListItemAvatar, Avatar, ListItemText, Typography,
} from '@material-ui/core';
import Header from '../../_organisms/Header/header.connect';
import InputForm from '../../_atoms/InputForm/input-form';
import Button from '../../_atoms/Button/button';
import WrapperPageUser from '../../../containers/WrapperPageUser/wrapper-page-user.connect';
import Logo from '../../../assets/Participant/logo.png';
import Illustration from '../../../assets/Participant/choix-3.png';

import withMessage from './message_old.style';
import MenuIcon from '@material-ui/icons/Menu';

class Message_old extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      open: false,
    };
    this.submitSearch = this.submitSearch.bind(this);
  }

  handleChangeSearch = (e) => {
    this.setState({
      search: e.target.value,
    });
  }

  submitSearch = (value) => () => {
    console.log(value);
  }

  handleToggleOpen = () => {
    const { open } = this.state;
    if (open) {
      this.setState({
        open: false,
      });
    } else {
      this.setState({
        open: true,
      });
    }
  }

  render() {
    const { history, classes } = this.props;
    const { search, open } = this.state;
    return (
      <>
        <Header title="Messages" history={history} />
        <WrapperPageUser>
          <div className={classes.root}>
            <section className={cx(open ? classes.wrapperLeft : classes.littleWidth)}>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="open drawer"
                onClick={this.handleToggleOpen}
              >
                <MenuIcon fontSize="large" titleAccess="Menu" />
              </IconButton>
              <div className={cx(open ? classes.wrapperSearch : classes.invisible)}>
                <InputForm
                  name="search"
                  autoComplete="off"
                  type="text"
                  label="Rechercher"
                  className={classes.fieldsetInput}
                  classNameInput={classes.inputSearch}
                  value={search}
                  onChange={this.handleChangeSearch}
                />
                <IconButton type="submit" className={classes.iconButtonSearch} aria-label="search" onClick={this.submitSearch(search)}>
                  <SendIcon fontSize="large" color="primary" />
                </IconButton>
              </div>
              <List className={classes.wrapperUser}>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar>
                      <img src={Logo} alt="avatar" className={classes.avatarImg} />
                    </Avatar>
                  </ListItemAvatar>
                  <div className={open ? classes.visible : classes.invisible}>
                    <ListItemText
                      primary="Demande de logo"
                      secondary="Bonjour oui cela est tout à fait possible"
                      classes={{
                        primary: classes.elementWeight,
                        secondary: classes.elementWeight,
                      }}
                      className={classes.fullWidth}
                    />
                    <ListItemText
                      primary="13:34"
                      className={classes.dateUser}
                      classes={{
                        primary: classes.dateUserText,
                      }}
                    />
                  </div>
                </ListItem>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar>
                      <img src={Illustration} alt="avatar" className={classes.avatarImg} />
                    </Avatar>
                  </ListItemAvatar>
                  <div className={open ? classes.visible : classes.invisible}>
                    <ListItemText
                      primary="Demande d'illustration"
                      secondary="Oui c'est fait"
                      classes={{
                        primary: classes.elementWeight,
                        secondary: classes.elementWeight,
                      }}
                      className={classes.fullWidth}
                    />
                    <ListItemText
                      primary="8/8/19"
                      className={classes.dateUser}
                      classes={{
                        primary: classes.dateUserText,
                      }}
                    />
                  </div>
                </ListItem>
              </List>
            </section>
            <section className={classes.wrapperRight}>
              <div className={classes.headerRight}>
                <div className={classes.concours}>
                  <Avatar>
                    <img src={Logo} alt="avatar" className={classes.avatarImg} />
                  </Avatar>
                  <div className={classes.concoursText}>
                    <Typography variant="h5" className={classes.titleConcours}>Demande de logo</Typography>
                    <Typography variant="body1" className={classes.propositionConcours}>Proposition N°13</Typography>
                  </div>
                </div>
                <Typography variant="h3" className={classes.infoConcours}>Concours cloturé, en attente des éléments</Typography>
                <Button>Valider</Button>
              </div>
              <div className={classes.messages}>
                <p>Hello</p>
              </div>
            </section>
          </div>
        </WrapperPageUser>
      </>
    );
  }
}

Message_old.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  history: Proptypes.objectOf(
    Proptypes.oneOfType([
      Proptypes.number,
      Proptypes.func,
      Proptypes.object,
      Proptypes.string,
    ]),
  ),
};

export default withMessage(Message_old);
