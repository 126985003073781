import { connect } from 'react-redux';
import { getStatutUser, addStatut } from '../../../../actions/user';

import Statut from './statut';

const mapStateToProps = ({ user }) => ({
  user: user.user,
  statutEntreprise: user.statutEntreprise,
});

export default connect(
  mapStateToProps,
  { getStatutUser, addStatut },
)(Statut);
