import React, { useEffect, useState } from 'react';

import { LinearProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { confirmationPayment } from '../../../actions/payment';
import useQuery from '../../../hooks/useQuery';

import WrapperPageInfo from '../../_organisms/PageInfoSuccess/page-info-success.connect';
import WrapperPageUser from '../../../containers/WrapperPageUser/wrapper-page-user.connect';

const ConfirmationPayment = () => {
  const dispatch = useDispatch();

  const [msgLocal, setMsgLocal] = React.useState('');
  const [title, setTitle] = React.useState('');
  const queryParams = useQuery();
  const idConcours = queryParams?.idConcours;
  const transactionId = queryParams?.transactionId;
  useEffect(() => {
    dispatch(confirmationPayment({
      idConcours,
      transactionId,
    }, ((res) => {
      setTitle(res.message);
      setMsgLocal('De nombreuses participations arriveront rapidement !');
      console.log(res);
    }), (err) => {
      console.log(err, 'err');
    }));
    console.log(idConcours, transactionId);
  }, []);

  const [openConnexion, setOpenConnexion] = useState(false);

  const handleOpenConnexion = () => {
    setOpenConnexion(true);
  };

  const handleCloseHeader = () => {
    setOpenConnexion(false);
  };

  return (
    <>
      {
        msgLocal ? (
          <WrapperPageUser>
            <WrapperPageInfo text={msgLocal} title={title} open={openConnexion} onClose={handleCloseHeader} onConnexion={handleOpenConnexion} />
          </WrapperPageUser>
        ) : (
          <LinearProgress />
        )
      }
    </>
  );
};

ConfirmationPayment.propTypes = {

};

export default ConfirmationPayment;
