import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import { Typography } from '@material-ui/core';
import withTileMessage from './tile-message.style';

// Type 1 --> Message
// Type 2 --> Info
// Type 3 --> Photo

const TileMessage = ({
  classes, text, classNamePaper, className, date,
}) => (
  <div className={cx(classes.root, className)}>
    <div className={cx(classes.wrapperPaper, classNamePaper)}>
      <Typography variant="body1" className={classes.text}>{text}</Typography>
    </div>
    <Typography variant="body2" className={classes.hour}>{date}</Typography>
  </div>
);

TileMessage.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  text: Proptypes.string,
  classNamePaper: Proptypes.string,
  className: Proptypes.string,
  myMessage: Proptypes.bool,
  date: Proptypes.string,
  typeMessage: Proptypes.number,
};

TileMessage.defaultProps = {
  typeMessage: 1,
};
export default withTileMessage(TileMessage);
