import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';
import { categoriesConcours } from '../../../constants/categories';
import withNavBarUser from './nav-bar-user.style';
import SearchCategories from '../SearchCategories/search-categories';
import TileNavbarUser from '../../_atoms/TileNavBarUser/tile-navbar-user';

const NavBarUser = ({ classes, className, onSubmit }) => (
  <nav className={cx(classes.root, className)}>
    <ul open className={classes.categories}>
      <SearchCategories onSubmit={onSubmit} classNameInputRoot={classes.searchInput} width="23rem" />
      {
          categoriesConcours.map((categorie) => (
            <li key={categorie.id} className={classes.listCategorie} onSubmit={onSubmit}>
              <TileNavbarUser category={categorie} />
            </li>
          ))
        }
    </ul>
  </nav>
);

NavBarUser.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  className: Proptypes.string,
  onSubmit: Proptypes.func,
};

export default withNavBarUser(NavBarUser);
