import React, { useEffect } from 'react';
import Proptypes from 'prop-types';

import { LinearProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { categoriesConcours } from '../../../constants/categories';

import Header from '../../_organisms/Header/header.connect';
import WrapperPageUser from '../../../containers/WrapperPageUser/wrapper-page-user.connect';
import SearchCategories from '../../_molecules/SearchCategories/search-categories';
import ListCategoryConcours from '../../_organisms/ListCategoryConcours/list-category-concours';
import withStep1Concours from './oragniserConcours.style';
import NavBarConcours from '../../_molecules/NavBarConcours/nav-bar-concours';
import { selectUser } from '../../../selectors/userSelectors';

const Step1Concours = ({
  classes, history,
}) => {
  const user = useSelector((state) => selectUser(state));

  useEffect(() => {
    if (user) {
      console.log(user, 'user');
      if (!user.ownerId || !user.payerId) {
        history.push({
          pathname: '/mon-profil/statut',
          state: {
            variant: 'warning',
            text: 'Merci de remplir les informations pour créer un concours !',
          },
        });
      }
    }
  }, [user]);

  const [categoryConcours, setCategoryConcours] = React.useState(categoriesConcours[0]);
  const handleClick = (category) => () => {
    history.push({
      pathname: '/organiser-concours/etape2',
      state: {
        category: category.title,
        costMini: category.price,
        format: category.format,
        idSubCategory: category.id,
      },
    });
  };

  const handleClickSearch = (searchValue) => {
    let subCategory = null;
    const categories = categoriesConcours.map((categorie) => categorie.subCategories.filter((subCategorie) => subCategorie.title.toLowerCase() === searchValue.toLowerCase()));
    categories.forEach((element) => {
      if (element.length > 0) {
        console.log(element);
        // eslint-disable-next-line prefer-destructuring
        subCategory = element[0];
      }
    });
    if (subCategory) {
      history.push({
        pathname: '/organiser-concours/etape2',
        state: {
          format: subCategory.format,
          category: subCategory.title,
          costMini: subCategory.price,
          idSubCategory: subCategory.id,
        },
      });
    }
  };

  const handleClickNavBar = (id) => {
    const findCategory = categoriesConcours.find((category) => category.id === id);
    setCategoryConcours(findCategory);
  };

  return (
    <>
      {
        user ? (
          <>
            <WrapperPageUser>
              <Header title="Etape 1: Séléctionner une catégorie" history={history} />
              <section className={classes.wrapBodyStep1}>
                <SearchCategories onSubmit={handleClickSearch} />
                <NavBarConcours onClickCategorie={handleClickNavBar} className={classes.navBarConcours} />
                {
                      !!categoryConcours && (
                        <ListCategoryConcours list={categoryConcours.subCategories} classNameRoot={classes.listCategoryConcours} onClick={handleClick} history={history} />
                      )
                    }
              </section>
            </WrapperPageUser>
          </>
        ) : (
          <LinearProgress />
        )
      }
    </>
  );
};

Step1Concours.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  history: Proptypes.objectOf(
    Proptypes.oneOfType([
      Proptypes.number,
      Proptypes.func,
      Proptypes.object,
      Proptypes.string,
    ]),
  ),
  loadUser: Proptypes.func,
  updateUser: Proptypes.func,
  msgSuccess: Proptypes.string,
};

export default withStep1Concours(Step1Concours);
