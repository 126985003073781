import React from 'react';
import ImageIcon from '@material-ui/icons/Image';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import VideocamIcon from '@material-ui/icons/Videocam';
import GifIcon from '@material-ui/icons/Gif';
import MessageIcon from '@material-ui/icons/Message';
import DeviceUnknownIcon from '@material-ui/icons/DeviceUnknown';
import Proptypes from 'prop-types';

const IconConcours = ({
  className, format, color, fontSize,
}) => {
  if (format === 1) {
    return (
      <ImageIcon color={color} fontSize={fontSize} className={className} />
    );
  }
  if (format === 2) {
    return (
      <VideocamIcon color={color} fontSize={fontSize} className={className} />
    );
  }
  if (format === 3) {
    return (
      <AudiotrackIcon color={color} fontSize={fontSize} className={className} />
    );
  }
  if (format === 4) {
    return (
      <GifIcon color={color} fontSize={fontSize} className={className} />
    );
  }
  if (format === 5) {
    return (
      <MessageIcon color={color} fontSize={fontSize} className={className} />
    );
  }
  return (
    <DeviceUnknownIcon color={color} fontSize={fontSize} className={className} />
  );
};

IconConcours.propTypes = {
  className: Proptypes.string,
  format: Proptypes.oneOfType([
    Proptypes.string,
    Proptypes.number,
  ]),
  color: Proptypes.string,
  fontSize: Proptypes.string,
};

IconConcours.defaultProps = {
  format: 10,
}

export default IconConcours;
