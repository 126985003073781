import { withStyles } from '@material-ui/core';

export default withStyles(({ breakpoints }) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '32rem',
    height: '35rem',
    borderRadius: '1rem',
    transition: '0.2s opacity ease-in-out',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: '0 11px 55px 10px rgba(0, 0, 0, 0.07), 0 13px 18px -8px rgba(0, 0, 0, 0.15)',
    },
    [breakpoints.down(750)]: {
      width: '100%',
    },
  },
  picture: {
    marginTop: '1rem',
    height: '40%',
  },
  title: {
    marginTop: '2rem',
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  detailsText: {
    fontSize: '1.8rem',
    color: '#555555',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    padding: '1rem',
  },
}));
