import React from 'react';
import Proptypes from 'prop-types';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { Divider, Typography } from '@material-ui/core';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { contentHomeGuest } from '../../../constants/homeGuest';

import bottomSvg from '../../../assets/SVG/Bottom.svg';
import checkSvg from '../../../assets/Icon/Icon_bottom_check.svg';
import illustration from '../../../assets/SVG/illustration-2.png';

import CarousselCategory from '../../_organisms/CarousselCategory/caroussel-category';
import { CardInformations, SubCardCategories } from '../../../constants/image';
import ListCardInformation from '../../_organisms/ListCardInformationProject/list-card-information-project';
import ItemWithIcon from '../../_molecules/ItemWithIcon/item-with-icon';
import Button from '../../_atoms/Button/button';
import CardNetwork from '../../_atoms/CardNetwork/card-network';
import withContentMiddleHome from '../Home/home.style';
import Testimonials from '../../_organisms/Testimonials/testimonials';

const ContentMiddleHome = ({ classes, onConnexion }) => (
  <>
    <article className={classes.concept} id="concept">
      <Typography
        variant="h2"
        className={classes.titleH2}
        display="block"
      >
        {contentHomeGuest.listCardInformation.title}
      </Typography>
      <div className={classes.listConcept}>
        <ListCardInformation
          className={classes.list}
          list={CardInformations}
        />
      </div>
    </article>
    <article className={classes.sliderCategories} id="sliderCategorie">
      <Typography
        variant="h2"
        className={classes.titleH2}
      >
        {contentHomeGuest.sliderCategories.title}
      </Typography>
      <Divider className={classes.divider} />
      <CarousselCategory list={SubCardCategories} classNameUl={classes.listCard} />
    </article>
    <article className={classes.network}>
      <div className={classes.leftNetwork}>
        <Typography
          variant="h2"
          className={cx(classes.titleH2, classes.noPadding)}
        >
          {contentHomeGuest.network.title}
        </Typography>
        <Typography variant="body1" className={classes.detailsTextNetwork}>{contentHomeGuest.network.detailsText}</Typography>
        <div className={classes.statsNumber}>
          <CardNetwork
            number={52}
            textInfo="Projets"
            icon={<EventAvailableIcon color="secondary" style={{ fontSize: '4rem' }} />}
          />
          <CardNetwork
            number={95}
            textInfo="Membres"
            icon={<AccountCircleIcon color="secondary" style={{ fontSize: '4rem' }} />}
          />
        </div>
      </div>
      <img className={classes.imageIllustration} src={illustration} alt="Illustration network" />
    </article>
    <article className={classes.informations} id="engagements">
      <Typography
        variant="h2"
        className={cx(classes.titleH2, classes.titleSatisfaction)}
        display="block"
      >
        {contentHomeGuest.satisfaction.title}
      </Typography>
      <div className={classes.listInformations}>
        <div className={classes.wrapperImg}>
          <img src={bottomSvg} alt="#" className={classes.img} />
        </div>
        <div className={classes.listContent}>
          {
              contentHomeGuest.satisfaction.list.map((textSatisfaction) => (
                <ItemWithIcon
                  icon={checkSvg}
                  text={textSatisfaction.title}
                  classNameRoot={classes.itemWithIcon}
                  width={26}
                  height={26}
                  key={textSatisfaction.key}
                />
              ))
            }
          <div className={classes.auth}>
            <Link to="/signup" className={classes.link}>
              <Button className={classes.btnInscription}>S&apos;inscrire</Button>
            </Link>
            <Typography className={classes.textAuth}>Vous avez déjà un compte ?
              <br />
              <button className={classes.textAuthConnection} onClick={onConnexion} type="button">Connectez vous</button>
            </Typography>
          </div>
        </div>
      </div>
    </article>
    <article className={classes.testimonials} id="temoignages">
      <Testimonials autoplay />
    </article>
  </>
);

ContentMiddleHome.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  onConnexion: Proptypes.func,
};

export default withContentMiddleHome(ContentMiddleHome);
