import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';
import { useTheme } from '@material-ui/core';

import withToggleButton from './toggle-buttons.style';
import Button from '../../_atoms/Button/button';

const ToggleButtons = ({
  classes, tabs, className, onClick, activeTab,
}) => {
  const theme = useTheme();

  return (
    <div className={cx(classes.containerBtn, className)}>
      {
        tabs.map((tab) => (
          <Button
            key={`tab-${tab.key}`}
            onClick={onClick(tab.key)}
            color={activeTab !== tab.key ? 'secondary' : 'default'}
            background={activeTab !== tab.key ? '#fefefe' : theme.palette.gradient.main}
            className={classes.btnTab}
            id={`tab-${tab.key}`}
          >
            {tab.label}
          </Button>
        ))
      }
    </div>
  );
};

ToggleButtons.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  tabs: Proptypes.array,
  className: Proptypes.string,
  activeTab: Proptypes.number,
  onClick: Proptypes.func,
};

ToggleButtons.defaultProps = {
  tabs: [{
    key: 0,
    label: 'Tout',
  },
  {
    key: 1,
    label: 'Organisateur',
  },
  {
    key: 2,
    label: 'Participant',
  },
  ],
  onClick: () => {},
};

export default withToggleButton(ToggleButtons);
