import { withStyles } from '@material-ui/styles';

export default withStyles({
  '@global': {
    '.MuiGrid-spacing-xs-8': {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '1rem',
    },
    '.PreviewList-smallPreviewImg-456': {
      height: 'inherit',
    },
  },
});
