import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageProgress from 'react-page-progress';
import Proptypes from 'prop-types';

import { useHistory, useLocation } from 'react-router-dom';
import { loadUser } from '../../actions/user';

import Footer from '../../components/_organisms/Footer/footer.connect';
import LinearProgress from '../../components/_atoms/LinearProgress/linearProgress';

import withApp from './App.style';
import ScrollToTop from '../ScrollToTop';
import './App.css';
import { clearErrors } from '../../actions/errorActions';
import SnackBarBottom from '../../components/_atoms/SnackBarBottom/snack-bar-bottom';
import { clearSuccess } from '../../actions/successActions';
// import Header from '../../components/_organisms/Header/header';

const App = ({
  children,
}) => {
  const location = useLocation();
  const history = useHistory();
  const accessToken = useSelector((state) => state.user.token);
  const userFetched = useSelector((state) => state.user.fetched);
  const error = useSelector((state) => state.error.msg);
  const success = useSelector((state) => state.success.msg);
  const dispatch = useDispatch();

  useEffect(() => {
    document.oncontextmenu = () => true; // Permettre le clique droit
    if (!userFetched) {
      dispatch(loadUser());
    }
  }, [accessToken, userFetched]);

  useEffect(() => {
    if (!accessToken && location.pathname !== '/') {
      history.push('/');
    }
  }, [accessToken]);

  useEffect(() => {
    let timer = null;
    if (error) {
      timer = setTimeout(() => dispatch(clearErrors()), 3000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [error]);

  useEffect(() => {
    let timer = null;
    if (success) {
      timer = setTimeout(() => dispatch(clearSuccess()), 3000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [success]);

  const isFetched = useMemo(() => (
    userFetched
  ), [userFetched]);
  return (
    <>
      <ScrollToTop />
      <SnackBarBottom
        text={error}
        open={!!error}
        variant="error"
        onClose={() => dispatch(clearErrors())}
      />
      <SnackBarBottom
        text={success}
        open={!!success}
        variant="success"
        onClose={() => dispatch(clearSuccess())}
      />
      {
        isFetched === false ? (
          <LinearProgress />
        ) : (
          <>
            <main style={{ minHeight: '70vh' }}>
              <PageProgress color="linear-gradient(82deg, rgba(253,127,85,1) 0%, rgba(252,81,101,1) 100%)" />

              {/*
                user.isAuthenticated ? (
                  <Header position="static" />
                ) : (
                  <Header needContainerHeader sticky={false} onConnexion={handleOpenConnexion} open={openConnexion} onClose={handleCloseHeader} />
                )
              */}
              {children}
            </main>
            <Footer />
          </>
        )
      }
    </>
  );
};

App.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  children: Proptypes.node,
};

export default withApp(App);
