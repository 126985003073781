import {
  FETCH_CREATE_MESSAGE,
  FETCH_CREATE_MESSAGE_FULFILLED,
  FETCH_CREATE_MESSAGE_ERROR,
  FETCH_READ_MESSAGES_BY_USER,
  FETCH_READ_MESSAGES_BY_USER_FULFILLED,
  FETCH_READ_MESSAGES_BY_USER_ERROR,
  FETCH_READ_MESSAGES_BY_PARTICIPATIONS,
  FETCH_READ_MESSAGES_BY_PARTICIPATIONS_FULFILLED,
  FETCH_READ_MESSAGES_BY_PARTICIPATIONS_ERROR,
  FETCH_READ_MESSAGES_BY_CONCOURS,
  FETCH_READ_MESSAGES_BY_CONCOURS_FULFILLED,
  FETCH_READ_MESSAGES_BY_CONCOURS_ERROR,
  FETCH_READ_MESSAGES_BY_ID_PARTICIPATION,
  FETCH_READ_MESSAGES_BY_ID_PARTICIPATION_FULFILLED,
  FETCH_READ_MESSAGES_BY_ID_PARTICIPATION_ERROR,
  FETCH_ADD_FILE_MESSAGE,
  FETCH_ADD_FILE_MESSAGE_FULFILLED,
  FETCH_ADD_FILE_MESSAGE_ERROR,
} from '../constants/actionTypes';

const initialState = {
  fetching: false,
  fetched: false,
  isLoading: false,
  userMessages: null,
  concoursMessages: null,
  participationsMessages: null,
  messagesById: null,
};

export default function messageReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CREATE_MESSAGE:
    case FETCH_READ_MESSAGES_BY_USER:
    case FETCH_READ_MESSAGES_BY_CONCOURS:
    case FETCH_READ_MESSAGES_BY_PARTICIPATIONS:
    case FETCH_READ_MESSAGES_BY_ID_PARTICIPATION:
    case FETCH_ADD_FILE_MESSAGE:
      return {
        ...state,
        fetching: true,
        isLoading: true,
      };
    case FETCH_CREATE_MESSAGE_FULFILLED:
    case FETCH_ADD_FILE_MESSAGE_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        isLoading: false,
      };
    case FETCH_READ_MESSAGES_BY_USER_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        userMessages: action.payload,
        isLoading: false,
      };
    case FETCH_READ_MESSAGES_BY_CONCOURS_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        concoursMessages: action.payload,
        isLoading: false,
      };
    case FETCH_READ_MESSAGES_BY_PARTICIPATIONS_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        participationsMessages: action.payload,
        isLoading: false,
      };
    case FETCH_READ_MESSAGES_BY_ID_PARTICIPATION_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        messagesById: action.payload,
        isLoading: false,
      };
    case FETCH_CREATE_MESSAGE_ERROR:
      return {
        ...state,
        fetching: false,
        isLoading: false,
        messageCreateSuccess: '',
      };
    case FETCH_ADD_FILE_MESSAGE_ERROR:
      return {
        ...state,
        fetching: false,
        isLoading: false,
        messageAddFileSuccess: '',
      };
    case FETCH_READ_MESSAGES_BY_USER_ERROR:
      return {
        ...state,
        fetching: false,
        isLoading: false,
        userMessages: null,
      };
    case FETCH_READ_MESSAGES_BY_PARTICIPATIONS_ERROR:
      return {
        ...state,
        fetching: false,
        isLoading: false,
        participationsMessages: null,
      };

    case FETCH_READ_MESSAGES_BY_CONCOURS_ERROR:
      return {
        ...state,
        fetching: false,
        isLoading: false,
        concoursMessages: null,
      };
    case FETCH_READ_MESSAGES_BY_ID_PARTICIPATION_ERROR:
      return {
        ...state,
        fetching: false,
        isLoading: false,
        messagesById: null,
      };
    default:
      return state;
  }
}
