import { withStyles } from '@material-ui/styles';

export default withStyles(({ palette, breakpoints }) => ({
  root: {
    maxWidth: '50rem',
    width: '80vw',
    margin: '3rem auto',
  },
  paperInfoPerso: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paperTitle: {
    fontSize: '1.6rem',
    marginTop: '-12rem',
    marginLeft: '-2rem',
    [breakpoints.down(600)]: {
      marginTop: '-20vw',
      fontSize: '1rem',
    },
  },
  informations: {
    display: 'inline-flex',
    margin: '2rem 0',
    width: '100%',
  },
  wrapperPwd: {
    display: 'flex',
    paddingRight: '2rem',
    alignItems: 'baseline',
  },
  textField: {
    margin: '0 2rem',
    width: '60%',
  },
  resetPass: {
    fontSize: '1.2rem',
    fontWeight: 500,
    color: palette.secondary.main,
  },
  '@global': {
    '.MuiInput-underline:before': {
      borderColor: palette.grey[200],
    },
  },

  buttons: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: '3rem  0',
  },
  btnSave: {
    width: '12rem',
    height: '3rem',
    background: palette.grey[200],
    color: palette.grey[800],
  },
  btnLogout: {
    width: '12rem',
    height: '3rem',
  },
  btnResetPass: {
    width: '12rem',
    height: '3rem',
    background: 'transparent',
    color: palette.primary.main,
  },
  textBtn: {
    fontSize: '1.2rem',
  },
  paperWallet: {
    margin: '2rem 0',
    paddingBottom: '2rem',
  },
  wrapperTitleWallet: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '90%',
    padding: '1rem 0',
    margin: '0 auto',
  },
  titleWallet: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    color: palette.grey[800],
  },
  iconButtonSettings: {
    padding: 0,
  },
  iconSettings: {
    fill: palette.grey[500],
  },
  wrapperInfoWallet: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '90%',
    margin: '0 auto',
    [breakpoints.down(500)]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  wrapperTitleInfoWallet: {
    display: 'flex',
    alignItems: 'center',
  },
  titleInfoWallet: {
    fontSize: '1.2rem',
    color: palette.grey[500],
    fontWeight: 'bold',
  },
  amountWallet: {
    color: palette.secondary.main,
    fontSize: '1.6rem',
    fontWeight: 'bold',
    marginLeft: '1rem',
  },
  btnTransfert: {
    marginLeft: '1rem',
    width: '12rem',
    height: '3rem',
    [breakpoints.down(500)]: {
      marginTop: '1rem',
    },
  },
}));
