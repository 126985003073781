import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  IconButton,
  Typography,
} from '@material-ui/core';

import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

import withExpandPannel from './expand-pannel-text.style';

const ExpandPannelText = ({
  classes, className, title, details, icon, iconExpanded,
}) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <ExpansionPanel
      className={cx(classes.root, className)}
      expanded={expanded}
      onChange={handleChange}
    >
      <ExpansionPanelSummary
        expandIcon={(
          <IconButton aria-label="expandIcon" color="inherit" style={{ padding: 0 }}>
            {expanded ? iconExpanded : icon}
          </IconButton>
        )}
        className={cx(expanded && classes.expansionSummaryExpanded)}
      >
        <Typography variant="h3" className={classes.titleText}>{title}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.expansionDetails}>
        <Typography variant="body1" className={classes.detailsText}>{details}</Typography>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

ExpandPannelText.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  title: Proptypes.string,
  details: Proptypes.string,
  icon: Proptypes.node,
  iconExpanded: Proptypes.node,
  className: Proptypes.string,
};

ExpandPannelText.defaultProps = {
  icon: <AddIcon />,
  iconExpanded: <RemoveIcon />,
};

export default withExpandPannel(ExpandPannelText);
