import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import {
  FormControlLabel, Radio, RadioGroup, Typography,
} from '@material-ui/core';
import withCoord from '../statut.style';
import Coord from '../Coord/coord';
import DatePicker from '../../../../_molecules/DatePicker/date-picker';

const Beneficiaire = ({
  classes,
  className,
  title,
  displayStatutBirthday,
  displayUbo,
  firstName,
  lastName,
  nationality,
  birthday,
  address,
  city,
  country,
  zip,
  statutBeneficiaire,
  onChangeChoixBeneficiaire,
  onChangeFirstName,
  onChangeLastName,
  onChangeNationality,
  onChangeBirthday,
  onChangeAddress,
  onChangeCity,
  onChangeCountry,
  onChangeZip,
  statut,
}) => (
  <div className={className}>
    <Typography variant="h2" className={classes.subTitle}>{title}</Typography>
    <div className={classes.center}>
      <Coord
        infoPerso
        firstName={firstName}
        lastName={lastName}
        nationality={nationality}
        onChangeFirstName={onChangeFirstName}
        onChangeLastName={onChangeLastName}
        onChangeNationality={onChangeNationality}
      />
    </div>
    <div className={classes.wrapperBirthday}>
      <Typography variant="h3" className={classes.subSubTitle}>Date de naissance</Typography>
      {
            displayStatutBirthday && (
              <Typography variant="body1" className={cx(classes.text, classes.textLegalisation)}>{statut.birthday}</Typography>
            )
          }
      <DatePicker
        className={classes.datePicker}
        value={birthday}
        onChange={onChangeBirthday}
        label="Date de naissance"
        maxDate={new Date()}
        minDate={new Date('1900')}
      />
    </div>
    <div className={classes.marginTop2}>
      <Typography variant="h3" className={classes.subSubTitle}>Adresse de résidence</Typography>
      <div className={classes.center}>
        <Coord
          isAddressPerso
          address={address}
          city={city}
          country={country}
          zip={zip}
          onChangeAddress={onChangeAddress}
          onChangeCity={onChangeCity}
          onChangeCountry={onChangeCountry}
          onChangeZip={onChangeZip}
        />
      </div>
    </div>
    {
      displayUbo && (
        <div className={classes.marginTop2}>
          <Typography variant="h2" className={classes.subTitle}>Déclaration UBO</Typography>
          <Typography variant="body1" className={classes.text}>{statut.ubo.intro}</Typography>
          <RadioGroup aria-label="Nombre-bénéficiaire" name="nb-beneficiaire" value={statutBeneficiaire} onChange={onChangeChoixBeneficiaire}>
            {
              statut.ubo.statutBeneficiaire.map((item) => (
                <FormControlLabel key={item.key} value={item.value} control={<Radio />} label={item.text} classes={{ label: classes.radioLabel }} className={classes.formControlLabel} />
              ))
            }
          </RadioGroup>
        </div>
      )
    }
  </div>
);

Beneficiaire.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  className: Proptypes.string,
  title: Proptypes.string,
  displayStatutBirthday: Proptypes.bool,
  displayUbo: Proptypes.bool,
  firstName: Proptypes.string,
  lastName: Proptypes.string,
  nationality: Proptypes.string,
  birthday: Proptypes.any, // TODO: Voir son type
  address: Proptypes.string,
  city: Proptypes.string,
  country: Proptypes.string,
  zip: Proptypes.string,
  statutBeneficiaire: Proptypes.oneOfType([
    Proptypes.number, Proptypes.string,
  ]),
  onChangeFirstName: Proptypes.func,
  onChangeLastName: Proptypes.func,
  onChangeNationality: Proptypes.func,
  onChangeBirthday: Proptypes.func,
  onChangeAddress: Proptypes.func,
  onChangeCity: Proptypes.func,
  onChangeCountry: Proptypes.func,
  onChangeZip: Proptypes.func,
  onChangeChoixBeneficiaire: Proptypes.func,
  statut: Proptypes.object,
};

Beneficiaire.defaultProps = {
  displayStatutBirthday: true,
  displayUbo: false,
  statutBeneficiaire: 0,
};

export default withCoord(Beneficiaire);
