import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, BrowserRouter } from 'react-router-dom';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';

import store from './store';
import AppRoutes from './routes';

// THEMES
import muiTheme from './themes/muiTheme';

import './index.css';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <MuiThemeProvider theme={muiTheme}>
        <CssBaseline />
        <Route component={AppRoutes} />
      </MuiThemeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);
