import { withStyles } from '@material-ui/core';

export default withStyles(({ spacing, breakpoints }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  slider: {
    margin: '0 auto',
    minWidth: (props) => props.onlyOneCard && '12rem', // car les cards des participations ont une taille min de 12rem
    minHeight: (props) => props.onlyOneCard && '12rem',
    [breakpoints.up('md')]: { // 4 cards
      width: (props) => (props.onlyOneCard ? '15vw' : '100rem'),
      height: (props) => (props.onlyOneCard && '15vw'),
    },
    [breakpoints.up('lg')]: { // 5 cards
      width: (props) => (props.onlyOneCard ? '15vw' : '123rem'),
      height: (props) => (props.onlyOneCard && '15vw'),
    },
    [breakpoints.down('sm')]: { // 3 cards
      width: (props) => (props.onlyOneCard ? '15vw' : '73rem'),
      height: (props) => (props.onlyOneCard && '15vw'),
    },
    [breakpoints.down(800)]: { // 2 cards
      width: (props) => (props.onlyOneCard ? '15vw' : '48rem'),
      height: (props) => (props.onlyOneCard && '15vw'),
    },
    [breakpoints.down(600)]: { // 2 cards
      width: (props) => (props.onlyOneCard ? '15vw' : '85vw'),
      height: (props) => (props.onlyOneCard && '15vw'),
    },
    position: 'relative',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
  },
  sliderContent: {
    overflow: 'hidden',
    fontSize: 0,
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
  },
  list: {
    margin: 0,
    padding: 0,
    display: 'flex',
    whiteSpace: 'nowrap',
    transition: 'margin 1s ease 0s',
  },
  arrows: {
    display: 'flex',
    pointerEvents: 'none',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'absolute',
    width: '100%',
    top: '45%',
    left: 0,
  },
  btnArrow: {
    margin: 0,
    padding: 0,
    background: 'none',
    border: 'none',
    pointerEvents: (props) => (props.numberOfCards <= 1 ? 'none' : 'auto'),
    display: (props) => (props.numberOfCards <= 1 && 'none'),
    transition: '0.2s opacity ease-in-out',
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  btnArrowRight: {
    // marginLeft: (props) => (props.onlyOneCard ? 0 : '-2rem'), // A cause du margin dans les cards
    [breakpoints.up('lg')]: {
      marginLeft: 0,
    },
    [breakpoints.down('sm')]: { // 3 cards
      marginLeft: 0,
    },
  },
  arrow: {
    display: 'flex',
    pointerEvents: 'initial',
    margin: 0,
    padding: 0,
    width: 0,
    position: 'relative',
    left: -spacing(3),
    zIndex: 'auto',
  },
  arrowIcon: {
    width: '5rem',
  },
  btnValider: {
    marginTop: '2rem',
    transition: '0.2s opacity ease-in-out',
    '&:hover': {
      opacity: 0.8,
    },
  },
}));
