import { withStyles } from '@material-ui/styles';

export default withStyles(({ palette, shadows, spacing }) => ({
  connexionReseau: {
    // marginTop: '3rem',
  },
  noDisplay: {
    display: 'none !important',
  },
  wrapperTextForget: {
    width: '100%',
  },
  titleForgetPassword: {
    fontSize: '2rem',
    textAlign: 'center',
    color: palette.grey[800],
  },
  bodyForgetPassword: {
    marginTop: '1rem',
    fontSize: '1.4rem',
    textAlign: 'center',
  },
  btn: {
    display: 'block',
    marginTop: '1.5rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    transition: '0.2s background-color ease-in-out',
    boxShadow: '5px 2px 4px 0 rgba(0, 0, 0, 0.5)',
  },
  btnForget: {
    width: '6rem',
    border: 'none',
    background: 'none',
    fontSize: '1.2rem',
    fontWeight: 500,
    color: palette.secondary.main,
    '&:hover': {
      color: palette.secondary.light,
      cursor: 'pointer',
    },
  },
  btnFb: {
    marginTop: 0,
    background: '#3b579d',
    '&:hover': {
      background: 'rgba(59,87,157,0.81)',
    },
  },
  btnGo: {
    background: '#4285f4',
    '&:hover': {
      background: 'rgba(66,133,244,0.8)',
    },
  },
  divider: {
    marginTop: '2.5rem',
    marginBottom: '2rem',
  },
  form: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  textField: {

  },
  btnConnexion: {
    margin: '1.5rem auto',
    transition: '0.2s opacity ease-in-out',
    boxShadow: '5px 2px 4px 0 rgba(0, 0, 0, 0.5)',
    '&:hover': {
      opacity: '0.8',
    },
  },
  password: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapperOublie: {
    position: 'relative',
    left: '-6rem',
    width: 0,
  },
  oublie: {
    width: '8rem',
    fontSize: '1.2rem',
    fontWeight: 500,
    color: palette.secondary.main,
    '&:hover': {
      color: palette.secondary.light,
      cursor: 'pointer',
    },
  },
  textNotMember: {
    fontSize: '1.4rem',
    textAlign: 'center',
    fontWeight: 500,
    color: '#7e8085',
  },
  textInscription: {
    color: palette.secondary.main,
  },
  link: {
    textDecoration: 'none',
  },
}));
