import { withStyles } from '@material-ui/styles';

export default withStyles({
  root: {
    width: (props) => props.width || '15rem',
    height: (props) => props.height || '4rem',
    color: (props) => props.color,
    background: (props) => props.background,
    transition: '0.2s opacity ease-in-out',
    '&:hover': {
      opacity: 0.8,
    },
  },
  label: {

  },
});
