import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import { Typography } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination/Pagination';
import { categoriesConcours } from '../../../constants/categories';
import { gainForWinner } from '../../../helpers/concours';
import NavCategories from '../NavCategories/nav-categories';
import CardInformationConcours from '../../_molecules/CardInformationConcours/card-information-concours';
import { parseDateConcoursAgo, parseDateConcoursEnd } from '../../../helpers/parse';
import CarousselCategory from '../CarousselCategory/caroussel-category';
import LinearProgress from '../../_atoms/LinearProgress/linearProgress';
import PaperInformations from '../PaperInformations/paper-informations';

import withDisplayConcours from './display-concours.style';
import FilterConcours from '../../_molecules/FilterConcours/filter-concours';
import { triConcours } from '../../../constants/concours';

const DisplayConcours = ({
  classes,
  concours,
  classNameCategories,
  sliderCategories,
  list,
  history,
  onClickCarousselCategory,
  titleConcours,
  error,
  children,
  isAllConcours,
  categoryName,
  nbrCardToDisplay,
  user,
  onChangeFilter,
  page,
  onChangePage,
}) => {
  const today = new Date();
  const handleClickSearch = (searchValue) => {
    let to = '';
    const categories = categoriesConcours.map((categorie) => categorie.subCategories.filter((subCategorie) => subCategorie.title.toLowerCase() === searchValue.toLowerCase()));
    categories.forEach((element) => {
      if (element.length > 0) {
        console.log(element);
        to = element[0].to;
      }
    });
    if (to) {
      history.push({
        pathname: `concours/${to}`,
      });
    }
  };

  const isParticipate = (participations) => {
    const array = participations.filter((element) => element.isParticipating);
    if (!array) return false;
    for (const participation of array) {
      if (participation?.idParticipant === user?.id) return true;
    }
    return false;
  };

  const isConcoureur = (idConcoureur) => {
    let result = false;
    if (idConcoureur === user?.id) result = true;

    return result;
  };

  const handleClickInformation = (singleConcours) => () => {
    console.log(singleConcours, 'singleConcours');
    if (user.id === singleConcours.idConcoureur) {
      history.push({
        pathname: `/participations/${singleConcours.id}`,
      });
    } else {
      history.push({
        pathname: `/details-concours/${singleConcours.id}`,
      });
    }
  };

  const numberConcoursDisplay = (page - 1) * nbrCardToDisplay;
  const nbrPage = concours ? Math.ceil(concours.length / nbrCardToDisplay) : 0;

  return (
    <>
      <section className={cx(classes.categories, classNameCategories)}>
        <NavCategories onSubmitSearch={handleClickSearch} />
      </section>
      {
        sliderCategories && (
          <section className={classes.sliderCategories}>
            <Typography variant="h2" className={classes.titleSlider}>Les concours auquelles vous aimerez participer ? </Typography>
            <CarousselCategory classNameRoot={classes.listCard} onlyTitle list={list} onClickForUser={onClickCarousselCategory} />
          </section>
        )
      }
      {
        concours ? (
          <>
            {
              concours.length > 0 && !error ? (
                <section className={classes.sectionConcours}>
                  <div style={{ marginTop: '2rem' }}>
                    <Typography variant="h3" className={classes.title}>{titleConcours}</Typography>
                    <article className={classes.articleConcours}>
                      <FilterConcours
                        className={classes.filter}
                        list={triConcours}
                        onChangeFilter={onChangeFilter}
                      />
                      <div className={classes.concours}>
                        {
                          concours.map((singleConcours, index) => (
                            <React.Fragment key={singleConcours.id}>
                              {
                                index >= numberConcoursDisplay && index < (numberConcoursDisplay + nbrCardToDisplay) && (
                                  <CardInformationConcours
                                    className={classes.cardInformationConcours}
                                    onClick={handleClickInformation(singleConcours)}
                                    key={singleConcours.id}
                                    format={singleConcours.subCategory.format}
                                    gain={gainForWinner(singleConcours.cost)}
                                    nbProposition={(singleConcours.participations && singleConcours.participations.length)}
                                    category={singleConcours.subCategory.name}
                                    message={singleConcours.message}
                                    end={parseDateConcoursEnd(today, singleConcours.endDate)}
                                    time={parseDateConcoursAgo(singleConcours.createdAt, today)}
                                    isParticipate={isParticipate(singleConcours.participations)}
                                    isConcoureur={isConcoureur(singleConcours.idConcoureur)}
                                  />
                                )
                              }
                            </React.Fragment>
                          ))
                        }
                      </div>
                      <Pagination
                        count={nbrPage}
                        page={page}
                        onChange={onChangePage}
                        color="primary"
                        variant="outlined"
                      />
                      {children}
                    </article>
                  </div>
                  {/* }
                  <aside className={classes.asideHowDid}>
                    <HowDid displayArrow />
                  </aside> { */}
                </section>
              ) : (
                <PaperInformations text={`Aucun concours pour le moment ${!isAllConcours ? `pour la catégorie ${categoryName}` : ''}`} />
              )
            }
          </>
        ) : (
          <LinearProgress />
        )
      }
    </>
  );
};

DisplayConcours.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  childen: Proptypes.node,
  history: Proptypes.objectOf(
    Proptypes.oneOfType([
      Proptypes.number,
      Proptypes.func,
      Proptypes.object,
      Proptypes.string,
    ]),
  ),
  onClickCarousselCategory: Proptypes.func,
  sliderCategories: Proptypes.bool,
  classNameCategories: Proptypes.string,
  titleConcours: Proptypes.string,
  list: Proptypes.array,
  concours: Proptypes.oneOfType([
    Proptypes.array,
    Proptypes.object,
  ]),
  nbrCardToDisplay: Proptypes.number,
  children: Proptypes.node,
  error: Proptypes.string,
  isAllConcours: Proptypes.bool,
  categoryName: Proptypes.string,
  user: Proptypes.object,
  onChangeFilter: Proptypes.func,
  page: Proptypes.number,
  onChangePage: Proptypes.func,
};

DisplayConcours.defaultProps = {
  sliderCategories: false,
  onClickCarousselCategory: () => {},
  nbrCardToDisplay: 4,
  error: '',
  isAllConcours: true,
  categoryName: '',
  onChangeFilter: () => {},
  onChangePage: () => {},
  page: 1,
};

export default withDisplayConcours(DisplayConcours);
