import { withStyles } from '@material-ui/styles';
import motOrganisateur from '../../../assets/Fond/motOrganisateur.png';

export default withStyles(({ palette, breakpoints, spacing }) => ({
  root: {

  },
  wrapperInfosConcours: {
    display: 'flex',
    [breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  left: {
    flex: 5,
    height: '100%',
    padding: '2rem',
    boxShadow: '0 14px 23px 4px rgba(0, 0, 0, 0.03)',
    [breakpoints.down('sm')]: {
      boxShadow: 'none',
    },
  },
  title: {
    fontSize: '2rem',
    color: palette.grey[800],
  },
  fieldsetInput: {
    width: '90%',
    margin: '2rem',
    pointerEvents: (props) => (props.lockedInput ? 'none' : 'auto'),
  },
  sliders: {
    marginBottom: '3rem',
  },
  slider: {
    marginTop: '2rem',
    pointerEvents: (props) => (props.lockedInput ? 'none' : 'auto'),
  },
  right: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    flex: 3,
    height: '100%',
    marginLeft: '3rem',
    padding: '2rem',
    [breakpoints.down('sm')]: {
      marginLeft: 0,
      paddingTop: '1rem',
    },

  },
  paperMessage: {
    paddingBottom: '2rem',
    width: '30rem',
    borderRadius: '2rem',
    boxShadow: '0 14px 23px 4px rgba(0, 0, 0, 0.03)',
    [breakpoints.down('sm')]: {
      paddingTop: 0,
      marginBottom: '2rem',
    },
  },
  topPaperMessage: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  img: {

  },
  infoUser: {
    textAlign: 'center',
    marginLeft: '4rem',
  },
  pseaudo: {
    color: palette.grey[800],
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
  dateCreation: {
    fontWeight: 'bold',
    color: palette.grey[400],
  },
  motOrganisateur: {
    borderTopRightRadius: '6px',
    background: `url(${motOrganisateur})`,
    width: '153px',
    height: '58px',
    backgroundRepeat: 'no-repeat',
  },
  textMotOrganisateur: {
    color: palette.common.white,
    fontWeight: 'bold',
    fontSize: '1.2rem',
    textAlign: 'center',
    marginTop: '8px',
    marginLeft: '7px',
  },
  message: {
    marginTop: '2rem',
    color: palette.grey[600],
    fontSize: '1.2rem',
    textAlign: 'center',
    fontWeight: 'bold',
    padding: '0 1rem',
    [breakpoints.down('sm')]: {
      paddingBottom: 0,
    },
  },
  wrapperHisParticipation: {
    padding: '2rem',
  },
  cardFormatText: {
    width: '80vw',
    margin: 'auto',
    padding: '0 1rem',
  },
  listParticipations: {
    marginTop: '4rem',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  participation: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerDetailsEngagParticipant: {
    width: '80%',
  },
  infoConcours: {
    marginTop: '2rem',
    boxShadow: '0 14px 23px 4px rgba(0, 0, 0, 0.03)',
  },
  wrapperTextEngagParticipant: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  textEngagParticipantValue: {
    fontWeight: 'bold',
    color: palette.grey[800],
    fontSize: '1.2rem',
    lineHeight: '2rem',
    textAlign: 'center',
  },
  textInfoEngagConcoureur: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: palette.grey[500],
    textAlign: 'center',
    paddingLeft: spacing(4),
    paddingRight: spacing(4),
    marginTop: '1rem',
    whiteSpace: 'break-spaces',
  },
  wrapperTextEngagConcoureur: {
    marginTop: '1rem',
    padding: '0 1rem',
  },
  textEngagConcoureurValue: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '1.2rem',
    color: palette.secondary.main,
  },
  textAreaField: {
    boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.2)',
    transition: 'all .3s ease-in-out',
    '&:hover': {
      opacity: 0.7,
      boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.2)',
      transition: 'all .3s ease-in-out',
    },
  },
  textArea: {
    '&::placeholder': {
      color: `${palette.grey[500]} !important`,
    },
  },
  btnParticiper: {
    textAlign: 'center',
    margin: '2rem auto',
  },
  btnNoPointerEvent: {
    pointerEvents: 'none',
    background: palette.grey[500],
  },
}));
