import { connect } from 'react-redux';
import { readMessageByConcours, readMessageByParticipations } from '../../../../actions/message';
import MessageFirstStep from './message-first-step';

const mapStateToProps = ({ messages }) => ({
  messages,
});

export default connect(
  mapStateToProps,
  { readMessageByConcours, readMessageByParticipations },
)(MessageFirstStep);
