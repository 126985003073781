import React, { useEffect } from 'react';
import Proptypes from 'prop-types';
import ContentTopHome from './content-top-home';
import ContentMiddleHome from './content-middle-home';
import withHome from '../Home/home.style';
import Header from '../../_organisms/Header/header';

const HomeGuest = ({ classes }) => {
  const [open, setOpen] = React.useState(false);
  const [sticky, setSticky] = React.useState(false);

  const handleScroll = () => {
    document.scrollingElement.scrollTop > 200
      ? setSticky(true)
      : setSticky(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, [sticky]);


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root} id="root">
      <section className={classes.top}>
        <Header position="static" sticky={sticky} open={open} onConnexion={handleOpen} onClose={handleClose} classNameHeaderGuest={classes.header} needContainerHeader={false} />
        <ContentTopHome className={classes.topBody} />
      </section>
      <section className={classes.middleBody}>
        <ContentMiddleHome onConnexion={handleOpen} />
      </section>
    </div>
  );
};

HomeGuest.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  history: Proptypes.objectOf(
    Proptypes.oneOfType([
      Proptypes.number,
      Proptypes.func,
      Proptypes.object,
      Proptypes.string,
    ]),
  ),
};

export default withHome(HomeGuest);
