import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import FormatQuoteIcon from '@material-ui/icons/FormatQuote';

import { Typography } from '@material-ui/core';
import withTileTestimonial from './tile-testimonial.style';

const TileTestimonial = React.forwardRef(({
  classes, className, title, source,
}, ref) => (
  <div className={cx(classes.root, className)} ref={ref}>
    <FormatQuoteIcon color="secondary" style={{ fontSize: '6rem' }} />
    <Typography variant="h3" className={classes.title}>{title}</Typography>
    <Typography className={classes.source}>{source}</Typography>
  </div>
));

TileTestimonial.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  className: Proptypes.string,
  title: Proptypes.string,
  source: Proptypes.string,
  ref: Proptypes.node,
};

export default withTileTestimonial(TileTestimonial);
