import { withStyles } from '@material-ui/styles';

export default withStyles(({
  palette,
}) => ({
  btn: {
    background: 'none',
    border: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  link: {
    textDecoration: 'none',
    color: palette.grey[800],
  },
  titleCategorie: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    padding: '1rem',
    '&:hover': {
      background: palette.grey[200],
    },
  },
  subCategoryLink: {
    color: palette.grey[800],
  },
}));
