import React from 'react';
import Proptypes from 'prop-types';
import cx from 'classnames';

import { Typography } from '@material-ui/core';
import withBulleNumber from './bulle-number.style';

const BulleNumber = ({ classes, className, text }) => (
  <div className={cx(classes.root, className)}>
    <Typography variant="h2" className={classes.text} color="secondary">{text}</Typography>
  </div>
);

BulleNumber.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  className: Proptypes.string,
  text: Proptypes.oneOfType([
    Proptypes.string, Proptypes.number,
  ]),
};

export default withBulleNumber(BulleNumber);
