import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import { Typography } from '@material-ui/core';
import TabPanel from '../../_molecules/TabPanel/tab-pannel';
import TablePerso from '../../_molecules/Table/table';

import withTabs from './tabs.style';
import ToggleButtons from '../../_molecules/ToggleButtons/toggle-buttons';

const TabsPerso = ({
  classes, tabs, tabsPannel, className, boxShadow, titleTabs, facture, toConcours, history,
}) => {
  const [activeTab, setActiveTab] = React.useState(0);

  const handleChange = (newValue) => () => {
    setActiveTab(newValue);
  };

  return (
    <div className={cx(!!titleTabs && classes.root, className)}>
      <div className={cx(classes.containerTabs, !!titleTabs && classes.containerTabs2)}>
        {
          !!titleTabs && (
            <Typography variant="h3" className={classes.titleTabs}>{titleTabs}</Typography>
          )
        }
        <div className={classes.containerBtn}>
          <ToggleButtons tabs={tabs} onClick={handleChange} activeTab={activeTab} />
        </div>
      </div>
      {
        tabsPannel.map((tabPannel) => (
          <TabPanel value={activeTab} index={tabPannel.key} key={`tab-pannel-${tabPannel.key}`}>
            <TablePerso
              headers={tabPannel.headers}
              informations={tabPannel.body}
              toConcours={toConcours}
              profil
              boxShadow={boxShadow}
              facture={facture}
              history={history}
            />
          </TabPanel>
        ))
      }
    </div>
  );
};

TabsPerso.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  ariaLabel: Proptypes.string,
  tabs: Proptypes.array,
  tabsPannel: Proptypes.array,
  className: Proptypes.string,
  boxShadow: Proptypes.bool,
  titleTabs: Proptypes.string,
  facture: Proptypes.bool,
  toConcours: Proptypes.bool,
  history: Proptypes.objectOf(
    Proptypes.oneOfType([
      Proptypes.number,
      Proptypes.func,
      Proptypes.object,
      Proptypes.string,
    ]),
  ),
};

TabsPerso.defaultProps = {
  boxShadow: true,
  facture: false,
  toConcours: false,
};

export default withTabs(TabsPerso);
