import React from 'react';
import Proptypes from 'prop-types';

import TileTutoriel from '../../_atoms/TileTutoriel/tile-tutoriel';
import { tutoriel } from '../../../constants/homeUser';

const Tutoriel = ({
  openFirstTuto,
  onClickSubmitFirstTuto,
  openSecondTuto,
  onClickSubmitSecondTuto,
  onClose,
  openThirdTuto,
  onClickSubmitThirdTuto,
}) => (
  <>
    <TileTutoriel
      detailsText={tutoriel.detailsText}
      title={tutoriel.title}
      illustration={tutoriel.tuto1.illustration}
      textIllustration={tutoriel.tuto1.textIllustration}
      open={openFirstTuto}
      onClose={onClose}
      onClickSubmit={onClickSubmitFirstTuto}
      textButton={tutoriel.tuto1.textButton}
    />
    <TileTutoriel
      detailsText={tutoriel.detailsText}
      title={tutoriel.title}
      illustration={tutoriel.tuto2.illustration}
      textIllustration={tutoriel.tuto2.textIllustration}
      open={openSecondTuto}
      illustrationRight
      onClose={onClose}
      onClickSubmit={onClickSubmitSecondTuto}
      textButton={tutoriel.tuto2.textButton}
    />
    <TileTutoriel
      detailsText={tutoriel.detailsText}
      title={tutoriel.title}
      illustration={tutoriel.tuto3.illustration}
      textIllustration={tutoriel.tuto3.textIllustration}
      open={openThirdTuto}
      onClose={onClose}
      onClickSubmit={onClickSubmitThirdTuto}
      textButton={tutoriel.tuto3.textButton}
    />
  </>
);

Tutoriel.propTypes = {
  openFirstTuto: Proptypes.bool,
  openSecondTuto: Proptypes.bool,
  openThirdTuto: Proptypes.bool,
  onClose: Proptypes.func,
  onClickSubmitFirstTuto: Proptypes.func,
  onClickSubmitSecondTuto: Proptypes.func,
  onClickSubmitThirdTuto: Proptypes.func,
};

Tutoriel.defaultProps = {
  openFirstTuto: false,
  openSecondTuto: false,
  openThirdTuto: false,
  onClose: () => {},
  onClickSubmitFirstTuto: () => {},
  onClickSubmitSecondTuto: () => {},
  onClickSubmitThirdTuto: () => {},
};

export default Tutoriel;
