import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import GetAppIcon from '@material-ui/icons/GetApp';

import {
  Backdrop, Fade, IconButton, Modal, Paper, Typography,
} from '@material-ui/core';
import ReactPlayer from 'react-player';
import { formatImage, formatTexte } from '../../../constants/formatCategory';
import withCardStyle from '../../_atoms/CardParticipant/card-participant.style';
import { statusConcoursFinish, statusConcoursPaid, statusConcoursWaitFinalFiles } from '../../../constants/concours';

const CardFileMessage = ({
  classes,
  className,
  background,
  format,
  allowMouseOver,
  isWinner,
  status,
  text,
  fileName,
  isFinalFile,
}) => {
  const [zoomImage, setZoomImage] = React.useState(false);
  const [mouseOver, setMouseOver] = React.useState(false);

  const handleZoomImage = () => {
    setZoomImage(true);
    setMouseOver(false);
  };

  const handleCloseZoomImage = () => {
    setMouseOver(false);
    setZoomImage(false);
  };

  const handleDownload = () => {
    window.open(background, '_blank');
  };

  const handleMouseOver = () => {
    if (!zoomImage && allowMouseOver) {
      setMouseOver(true);
    }
  };

  const handleMouseLeave = () => {
    setMouseOver(false);
  };

  return (
    <>
      <Paper
        className={cx(classes.root, className)}
      >
        <div
          className={classes.wrapper}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
        >
          {
            format === formatTexte && (
              <Typography variant="body2" className={cx(mouseOver ? classes.hidden : classes.text)}>
                {text}
              </Typography>
            )
          }
          { format !== formatTexte && (
            <>
              <Modal
                aria-labelledby="Card-participant"
                aria-describedby="Card-participant"
                className={classes.modal}
                open={zoomImage}
                onClose={handleCloseZoomImage}
                closeAfterTransition
                BackdropProps={{
                  timeout: 500,
                }}
                BackdropComponent={Backdrop}
              >
                <Fade in={zoomImage}>
                  <div className={classes.paper}>
                    {
                      format === formatImage ? (
                        <img src={background} alt="participant" className={classes.imgModal} />
                      )
                        : (
                          <ReactPlayer
                            url={background}
                            className={classes.player}
                            controls
                            config={{
                              file: {
                                attributes: {
                                  controlsList: 'nodownload nofullscreen disablePictureInPicture',
                                  disablePictureInPicture: true,
                                },
                              },
                            }}
                          />
                        )
                    }
                  </div>
                </Fade>
              </Modal>
            </>
          )}
          <div className={cx((mouseOver && !zoomImage && allowMouseOver) ? classes.wrapperBtnIcon : classes.hidden)}>
            <div style={{ marginBottom: '1rem' }}>
              <IconButton aria-label="Agrandir l'image" className={cx(classes.btnIcon, (format === 5 || isFinalFile) && classes.hidden)} onClick={handleZoomImage}>
                <ZoomOutMapIcon fontSize="large" />
              </IconButton>
              {
                isWinner && (status === statusConcoursWaitFinalFiles || status === statusConcoursFinish) && (
                <IconButton aria-label="Télécharger" className={cx(classes.btnIcon, classes.marginIcon)} onClick={handleDownload}>
                  <GetAppIcon fontSize="large" />
                </IconButton>
                )
              }
            </div>
          </div>
        </div>
        <Typography variant="body2" className={classes.fileName}>{fileName}</Typography>
      </Paper>
    </>
  );
};

CardFileMessage.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  className: Proptypes.string,
  format: Proptypes.oneOfType([
    Proptypes.string,
    Proptypes.number,
  ]),
  text: Proptypes.string,
  background: Proptypes.node,
  allowMouseOver: Proptypes.bool,
  isWinner: Proptypes.oneOfType([
    Proptypes.bool, Proptypes.number,
  ]),
  fileName: Proptypes.string,
  status: Proptypes.number,
  isFinalFile: Proptypes.bool,
};

CardFileMessage.defaultProps = {
  allowMouseOver: true,
  status: statusConcoursPaid,
  isWinner: false,
  isFinalFile: false,
};

export default withCardStyle(CardFileMessage);
