import React from 'react';
import Proptypes from 'prop-types';
import { DropzoneDialog } from 'material-ui-dropzone';

import withDropzoneDialogPerso from './dropzone-dialog.style';

// TODO: maxFileSize

const DropzoneDialogPerso = ({
  open,
  dropzoneText,
  filesLimit,
  onSave,
  onChange,
  acceptedFiles,
  showPreviews,
  maxFileSize,
  dialogTitle,
  showFilesNames,
  useChipsForPreview,
  showFileNamesInPreview,
  onClose,
}, ...rest) => (
  <>
    <DropzoneDialog
      open={open}
      dropzoneText={dropzoneText}
      filesLimit={filesLimit}
      onSave={onSave}
      acceptedFiles={acceptedFiles}
      onChange={onChange}
      showPreviews={showPreviews}
      showFilesNames={showFilesNames}
      dialogTitle={dialogTitle}
      submitButtonText="Envoyer"
      cancelButtonText="Annuler"
      maxFileSize={maxFileSize}
      onClose={onClose}
      useChipsForPreview={useChipsForPreview}
      showFileNamesInPreview={showFileNamesInPreview}
      {...rest}
    />
  </>
);

DropzoneDialogPerso.propTypes = {
  open: Proptypes.bool,
  dropzoneText: Proptypes.string,
  dialogTitle: Proptypes.string,
  filesLimit: Proptypes.number,
  onChange: Proptypes.func,
  onSave: Proptypes.func,
  acceptedFiles: Proptypes.array,
  showPreviews: Proptypes.bool,
  showFilesNames: Proptypes.bool,
  maxFileSize: Proptypes.number,
  onClose: Proptypes.func,
  showFileNamesInPreview: Proptypes.bool,
  useChipsForPreview: Proptypes.bool,
};

DropzoneDialogPerso.defaultProps = {
  dialogTitle: 'Uploader votre fichier',
  dropzoneText: 'Faites glisser et déposez un fichier ou cliquez',
  filesLimit: 1,
  onChange: () => {},
  onSave: () => {},
  showPreviews: true,
  showFilesNames: true,
  useChipsForPreview: false,
  showFileNamesInPreview: true,
  maxFileSize: 500000000,
  onClose: () => {},
};

export default withDropzoneDialogPerso(DropzoneDialogPerso);
