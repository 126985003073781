import { withStyles } from '@material-ui/core';

export default withStyles(({ breakpoints, palette }) => ({
  wrapperPaper: {
    display: 'flex',
    height: '50vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    width: '30vw',
    minWidth: '28rem',
    height: '20vw',
    minHeight: '28rem',
  },
  title: {
    textAlign: 'center',
    fontSize: '2rem',
    color: palette.secondary.main,
    fontWeight: 'bold',
    padding: '2rem',
  },
}));
