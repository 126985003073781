import { withStyles } from '@material-ui/core';

export default withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  list: {
    margin: 0,
    padding: 0,
    display: 'flex',
    whiteSpace: 'nowrap',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  card: {
    margin: '1rem',
  },
});
