import React from 'react';
import cx from 'classnames';
import ReactRotatingText from 'react-rotating-text';
import Proptypes from 'prop-types';

import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import illustration from '../../../assets/SVG/illustration-1.png';
import withContentTop from './content-top-home.style';
import Button from '../../_atoms/Button/button';
import { carousselTextCategory } from '../../../constants/homeGuest';

const ContentTopHome = ({
  classes, onInscription, className,
}) => (
  <>
    <section className={cx(classes.wrapper, className)}>
      <article className={classes.contentText}>
        <Typography variant="h3" display="inline" className={classes.title}>Besoin d&apos;un <ReactRotatingText items={carousselTextCategory} pause={1500} emptyPause={100} /> ?</Typography>
        <Typography variant="body1" display="block" className={classes.text}>{'Quel que soit votre besoin, \n'}
          <span style={{ fontWeight: 'bold' }}>Concoureur </span>vous apporte la solution !
        </Typography>
        <Link to="/signup" className={classes.link}>
          <Button
            background="#ffffff"
            variant="text"
            width="18rem"
            className={classes.btnInscription}
            onClick={onInscription}
            classes={{
              label: classes.labelInscription,
            }}
          >
            S&apos;inscrire
          </Button>
        </Link>
      </article>
      <div className={classes.picture}>
        <img className={classes.imageIllustration} src={illustration} alt="Home" />
      </div>
    </section>

  </>
);

ContentTopHome.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  onInscription: Proptypes.func,
  className: Proptypes.string,
};

ContentTopHome.defaultProps = {
  onInscription: () => {},
};

export default withContentTop(ContentTopHome);
