import axios from 'axios';
import { returnErrors } from './errorActions';
import {
  FETCH_GET_PARTICIPATION,
  FETCH_GET_PARTICIPATION_FULFILLED,
  FETCH_GET_PARTICIPATION_ERROR,
  FETCH_UPDATE_STARS_PARTICIPATION,
  FETCH_UPDATE_STARS_PARTICIPATION_ERROR,
  FETCH_UPDATE_STARS_PARTICIPATION_FULFILLED,
  FETCH_REPORT_PARTICIPATION,
  FETCH_REPORT_PARTICIPATION_FULFILLED,
  FETCH_REPORT_PARTICIPATION_ERROR,
  FETCH_ADD_FILE_PARTICIPANT,
  FETCH_ADD_FILE_PARTICIPANT_FULFILLED,
  FETCH_ADD_FILE_PARTICIPANT_ERROR,
} from '../constants/actionTypes';
import { apiKey } from '../constants/api';
import { tokenConfig } from '../helpers/tokenConfig';
import { returnSuccess } from './successActions';

export const addFileToParticipation = (idParticipation, file = null, cbSuccess = () => {}, cbError = () => {}) => (dispatch, getState) => {
  // dispatch(clearErrors());
  dispatch({ type: FETCH_ADD_FILE_PARTICIPANT });

  const body = new FormData();
  body.append('fileParticipation', file);
  body.append('idConcours', idParticipation);

  axios.post(`${apiKey}/participation/addFile/${idParticipation}`, body, tokenConfig(getState))
    .then((res) => {
      cbSuccess(res.data.message);
      dispatch(
        returnSuccess(res.data?.message, 'DELETE_WINNER'),
      );
      dispatch({
        type: FETCH_ADD_FILE_PARTICIPANT_FULFILLED,
      });
    })
    .catch((err) => {
      cbError(err.response?.data?.message?.message);
      dispatch({
        type: FETCH_ADD_FILE_PARTICIPANT_ERROR,
      });
      dispatch(
        returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'ADD_PARTICIPATION_FAIL'),
      );
    });
};

export const updateStars = (idParticipation, stars, cbSuccess = () => {}, cbError = () => {}) => (dispatch, getState) => {
  // dispatch(clearErrors());
  dispatch({ type: FETCH_UPDATE_STARS_PARTICIPATION });

  const body = JSON.stringify({
    idParticipation,
    stars,
  });

  axios.post(`${apiKey}/participation/stars/update`, body, tokenConfig(getState))
    .then((res) => {
      cbSuccess(res.data);
      dispatch(
        returnSuccess(res.data?.message, 'STARS_PARTICIPATION'),
      );
      dispatch({
        type: FETCH_UPDATE_STARS_PARTICIPATION_FULFILLED,
      });
    })
    .catch((err) => {
      cbError(err.response?.data?.message?.message);
      dispatch(
        returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'UPDATE_STARS_FAIL'),
      );
      dispatch({
        type: FETCH_UPDATE_STARS_PARTICIPATION_ERROR,
      });
    });
};

export const getParticipationById = (idParticipation, cbSuccess = () => {}, cbError = () => {}) => (dispatch, getState) => {
  // dispatch(clearErrors());
  dispatch({ type: FETCH_GET_PARTICIPATION });

  axios.get(`${apiKey}/participation/${idParticipation}`, tokenConfig(getState))
    .then((res) => {
      cbSuccess(res.data);
      dispatch({
        type: FETCH_GET_PARTICIPATION_FULFILLED,
      });
    })
    .catch((err) => {
      cbError(err.response?.data?.message?.message);
      dispatch({
        type: FETCH_GET_PARTICIPATION_ERROR,
      });
      dispatch(
        returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'GET_PARTICIPATION_BY_ID_FAIL'),
      );
    });
};

export const reportParticipation = (idParticipation, cbSuccess = () => {}, cbError = () => {}) => (dispatch, getState) => {
  // dispatch(clearErrors());
  dispatch({ type: FETCH_REPORT_PARTICIPATION });

  axios.get(`${apiKey}/participation/report/${idParticipation}`, tokenConfig(getState))
    .then((res) => {
      cbSuccess(res.data);
      dispatch(
        returnSuccess(res.data?.message, 'REPORT_PARTICIPATION'),
      );
      dispatch({
        type: FETCH_REPORT_PARTICIPATION_FULFILLED,
      });
    })
    .catch((err) => {
      cbError(err.response?.data?.message?.message);
      dispatch({
        type: FETCH_REPORT_PARTICIPATION_ERROR,
      });
      dispatch(
        returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'REPORT_PARTICIPATION_FAIL'),
      );
    });
};
