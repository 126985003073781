import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import {
  Table, TableContainer, TableRow, TableHead, TableBody, TableCell, Paper, Typography,
} from '@material-ui/core';

import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import CallMadeIcon from '@material-ui/icons/CallMade';
import EditIcon from '@material-ui/icons/Edit';

import Pagination from '@material-ui/lab/Pagination/Pagination';
import withTable from './table.style';

const TablePerso = ({
  classes,
  headers,
  informations,
  toConcours,
  history,
  classNameBigScreen,
  classNameSmallScreen,
  facture,
  nbRows,
  children,
  profil,
}) => {
  const [page, setPage] = React.useState(1);

  const handleChangePage = (event, value) => {
    console.log(informations, 'informations');
    setPage(value);
  };

  const handleClickToConcours = (row) => (e) => {
    if ('idConcours' in row) { // donc on est dans un cas de participation
      history.push({
        pathname: `/details-concours/${row.idConcours}`,
      });
    } else {
      history.push({
        pathname: `/participations/${row.key}`,
      });
    }
  };

  const handleClickToModifyConcours = (row) => (e) => {
    if ('idConcours' in row) { // donc on est dans un cas de participation
      history.push({
        pathname: `/modifier-concours/${row.idConcours}`,
      });
    }
    history.push({
      pathname: `/modifier-concours/${row.key}`,
    });
  };

  const numberDisplay = (page - 1) * nbRows;
  const nbrPage = informations ? Math.ceil(informations.length / nbRows) : 0;

  return (
    // Screen > 800px
    <>
      <TableContainer className={cx(classes.root, classes.bigScreen, classNameBigScreen)}>
        <Table className={classes.table}>
          <TableHead className={classes.tableHead}>
            <TableRow className={classes.row}>
              {
                headers.map((header) => (
                  <TableCell key={header.key} component="th" className={classes.cellHead}>{header.text}</TableCell>
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              informations.map((row, index) => {
                const tableBody = Object.entries(row.content).map((keyName) => (
                  <React.Fragment key={`objectEntires-${keyName[0]}`}>
                    {/* TODO: Le problème de unique key est ici */}
                    <TableCell component="td" className={classes.cellBody}>{keyName[1]}</TableCell>
                  </React.Fragment>
                ));
                return (
                  <>
                    {
                      index >= numberDisplay && index < (numberDisplay + nbRows) && (
                      <TableRow key={`row-${row.key}`} className={classes.row}>
                        {tableBody}
                          {
                            !('idConcours' in row) && toConcours && (
                              <TableCell component="td" className={classes.cellBody}>
                                <EditIcon
                                  fontSize="large"
                                  color="secondary"
                                  onClick={handleClickToModifyConcours(row)}
                                  className={classes.icon}
                                />
                              </TableCell>
                            )
                          }
                        {
                          toConcours && (
                            <TableCell component="td" className={classes.cellBody}>
                              <CallMadeIcon
                                fontSize="large"
                                color="secondary"
                                onClick={handleClickToConcours(row)}
                                className={classes.icon}
                              />
                            </TableCell>
                          )
                        }
                      </TableRow>
                      )
                    }
                  </>
                );
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
      {
        !profil ? (
          { children }
        ) : (
          <>
            {
              informations.map((information, index) => (
                <React.Fragment key={information.key}>
                  {
                    index >= numberDisplay && index < (numberDisplay + nbRows) && (
                      <Paper className={cx(classes.root, classes.smallScreen, classNameSmallScreen)} key={information.key}>
                        <Typography variant="h3" className={cx(classes.textAlign, classes.smallScreen_title)}>{information.content.category}</Typography>
                        <div className={classes.smallScreen_wrapperInformations}>
                          {
                            headers.slice(0, 0).concat(headers.slice(1, headers.length)).map((element, index) => {
                              let informationObject = Object.values(information.content);
                              informationObject = informationObject.slice(0, 0).concat(informationObject.slice(1, informationObject.length));
                              return (
                                <div className={classes.smallScreen_wrapperRow} key={element.key}>
                                  <Typography variant="body1" className={classes.smallScreen_title}>{element.text}</Typography>
                                  {
                                    element.text === 'Factures' ? (
                                      <PictureAsPdfIcon
                                        fontSize="large"
                                        color="secondary"
                                        className={classes.icon}
                                        titleAccess="Télécharger le pdf"
                                      />
                                    ) : (
                                      <Typography variant="body1" className={classes.smallScreen_text}>{informationObject[index]}</Typography>
                                    )
                                  }
                                  {
                                    element.text === 'Édition' && (
                                      <EditIcon
                                        fontSize="large"
                                        color="secondary"
                                        onClick={handleClickToModifyConcours(information)}
                                        className={classes.icon}
                                      />
                                    )
                                  }
                                  {
                                    element.text === 'Concours' && (
                                      <CallMadeIcon
                                        fontSize="large"
                                        color="secondary"
                                        onClick={(handleClickToConcours(information))}
                                        className={classes.icon}
                                      />
                                    )
                                  }
                                  {
                                    element.text === 'Participation' && (
                                      <CallMadeIcon
                                        fontSize="large"
                                        color="secondary"
                                        onClick={(handleClickToConcours(information))}
                                        className={classes.icon}
                                      />
                                    )
                                  }
                                </div>
                              );
                            })
                          }
                        </div>
                      </Paper>
                    )
                  }
                </React.Fragment>
              ))
            }
          </>
        )
      }
      <Pagination
        count={nbrPage}
        page={page}
        onChange={handleChangePage}
        color="primary"
        variant="outlined"
        className={classes.pagination}
      />
    </>
  );
};


TablePerso.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  headers: Proptypes.array,
  informations: Proptypes.array,
  toConcours: Proptypes.bool,
  history: Proptypes.objectOf(
    Proptypes.oneOfType([
      Proptypes.number,
      Proptypes.func,
      Proptypes.object,
      Proptypes.string,
    ]),
  ),
  classNameBigScreen: Proptypes.string,
  classNameSmallScreen: Proptypes.string,
  boxShadow: Proptypes.bool,
  facture: Proptypes.bool,
  children: Proptypes.node,
  profil: Proptypes.bool,
  nbRows: Proptypes.number,
};

TablePerso.defaultProps = {
  toConcours: false,
  boxShadow: true,
  facture: false,
  profil: false,
  nbRows: 5,
};

export default withTable(TablePerso);
