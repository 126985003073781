import { connect } from 'react-redux';
import {
  login, clearSuccessMsg, loginGoogle, loginFacebook, resetPassword,
} from '../../../actions/user';
import Connection from './connexion';

const mapStateToProps = ({ user, error }) => ({
  user,
  error,
});

export default connect(
  mapStateToProps,
  {
    login, clearSuccessMsg, loginGoogle, loginFacebook, resetPassword,
  },
)(Connection);
