// Setup config/headers and token
export const tokenConfig = (getState) => {
  // Get token from localstorage
  const { token } = getState().user;

  // Headers
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  };

  // If token, add to headers
  if (token) {
    config.headers.authorization = token;
  }

  return config;
};
