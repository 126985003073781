import {
  FETCH_UPDATE_STARS_PARTICIPATION,
  FETCH_UPDATE_STARS_PARTICIPATION_FULFILLED,
  FETCH_UPDATE_STARS_PARTICIPATION_ERROR,
  FETCH_GET_PARTICIPATION,
  FETCH_GET_PARTICIPATION_ERROR,
  FETCH_GET_PARTICIPATION_FULFILLED, FETCH_REPORT_PARTICIPATION_ERROR, FETCH_REPORT_PARTICIPATION_FULFILLED, FETCH_ADD_FILE_PARTICIPANT_FULFILLED, FETCH_ADD_FILE_PARTICIPANT, FETCH_ADD_FILE_PARTICIPANT_ERROR,
} from '../constants/actionTypes';

const initialState = {
  fetching: false,
  fetched: false,
  isLoading: false,
};

export default function participationReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_UPDATE_STARS_PARTICIPATION:
    case FETCH_GET_PARTICIPATION:
    case FETCH_ADD_FILE_PARTICIPANT:
      return {
        ...state,
        fetching: true,
        isLoading: true,
      };
    case FETCH_UPDATE_STARS_PARTICIPATION_FULFILLED:
    case FETCH_GET_PARTICIPATION_FULFILLED:
    case FETCH_REPORT_PARTICIPATION_FULFILLED:
    case FETCH_ADD_FILE_PARTICIPANT_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        success: true,
        isLoading: false,
      };
    case FETCH_UPDATE_STARS_PARTICIPATION_ERROR:
    case FETCH_GET_PARTICIPATION_ERROR:
    case FETCH_REPORT_PARTICIPATION_ERROR:
    case FETCH_ADD_FILE_PARTICIPANT_ERROR:
      return {
        ...state,
        fetching: false,
        isLoading: false,
      };
    default:
      return state;
  }
}
