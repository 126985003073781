import { withStyles } from '@material-ui/core';
import videoGenerique from '../../../assets/Image/Icon Fichier/files_icon-video@2x.png';
import audioGenerique from '../../../assets/Image/Icon Fichier/files_icon-music@2x.png';
import zipGenerique from '../../../assets/Image/Icon Fichier/files_icon-zip@2x.png';
import { formatAudio, formatImage, formatVideo } from '../../../constants/formatCategory';

export default withStyles(({
  palette, shadows, spacing, breakpoints,
}) => ({
  root: {
    width: '15vw',
    height: '15vw',
    minWidth: '12rem',
    borderRadius: '1rem',
    minHeight: '12rem',
  },
  wrapper: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundImage: (props) => {
      if (props.isFinalFile) return `url(${zipGenerique})`;
      if (props.format === formatImage) return `url(${props.background})`;
      if (props.format === formatVideo) return `url(${videoGenerique})`;
      if (props.format === formatAudio) return `url(${audioGenerique})`;
      return palette.common.white;
    },
    borderRadius: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    '&:hover': {
      background: (props) => props.allowMouseOver && '#e5e5e5',
    },
  },
  player: {
    width: '80vw !important',
    height: (props) => (props.format === formatAudio ? '5rem !important'
      : props.format === formatVideo && '100% !important'),
    // marginTop: '5rem',
    '@global': {
      audio: {
        height: '5rem !important',
        '&:focus': {
          outline: 'none',
        },
      },
      video: {
        '&:focus': {
          outline: 'none',
        },
      },
    },
  },
  text: {
    fontWeight: 'bold',
    fontSize: '1.4rem',
    color: palette.grey[800],
  },
  fileName: {
    padding: '1rem',
    textAlign: 'center',
    fontSize: '1.2rem',
    color: palette.grey[800],
  },
  img: {
    maxWidth: '100%',
    height: '100%',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'initial',
  },
  paper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'auto',
    maxWidth: '90vw',
    height: (props) => (props.format === formatImage ? '50vh' : 'auto'),
    boxShadow: (props) => (props.format === formatImage ? shadows[8] : 'none'),
    background: (props) => (props.format === formatImage) && 'white',
    '&:focus': {
      outline: 'none',
    },
    [breakpoints.down('sm')]: {
      height: '60vh',
    },

    [breakpoints.down(500)]: {
      height: '40vh',
    },
  },
  imgModal: {
    maxHeight: '100%',
    height: '70vw',
    maxWidth: 'inherit',
  },
  visible: {
    display: 'flex',
  },
  hidden: {
    display: 'none',
  },
  wrapperBtnIcon: {
  },
  btnIcon: {
    transition: '0.2s opacity ease-in-out',
    background: palette.gradient.main,
    '&:hover': {
      background: palette.gradient.main,
      opacity: 0.8,
    },
    color: palette.common.white,
  },
  marginIcon: {
    marginLeft: '1rem',
  },
  btnNoPointerEvent: {
    pointerEvents: 'none',
    background: palette.grey[500],
  },
}));
