import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Menu, MenuItem, Typography } from '@material-ui/core';
import withTileNavBarUser from './tile-navbar-user.style';

const ITEM_HEIGHT = 48;

const TileNavBarUser = ({ classes, category }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleOver = (e) => {
    if (anchorEl !== e.currentTarget) setAnchorEl(e.currentTarget);
  };
  const handleLeave = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <button
        aria-owns={anchorEl ? 'simple-menu' : undefined}
        aria-haspopup="true"
        type="button"
        className={classes.btn}
        onMouseOver={handleOver}
        onClick={handleOver}
      >
        <Link
          className={classes.link}
          to={{
            pathname: `/concours/${category.to}`,
          }}
        >
          <Typography variant="body1" className={classes.titleCategorie}>{category.title}</Typography>
        </Link>
      </button>
      <Menu
        id="simple-menu"
        MenuListProps={{
          onMouseLeave: handleLeave,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleLeave}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 6,
            width: '20ch',
          },
        }}
      >
        {
                category.subCategories.map((subcategorie) => (
                  <MenuItem key={subcategorie.id} onClick={handleLeave}>
                    <Link
                      className={cx(classes.link, classes.subCategoryLink)}
                      to={{
                        pathname: `/concours/${subcategorie.to}`,
                        state: { idSubCategory: subcategorie.id },
                      }}
                    >
                      <li
                        className={classes.subCategorie}
                      >
                        {subcategorie.title}
                      </li>
                    </Link>
                  </MenuItem>
                ))
              }
      </Menu>
    </>
  );
};

TileNavBarUser.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  category: Proptypes.shape({
    id: Proptypes.number,
    title: Proptypes.string,
    to: Proptypes.string,
    subCategories: Proptypes.arrayOf(Proptypes.object),
  }),
};

TileNavBarUser.defaultProps = {
  category: {},
};

export default withTileNavBarUser(TileNavBarUser);
