import { withStyles } from '@material-ui/core';
import { widthCardCategoryConcours } from '../../../constants/size';

export default withStyles(({ palette, breakpoints }) => ({
  root: {
    display: 'inline-block',
    position: (props) => (props.active ? 'relative' : 'static'),
    top: (props) => (props.active ? '-4px' : '0'),
    width: (props) => (props.onlyBackground ? widthCardCategoryConcours : '23rem'),
    height: (props) => (props.onlyBackground ? '15rem' : '23rem'),
    boxShadow: (props) => ((props.onlyBackground) ? '3px 3px 3px -1px rgba(0,0,0,0.69)' : 'initial'),
    borderRadius: '1rem',
    [breakpoints.down(600)]: {
      width: (props) => (props.onlyBackground ? widthCardCategoryConcours : '40vw'),
      height: (props) => (props.onlyBackground ? '15rem' : '48vw'),
    },

    [breakpoints.down('sm')]: {
      width: (props) => props.onlyBackground && '30rem',
    },
    [breakpoints.down(650)]: {
      width: (props) => props.onlyBackground && '43vw',
    },

    [breakpoints.down(330)]: {
      width: '38vw',
      height: '56vw',
    },
  },
  btn: {
    display: (props) => props.disabled && 'none',
    background: 'none',
    border: 'none',
    width: '100%',
    height: '100%',
    margin: 0,
    padding: 0,
    '&:focus': {
      outline: 'none',
    },
    textAlign: 'left',
  },
  h4: {
    fontSize: (props) => (props.onlyTitle ? '2rem' : '1.6rem'),
    [breakpoints.down(500)]: {
      fontSize: (props) => (props.onlyTitle ? '1.2rem' : '1.2rem'),
    },
    fontWeight: 'bold',
    textAlign: (props) => props.onlyTitle && 'center',
    textShadow: '2px 2px 5px #474747',

  },
  containerContent: {
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%',
    height: 'inherit',
    maxHeight: '23rem',
    background: 'center',
    backgroundImage: (props) => `url(${props.background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: '1rem',
    transition: '0.2s opacity ease-in-out',
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.85,
    },
  },
  containerContentActive: {
    border: '0.5rem solid',
    borderColor: palette.secondary.light,
    borderRadius: '1.5rem',
  },
  wrapperContent: {
    width: '100%',
    background: 'linear-gradient(0deg, rgba(254,82,102,0.6) 0%, rgba(255,255,255,0) 98%)',
    borderRadius: '1rem',
    display: (props) => props.onlyBackground && 'none',
  },
  content: {
    color: 'white',
    margin: '2vh',
  },
  display: {
    display: (props) => (props.onlyTitle || props.onlyBackground) && 'none',
  },
}));
