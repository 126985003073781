import { withStyles } from '@material-ui/core';
import { responsiveHome } from '../../../constants/size';

export default withStyles(({ breakpoints, palette }) => ({
  paper: {
    width: '70%',
    padding: '2rem',
    maxWidth: '70rem',
  },
  btnSubmit: {
    width: '25rem',
    fontWeight: 'normal',
  },
  title: {
    fontSize: '2rem',
  },
  container: {
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  detailsText: {
    whiteSpace: 'pre-wrap',
    fontSize: '1.2rem',
    textAlign: 'center',
    fontWeight: 'bold',
    color: palette.grey[700],
  },
  containerIllustration: {
    marginTop: '2rem',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-around',
    padding: '0 2rem',
    flexDirection: (props) => (props.illustrationRight ? 'row-reverse' : 'row'),
    [breakpoints.down(responsiveHome)]: {
      padding: 0,
    },
  },
  wrapperImg: {
    width: '70%',
    maxWidth: '20rem',
  },
  img: {
    width: '100%',
  },
  textIllustration: {
    maxWidth: '20rem',
    fontSize: '1.4rem',
    fontWeight: 'bold',
    color: palette.grey[700],
    width: '70%',
    textAlign: 'center',
  },
}));
