import React from 'react';
import Proptypes from 'prop-types';
import cx from 'classnames';

import './style.css';

class InputForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      value: props.value || '',
      error: props.error || '',
      label: props.label || '',
    };
  }

  changeValue(event) {
    const { value } = event.target;
    this.setState({ value, error: '' });
  }

  render() {
    const {
      className,
      id,
      name,
      height,
      refInput,
      type,
      locked,
      autoComplete,
      fullWidth,
      classNameInput,
      readOnly,
      noOnChange,
      required,
      onChange,
      ...rest
    } = this.props;

    const {
      active, value, error, label,
    } = this.state;


    const fieldClassName = `field ${(locked ? active : active || value)
    && 'active'} ${(label && 'labelActive')} ${locked && !active && 'locked'}`;

    return (
      <div
        className={cx(fieldClassName, fullWidth && 'fullWidth', className)}
        style={{
          height,
        }}
      >
        <input
          id={id}
          name={name}
          value={value}
          placeholder={label}
          onChange={noOnChange ? this.changeValue.bind(this) : onChange}
          onFocus={() => !locked && this.setState({ active: true })}
          onBlur={() => !locked && this.setState({ active: false })}
          ref={refInput}
          aucomplete={autoComplete}
          type={type}
          readOnly={readOnly}
          {...rest}
          className={classNameInput}
        />
        <label htmlFor={1} className={error && 'error'}>
          {error || label}
          {required && (
            <span style={{ color: 'red' }}> *</span>
          )}
        </label>
      </div>
    );
  }
}

InputForm.propTypes = {
  className: Proptypes.string,
  height: Proptypes.string,
  id: Proptypes.oneOfType([
    Proptypes.string, Proptypes.number,
  ]),
  name: Proptypes.string,
  label: Proptypes.string,
  fullWidth: Proptypes.bool,
  classNameInput: Proptypes.string,
  refInput: Proptypes.func,
  value: Proptypes.string,
  error: Proptypes.string,
  locked: Proptypes.bool,
  type: Proptypes.string,
  autoComplete: Proptypes.string,
  required: Proptypes.bool,
  readOnly: Proptypes.bool,
  noOnChange: Proptypes.bool,
  onChange: Proptypes.func,
};

InputForm.defaultProps = {
  locked: false,
  height: '4rem',
  autoComplete: 'off',
  fullWidth: false,
  required: true,
  readOnly: false,
  label: '',
  noOnChange: true,
  onChange: () => {},
};


export default InputForm;
