import React, { useEffect } from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import { Typography } from '@material-ui/core';
import withNavBarConcours from './nav-bar-concours.style';
import { categoriesConcours } from '../../../constants/categories';

const NavBarConcours = ({ classes, className, onClickCategorie }) => {
  useEffect(() => {
    const design = document.querySelector(`.${classes.btnCategory}`);
    design.className = classes.categoryActive;
  }, [classes.btnCategory, classes.categoryActive]);

  const handleOver = (e) => {
    if (e.currentTarget.className !== classes.categoryActive) {
      e.currentTarget.className = classes.categoryHover;
    }
  };

  const handleLeave = (e) => {
    if (e.currentTarget.className !== classes.categoryActive) {
      e.currentTarget.className = classes.btnCategory;
    }
  };

  const handleClickCategory = (id) => (e) => {
    const listCategoryActive = document.querySelectorAll(`.${classes.categoryActive}`);
    if (listCategoryActive.length > 0) {
      listCategoryActive.forEach((element) => {
        // eslint-disable-next-line no-param-reassign
        element.className = classes.btnCategory;
      });
    }
    e.currentTarget.className = classes.categoryActive;
    onClickCategorie(id);
  };
  return (
    <nav className={cx(classes.root, className)}>
      <ul className={classes.listCategories}>
        {
          categoriesConcours.map((categorie) => (
            <li key={categorie.id}>
              <button type="button" onClick={handleClickCategory(categorie.id)} className={classes.btnCategory} onMouseOver={handleOver} onMouseLeave={handleLeave}>
                <Typography variant="body1" className={classes.titleCategory}>{categorie.title}</Typography>
              </button>
            </li>
          ))
          }
      </ul>
    </nav>
  );
};
NavBarConcours.propTypes = {
  className: Proptypes.string,
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  onClickCategorie: Proptypes.func,
};

NavBarConcours.defaultProps = {
  onClickCategorie: () => {},
};

export default withNavBarConcours(NavBarConcours);
