import axios from 'axios';
import { returnErrors } from './errorActions';
import { apiKey } from '../constants/api';

import {
  FETCH_GET_SUBCATEGORY,
  FETCH_GET_SUBCATEGORY_FULFILLED,
  FETCH_GET_SUBCATEGORY_ERROR,
} from '../constants/actionTypes';
import { tokenConfig } from '../helpers/tokenConfig';

export const getSubCategoryById = (idSubcategory, cbSuccess = () => {}, cbError = () => {}) => (dispatch, getState) => {
  // dispatch(clearErrors());
  dispatch({ type: FETCH_GET_SUBCATEGORY });

  axios.get(`${apiKey}/subcategory/read/${idSubcategory}`, tokenConfig(getState))
    .then((res) => {
      cbSuccess(res.data.results.subCategories);
      dispatch({ type: FETCH_GET_SUBCATEGORY_FULFILLED, payload: res.data.results.subCategories });
    })
    .catch((err) => {
      cbError(err.response?.data?.message?.message);
      dispatch(
        returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'GET_SUBCATEGORY_FAIL'),
      );
      dispatch({ type: FETCH_GET_SUBCATEGORY_ERROR });
    });
};
