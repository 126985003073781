import React from 'react';
import Proptypes from 'prop-types';
import { Hidden } from '@material-ui/core';
import NavBarUser from '../../_molecules/NavBarUser/nav-bar-user';
import SearchCategories from '../../_molecules/SearchCategories/search-categories';

import withNavCategories from './nav-categories.style';

const NavCategories = ({ onSubmitSearch, classes }) => (
  <>
    <Hidden smDown>
      <div className={classes.wrapperNavBarUser}>
        <NavBarUser onSubmit={onSubmitSearch} className={classes.navBarUser} />
      </div>
    </Hidden>
    <Hidden mdUp>
      <SearchCategories onSubmit={onSubmitSearch} className={classes.searchMobile} />
    </Hidden>
  </>
);

NavCategories.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  onSubmitSearch: Proptypes.func,
};

NavCategories.defaultProps = {
  onSubmitSearch: () => {},
};

export default withNavCategories(NavCategories);
