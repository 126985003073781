import {
  FETCH_CREATE_PAYMENT,
  FETCH_CREATE_PAYMENT_FULFILLED,
  FETCH_CREATE_PAYMENT_ERROR,
  FETCH_CONFIRMATION_PAYMENT,
  FETCH_CONFIRMATION_PAYMENT_FULFILLED,
} from '../constants/actionTypes';

const initialState = {
  fetching: false,
  fetched: false,
  isLoading: false,
  redirectUrlPayment: '',
  idConcours: null,
};

export default function PaymentReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CREATE_PAYMENT:
    case FETCH_CONFIRMATION_PAYMENT:
      return {
        ...state,
        fetching: true,
        isLoading: true,
      };
    case FETCH_CREATE_PAYMENT_FULFILLED:
      return {
        fetching: false,
        fetched: true,
        isLoading: false,
        redirectUrlPayment: action.redirectUrl,
        idConcours: action.idConcours,
      };
    case FETCH_CONFIRMATION_PAYMENT_FULFILLED:
      return {
        fetching: false,
        fetched: true,
        isLoading: false,
        idConcours: action.idConcours,
      };
    case FETCH_CREATE_PAYMENT_ERROR:
      return {
        ...state,
        fetching: false,
        isLoading: false,
        redirectUrlPayment: '',
        idConcours: null,
      };
    default:
      return state;
  }
}
