import { withStyles } from '@material-ui/core';

export default withStyles(({ palette }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    background: (props) => `url(${props.icon})`,
    backgroundRepeat: 'no-repeat',
    width: (props) => props.width,
    height: (props) => props.height,
  },
  text: {
    fontSize: '1.8rem',
    fontWeight: 'normal',
    lineHeight: '1.2',
    color: palette.grey[800],
    marginLeft: '1rem',
    textAlign: 'justify',
  },
}));
