// API USER

export const FETCH_SIGNUP = 'FETCH_SIGNUP';
export const FETCH_SIGNUP_FULFILLED = 'FETCH_SIGNUP_FULFILLED';
export const FETCH_SIGNUP_ERROR = 'FETCH_SIGNUP_ERROR';

// VERIFY USER
export const FETCH_VERIFY_USER = 'FETCH_VERIFY_USER';
export const FETCH_VERIFY_USER_FULFILLED = 'FETCH_VERIFY_USER_FULFIllED';
export const FETCH_VERIFY_USER_ERROR = 'FETCH_VERIFY_USER_ERROR';

// PASSWORD LOST
export const FETCH_RESET_PASSWORD = 'FETCH_RESET_PASSWORD';
export const FETCH_RESET_PASSWORD_FULFILLED = 'FETCH_RESET_PASSWORD_FULFILLED';
export const FETCH_RESET_PASSWORD_ERROR = 'FETCH_RESET_PASSWORD_ERROR';

// RECOVER PASSWORD
export const FETCH_RECOVER_PASSWORD = 'FETCH_RECOVER_PASSWORD';
export const FETCH_RECOVER_PASSWORD_FULFILLED = 'FETCH_RECOVER_PASSWORD_FULFILLED';
export const FETCH_RECOVER_PASSWORD_ERROR = 'FETCH_RECOVER_PASSWORD_ERROR';

// API Login
export const FETCH_LOGIN = 'FETCH_LOGIN';
export const FETCH_LOGIN_FULFILLED = 'FETCH_LOGIN_FULFILLED';
export const FETCH_LOGIN_ERROR = 'FETCH_LOGIN_ERROR';

// Login Google
export const FETCH_LOGIN_GOOGLE = 'FETCH_LOGIN_GOOGLE';
export const FETCH_LOGIN_GOOGLE_FULFILLED = 'FETCH_LOGIN_GOOGLE_FULFILLED';
export const FETCH_LOGIN_GOOGLE_ERROR = 'FETCH_LOGIN_GOOGLE_ERROR';

// Login Facebook
export const FETCH_LOGIN_FACEBOOK = 'FETCH_LOGIN_FACEBOOK';
export const FETCH_LOGIN_FACEBOOK_FULFILLED = 'FETCH_LOGIN_FACEBOOK_FULFILLED';
export const FETCH_LOGIN_FACEBOOK_ERROR = 'FETCH_LOGIN_FACEBOOK_ERROR';

// LOAD USER
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';

// UPDATE USER
export const FETCH_USER_UPDATE = 'FETCH_USER_UPDATE';
export const FETCH_USER_UPDATE_FULFILLED = 'FETCH_USER_UPDATE_FULFILLED';
export const FETCH_USER_UPDATE_ERROR = 'FETCH_USER_UPDATE_ERROR';

// GET STATUT OF USER
export const FETCH_GET_STATUT_USER = 'FETCH_GET_STATUT_USER';
export const FETCH_GET_STATUT_USER_FULFILLED = 'FETCH_GET_STATUT_USER_FULFILLED';
export const FETCH_GET_STATUT_USER_ERROR = 'FETCH_GET_STATUT_USER_ERROR';

// ADD STATUT TO USER
export const FETCH_ADD_STATUT_TO_USER = 'FETCH_ADD_STATUT_TO_USER';
export const FETCH_ADD_STATUT_TO_USER_FULFILLED = 'FETCH_ADD_STATUT_TO_USER_FULFILLED';
export const FETCH_ADD_STATUT_TO_USER_ERROR = 'FETCH_ADD_STATUT_TO_USER_ERROR';

// FINISH TUTORIAL
export const FETCH_FINISH_TUTORIAL = 'FETCH_FINISH_TUTORIAL';
export const FETCH_FINISH_TUTORIAL_FULFILLED = 'FETCH_FINISH_TUTORIAL_FULFILLED';
export const FETCH_FINISH_TUTORIAL_ERROR = 'FETCH_FINISH_TUTORIAL_ERROR';

// FORGET PASSWORD
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_FULFILLED = 'FORGET_PASSWORD_FULFILLED';
export const FORGET_PASSWORD_ERROR = 'FORGET_PASSWORD_ERROR';

// Errors
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

// Success
export const GET_SUCCESS = 'GET_SUCCESS';
export const CLEAR_SUCCESS = 'CLEAR_SUCCESS';

// Logout
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

// API SUBCATEGORY

// get subcategory with options by id
export const FETCH_GET_SUBCATEGORY = 'FETCH_GET_SUBCATEGORY';
export const FETCH_GET_SUBCATEGORY_FULFILLED = 'FETCH_GET_SUBCATEGORY_FULFILLED';
export const FETCH_GET_SUBCATEGORY_ERROR = 'FETCH_GET_SUBCATEGORY_ERROR';

// API CONCOURS

// Create a concours
export const FETCH_CREATECONCOURS = 'FETCH_CREATECONCOURS';
export const FETCH_CREATECONCOURS_FULFILLED = 'FETCH_CREATECONCOURS_FULFILLED';
export const FETCH_CREATECONCOURS_ERROR = 'FETCH_CREATECONCOURS_ERROR';

// get Concours by ID
export const FETCH_CONCOURSID = 'FETCH_CONCOURSID';
export const FETCH_CONCOURSID_FULFILLED = 'FETCH_CONCOURSID_FULFILLED';
export const FETCH_CONCOURSID_ERROR = 'FETCH_CONCOURSID_ERROR';

// get Concours by Filter
export const FETCH_CONCOURS_WITH_FILTER = 'FETCH_CONCOURS_WITH_FILTER';
export const FETCH_CONCOURS_WITH_FILTER_FULFILLED = 'FETCH_CONCOURS_WITH_FILTER_FULFILLED';
export const FETCH_CONCOURS_WITH_FILTER_ERROR = 'FETCH_CONCOURS_WITH_FILTER_ERROR';

// get Concours by category
export const FETCH_CONCOURS_CATEGORY = 'FETCH_CONCOURS_CATEGORY';
export const FETCH_CONCOURS_CATEGORY_FULFILLED = 'FETCH_CONCOURS_CATEGORY_FULFILLED';
export const FETCH_CONCOURS_CATEGORY_ERROR = 'FETCH_CONCOURS_CATEGORY_ERROR';

// get Concours by subCategory
export const FETCH_CONCOURS_SUBCATEGORY = 'FETCH_CONCOURS_SUBCATEGORY';
export const FETCH_CONCOURS_SUBCATEGORY_FULFILLED = 'FETCH_CONCOURS_SUBCATEGORY_FULFILLED';
export const FETCH_CONCOURS_SUBCATEGORY_ERROR = 'FETCH_CONCOURS_SUBCATEGORY_ERROR';

// get all Concours
export const FETCH_ALL_CONCOURS = 'FETCH_ALL_CONCOURS';
export const FETCH_ALL_CONCOURS_FULFILLED = 'FETCH_ALL_CONCOURS_FULFILLED';
export const FETCH_CONCOURS_FULFILLED_NOCONTENT = 'FETCH_CONCOURS_FULFILLED_NOCONTENT';
export const FETCH_ALL_CONCOURS_ERROR = 'FETCH_ALL_CONCOURS_ERROR';

// get all Concours created by the user
export const FETCH_GET_CONCOURS_CREATED_BY_USER = 'FETCH_GET_CONCOURS_CREATED_BY_USER';
export const FETCH_GET_CONCOURS_CREATED_BY_USER_FULFILLED = 'FETCH_GET_CONCOURS_CREATED_BY_USER_FULFILLED';
export const FETCH_GET_CONCOURS_CREATED_BY_USER_NO_CONTENT = 'FETCH_GET_CONCOURS_CREATED_BY_USER_NO_CONTENT';
export const FETCH_GET_CONCOURS_CREATED_BY_USER_ERROR = 'FETCH_GET_CONCOURS_CREATED_BY_USER_ERROR';

// get all Concours created by the user
export const FETCH_GET_CONCOURS_PARTICIPED_BY_USER = 'FETCH_GET_CONCOURS_PARTICIPED_CREATED_BY_USER';
export const FETCH_GET_CONCOURS_PARTICIPED_BY_USER_FULFILLED = 'FETCH_GET_CONCOURS_PARTICIPED_BY_USER_FULFILLED';
export const FETCH_GET_CONCOURS_PARTICIPED_BY_USER_NO_CONTENT = 'FETCH_GET_CONCOURS_PARTICIPED_BY_USER_NO_CONTENT';
export const FETCH_GET_CONCOURS_PARTICIPED_BY_USER_ERROR = 'FETCH_GET_CONCOURS_PARTICIPED_BY_USER_ERROR';

// add participant to a concours
export const FETCH_ADD_PARTICIPANT = 'FETCH_ADD_PARTICIPANT';
export const FETCH_ADD_PARTICIPANT_FULFILLED = 'FETCH_ADD_PARTICIPANT_FULFILLED';
export const FETCH_ADD_PARTICIPANT_ERROR = 'FETCH_ADD_PARTICIPANT_ERROR';

// modify a concours
export const FETCH_MODIFY_CONCOURS = 'FETCH_MODIFY_CONCOURS';
export const FETCH_MODIFY_CONCOURS_FULFILLED = 'FETCH_MODIFY_CONCOURS_FULFILLED';
export const FETCH_MODIFY_CONCOURS_ERROR = 'FETCH_MODIFY_CONCOURS_ERROR';

// add file to a participant of concours
export const FETCH_ADD_FILE_PARTICIPANT = 'FETCH_ADD_FILE_PARTICIPANT';
export const FETCH_ADD_FILE_PARTICIPANT_FULFILLED = 'FETCH_ADD_FILE_PARTICIPANT_FULFILLED';
export const FETCH_ADD_FILE_PARTICIPANT_ERROR = 'FETCH_ADD_FILE_PARTICIPANT_ERROR';

// get a participation by id
export const FETCH_GET_PARTICIPATION = 'FETCH_GET_PARTICIPATION';
export const FETCH_GET_PARTICIPATION_FULFILLED = 'FETCH_GET_PARTICIPATION_FULFILLED';
export const FETCH_GET_PARTICIPATION_ERROR = 'FETCH_GET_PARTICIPATION_ERROR';

// get all winner by concours
export const FETCH_GET_ALL_WINNER_BY_CONCOURS = 'FETCH_GET_ALL_WINNER_BY_CONCOURS';
export const FETCH_GET_ALL_WINNER_BY_CONCOURS_FULFILLED = 'FETCH_GET_ALL_WINNER_BY_CONCOURS_FULFILLED';
export const FETCH_GET_ALL_WINNER_BY_CONCOURS_ERROR = 'FETCH_GET_ALL_WINNER_BY_CONCOURS_ERROR';

// add a winner
export const FETCH_ADD_WINNER = 'FETCH_ADD_WINNER';
export const FETCH_ADD_WINNER_FULFILLED = 'FETCH_ADD_WINNER_FULFILLED';
export const FETCH_ADD_WINNER_ERROR = 'FETCH_ADD_WINNER_ERROR';

// delete a winner
export const FETCH_DELETE_WINNER = 'FETCH_DELETE_WINNER';
export const FETCH_DELETE_WINNER_FULFILLED = 'FETCH_DELETE_WINNER_FULFILLED';
export const FETCH_DELETE_WINNER_ERROR = 'FETCH_DELETE_WINNER_ERROR';

// choose prefinal winner
export const FETCH_PREFINAL_WINNER = 'FETCH_PREFINAL_WINNER';
export const FETCH_PREFINAL_WINNER_FULFILLED = 'FETCH_PREFINAL_WINNER_FULFILLED';
export const FETCH_PREFINAL_WINNER_ERROR = 'FETCH_PREFINAL_WINNER_ERROR';

// choose final winner
export const FETCH_CHOOSE_FINAL_WINNER = 'FETCH_CHOOSE_FINAL_WINNER';
export const FETCH_CHOOSE_FINAL_WINNER_FULFILLED = 'FETCH_CHOOSE_FINAL_WINNER_FULFILLED';
export const FETCH_CHOOSE_FINAL_WINNER_ERROR = 'FETCH_CHOOSE_FINAL_WINNER_ERROR';

// update stars of participations
export const FETCH_UPDATE_STARS_PARTICIPATION = 'FETCH_UPDATE_STARS_PARTICIPATION';
export const FETCH_UPDATE_STARS_PARTICIPATION_FULFILLED = 'FETCH_UPDATE_STARS_PARTICIPATION_FULFILLED';
export const FETCH_UPDATE_STARS_PARTICIPATION_ERROR = 'FETCH_UPDATE_STARS_PARTICIPATION_ERROR';

// Report a participation
export const FETCH_REPORT_PARTICIPATION = 'FETCH_REPORT_PARTICIPATION';
export const FETCH_REPORT_PARTICIPATION_FULFILLED = 'FETCH_REPORT_PARTICIPATION_FULFILLED';
export const FETCH_REPORT_PARTICIPATION_ERROR = 'FETCH_REPORT_PARTICIPATION_ERROR';

// create a message
export const FETCH_CREATE_MESSAGE = 'FETCH_CREATE_MESSAGE';
export const FETCH_CREATE_MESSAGE_FULFILLED = 'FETCH_CREATE_MESSAGE_FULFILLED';
export const FETCH_CREATE_MESSAGE_ERROR = 'FETCH_CREATE_MESSAGE_ERROR';

// read message byUser
export const FETCH_READ_MESSAGES_BY_USER = 'FETCH_READ_MESSAGES_BY_USER';
export const FETCH_READ_MESSAGES_BY_USER_FULFILLED = 'FETCH_READ_MESSAGES_BY_USER_FULFILLED';
export const FETCH_READ_MESSAGES_BY_USER_ERROR = 'FETCH_READ_MESSAGES_BY_USER_ERROR';

// read message by participation
export const FETCH_READ_MESSAGES_BY_PARTICIPATIONS = 'FETCH_READ_MESSAGES_BY_PARTICIPATIONS';
export const FETCH_READ_MESSAGES_BY_PARTICIPATIONS_FULFILLED = 'FETCH_READ_MESSAGES_BY_PARTICIPATIONS_FULFILLED';
export const FETCH_READ_MESSAGES_BY_PARTICIPATIONS_ERROR = 'FETCH_READ_MESSAGES_BY_PARTICIPATIONS_ERROR';

// read message by concours
export const FETCH_READ_MESSAGES_BY_CONCOURS = 'FETCH_READ_MESSAGES_BY_CONCOURS';
export const FETCH_READ_MESSAGES_BY_CONCOURS_FULFILLED = 'FETCH_READ_MESSAGES_BY_CONCOURS_FULFILLED';
export const FETCH_READ_MESSAGES_BY_CONCOURS_ERROR = 'FETCH_READ_MESSAGES_BY_CONCOURS_ERROR';

// read message byIdParticipation
export const FETCH_READ_MESSAGES_BY_ID_PARTICIPATION = 'FETCH_READ_MESSAGES_BY_ID_PARTICIPATION';
export const FETCH_READ_MESSAGES_BY_ID_PARTICIPATION_FULFILLED = 'FETCH_READ_MESSAGES_BY_ID_PARTICIPATION_FULFILLED';
export const FETCH_READ_MESSAGES_BY_ID_PARTICIPATION_ERROR = 'FETCH_READ_MESSAGES_BY_ID_PARTICIPATION_ERROR';

// add file to message by participation
export const FETCH_ADD_FILE_MESSAGE = 'FETCH_ADD_FILE_MESSAGE';
export const FETCH_ADD_FILE_MESSAGE_FULFILLED = 'FETCH_ADD_FILE_MESSAGE_FULFILLED';
export const FETCH_ADD_FILE_MESSAGE_ERROR = 'FETCH_ADD_FILE_MESSAGE_ERROR';

// add final file
export const FETCH_ADD_FINAL_FILE = 'FETCH_ADD_FINAL_FILE';
export const FETCH_ADD_FINAL_FILE_FULFILLED = 'FETCH_ADD_FINAL_FILE_FULFILLED';
export const FETCH_ADD_FINAL_FILE_ERROR = 'FETCH_ADD_FINAL_FILE_ERROR';

// PAYMENT

// create a payment
export const FETCH_CREATE_PAYMENT = 'FETCH_CREATE_PAYMENT';
export const FETCH_CREATE_PAYMENT_FULFILLED = 'FETCH_CREATE_PAYMENT_FULFILLED';
export const FETCH_CREATE_PAYMENT_ERROR = 'FETCH_CREATE_PAYMENT_ERROR';

// confirmation a payment
export const FETCH_CONFIRMATION_PAYMENT = 'FETCH_CONFIRMATION_PAYMENT';
export const FETCH_CONFIRMATION_PAYMENT_FULFILLED = 'FETCH_CONFIRMATION_PAYMENT_FULFILLED';
export const FETCH_CONFIRMATION_PAYMENT_ERROR = 'FETCH_CONFIRMATION_PAYMENT_ERROR';

// clear concours
export const FETCH_CONCOURS_CLEAR = 'FETCH_CONCOURS_CLEAR';
