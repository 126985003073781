import { withStyles } from '@material-ui/styles';

export default withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  iconButton: {
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.09), 0 1px 1px 0 rgba(0, 0, 0, 0.12)',
    padding: '0.4rem',
    position: 'relative',
    left: '-4rem',
  },
  '@global': {
    '.MuiOutlinedInput-input': {
      width: '65%',
    },
  },
});
