import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import { Menu, MenuItem, Typography } from '@material-ui/core';
import Button from '../../_atoms/Button/button';

import withFilterConcours from './filter-concours.style';

const FilterConcours = ({
  classes,
  className,
  list,
  onChangeFilter,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [valueFilter, setValueFilter] = React.useState(0);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (key) => (e) => {
    setValueFilter(key);
    onChangeFilter(key); // Permet de renvoyer l'information key au composant parent
    handleClose();
  };

  return (
    <div className={cx(classes.root, className)} onMouseLeave={handleClose}>
      <Typography variant="body2" className={classes.textTrie}>Trier par</Typography>
      <Button className={classes.btnTrie} onClick={handleClick}><span style={{ fontSize: '1.4rem' }}>{list[valueFilter].text}</span></Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {
          list.map((item) => (
            <MenuItem onClick={handleChange(item.key)} key={item.key}><span className={classes.subTextTrie}>{item.text}</span></MenuItem>
          ))
        }
      </Menu>
    </div>
  );
};

FilterConcours.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  className: Proptypes.string,
  value: Proptypes.oneOfType([
    Proptypes.string,
    Proptypes.number,
  ]),
  list: Proptypes.arrayOf(Proptypes.object),
  onChangeFilter: Proptypes.func,
};

FilterConcours.defaultProps = {
  list: [],
  onChangeFilter: () => {},
};

export default withFilterConcours(FilterConcours);
