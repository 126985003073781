import logoCardCredit from '../assets/SVG/credit-card.svg';

export const typeCards = [
  {
    key: 1,
    text: 'Paiement par VISA/MASTERCARD',
    value: 'CB_VISA_MASTERCARD',
    icon: logoCardCredit,
  },
  {
    key: 2,
    text: 'Paiement par MAESTRO',
    value: 'MAESTRO',
    icon: logoCardCredit,
  },
  {
    key: 3,
    text: 'Paiement par AMEX',
    value: 'AMEX',
    icon: logoCardCredit,
  },
  {
    key: 4,
    text: 'Paiement par PAYLIB',
    value: 'PAYLIB',
    icon: logoCardCredit,
  },
];
