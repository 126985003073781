export const statut = {
  describeTitle: "Ces informations seront reprises lors de l'édition de factures. Celles-ci sont légalement requises, confidentielles, et ne seront pas revendus à des tiers. Veuillez à les remplir correctement",
  status: [
    {
      key: 0,
      value: 0,
      text: 'Particulier',
      textLegalisation: "Vous reconnaissez être informé des règles juridiques et fiscales de votre pays  de résidence applicables à votre activité sur notre  site. En particulier, si vous êtes un particulier résident en France, vous  reconnaissez ne pas entrer dans le champ d'application de la TVA au sens  de l'article 256 du CGI.",
    },
    {
      key: 1,
      value: 1,
      text: 'Auto-entrepreneur ou entreprise individuelle',
      textLegalisation: 'Ce dispositif dispense les entreprises de la déclaration et du paiement de la TVA sur les prestations ou ventes qu’elles réalisent à condition que le CA soit inférieur à 33 200 €, ou 35 200 € si le CA de l’antépénultième année n’excède pas 33 200 €. L’indication par erreur de la TVA sur une facture émise pour le compte d’un vendeur bénéficiant de ce régime rendrait celui-ci redevable de la taxe du seul fait de sa facturation.',
    },
    {
      key: 2,
      value: 2,
      text: 'Société (France uniquement)',
    },
    {
      key: 3,
      value: 3,
      text: 'Société (autres pays)',
    },
  ],
  describe: 'Ces informations servent à éditer les factures de vos prestations. Celles-ci sont légalement requises, confidentielles, et ne seront pas revendues à des tiers. Veillez à les remplir correctement. Attention : un seul compte par personne est permis.',
  birthday: 'Pour pouvoir participer aux concours, vous devez  avoir au moins 18 ans, sans quoi, il  vous sera impossible de récupérer vos gains.',
  certification: "Vous certifiez sur l'honneur que les informations renseignées sont exactes",
  ubo: {
    intro: 'Il vous est requis de faire une déclaration des bénéficiaires effectifs (UBO), c\'est-à-dire des personnes détenant au moins 25 % des parts de la société ou des droits de vote.',
    statutBeneficiaire: [
      {
        key: 0,
        value: 0,
        text: 'Je suis le seul bénéficiaire effectif',
      },
      {
        key: 1,
        value: 1,
        text: 'Je ne suis pas le seul bénéficiaire effectif',
      },
      {
        key: 2,
        value: 2,
        text: 'Je ne suis pas bénéficiaire effectif',
      },
      {
        key: 3,
        value: 3,
        text: 'Il n\'y a aucun bénéficiaire effectif',
      },
    ],
  },
};
