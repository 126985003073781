import React, { useEffect } from 'react';
import Proptypes from 'prop-types';

import DetailsConcours from '../../_organisms/DetailsConcours/details-concours';
import WrapperPageUser from '../../../containers/WrapperPageUser/wrapper-page-user.connect';
import Header from '../../_organisms/Header/header.connect';

import withModifyConcours from './modify-concours.style';

const ModifyConcours = ({
  classes, match, getConcoursById, history, modifyConcours,
}) => {
  const [companyName, setCompanyName] = React.useState('');
  const [companySkills, setCompanySkills] = React.useState('');
  const [target, setTarget] = React.useState('');
  const [cursorModern, setCursorModern] = React.useState(0);
  const [cursorYoung, setCursorYoung] = React.useState(0);
  const [cursorFun, setCursorFun] = React.useState(0);
  const [cursorLuxury, setCursorLuxury] = React.useState(0);

  const [concoursLocal, setConcoursLocal] = React.useState(null);

  useEffect(() => {
    const { params } = match;
    const { id } = params;

    console.log(params);
    getConcoursById(id, (data) => {
      setConcoursLocal(data);
      setCompanyName(data.companyName);
      setCompanySkills(data.companySkills);
      setTarget(data.target);
      setCursorModern(data.cursorModern);
      setCursorYoung(data.cursorYoung);
      setCursorFun(data.cursorFun);
      setCursorLuxury(data.cursorLuxury);
      console.log(data, 'concours modifyConcours');
    });
  }, [match, getConcoursById]);

  const handleChangeCompanyName = (event) => {
    setCompanyName(event.target.value);
  };

  const handleChangeCompanySkills = (event) => {
    setCompanySkills(event.target.value);
  };

  const handleChangeTarget = (event) => {
    setTarget(event.target.value);
  };

  const handleChangeCursorMordern = (_, newValue) => {
    setCursorModern(newValue);
  };

  const handleChangeCursorYoung = (_, newValue) => {
    setCursorYoung(newValue);
  };

  const handleChangeFun = (_, newValue) => {
    setCursorFun(newValue);
  };

  const handleChangeLuxury = (_, newValue) => {
    setCursorLuxury(newValue);
  };

  const handleClickModify = () => {
    modifyConcours({
      companyName,
      companySkills,
      target,
      idConcours: concoursLocal.id,
      cursorModern,
      cursorYoung,
      cursorFun,
      cursorLuxury,
    });
  };

  return (
    <WrapperPageUser>
      <Header title="Modifier le concours" history={history} />
      {
        concoursLocal && (
          <DetailsConcours
            classNameField={classes.field}
            classNameBtnSubmit={concoursLocal.status > 4 && classes.btnNoPointerEvent}
            lockedInput={concoursLocal.status > 4}
            valueCompanyName={companyName}
            valueCompanySkills={companySkills}
            valueTarget={target}
            valueCursorModern={cursorModern}
            valueCursorYoung={cursorYoung}
            valueCursorFun={cursorFun}
            valueCursorLuxury={cursorLuxury}
            onChangeCompanyName={handleChangeCompanyName}
            onChangeCompanySkills={handleChangeCompanySkills}
            onChangeTarget={handleChangeTarget}
            onChangeCursorModern={handleChangeCursorMordern}
            onChangeCursorYoung={handleChangeCursorYoung}
            onChangeFun={handleChangeFun}
            onChangeLuxury={handleChangeLuxury}
            onClickSubmit={handleClickModify}
            concours={concoursLocal}
            sendMessage={false}
            textSubmit={concoursLocal.status > 4 ? 'Gagnant déjà choisi' : 'Modifier'}
          />
        )
      }
    </WrapperPageUser>
  );
};

ModifyConcours.propTypes = {
  match: Proptypes.shape({
    path: Proptypes.string,
    url: Proptypes.string,
    isExact: Proptypes.bool,
    params: Proptypes.object,
  }),
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  getConcoursById: Proptypes.func,
  history: Proptypes.objectOf(
    Proptypes.oneOfType([
      Proptypes.number,
      Proptypes.func,
      Proptypes.object,
      Proptypes.string,
    ]),
  ),
  messageSuccess: Proptypes.string,
  modifyConcours: Proptypes.func,
};

export default withModifyConcours(ModifyConcours);
