import React from 'react';
import Proptypes from 'prop-types';
import moment from 'moment';
import cx from 'classnames';
import useForm from 'react-hook-form';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import InputForm from '../../_atoms/InputForm/input-form';
import Button from '../../_atoms/Button/button';
import withInscriptionForm from './inscription.style';

moment.locale('fr');

const InscriptionForm = ({
  classes, onSubmit, radioCGU, onChangeRadioCGU,
}) => {
  const {
    register, handleSubmit,
  } = useForm();

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <div>
        {
          /*
            <div className={classes.twoInput}>
              <InputForm name="lastName" id="lastName-register" autoComplete="off" type="text" label="Nom" className={classes.fieldsetInput} classNameInput={classes.input} refInput={register} />
              <InputForm name="firstName" id="firstName-register" type="text" label="Prénom" className={cx(classes.fieldsetInput, classes.inputMargin)} classNameInput={classes.input} refInput={register} />
            </div>
          */
        }
        <InputForm name="email" id="email-register" type="email" label="Email" className={classes.fieldsetInput} classNameInput={classes.input} fullWidth refInput={register} />
        <InputForm name="pseudo" id="pseudo-register" type="text" label="Pseudo" className={classes.fieldsetInput} classNameInput={classes.input} fullWidth refInput={register} />
        <div className={classes.twoInput}>
          <InputForm name="password" id="password-register" type="password" label="Mot de passe" className={classes.fieldsetInput} classNameInput={classes.input} refInput={register} />
          <InputForm name="confirmPassword" id="confirmPassword-register" type="password" label="Confirmation" className={cx(classes.fieldsetInput, classes.inputMargin)} classNameInput={classes.input} refInput={register} />
        </div>
      </div>
      <div className={classes.wrapperBtnContinuer}>
        <FormControlLabel
          control={<Checkbox classes={{ checked: classes.radioChecked }} icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} />}
          label="Vous acceptez les C.G.U de notre site"
          value={radioCGU}
          onChange={onChangeRadioCGU}
          name="CGUValidate"
          classes={{ label: classes.radioLabel }}
          className={classes.formControlLabel}
        />
        <Button className={classes.btnContinuer} type="submit">Continuer</Button>
      </div>
    </form>
  );
};

InscriptionForm.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  onSubmit: Proptypes.func,
  radioCGU: Proptypes.bool,
  onChangeRadioCGU: Proptypes.func,
};

InscriptionForm.defaultProps = {
  radioCGU: false,
  onChangeRadioCGU: () => {},
  onSubmit: () => {},
};

export default withInscriptionForm(InscriptionForm);
