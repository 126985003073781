import { withStyles } from '@material-ui/styles';

export default withStyles(({ breakpoints, palette }) => ({
  wrapper: {
    display: 'flex',
    color: 'white',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '5.8rem',
    fontFamily: 'Roboto, arial, sans-serif',
    width: '100vw',
    [breakpoints.down(750)]: {
      fontSize: '4.8rem',
    },
    [breakpoints.down(600)]: {
      fontSize: '3.6rem',
    },
  },
  contentText: {
    height: '22rem',
    width: '100%',
    textAlign: 'center',
  },
  text: {
    marginTop: '2rem',
    fontSize: '3rem',
    fontFamily: 'Helvetica, arial, sans-serif',
    fontWeight: 'normal',
    whiteSpace: 'break-spaces',
    lineHeight: 'normal',
    [breakpoints.down(750)]: {
      fontSize: '2.2rem',
    },
  },
  btnInscription: {
    color: palette.secondary.main,
    backgroundColor: palette.common.white,
    marginTop: '3rem',
    fontSize: '3rem',
    transition: '0.2s background-color ease-in-out',
    '&:hover': {
      backgroundColor: palette.secondary.light,
    },
  },
  labelInscription: {
    fontSize: '2rem',
  },
  picture: {
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center',
  },
  imageIllustration: {
    width: '100%',
    height: '100%',
    maxWidth: '100rem',
  },
  link: {
    height: 0,
    textDecoration: 'none',
  },
}));
