import React from 'react';
import useForm from 'react-hook-form';

import Proptypes from 'prop-types';
import cx from 'classnames';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import withConnexionForm from './connexion.style';
import Button from '../../_atoms/Button/button';

const ConnexionForm = ({
  classes, onSubmit, msgSuccess, onClose, onClickForget, isForgetPassword,
}) => {
  const {
    register, handleSubmit, errors,
  } = useForm();

  return (
    <form
      autoComplete="off"
      className={classes.form}
      onSubmit={handleSubmit(onSubmit)}
    >
      <TextField
        id="email"
        name="email"
        type="email"
        className={classes.textField}
        label="E-mail"
        margin="normal"
        variant="outlined"
        fullWidth
        inputRef={register({ required: true })}
        error={!!errors.email}
      />
      <div className={cx(classes.password, isForgetPassword && classes.noDisplay)}>
        <TextField
          id="password"
          name="password"
          type="password"
          autoComplete="off"
          className={classes.textField}
          label="Password"
          margin="normal"
          variant="outlined"
          fullWidth
          inputRef={register({ required: true })}
          error={!!errors.password}
        />
        <div className={classes.wrapperOublie}>
          <button type="button" className={classes.btnForget} onClick={onClickForget}>
            <span variant="body1" className={classes.oublie}>Oublié ?</span>
          </button>
        </div>
      </div>
      {
        msgSuccess ? (
          <Button className={classes.btnConnexion} width="25rem" type="submit" onClick={onClose}>{isForgetPassword ? 'Réinistialiser' : 'Connexion'}</Button>
        ) : (
          <Button className={classes.btnConnexion} width="25rem" type="submit">{isForgetPassword ? 'Réinistialiser' : 'Connexion'}</Button>
        )
      }
      <Typography variant="body1" className={classes.textNotMember} display="inline">Pas encore membre ?
        <Link to="/signup" className={classes.link}>
          <span className={classes.textInscription}> Inscrivez-vous. </span>
        </Link>
      </Typography>
    </form>
  );
};

ConnexionForm.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  onSubmit: Proptypes.func,
  onClickForget: Proptypes.func,
  msgSuccess: Proptypes.string,
  onClose: Proptypes.func,
  isForgetPassword: Proptypes.bool,
};

ConnexionForm.defaultProps = {
  isForgetPassword: false,
};

export default withConnexionForm(ConnexionForm);
