import React from 'react';
import cx from 'classnames';

import CountUp from 'react-countup';

import VisibilitySensor from 'react-visibility-sensor';

import Proptypes from 'prop-types';

import { Paper, Typography } from '@material-ui/core';

import withCardNetwork from './card-network.style';

const CardNetwork = ({
  classes, className, icon, number, textInfo,
}) => {
  // const [visibleSensor, setVisibleSensor] = React.useState(true);

  // const finishAnimation = () => setVisibleSensor(false);

  return (
    <Paper elevation={0} className={cx(classes.root, className)}>
      {icon}
      <div className={classes.infos}>
        <CountUp start={0} end={number} duration="2">

          {({ countUpRef, start }) => (
            <VisibilitySensor
              onChange={start}
              delayedCall
            >
              <Typography variant="body1" className={classes.number} ref={countUpRef} />
            </VisibilitySensor>
          )}
        </CountUp>
        <Typography variant="body1" className={classes.textInfo}>{textInfo}</Typography>
      </div>
    </Paper>
  );
};

CardNetwork.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  icon: Proptypes.node,
  className: Proptypes.string,
  number: Proptypes.oneOfType([
    Proptypes.string, Proptypes.number,
  ]),
  textInfo: Proptypes.string,
};

export default withCardNetwork(CardNetwork);
