import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import chevronLeft from '../../../assets/SVG/btn-back.svg';
import chevronRight from '../../../assets/SVG/btn-right.svg';
import CardCategoryConcours from '../../_molecules/CardCategoryConcours/card-category-concours';

import withCarousselCategoryConcours from './caroussel-category-organiser-concours.style';

class CarousselCategoryOrganiserConcours extends React.Component {
  constructor(props) {
    super(props);
    this.slider = React.createRef();
    this.sliderContent = React.createRef();
    this.sliderContentInner = React.createRef();
    this.card = React.createRef();
    this.deltaX = 0;
    this.time = null;
  }

  displayPicture = (event) => {
    const { list } = this.props;
    const { current } = this.card;
    if (event.target.alt.includes('left')) {
      this.deltaX -= current.offsetWidth + 20;

      this.deltaX %= (list.length - 1) * current.offsetWidth;

      if (this.deltaX < 0) {
        this.deltaX = 0;
      }
    } else if (event.target.alt.includes('right')) {
      this.deltaX += current.offsetWidth + 20;

      this.deltaX %= (list.length - 1) * (current.offsetWidth + 20);
    }

    this.sliderContentInner.current.style.marginLeft = `-${this.deltaX}px`;
  };

  handleMouseLeave = () => {
    this.time = setTimeout(() => {
      this.deltaX = 0;
      if (this.sliderContentInner.current) this.sliderContentInner.current.style.marginLeft = 0;
    }, 2000);
  }

  render() {
    const {
      classes, list, classNameRoot, onClick,
    } = this.props;
    return (
      <div className={cx(classes.root, classNameRoot)} onMouseLeave={this.handleMouseLeave} onMouseEnter={() => clearInterval(this.time)}>
        <div ref={this.slider} className={cx(classes.slider, classNameRoot)}>
          <div ref={this.sliderContent} className={classes.sliderContent}>
            <ul ref={this.sliderContentInner} className={cx(classes.list, classNameRoot)}>
              {
                list.map((item) => (
                  <li key={item.id}>
                    <CardCategoryConcours
                      key={item.id}
                      title={item.title}
                      price={item.price}
                      background={item.image}
                      className={classes.card}
                      ref={this.card}
                      onClick={(onClick(item))}
                    />
                  </li>
                ))
              }
            </ul>
          </div>
          {
            list.length > 2 && (
              <div className={classes.arrows}>
                <figure className={cx(classes.arrow, classes.arrowLeft)}>
                  <button type="button" onClick={this.displayPicture} className={classes.btnArrow}>
                    <img src={chevronLeft} className={classes.arrowIcon} alt="chevron-left" />
                  </button>
                </figure>
                <figure className={cx(classes.arrow, classes.arrowRight)}>
                  <button type="button" onClick={this.displayPicture} className={cx(classes.btnArrow, classes.btnArrowRight)}>
                    <img src={chevronRight} className={classes.arrowIcon} alt="chevron-right" />
                  </button>
                </figure>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

CarousselCategoryOrganiserConcours.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  classNameRoot: Proptypes.string,
  list: Proptypes.arrayOf(Proptypes.object),
  onClick: Proptypes.func,
};

CarousselCategoryOrganiserConcours.defaultProps = {
  onClick: () => {},
};

export default withCarousselCategoryConcours(CarousselCategoryOrganiserConcours);
