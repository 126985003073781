import React from 'react';
import Proptypes from 'prop-types';

import withCarousselCategory from './caroussel-category.style';
import CardCategory from '../../_molecules/CardCategory/card-category';
import CarousselWrapper from '../../_molecules/CarousselWrapper/caroussel-wrapper';

class CarousselCategory extends React.Component {
  constructor(props) {
    super(props);
    this.card = React.createRef();
    this.state = {
      categoryUser: [],
      listLocal: props.list.map((element) => (
        {
          ...element,
          active: false,
        }
      )),
    };
  }

  handleCallbackClick = (id) => {
    const { categoryUser, listLocal } = this.state;
    const newCategoryUser = categoryUser;
    const newList = listLocal;
    if (!categoryUser) {
      newCategoryUser.push(id);
    } else if (categoryUser.indexOf(id) === -1) {
      newCategoryUser.push(id);
      newList.map((element) => {
        const newElement = element;
        if (element.id === id) {
          newElement.active = true;
        }
        return newElement;
      });
    } else {
      const pos = categoryUser.indexOf(id);
      newCategoryUser.splice(pos, 1);
      newList.map((element) => {
        const newElement = element;
        if (element.id === id) {
          newElement.active = false;
        }
        return newElement;
      });
    }

    this.setState({
      categoryUser: newCategoryUser,
      listLocal: newList,
    });
  }

  render() {
    const {
      classes,
      classNameRoot,
      classNameUl,
      onlyTitle,
      onClickForUser,
      classNameArrows,
      onlyOneCard,
    } = this.props;
    const { listLocal, categoryUser } = this.state;
    return (
      <CarousselWrapper
        classNameRoot={classNameRoot}
        classNameUl={classNameUl}
        onClickForUser={onClickForUser}
        classNameArrows={classNameArrows}
        onlyTitle={onlyTitle}
        onlyOneCard={onlyOneCard}
        categoryUser={categoryUser}
        cardRef={this.card}
        numberOfCards={listLocal.length}
      >
        {
                listLocal.map((item) => (
                  <li key={item.id}>
                    <CardCategory
                      key={item.id}
                      id={item.id}
                      title={item.title}
                      price={item.price}
                      background={item.image}
                      className={classes.card}
                      ref={this.card}
                      active={item.active}
                      onlyTitle={onlyTitle}
                      callBackClick={this.handleCallbackClick}
                    />
                  </li>
                ))
        }
      </CarousselWrapper>
    );
  }
}

CarousselCategory.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  onlyTitle: Proptypes.bool,
  classNameRoot: Proptypes.string,
  classNameArrows: Proptypes.string,
  classNameUl: Proptypes.string,
  onClickForUser: Proptypes.func,
  onlyOneCard: Proptypes.bool,
  children: Proptypes.bool,
};

CarousselCategory.defaultProps = {
  onlyOneCard: false,
};

export default withCarousselCategory(CarousselCategory);
