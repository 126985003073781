import React from 'react';
import Proptypes from 'prop-types';
import cx from 'classnames';

import { Typography } from '@material-ui/core';
import withCardCategoryConcours from './card-category-concours.style';
import CardCategory from '../CardCategory/card-category';

const CardCategoryConcours = React.forwardRef(({
  classes, background, className, title, price, classNameCard, onClick,
}, ref) => (
  <div className={cx(classes.root, className)} ref={ref}>
    <CardCategory background={background} onlyBackground className={classNameCard} disabled={false} onClick={onClick} />
    <div className={classes.informations}>
      <Typography variant="h5" className={classes.title}>{title}</Typography>
      <Typography variant="body2" className={classes.text}>{`à partir de ${price}€`}</Typography>
    </div>
  </div>
));

CardCategoryConcours.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  background: Proptypes.string,
  className: Proptypes.string,
  classNameCard: Proptypes.string,
  title: Proptypes.string,
  price: Proptypes.oneOfType([
    Proptypes.string,
    Proptypes.number,
  ]),
  onClick: Proptypes.func,
};

CardCategoryConcours.defaultProps = {
  onClick: () => {},
};

export default withCardCategoryConcours(CardCategoryConcours);
