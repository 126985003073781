import React, { useState, useEffect } from 'react';
import Proptypes from 'prop-types';

import WrapperPageUser from '../../../containers/WrapperPageUser/wrapper-page-user.connect';
import Header from '../../_organisms/Header/header.connect';
import DisplayConcours from '../../_organisms/DisplayConcours/display-concours';
import { CardCategories } from '../../../constants/image';
import { categoriesConcours } from '../../../constants/categories';
import { ucFirst } from '../../../helpers/string';
import withAllConcours from './concours-by-sub.style';
import { triConcours } from '../../../constants/concours';

const ConcoursBySub = ({
  classes,
  history,
  match,
  getConcoursWithFilter,
  clearConcours,
  user,
}) => {
  const [error, setError] = useState(null);
  const [id, setId] = useState(null);
  const [page, setPage] = React.useState(1);
  const [isAllConcours, setIsAllConcours] = useState(true);
  const [categoryName, setCategoryName] = useState('');
  const [concoursLocal, setConcoursLocal] = useState(null);

  useEffect(() => {
    clearConcours();
    const { params } = match;
    const { category, subCategory } = params;
    // Si on demande d'observer tout les concours
    if (params.category === 'all') {
      getConcoursWithFilter({
        selector: 'createdAt', orderedBy: 'DESC',
      }, (data) => {
        console.log(data, 'allConcours');
        setConcoursLocal(data);
      });
    } else if (!subCategory) { // Si on cherche en fonction d'une category
      const categoryLower = category.toLowerCase();
      const findCategory = categoriesConcours.find((categoryArray) => categoryArray.to.toLowerCase() === categoryLower);
      setId(findCategory.id);
      if (!findCategory) {
        history.push({
          pathname: '/',
        });
      } else {
        getConcoursWithFilter({
          selector: 'createdAt',
          orderedBy: 'DESC',
          idCategory: findCategory.id,
        }, (data) => {
          setConcoursLocal(data);
          setError('');
          /* Algo utilisé pour la fonction concoursBbyCategory
          const concoursArray = [];
          data.subCategories.forEach(({ name, format, concours }) => {
            concours.forEach((singleConcours) => {
              // Je l'ai appelé sous-catégory pour qu'il respecte la même architecture que pour les concours par sous-category
              concoursArray.push({ ...singleConcours, subCategory: { name, format } });
            });
          });
          console.log(concoursArray);
          setConcoursLocal(concoursArray);
          */
        }, (err) => {
          setConcoursLocal([]);
          setError(err.error);
          setCategoryName(ucFirst(category.replace('-', ' ')));
        });
      }
    } else { // Si on cherche en fonction d'une sous catégory
      setIsAllConcours(false);
      const categoryLower = category.toLowerCase();
      const findCategory = categoriesConcours.find((categoryArray) => categoryArray.to.toLowerCase() === categoryLower);
      if (!findCategory) {
        history.push({
          pathname: '/',
        });
      } else {
        const subCategoryLower = subCategory.toLowerCase();
        const findSubCategory = findCategory.subCategories.find((subCategoryArray) => subCategoryArray.to.toLowerCase().split('/')[1] === subCategoryLower);
        const idSubCategory = findSubCategory.id;
        setId(idSubCategory);

        getConcoursWithFilter({
          selector: 'createdAt',
          orderedBy: 'DESC',
          idSubCategory,
        }, (data) => {
          setConcoursLocal(data);
          setError('');
        }, (err) => {
          setError(err.error);
          setConcoursLocal([]);
          setCategoryName(ucFirst(subCategory.replace('-', ' ')));
        });
      }
    }
  },
  [match, getConcoursWithFilter, isAllConcours, clearConcours, history]);

  const handleChangeFilter = (key) => {
    const { params } = match;
    const { category, subCategory } = params;

    getConcoursWithFilter({
      selector: triConcours[key].selector,
      orderedBy: triConcours[key].orderedBy,
      idCategory: !!category && id,
      idSubCategory: !!subCategory && id,
    }, (result) => {
      setConcoursLocal(result);
      console.log(result, 'concoursFiltered');
      setPage(1);
    }, (err) => {
      setError(err.error);
      console.log(err);
    });
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <WrapperPageUser>
      <div className={classes.root}>
        <Header title="Prêt à relever le défi ?" history={history} />
        <DisplayConcours
          concours={concoursLocal}
          history={history}
          list={CardCategories}
          titleConcours="Les concours ouverts: "
          error={error}
          isAllConcours={isAllConcours}
          categoryName={categoryName}
          nbrCardToDisplay={6}
          user={user}
          onChangeFilter={handleChangeFilter}
          page={page}
          onChangePage={handleChangePage}
        />
      </div>
    </WrapperPageUser>
  );
};

ConcoursBySub.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  history: Proptypes.objectOf(
    Proptypes.oneOfType([
      Proptypes.number,
      Proptypes.func,
      Proptypes.object,
      Proptypes.string,
    ]),
  ),
  concours: Proptypes.oneOfType([
    Proptypes.array,
    Proptypes.object,
  ]),
  match: Proptypes.shape({
    path: Proptypes.string,
    url: Proptypes.string,
    isExact: Proptypes.bool,
    params: Proptypes.object,
  }),
  getConcoursWithFilter: Proptypes.func,
  clearConcours: Proptypes.func,
  user: Proptypes.object,
};

ConcoursBySub.defaultProps = {
  getConcoursWithFilter: () => {},
  clearConcours: () => {},
};

export default withAllConcours(ConcoursBySub);
