import React from 'react';
import Proptypes from 'prop-types';
import Header from '../../_organisms/Header/header.connect';
import { CardCategories } from '../../../constants/image';
import Tutoriel from '../../_organisms/Tutoriel/tutoriel';
import DisplayConcours from '../../_organisms/DisplayConcours/display-concours';
import { triConcours } from '../../../constants/concours';

import withHome from '../Home/home.style';

const HomeUser = ({
  classes, concours, history, user, getConcoursWithFilter, finishTutorial, isFirstTime,
}) => {
  const [page, setPage] = React.useState(1);
  const [openFirstTuto, setOpenFirstTuto] = React.useState(true);
  const [openSecondTuto, setOpenSecondTuto] = React.useState(false);
  const [openThirdTuto, setOpenThirdTuto] = React.useState(false);

  const handleCloseTutoriel = () => {
    setOpenFirstTuto(false);
    setOpenSecondTuto(false);
    setOpenThirdTuto(false);
  };

  const handleClickSubmitFirstTuto = () => {
    handleCloseTutoriel();
    setOpenSecondTuto(true);
  };

  const handleClickSubmitSecondTuto = () => {
    handleCloseTutoriel();
    setOpenThirdTuto(true);
  };

  const handleClickSubmitThirdTuto = () => {
    finishTutorial(() => {
      handleCloseTutoriel();
    });
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleChangeFilter = (key) => {
    getConcoursWithFilter({
      selector: triConcours[key].selector,
      orderedBy: triConcours[key].orderedBy,
    }, (result) => {
      console.log(result, 'concoursFiltered');
      setPage(1);
    }, (err) => console.log(err));
  };

  return (
    <>
      <div className={classes.root}>
        <Header title="Prêt à relever le défi ?" history={history} />
        <DisplayConcours
          sliderCategories={false}
          list={CardCategories}
          history={history}
          concours={concours}
          titleConcours="Les concours ouverts : "
          onClickCarousselCategory={(id) => () => console.log(id)}
          user={user}
          onChangeFilter={handleChangeFilter}
          nbrCardToDisplay={6}
          onChangePage={handleChangePage}
          page={page}
        />
        <Tutoriel
          openFirstTuto={isFirstTime && openFirstTuto}
          openSecondTuto={openSecondTuto}
          openThirdTuto={openThirdTuto}
          onClose={handleCloseTutoriel}
          onClickSubmitFirstTuto={handleClickSubmitFirstTuto}
          onClickSubmitSecondTuto={handleClickSubmitSecondTuto}
          onClickSubmitThirdTuto={handleClickSubmitThirdTuto}
        />
      </div>
    </>
  );
};


HomeUser.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  history: Proptypes.objectOf(
    Proptypes.oneOfType([
      Proptypes.number,
      Proptypes.func,
      Proptypes.object,
      Proptypes.string,
    ]),
  ),
  concours: Proptypes.oneOfType([
    Proptypes.array,
    Proptypes.object,
  ]),
  user: Proptypes.object,
  getConcoursWithFilter: Proptypes.func,
  finishTutorial: Proptypes.func,
  isFirstTime: Proptypes.bool,
};

export default withHome(HomeUser);
