import { connect } from 'react-redux';
import WrapperPageUser from './wrapper-page-user';

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(
  mapStateToProps,
  null,
)(WrapperPageUser);
