import { connect } from 'react-redux';
import Step3 from './step3';

import { getSubCategoryById } from '../../../actions/subCategory';

const mapStateToProps = ({ subCategory }) => ({
  subCategory: subCategory.subCategory,
});

export default connect(
  mapStateToProps,
  { getSubCategoryById },
)(Step3);
