import { withStyles } from '@material-ui/core';

export default withStyles(({ breakpoints, palette }) => ({
  containerHeader: {
    background: palette.gradient.main,
    height: '12rem',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);',
    [breakpoints.down('xs')]: {
      height: '8rem',
    },
  },
  paper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2rem',
    padding: '5rem',
  },
  wrapperImg: {
  },
  wrapperForm: {
    alignSelf: 'center',
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  img: {
    width: '100%',
  },
  title: {
    textAlign: 'center',
    color: palette.secondary.main,
    fontWeight: 'bold',
  },
  form: {
    marginLeft: '2rem',
  },
  fieldsetInput: {
    boxShadow: '4px 4px 20px 0 #cfcfcf',
    margin: '2rem 0 0 0',
    padding: 0,
    transition: '0.2s opacity ease-in-out',
    [breakpoints.down('xs')]: {
      width: '100%',
    },
    '&:hover': {
      opacity: 0.7,
      boxShadow: '4px 4px 20px 0 #cfcfcf',
    },
  },
  input: {
    '&::placeholder': {
      color: 'black',
      opacity: 0.4,
    },
  },
  inputMargin: {
    marginLeft: '2rem',
    [breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  twoInput: {
    display: 'flex',
    [breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  formControlLabel: {
    marginTop: '2rem',
  },
  radioLabel: {
    fontWeight: 'bold',
    color: palette.grey[600],
    fontSize: '1.4rem',
  },
  radioChecked: {
    '&, & + $radioLabel': { // div + p permettra de cibler n'importe quel élément <p> qui suit immédiatement un élément <div>.
      color: palette.secondary.main,
    },
  },
  wrapperBtnContinuer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnContinuer: {
    marginTop: '1rem',
    transition: '0.2s opacity ease-in-out',
    '&:hover': {
      opacity: 0.8,
    },
  },
}));
