export const contentHomeGuest = {
  listCardInformation: {
    title: 'Un concept simple pour vous aider à lancer votre projet',
  },
  sliderCategories: {
    title: 'Plus de 50 catégories disponibles',
  },
  satisfaction: {
    title: 'La garantie de votre satisfaction',
    list: [
      {
        key: 1,
        title: "Profitez d'un réseau de professionel prêt à en découdre pour vous satisfaire.",
      },
      {
        key: 2,
        title: 'Délais, prix et vainqueurs, vous restez maître de la situation.',
      },
      {
        key: 3,
        title: "Un accompagnement complet depuis la rédaction de votre brief jusqu'à la livraison de la solution",
      },
    ],
  },
  network: {
    title: 'Un réseau de talents à votre portée',
    detailsText: 'La créativité n\'a pas de limite et vous le verrez bien: '
      + 'chaque proposition reçue sera pour vous une nouvelle façon de donner vie à vos projets.',
  },
  testimonials: [{
    key: 1,
    title: '« J’ai reçu plus de 45 propositions\n de logos en 48h »',
    source: 'Benjamin, Fondateur d’un site E-commerce',
  }, {
    key: 2,
    title: '«  Concoureur.com m’a permis d’illustrer mon projet d’application »',
    source: 'Lea, porteuse de projet',
  }, {
    key: 3,
    title: '«  3 Un réseaux de talents à votre portée et en toute circonstances »',
    source: 'Jacquess DUFOUR @Président Les Entrep’ Ile de France',
  },
  ],
};

export const carousselTextCategory = ['Logo', 'Design site web', 'Slogan', 'Livre audio', 'Article de Blog', 'Montage Vidéo'];
