import { withStyles } from '@material-ui/styles';

export default withStyles({
  form: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '25rem',
  },
  textField: {
    marginTop: '0.5rem',
  },
  btn: {
    width: '100%',
    margin: '0 auto',
  },
});
