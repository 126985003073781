import { withStyles } from '@material-ui/core/styles';
// import herobg from '../../../assets/Fond/hero-bg-shape.svg';
import herobg from '../../../assets/Fond/bg-gradient-home.svg';
import { mobileSectionHeader, responsiveHome } from '../../../constants/size';

export default withStyles(({ breakpoints, palette }) => ({
  root: {
    width: '100%',
  },
  top: {
    backgroundImage: `url(${herobg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    zIndex: 1,
    width: '100%',
    height: '100%',
    backgroundPosition: 'center bottom 10rem',
    position: 'relative',
    overflow: 'visible',
    paddingBottom: 0,
  },
  header: {
    height: '12rem',
    [breakpoints.down(mobileSectionHeader)]: {
      height: '8rem',
    },
  },
  topBody: {
    width: '90%',
    margin: '0 auto',
  },
  middleBody: {
    [breakpoints.down('sm')]: {
      marginTop: '2rem',
    },
  },
  concept: {
    marginTop: '7rem',
  },
  sliderCategories: {
    marginTop: '4rem',
    [breakpoints.down(mobileSectionHeader)]: {
      marginTop: '2rem',
    },
  },
  titleH2: {
    textAlign: 'center',
    fontWeight: 'bold',
    color: palette.grey[800],
    padding: '0 1rem',
    fontSize: '2.6rem',
  },
  noPadding: {
    padding: 0,
  },
  titleMiddle: {
    marginTop: '1.2rem',
  },
  divider: {
    margin: '3rem auto',
    marginBottom: '1rem',
    display: 'block',
    width: '20rem',
  },
  listCard: {
    marginTop: '3rem',
  },
  network: {
    marginTop: '3rem',
    padding: '5rem',
    backgroundColor: '#fef6f6',
    display: 'flex',
    justifyContent: 'space-around',
    [breakpoints.down(responsiveHome)]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  leftNetwork: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  detailsTextNetwork: {
    marginTop: '2rem',
    fontSize: '1.8rem',
    color: palette.grey[800],
    maxWidth: '40rem',
    textAlign: 'justify',
    whiteSpace: 'pre-wrap',
    [breakpoints.down(responsiveHome)]: {
      textAlign: 'center',
    },
  },
  imageIllustration: {
    marginLeft: '1rem',
    width: '50%',
    height: '50%',
    [breakpoints.down(responsiveHome)]: {
      marginLeft: 0,
      marginTop: '2rem',
      width: '100%',
      height: '100%',
    },
  },
  statsNumber: {
    width: '40rem',
    marginTop: '3rem',
    display: 'flex',
    justifyContent: 'space-around',
    [breakpoints.down(responsiveHome)]: {
      minWidth: '30rem',
      width: '80vw',
    },
  },
  informations: {
    margin: '3rem 0',
  },
  listConcept: {
    marginTop: '4rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  listInformations: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%',
    margin: '4rem auto 0 auto',
    [breakpoints.down(responsiveHome)]: {
      margin: '2rem auto',
      flexDirection: 'column',
    },
  },
  titleSatisfaction: {
    color: palette.secondary.main,
  },
  wrapperImg: {
    marginLeft: '2rem',
    width: '50%',
    [breakpoints.down(responsiveHome)]: {
      marginLeft: 0,
      width: '100%',
    },
  },
  img: {
    width: '100%',
  },
  listContent: {
    width: '50%',
    [breakpoints.down(responsiveHome)]: {
      width: '100%',
      marginTop: '2rem',
    },
  },
  itemWithIcon: {
    marginTop: '1rem',
  },
  auth: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '3rem',
    alignItems: 'center',
  },
  textAuth: {
    marginLeft: '1rem',
    fontWeight: 'bold',
    fontSize: '1.4rem',
    color: palette.grey[800],
    textAlign: 'center',
  },
  btnInscription: {
    transition: '0.2s opacity ease-in-out',
    '&:hover': {
      opacity: 0.8,
    },
  },
  textAuthConnection: {
    border: 'none',
    fontSize: '1.4rem',
    fontWeight: 'bold',
    fontFamily: 'Futura, Helvetica',
    color: palette.primary.main,
    textDecoration: 'underline',
    background: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  testimonials: {
    padding: '2rem 0',
    background: '#fef6f6',
  },
  link: {
    textDecoration: 'none',
  },
  categories: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0',
  },
  howDidRoot: {
    width: '40rem',
    [breakpoints.down('sm')]: {
      width: '60rem',
    },
    [breakpoints.down(650)]: {
      width: '95vw',
    },
  },
  howDidPaperChildren: {
    [breakpoints.down('sm')]: {
      marginTop: '-5rem',
      padding: '2rem',
    },
    [breakpoints.down(650)]: {
      marginTop: '-10vw',
    },
  },
  howDidTitle: {
    marginTop: '-14rem',
    [breakpoints.down('sm')]: {
      marginTop: '-20rem',
    },
    [breakpoints.down(650)]: {
      marginTop: '-32vw',
    },
    [breakpoints.down(430)]: {
      marginTop: '-37vw',
    },
  },
  howDidNumeroMessage: {
    fontWeight: 'bold',
    fontSize: '1.4rem',
    textAlign: 'center',
  },
  howDidFirstMessage: {
    paddingLeft: '2rem',
    [breakpoints.down('sm')]: {
      padding: 0,
      width: '80%',
      margin: '0 auto',
    },
  },
  howDidMessage: {
    paddingRight: '2rem',
    fontWeight: 'bold',
    color: palette.grey[800],
    fontSize: '1.2rem',
    textAlign: 'center',
    [breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  howDidWrapperMessages: {
    [breakpoints.down('sm')]: {
      display: 'flex',
    },
    [breakpoints.down(650)]: {
      flexDirection: 'column',
    },
  },
  howDidWrapperMessage: {
    padding: '2rem',
    display: 'flex',
    alignItems: 'center',
    [breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      marginTop: '2rem',
      padding: 0,
    },
  },
  howDidMessages: {
    [breakpoints.down(650)]: {
      marginTop: '1rem',
    },
  },
  howDidImg: {
    width: '40%',
    [breakpoints.down('sm')]: {
      width: '18rem',
    },
  },
  btnArrow: {
    margin: 0,
    padding: 0,
    background: 'none',
    border: 'none',
    transition: '0.2s opacity ease-in-out',
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  arrow: {
    display: 'flex',
    pointerEvents: 'initial',
    margin: 0,
    padding: 0,
    width: 0,
    position: 'relative',
    zIndex: 2,
    left: '-6%',
    top: '-30rem',
    [breakpoints.down('sm')]: {
      left: '-8%',
      top: '-18rem',
    },
    [breakpoints.down(650)]: {
      left: '-8%',
      top: '-30rem',
    },
  },
  arrowIcon: {
    width: '5rem',
  },
}));
