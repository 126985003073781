import { withStyles } from '@material-ui/styles';

export default withStyles(({ palette, breakpoints }) => ({
  root: {
    width: '80vw',
    maxWidth: '70rem',
    padding: '3rem',
    margin: '3rem auto',
  },
  wrapperTitle: {

  },
  title: {
    fontSize: '2rem',
    color: palette.grey[800],
    fontWeight: 'bold',
  },
  text: {
    fontSize: '1.4rem',
    color: palette.grey[800],
    marginTop: '1rem',
    marginBottom: '2rem',
  },
  textLegalisation: {
    background: 'rgba(239, 239, 239, 0.5)',
    padding: '1rem',
  },
  wrapperStatut: {

  },
  subTitle: {
    fontSize: '1.6rem',
    color: palette.grey[800],
    fontWeight: 'bold',
    alignSelf: 'flex-start',
  },
  subSubTitle: {
    fontSize: '1.4rem',
    color: palette.grey[800],
    fontWeight: 'bold',
    alignSelf: 'flex-start',
  },
  radioLabel: {
    fontWeight: 'bold',
    color: palette.grey[600],
    fontSize: '1.4rem',
  },
  wrapperBirthday: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  marginTop2: {
    marginTop: '2rem',
  },
  marginTop4: {
    marginTop: '4rem',
  },
  datePicker: {
    width: '20rem',
    marginTop: '2rem',
  },
  center: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '2rem',
    marginTop: '1rem',
    alignItems: 'baseline',
    width: '100%',
    [breakpoints.down(800)]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  twoColumns: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    alignItems: 'flex-start',
    [breakpoints.down(800)]: {
      width: '100%',
      alignItems: 'center',
    },
  },
  twoColumnsPart2: {
    alignItems: 'flex-end',
    [breakpoints.down(800)]: {
      alignItems: 'center',
    },
  },
  textField: {
    width: '80%',
    marginTop: '1rem',
  },
  width30: {
    width: '30%',
    [breakpoints.down(800)]: {
      width: '80%',
    },
  },
  biggerTextField: {
    width: '40%',
    [breakpoints.down(800)]: {
      width: '80%',
    },
  },
  fullWidthTextField: {
    [breakpoints.up(800)]: {
      width: '100%',
    },
  },
  formBottom: {
    marginTop: '6rem',
    display: 'flex',
    [breakpoints.down(800)]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  button: {
    [breakpoints.down(800)]: {
      marginTop: '2rem',
    },
  },
  buttonBeneficiaire: {
    background: 'transparent',
    width: 'auto',
    padding: 0,
  },
  textBtn: {
    fontSize: '1.2rem',
  },
  textBtnBeneficiaire: {
    color: palette.secondary.main,
  },
}));
