import How1 from '../assets/SVG/How1.svg';
import How2 from '../assets/SVG/How2.svg';
import How3 from '../assets/SVG/How3.svg';

export const howDidText = {
  title: 'Comment ça marche ?',
  littleText: 'Vous avez du talent et vous pensez être la personne idéal pour gagner le concours ?',
  message1: {
    text: "Analysez la demande de l'organisateur et posez lui des questions si besoin.",
    image: How1,
  },
  message2: {
    text: 'Envoyez votre proposition directement depuis la plateforme.',
    image: How2,
  },
  message3: {
    text: 'Vous faites partis des heureux gagnants ? Finalisez votre travail et remportez la mise !',
    image: How3,
  },
};

export const tutoriel = {
  title: 'Comment participer à un concours ?',
  detailsText: 'Vous avez du talent et vous pensez être la personne idéal pour gagner le concours ? \n '
    + 'Voici nos conseils pour mettre toutes les chances de vôtre coté :',
  tuto1: {
    textIllustration: 'Analysez la demande de l’organisateur et posez lui des questions si besoin',
    illustration: How1,
    textButton: 'Suivant',
  },
  tuto2: {
    textIllustration: 'Envoyez votre proposition directement depuis la plateforme',
    illustration: How2,
    textButton: 'Suivant',
  },
  tuto3: {
    textIllustration: 'Vous faites partis des heureux gagnants ?\n'
      + 'Finalisez votre travail et remportez la mise !',
    illustration: How3,
    textButton: "C'est parti !",
  },
};
