import React, { useState } from 'react';
import Proptypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import WrapperPageInfo from '../../_organisms/PageInfoSuccess/page-info-success.connect';

const ValidateAuth = ({ location }) => {
  const [openConnexion, setOpenConnexion] = useState(false);

  const handleOpenConnexion = () => {
    setOpenConnexion(true);
  };

  const handleCloseHeader = () => {
    setOpenConnexion(false);
  };

  return (
    <>
      {
        (!!location.state && !!location.state.text) ? (
          <WrapperPageInfo text={location.state.text} title={location.state.title} open={openConnexion} onClose={handleCloseHeader} onConnexion={handleOpenConnexion} />
        ) : (
          <Redirect to="/" />
        )
      }
    </>
  );
};

ValidateAuth.propTypes = {
  location: Proptypes.objectOf(
    Proptypes.oneOfType([
      Proptypes.string, Proptypes.object,
    ]),
  ),
};

export default ValidateAuth;
