import { withStyles } from '@material-ui/core';

export default withStyles(({ spacing, palette, breakpoints }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  slider: {
    margin: '0 auto',
    width: '62vw',
    position: 'relative',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    [breakpoints.down('sm')]: {
      width: '63rem',
    },
    [breakpoints.down(650)]: {
      width: '92vw',
    },
  },
  sliderContent: {
    overflow: 'hidden',
    fontSize: 0,
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
  },
  list: {
    margin: 0,
    padding: 0,
    display: 'flex',
    whiteSpace: 'nowrap',
    transition: 'margin 1s ease 0s',
  },
  card: {
    zIndex: 1,
    marginRight: '2rem',
  },
  arrows: {
    display: 'flex',
    pointerEvents: 'none',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'absolute',
    width: '100%',
    top: '30%',
    left: 0,
  },
  btnArrow: {
    margin: 0,
    padding: 0,
    background: 'none',
    border: 'none',
    transition: '0.2s opacity ease-in-out',
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  arrow: {
    display: 'flex',
    pointerEvents: 'initial',
    margin: 0,
    padding: 0,
    width: 0,
    position: 'relative',
    left: -spacing(3),
    zIndex: 2,
  },
  arrowRight: {
    left: '-32px',
  },
  arrowIcon: {
    width: '5rem',
  },
}));
