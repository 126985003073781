import React from 'react';
import Proptypes from 'prop-types';
import cx from 'classnames';

import { Paper, Typography } from '@material-ui/core';

import withPaperPaiement from './paper-paiement.style';

const PaperPaiement = ({
  classes, className, text, onClick,
}) => (
  <Paper className={cx(classes.root, className)}>
    <button className={classes.wrapperLogo} onClick={onClick} type="button">
      <div className={classes.logo} />
      <Typography variant="h3" className={classes.text}>{text}</Typography>
    </button>
  </Paper>
);

PaperPaiement.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  className: Proptypes.string,
  icon: Proptypes.node,
  text: Proptypes.string,
  onClick: Proptypes.func,
};

PaperPaiement.defaultProps = {
  onClick: () => {},
};

export default withPaperPaiement(PaperPaiement);
