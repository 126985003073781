import React from 'react';
import Proptypes from 'prop-types';

import Header from '../../../_organisms/Header/header.connect';
import WrapperPageUser from '../../../../containers/WrapperPageUser/wrapper-page-user.connect';
import TabsPerso from '../../../_organisms/Tabs/tabs';

import { parseTabsMyConcours } from '../../../../helpers/concours';
import withMyConcours from './my-concours.style';

const MyConcours = ({
  concoursByUser,
  concoursParticiped,
  history,
}) => (
  <>
    <WrapperPageUser>
      <Header title="Mes concours" history={history} />
      {
        concoursByUser && concoursParticiped && (
          <>
            <TabsPerso
              tabsPannel={parseTabsMyConcours(concoursByUser, concoursParticiped)}
              toConcours
              history={history}
              tabs={[
                {
                  key: 0,
                  label: 'Organisateur',
                },
                {
                  key: 1,
                  label: 'Participant',
                },
              ]}
            />
          </>
        )
      }
    </WrapperPageUser>
  </>
);

MyConcours.propTypes = {
  history: Proptypes.objectOf(
    Proptypes.oneOfType([
      Proptypes.number,
      Proptypes.func,
      Proptypes.object,
      Proptypes.string,
    ]),
  ),
  concoursByUser: Proptypes.array,
  concoursParticiped: Proptypes.array,
};

export default withMyConcours(MyConcours);
