import { withStyles } from '@material-ui/core';

export default withStyles(({ breakpoints, palette }) => ({
  wrapBodyStep1: {
    marginTop: '4rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  category: {

  },
  title: {
    fontSize: '2rem',
    color: palette.grey[800],
    textAlign: 'center',
  },
  listCategoryConcours: {
    marginTop: '1rem',
  },
  navBarConcours: {
    marginTop: '2rem',
  },
  wrapBodyStep2: {
    marginTop: '4rem',
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
  },
  titleInput: {
    marginTop: '2rem',
  },
  fieldsetInput: {
    boxShadow: '4px 4px 20px 0 #cfcfcf',
    margin: '2rem 0',
    padding: 0,
    transition: '0.2s opacity ease-in-out',
    [breakpoints.down('xs')]: {
      width: '100%',
    },
    '&:hover': {
      opacity: 0.7,
      boxShadow: '4px 4px 20px 0 #cfcfcf',
    },
  },
  input: {
    '&::placeholder': {
      color: 'black !important',
      opacity: 0.4,
    },
  },
  sliderInfo: {
    margin: '3rem 2rem 2rem 2rem',
    width: '100%',
  },
  btnContinuer: {
    margin: '2rem auto',
    transition: '0.2s opacity ease-in-out',
    '&:hover': {
      opacity: '0.8',
    },
  },
  wrapBodyStep3: {
    flexDirection: 'row',
    [breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  step3Left: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleStep3Left: {
    textAlign: 'center',
  },
  wrapperOffreBase: {

  },
  listOffre: {
    color: palette.grey[600],
    fontWeight: 'bold',
    fontSize: '1.4rem',
  },
  itemOffre: {
    '&::before': {
      content: '"- "',
    },
  },
  preRequis: {
    [breakpoints.down('xs')]: {
      marginTop: '1rem',
    },
  },
  step3Right: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    [breakpoints.down('xs')]: {
      marginTop: '1rem',
    },
  },
  wrapperDivider: {
    margin: '3rem 6rem',
    [breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  divider: {
    width: '1rem',
  },
  detailsRight: {
    marginTop: '2rem',
    marginBottom: '2rem',
  },
  options: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  radioChecked: {
    '&, & + $radioLabel': { // div + p permettra de cibler n'importe quel élément <p> qui suit immédiatement un élément <div>.
      color: palette.secondary.main,
    },
  },
  detailPrice: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    color: palette.grey[600],
    fontSize: '1.2rem',
    marginTop: '3rem',
  },
  priceStep3: {
    fontSize: '2rem',
    color: palette.secondary.main,
    marginLeft: '0.5rem',
  },
  date: {
    color: palette.secondary.main,
    fontWeight: 'bold',
    fontSize: '2rem',
    marginTop: '2rem',
  },
  details: {
    fontWeight: 'bold',
    color: palette.grey[600],
    width: '80%',
    marginTop: '2rem',
    textAlign: 'center',
    fontSize: '1.4rem',
  },
  colorPrimary: {
    color: palette.secondary.main,
  },
  datePicker: {
    width: '20rem',
    marginTop: '2rem',
  },
  textField: {
    marginTop: '2rem',
    width: '20rem',
  },
  wrapperDatePicker: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  wrapperCost: {
    marginTop: '4rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [breakpoints.down('xs')]: {
      marginTop: '2rem',
    },
  },
  wrapperRulesChoice: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '70%',
    justifyContent: 'space-around',
  },
  formControlLabel: {
    marginTop: '2rem',
  },
  radioLabel: {
    fontWeight: 'bold',
    color: palette.grey[600],
    fontSize: '1.4rem',
  },
  formBottom: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '4rem 0',
  },
  btnStep: {
    marginTop: '1rem',
    transition: '0.2s opacity ease-in-out',
    '&:hover': {
      opacity: '0.8',
    },
  },
  step5Left: {
    // width: '70%',
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  wrapperStep5Details: {
    margin: '3rem',
  },
  informationTitleStep5: {
    fontWeight: 'bold',
    fontSize: '1.4rem',
    color: palette.grey[600],
    marginTop: '2rem',
    textAlign: 'center',
  },
  informationStep5: {
    fontWeight: 'normal',
  },
  paiement: {
    display: 'flex',
    marginTop: '1rem',
    marginBottom: '3rem',
    flexWrap: 'wrap',
    gap: '5rem',
    [breakpoints.down(450)]: {
      flexDirection: 'column',
    },
  },
  paypal: {
    marginLeft: '2rem',
    [breakpoints.down(450)]: {
      marginLeft: 0,
      marginTop: '2rem',
    },
  },
  '@global': {
    // You should target [class*="MuiButton-root"] instead if you nest themes.
    // Gain pour le vainqueur
    '.MuiOutlinedInput-input': {
      textAlign: 'center',
    },
    // La date de fin du concours
    '.MuiOutlinedInput-inputAdornedStart': {
      textAlign: 'left',
    },
  },
}));
