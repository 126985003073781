import React from 'react';
import cx from 'classnames';
import { Motion, spring } from 'react-motion';
import Proptypes from 'prop-types';

import TileTestimonial from '../../_atoms/TileTestimonial/tile-testimonial';

// import chevronLeft from '../../../assets/SVG/btn-back.svg';
// import chevronRight from '../../../assets/SVG/btn-right.svg';

import withTestimonials from './testimonials.style';
import useWindowDimensions from '../../../containers/useWindowDimensions/use-window-dimensions';

const CAROUSEL_CONTENT = [
  (style) => (
    <TileTestimonial
      style={style}
      title="«  J’ai reçu plus de 45 propositions de logos en 48h » "
      source="Benjamin, Fondateur d’un site E-commerce"
    />
  ),
  (style) => (
    <TileTestimonial
      style={style}
      title="«  Concoureur.com m’a permis d’illustrer mon projet d’application » "
      source="Lea, Porteuse de projet"
    />
  ),
  (style) => (
    <TileTestimonial
      style={style}
      title="«  Concoureur c’est la simplicité d’utilisation, la transparence et surtout des économies » "
      source="Karim, Designer UX / UI"
    />
  ),
];

const Testimonials = ({
  classes, className,
}) => {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  /* const [timerId, setTimerId] = React.useState(null);

  const handleCarousselTurn = () => {
    let newCurrentSlide = currentSlide;

    if (currentSlide + 1 >= CAROUSEL_CONTENT.length) {
      newCurrentSlide = 0;
    } else {
      newCurrentSlide += 1;
    }

    setCurrentSlide(newCurrentSlide);
  };

  useEffect(() => {
    if (autoplay) {
      const newTimerId = setInterval(() => handleCarousselTurn(), timer);
      setTimerId(newTimerId);

      return clearInterval(timerId);
    }
  }, [timerId]);
*/
  const handleClick = (to) => () => {
    setCurrentSlide(to);
  };

  const handleClickLeft = () => {
    let newCurrentSlide = currentSlide;

    if (currentSlide === 0) {
      newCurrentSlide = CAROUSEL_CONTENT.length - 1;
    } else {
      newCurrentSlide -= 1;
    }

    setCurrentSlide(newCurrentSlide);
  };

  const handleClickRight = () => {
    let newCurrentSlide = currentSlide;

    if (currentSlide + 1 >= CAROUSEL_CONTENT.length) {
      newCurrentSlide = 0;
    } else {
      newCurrentSlide += 1;
    }
    setCurrentSlide(newCurrentSlide);
  };

  const indexArray = [...Array(CAROUSEL_CONTENT.length).keys()];

  const { width } = useWindowDimensions();

  const leftStartCoord = -1 * currentSlide * width * 0.8;

  const configs = [];
  CAROUSEL_CONTENT.reduce((prevLeft, _, i) => {
    configs.push({
      left: spring(prevLeft, { stiffness: 170, damping: 26 }),
    });
    return prevLeft + width * 0.8;
  }, leftStartCoord);

  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.motionContainer}>
        {configs.map((style, i) => (
          <Motion key={i} style={style}>
            {(style2) => (
              <div className={classes.motionInner} style={style2}>
                {CAROUSEL_CONTENT[i](style2)}
              </div>
            )}
          </Motion>
        ))}
      </div>

      <div className={classes.dots}>
        {indexArray.map((index) => (
          <button
            type="button"
            key={`dot-${index}`}
            onClick={(handleClick(index))}
            style={{
              marginLeft: '5px',
              cursor: 'pointer',
              backgroundColor: 'transparent',
              border: 'none',
              fontSize: '2.5rem',
              color: currentSlide === index ? '#fd374f' : '#ff96a1',
              textShadow: '0 3px 3px mistyrose',
            }}
          >
            &#9679;
          </button>
        ))}
      </div>
      {
        /*
          <div className={classes.arrows}>
          <figure className={cx(classes.arrow, classes.arrowLeft)}>
            <button type="button" onClick={handleClickLeft} className={classes.btnArrow}>
              <img src={chevronLeft} className={classes.arrowIcon} alt="chevron-left" />
            </button>
          </figure>
          <figure className={classes.arrow}>
            <button type="button" onClick={handleClickRight} className={cx(classes.btnArrow, classes.btnArrowRight)}>
              <img src={chevronRight} className={classes.arrowIcon} alt="chevron-right" />
            </button>
          </figure>
        </div>
         */
      }
    </div>
  );
};

Testimonials.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  autoplay: Proptypes.bool,
  timer: Proptypes.number,
  className: Proptypes.bool,
};

Testimonials.defaultProps = {
  timer: 5000,
};

export default withTestimonials(Testimonials);
