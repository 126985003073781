import { withStyles } from '@material-ui/styles';

export default withStyles(({ palette, shadows, spacing }) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: palette.background.paper,
    borderRadius: '2rem',
    boxShadow: shadows[5],
    padding: spacing(2, 4, 3),
    '&:focus': {
      outline: 'none',
    },
  },
  closeButton: {
    position: 'relative',
    left: '90%',
    bottom: '1.4rem',
    color: palette.grey[500],
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}));
