import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

import {
  Checkbox,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';

import WrapperPageUser from '../../../../containers/WrapperPageUser/wrapper-page-user.connect';
import Header from '../../../_organisms/Header/header.connect';

import { statut } from '../../../../constants/profil';

import DatePicker from '../../../_molecules/DatePicker/date-picker';
import Button from '../../../_atoms/Button/button';
import Coord from './Coord/coord';
import InfoComplementaireEnt from './InfoComplementaireEnt/info-complementaire-ent';
import Beneficiaire from './Beneficiaire/beneficiaire';
import SnackBarBottom from '../../../_atoms/SnackBarBottom/snack-bar-bottom';
import { ucFirst } from '../../../../helpers/string';
import { isObjEmpty } from '../../../../helpers/object';

import withStatut from './statut.style';

class Statut extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statutValue: 0,
      firstName: '',
      lastName: '',
      birthday: new Date('2000'),
      country: '',
      nationality: '',
      address: '',
      zip: '',
      city: '',
      countryCompany: '',
      addressCompany: '',
      zipCompany: '',
      cityCompany: '',
      radioValider: false,
      radioBeneTva: false,
      siret: '',
      tva: '',
      socialReason: '',
      legalStatus: '',
      statutBeneficiaire: 0,
      localBeneficiaire: [],
    };
  }

  componentDidMount() {
    const { getStatutUser, location } = this.props;
    window.setTimeout(() => {
      location.state = undefined;
    }, 500);
    console.log(location.state);

    getStatutUser((data) => {
      console.log(data, 'data');
      if (isObjEmpty(data) === false) {
        this.setState({
          socialReason: data.socialReason,
          legalStatus: data.legalStatus,
          addressCompany: data.address,
          zipCompany: data.zip.toString(),
          cityCompany: data.city,
          countryCompany: data.countryCompany,
          radioBeneTva: data.regimeTVA,
          tva: data.numTVA,
          siret: data.numSiret,
          statutValue: data.statut,
          statutBeneficiaire: data.statutBeneficiaire,
        });
        if (data.beneficiaires) {
          const beneficiaireGerant = data.beneficiaires.filter((elem) => elem.isGerant === true);
          const othersBeneficiaires = data.beneficiaires.filter((elem) => elem.isGerant === false);

          if (beneficiaireGerant) {
            const tmp = beneficiaireGerant[0];
            this.setState({
              firstName: tmp.firstName,
              lastName: tmp.lastName,
              birthday: tmp.birthday,
              country: tmp.country,
              nationality: tmp.nationality,
              address: tmp.address,
              zip: tmp.zip.toString(),
              city: tmp.city,
            });
          }

          if (othersBeneficiaires) {
            this.setState({
              localBeneficiaire: othersBeneficiaires,
            });
          }
        }
      }
    });
  }

  addBeneficiaire = () => {
    const { localBeneficiaire } = this.state;
    const beneficiaire = {
      firstName: '',
      lastName: '',
      nationality: '',
      address: '',
      zip: '',
      country: '',
      city: '',
      birthday: new Date('2000'),
      isGerant: false,
    };
    const tmp = localBeneficiaire;
    tmp.push(beneficiaire);
    this.setState({
      localBeneficiaire: tmp,
    });
  };

  handleChangeStatut = (event) => {
    this.setState({
      statutValue: parseInt(event.target.value, 10),
    });
  }

  handleChangeChoixBeneficiaire = (event) => {
    const newValue = parseInt(event.target.value, 10);
    this.setState({
      statutBeneficiaire: newValue,
    });
    if (newValue === 1 || newValue === 2) {
      const beneficiaire = {
        firstName: '',
        lastName: '',
        country: '',
        nationality: '',
        address: '',
        zip: '',
        city: '',
        isGerant: false,
        birthday: new Date('2000'),
      };
      this.setState({
        localBeneficiaire: [beneficiaire],
      });
    } else {
      this.setState({
        localBeneficiaire: [],
      });
    }
  }

  handleChange = (e) => {
    const { value } = e.target;
    this.setState({
      [e.target.name]: value,
    });
  }

  handleChangeElementBeneficiaire = (index) => (e) => {
    const { localBeneficiaire } = this.state;
    const tmp = localBeneficiaire;
    tmp[index][e.target.name] = e.target.value;

    this.setState({
      localBeneficiaire: tmp,
    });
  }

  handleChangeBirthdayBeneficiaire = (index) => (date) => {
    const { localBeneficiaire } = this.state;
    const tmp = localBeneficiaire;
    tmp[index].birthday = date;

    this.setState({
      localBeneficiaire: tmp,
    });
  }

  handleChangeBirthday = (date) => {
    this.setState({
      birthday: date,
    });
  }

  handleRadioBenefTva = () => {
    const { radioBeneTva } = this.state;
    if (radioBeneTva) this.setState({ radioBeneTva: false });
    else this.setState({ radioBeneTva: true });
  };

  handleRadioValider = () => {
    const { radioValider } = this.state;
    if (radioValider) this.setState({ radioValider: false });
    else this.setState({ radioValider: true });
  };

  handleSubmit = () => {
    const {
      statutValue,
      firstName,
      lastName,
      birthday,
      country,
      nationality,
      address,
      zip,
      city,
      countryCompany,
      addressCompany,
      zipCompany,
      cityCompany,
      radioValider,
      statutBeneficiaire,
      radioBeneTva,
      siret,
      tva,
      socialReason,
      legalStatus,
      localBeneficiaire,
    } = this.state;

    if (radioValider) {
      const beneficiaireGerant = {
        firstName: ucFirst(firstName),
        lastName: ucFirst(lastName),
        birthday,
        country,
        nationality,
        address,
        zip,
        city,
        isGerant: true,
      };

      const entreprise = {
        socialReason,
        legalStatus,
        statutBeneficiaire,
        address: addressCompany,
        country: countryCompany,
        city: cityCompany,
        zip: zipCompany,
        numSiret: siret,
        statut: statutValue,
        numTVA: tva,
        regimeTVA: radioBeneTva,
      };

      const { addStatut } = this.props;
      const beneficiaires = [beneficiaireGerant, ...localBeneficiaire];

      addStatut({
        ...entreprise,
        beneficiaires,
      });
    } else {
      this.setState({
        openErrorSnack: true,
      });
    }
  }

  handleCloseSnack = () => {
    const { location } = this.props;
    location.state = null;
    this.setState({
      openErrorSnack: false,
    });
  }

  render() {
    const {
      classes, user, history, location,
    } = this.props;
    const {
      statutValue,
      firstName,
      lastName,
      country,
      nationality,
      birthday,
      address,
      city,
      zip,
      countryCompany,
      addressCompany,
      zipCompany,
      cityCompany,
      tva,
      siret,
      socialReason,
      legalStatus,
      radioBeneTva,
      radioValider,
      statutBeneficiaire,
      localBeneficiaire,
      openErrorSnack,
    } = this.state;
    return (
      <>
        <Header title="Gérer votre profil" history={history} />
        <WrapperPageUser>
          <>
            {
              openErrorSnack && (
                <SnackBarBottom variant="error" open={openErrorSnack} onClose={this.handleCloseSnack} text="Vous n'avez pas certifié que les informations renseignées sont exactes !" />
              )
            }
            {
              location?.state && (
                <SnackBarBottom variant={location.state?.variant} open={!!location.state?.text} onClose={this.handleCloseSnack} text={location.state.text} />
              )
            }
          </>
          {
            user && (
              <Paper className={classes.root}>
                <div className={classes.wrapperTitle}>
                  <Typography variant="h1" className={classes.title}>Mes coordonnées</Typography>
                  <Typography variant="body1" className={classes.text}>{statut.describe}</Typography>
                </div>
                <div className={classes.wrapperStatut}>
                  <Typography variant="h2" className={classes.subTitle}>Statut</Typography>
                  <RadioGroup aria-label="Statut" name="statut" value={parseInt(statutValue, 10)} onChange={this.handleChangeStatut}>
                    {
                      statut.status.map((item) => (
                        <FormControlLabel key={item.key} value={parseInt(item.value, 10)} control={<Radio />} label={item.text} classes={{ label: classes.radioLabel }} className={classes.formControlLabel} />
                      ))
                    }
                  </RadioGroup>
                </div>
                <>
                  {
                    statutValue === 0 && (
                      <>
                        <div className={classes.marginTop2}>
                          <Typography variant="h2" className={classes.subTitle}>Coordonnées personnelles</Typography>
                          <Typography variant="body1" className={cx(classes.text, classes.textLegalisation)}>{statut.status[0].textLegalisation}</Typography>
                          <div className={classes.center}>
                            <Coord
                              infoPerso
                              firstName={firstName}
                              lastName={lastName}
                              nationality={nationality}
                              onChangeFirstName={this.handleChange}
                              onChangeLastName={this.handleChange}
                              onChangeNationality={this.handleChange}
                            />
                          </div>
                          <div className={classes.wrapperBirthday}>
                            <Typography variant="h3" className={classes.subSubTitle}>Date de naissance</Typography>
                            <Typography variant="body1" className={cx(classes.text, classes.textLegalisation)}>{statut.birthday}</Typography>
                            <DatePicker
                              className={classes.datePicker}
                              value={birthday}
                              onChange={this.handleChangeBirthday}
                              label="Date de naissance"
                              maxDate={new Date()}
                              minDate={new Date('1900')}
                            />
                          </div>
                        </div>
                        <div className={classes.marginTop2}>
                          <Typography variant="h3" className={classes.subSubTitle}>Adresse de résidence</Typography>
                          <div className={classes.center}>
                            <Coord
                              isAddressPerso
                              address={address}
                              city={city}
                              country={country}
                              zip={zip}
                              onChangeAddress={this.handleChange}
                              onChangeCity={this.handleChange}
                              onChangeCountry={this.handleChange}
                              onChangeZip={this.handleChange}
                            />
                          </div>
                        </div>
                      </>
                    )
                  }
                  {
                    statutValue === 1 && (
                      <>
                        <div className={classes.marginTop2}>
                          <Typography variant="h2" className={classes.subTitle}>Coordonnées personnelles</Typography>
                          <div className={classes.center}>
                            <Coord
                              infoPerso
                              firstName={firstName}
                              lastName={lastName}
                              nationality={nationality}
                              onChangeFirstName={this.handleChange}
                              onChangeLastName={this.handleChange}
                              onChangeNationality={this.handleChange}
                            />
                          </div>
                          <div className={classes.marginTop2}>
                            <Typography variant="h3" className={classes.subSubTitle}>Informations complémentaires</Typography>
                            <InfoComplementaireEnt
                              needTva
                              siret={siret}
                              tva={tva}
                              onChangeRadioBeneTva={this.handleRadioBenefTva}
                              radioBeneTva={radioBeneTva}
                              onChangeSiret={this.handleChange}
                              onChangeTva={this.handleChange}
                            />
                            <Typography variant="body1" className={cx(classes.text, classes.textLegalisation)}>{statut.status[1].textLegalisation}</Typography>
                          </div>
                          <div className={classes.wrapperBirthday}>
                            <Typography variant="h3" className={classes.subSubTitle}>Date de naissance</Typography>
                            <Typography variant="body1" className={cx(classes.text, classes.textLegalisation)}>{statut.birthday}</Typography>
                            <DatePicker
                              className={classes.datePicker}
                              value={birthday}
                              onChange={this.handleChangeBirthday}
                              label="Date de naissance"
                              maxDate={new Date()}
                              minDate={new Date('1900')}
                            />
                          </div>
                        </div>
                        <div className={classes.marginTop2}>
                          <Typography variant="h3" className={classes.subSubTitle}>Adresse de résidence</Typography>
                          <div className={classes.center}>
                            <Coord
                              isAddressPerso
                              address={address}
                              city={city}
                              country={country}
                              zip={zip}
                              onChangeAddress={this.handleChange}
                              onChangeCity={this.handleChange}
                              onChangeCountry={this.handleChange}
                              onChangeZip={this.handleChange}
                            />
                          </div>
                        </div>
                      </>
                    )
                  }
                  {
                    statutValue === 2 && (
                      <>
                        <div className={classes.marginTop2}>
                          <Typography variant="h2" className={classes.subTitle}>Coordonnées de l&apos;entreprise</Typography>
                          <div className={classes.center}>
                            <Coord
                              infoCompany
                              socialReason={socialReason}
                              legalStatus={legalStatus}
                              onChangeSocialReason={this.handleChange}
                              onChangeLegalStatus={this.handleChange}
                            />
                          </div>
                          <div className={classes.marginTop2}>
                            <div className={classes.center}>
                              <Coord
                                isAddressCompany
                                addressCompany={addressCompany}
                                cityCompany={cityCompany}
                                zipCompany={zipCompany}
                                countryCompany={countryCompany}
                                onChangeAddressCompany={this.handleChange}
                                onChangeCityCompany={this.handleChange}
                                onChangeZipCompany={this.handleChange}
                                onChangeCountryCompany={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className={classes.marginTop2}>
                            <Typography variant="h3" className={classes.subSubTitle}>Informations complémentaires</Typography>
                            <InfoComplementaireEnt
                              needTva
                              siret={siret}
                              tva={tva}
                              onChangeRadioBeneTva={this.handleRadioBenefTva}
                              radioBeneTva={radioBeneTva}
                              onChangeSiret={this.handleChange}
                              onChangeTva={this.handleChange}
                            />
                            <Typography variant="body1" className={cx(classes.text, classes.textLegalisation)}>{statut.status[1].textLegalisation}</Typography>
                          </div>
                        </div>
                        <Beneficiaire
                          className={classes.marginTop4}
                          statut={statut}
                          title="Représentant légal"
                          firstName={firstName}
                          lastName={lastName}
                          nationality={nationality}
                          onChangeFirstName={this.handleChange}
                          onChangeLastName={this.handleChange}
                          onChangeNationality={this.handleChange}
                          birthday={birthday}
                          statutBeneficiaire={statutBeneficiaire}
                          onChangeBirthday={this.handleChangeBirthday}
                          address={address}
                          city={city}
                          country={country}
                          zip={zip}
                          onChangeAddress={this.handleChange}
                          onChangeCity={this.handleChange}
                          onChangeCountry={this.handleChange}
                          onChangeZip={this.handleChange}
                          displayUbo
                          onChangeChoixBeneficiaire={this.handleChangeChoixBeneficiaire}
                        />
                        {
                          localBeneficiaire && localBeneficiaire.length > 0 && (
                            <>
                              {
                                localBeneficiaire.map((beneficiaire, index) => (
                                  <Beneficiaire
                                    className={classes.marginTop2}
                                    displayUbo={false}
                                    title="Bénéficiaire effectif"
                                    firstName={beneficiaire.firstName}
                                    lastName={beneficiaire.lastName}
                                    nationality={beneficiaire.nationality}
                                    statut={statut}
                                    onChangeFirstName={this.handleChangeElementBeneficiaire(index)}
                                    onChangeLastName={this.handleChangeElementBeneficiaire(index)}
                                    onChangeNationality={this.handleChangeElementBeneficiaire(index)}
                                    birthday={beneficiaire.birthday}
                                    displayStatutBirthday={false}
                                    onChangeBirthday={this.handleChangeBirthdayBeneficiaire(index)}
                                    address={beneficiaire.address}
                                    city={beneficiaire.city}
                                    country={beneficiaire.country}
                                    zip={beneficiaire.zip}
                                    onChangeAddress={this.handleChangeElementBeneficiaire(index)}
                                    onChangeCity={this.handleChangeElementBeneficiaire(index)}
                                    onChangeCountry={this.handleChangeElementBeneficiaire(index)}
                                    onChangeZip={this.handleChangeElementBeneficiaire(index)}

                                  />
                                ))
                              }
                              {
                                localBeneficiaire.length < 3 && (
                                  <Button onClick={this.addBeneficiaire} className={cx(classes.button, classes.buttonBeneficiaire)}>
                                    <span className={cx(classes.textBtn, classes.textBtnBeneficiaire)}>Ajouter un bénéficiaire</span>
                                  </Button>
                                )
                              }
                            </>
                          )
                        }
                      </>
                    )
                  }
                  {
                    statutValue === 3 && (
                      <>
                        <div className={classes.marginTop2}>
                          <Typography variant="h2" className={classes.subTitle}>Coordonnées de l&apos;entreprise</Typography>
                          <div className={classes.center}>
                            <Coord
                              infoEntreprise
                              needFormJuri={false}
                              socialReason={socialReason}
                              countryCompany={countryCompany}
                              onChangeDenominationEnt={this.handleChange}
                              onChangeCountryCompany={this.handleChange}
                            />
                          </div>
                          <div className={classes.marginTop2}>
                            <div className={classes.center}>
                              <Coord
                                isAddressCompany
                                addressCompany={addressCompany}
                                cityCompany={cityCompany}
                                zipCompany={zipCompany}
                                countryCompany={countryCompany}
                                onChangeAddressCompany={this.handleChange}
                                onChangeCityCompany={this.handleChange}
                                onChangeZipCompany={this.handleChange}
                                onChangeCountryCompany={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className={classes.marginTop2}>
                            <Typography variant="h3" className={classes.subSubTitle}>Informations complémentaires</Typography>
                            <InfoComplementaireEnt
                              needSiret={false}
                              tva={tva}
                              onChangeRadioBeneTva={this.handleRadioBenefTva}
                              radioBeneTva={radioBeneTva}
                              onChangeTva={this.handleChange}
                            />
                          </div>
                        </div>
                        <Beneficiaire
                          className={classes.marginTop2}
                          title="Représentant légal"
                          firstName={firstName}
                          lastName={lastName}
                          nationality={nationality}
                          statut={statut}
                          onChangeFirstName={this.handleChange}
                          onChangeLastName={this.handleChange}
                          onChangeNationality={this.handleChange}
                          birthday={birthday}
                          onChangeBirthday={this.handleChangeBirthday}
                          address={address}
                          city={city}
                          country={country}
                          zip={zip}
                          onChangeAdresse={this.handleChange}
                          onChangeCity={this.handleChange}
                          onChangeCountry={this.handleChange}
                          onChangeZip={this.handleChange}
                          statutBeneficiaire={statutBeneficiaire}
                          displayUbo
                          onChangeChoixBeneficiaire={this.handleChangeChoixBeneficiaire}
                        />
                        {
                          localBeneficiaire && localBeneficiaire.length > 0 && (
                            <>
                              {
                                localBeneficiaire.map((beneficiaire) => (
                                  <Beneficiaire
                                    className={classes.marginTop4}
                                    displayUbo={false}
                                    title="Bénéficiaire effectif"
                                    firstName={beneficiaire.firstName}
                                    lastName={beneficiaire.lastName}
                                    nationality={beneficiaire.nationality}
                                    statut={statut}
                                    onChangeFirstName={this.handleChange}
                                    onChangeLastName={this.handleChange}
                                    onChangeNationality={this.handleChange}
                                    birthday={birthday}
                                    onChangeBirthday={this.handleChangeBirthday}
                                    address={address}
                                    city={city}
                                    country={country}
                                    zip={zip}
                                    onChangeAddress={this.handleChange}
                                    onChangeCity={this.handleChange}
                                    onChangeCountry={this.handleChange}
                                    onChangeZip={this.handleChange}
                                  />
                                ))
                              }
                              {
                                localBeneficiaire.length <= 3 && (
                                  <Button onClick={this.addBeneficiaire} className={cx(classes.button, classes.buttonBeneficiaire)}>
                                    <span className={cx(classes.textBtn, classes.textBtnBeneficiaire)}>Ajouter un bénéficiaire</span>
                                  </Button>
                                )
                              }
                            </>
                          )
                        }
                      </>
                    )
                  }
                </>
                <div className={classes.formBottom}>
                  <FormControlLabel
                    control={<Checkbox classes={{ checked: classes.radioChecked }} icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} />}
                    label={statut.certification}
                    value={radioValider}
                    onChange={this.handleRadioValider}
                    name="CGUValidate"
                    classes={{ label: classes.radioLabel }}
                    className={classes.formControlLabel}
                  />
                  <Button onClick={this.handleSubmit} className={classes.button}><span className={classes.textBtn}>Enregistrer</span></Button>
                </div>
              </Paper>
            )
          }
        </WrapperPageUser>
      </>
    );
  }
}

Statut.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  history: Proptypes.objectOf(
    Proptypes.oneOfType([
      Proptypes.number,
      Proptypes.func,
      Proptypes.object,
      Proptypes.string,
    ]),
  ),
  user: Proptypes.object,
  getStatutUser: Proptypes.func,
  addStatut: Proptypes.func,
  location: Proptypes.objectOf(
    Proptypes.oneOfType([
      Proptypes.string, Proptypes.object,
    ]),
  ),
};

Statut.defaultProps = {
  getStatutUser: () => {},
  addStatut: () => {},
};

export default withStatut(Statut);
