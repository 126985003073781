import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';
import withHeader from './header.style';
import WrapperPageUser from '../../../containers/WrapperPageUser/wrapper-page-user.connect';
import HeaderGuest from '../HeaderGuest/header-guest';
import HeaderUser from '../HeaderUser/header-user.connect';

const Header = ({
  title,
  classNameHeaderGuest,
  position,
  sticky,
  allLinks,
  onConnexion,
  open,
  onClose,
  history,
  badgeNotification,
  badgeMessage,
  logout,
  classes,
  needContainerHeader,
}) => (
  <WrapperPageUser
    fallback={(
      <>
        {
        needContainerHeader ? (
          <HeaderGuest position="sticky" sticky={false} className={cx(classes.headerGuest, classNameHeaderGuest)} onClose={onClose} open={open} onConnexion={onConnexion} />
        ) : (
          <HeaderGuest position={position} sticky={sticky} className={classNameHeaderGuest} onClose={onClose} open={open} onConnexion={onConnexion} allLinks={allLinks} />
        )
      }
      </>
    )}
  >
    <HeaderUser title={title} position={position} history={history} badgeNotification={badgeNotification} badgeMessage={badgeMessage} logout={logout} />
  </WrapperPageUser>
);

Header.propTypes = {
  classNameHeaderGuest: Proptypes.string,
  title: Proptypes.string,
  badgeNotification: Proptypes.number,
  badgeMessage: Proptypes.number,
  logout: Proptypes.func,
  position: Proptypes.oneOf([
    'static', 'fixed', 'relative', 'sticky',
  ]),
  history: Proptypes.objectOf(
    Proptypes.oneOfType([
      Proptypes.number,
      Proptypes.func,
      Proptypes.object,
      Proptypes.string,
    ]),
  ),
  onConnexion: Proptypes.func,
  onClose: Proptypes.func,
  open: Proptypes.bool,
  sticky: Proptypes.bool,
  allLinks: Proptypes.bool,
  needContainerHeader: Proptypes.bool,
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
};

Header.defaultProps = {
  needContainerHeader: true,
};

export default withHeader(Header);
