import { connect } from 'react-redux';
import {
  getConcoursById, modifyConcours,
} from '../../../actions/concours';
import ModifyConcours from './modify-concours';

const mapStateToProps = ({ user, concours }) => ({
  user: user.user,
  messageSuccess: concours.message,
});

export default connect(
  mapStateToProps,
  {
    getConcoursById, modifyConcours,
  },
)(ModifyConcours);
