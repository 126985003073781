import { connect } from 'react-redux';
import {
  getConcoursById, addParticipant, clearSuccessMsg,
} from '../../../actions/concours';
import { addFileToParticipation } from '../../../actions/participation';
import { createMessage } from '../../../actions/message';
import { updateUser } from '../../../actions/user';
import DetailsConcours from './details-concours-participant';

const mapStateToProps = ({ concours, user }) => ({
  user: user.user,
  concours: concours.singleConcours,
});

export default connect(
  mapStateToProps,
  {
    getConcoursById,
    addParticipant,
    addFileToParticipation,
    clearSuccessMsg,
    createMessage,
    updateUser,
  },
)(DetailsConcours);
