import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import CloseIcon from '@material-ui/icons/Close';
import {
  Backdrop, Fade, IconButton, Modal, Typography,
} from '@material-ui/core';
import Button from '../Button/button';
import withModalInformation from './modal-information.style';

const ModalInformation = ({
  classes,
  classesBtn,
  classNamePaper,
  classNameContainerDetails,
  classNameTitle,
  open,
  onClose,
  title,
  details,
  button,
  textButton,
  onClickSubmit,
  children,
  classNameBtnSubmit,
  widthButton,
  imgParticipant,
}) => (
  <Modal
    aria-labelledby="Card-participant"
    aria-describedby="Card-participant"
    className={classes.modal}
    open={open}
    onClose={onClose}
    closeAfterTransition
    BackdropProps={{
      timeout: 500,
    }}
    BackdropComponent={Backdrop}
  >
    <Fade in={open}>
      <div className={cx(classes.paper, classNamePaper)}>
        <div className={classes.top}>
          <Typography variant="h3" className={cx(classes.title, classNameTitle)}>{title}</Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={cx(classes.containerDetails, classNameContainerDetails)}>
          {
            !!imgParticipant && (
              <div className={classes.wrapperImg}>
                <img src={imgParticipant} alt="participant" className={classes.img} />
              </div>
            )
          }
          {
            children ? (
              <>
                {children}
              </>
            ) : (
              <>
                <Typography variant="body1" className={classes.details}>{details}</Typography>
              </>
            )
          }
        </div>
        {
          button && (
            <Button onClick={onClickSubmit} width={widthButton} className={cx(classes.btn, classNameBtnSubmit)} classes={classesBtn}>{textButton}</Button>
          )
        }
      </div>
    </Fade>
  </Modal>
);

ModalInformation.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  classesBtn: Proptypes.object,
  classNamePaper: Proptypes.string,
  classNameTitle: Proptypes.string,
  classNameContainerDetails: Proptypes.string,
  classNameBtnSubmit: Proptypes.oneOfType([Proptypes.string, Proptypes.bool]),
  open: Proptypes.bool,
  onClose: Proptypes.func,
  title: Proptypes.string,
  details: Proptypes.string,
  button: Proptypes.bool,
  textButton: Proptypes.string,
  onClickSubmit: Proptypes.func,
  children: Proptypes.node,
  widthButton: Proptypes.string,
  imgParticipant: Proptypes.node,
  width: Proptypes.string,
};

ModalInformation.defaultProps = {
  onClickSubmit: () => {},
  onClose: () => {},
  button: true,
  textButton: 'Confirmer',
  width: '30rem',
};

export default withModalInformation(ModalInformation);
