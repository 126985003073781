import {
  FETCH_GET_SUBCATEGORY,
  FETCH_GET_SUBCATEGORY_FULFILLED,
  FETCH_GET_SUBCATEGORY_ERROR,
} from '../constants/actionTypes';

const initialState = {
  fetching: false,
  fetched: false,
  isLoading: false,
  subCategory: null,
};

export default function subCategoryReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_GET_SUBCATEGORY:
      return {
        ...state,
        fetching: true,
        isLoading: true,
      };
    case FETCH_GET_SUBCATEGORY_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        subCategory: action.payload,
        isLoading: false,
      };
    case FETCH_GET_SUBCATEGORY_ERROR:
      return {
        ...state,
        fetching: false,
        isLoading: false,
        subCategory: null,
      };
    default:
      return state;
  }
}
