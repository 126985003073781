import { withStyles } from '@material-ui/styles';

export default withStyles(({ breakpoints, palette }) => ({
  form: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    [breakpoints.down(650)]: {
      flexDirection: 'column',
    },
  },
  fieldsetInput: {
    flex: 1,
    marginBottom: '1rem',
  },
  textArea: {
    marginLeft: '2rem',
    marginRight: '1rem',
    flex: 2,
    [breakpoints.down(650)]: {
      marginLeft: 0,
      marginRight: 0,
      marginBottom: '1rem',
    },
  },
  btnEnvoyer: {
    background: palette.common.white,
    color: palette.primary.main,
    transition: '0.2s background-color ease-in-out',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.8)',
    },
  },
}));
