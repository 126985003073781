import axios from 'axios';
import { returnErrors } from './errorActions';
import { apiKey } from '../constants/api';

import {
  FETCH_CREATE_PAYMENT,
  FETCH_CREATE_PAYMENT_FULFILLED,
  FETCH_CREATE_PAYMENT_ERROR,
  FETCH_CONFIRMATION_PAYMENT,
  FETCH_CONFIRMATION_PAYMENT_FULFILLED,
  FETCH_CONFIRMATION_PAYMENT_ERROR,
} from '../constants/actionTypes';

import { tokenConfig } from '../helpers/tokenConfig';
import { returnSuccess } from './successActions';

export const createPayment = (
  { idConcours, cardType },
  cbSuccess = () => {},
  cbError = () => {},
) => (dispatch, getState) => {
  // dispatch(clearErrors());
  dispatch({ type: FETCH_CREATE_PAYMENT });

  const body = JSON.stringify({
    idConcours,
    cardType,
  });

  axios.post(`${apiKey}/payment/create/web/payin`, body, tokenConfig(getState))
    .then((res) => {
      console.log(res, 'res');
      cbSuccess(res.data.results.redirectUrl);
      dispatch(
        returnSuccess(res.data?.message, 'CREATE_PAYMENT'),
      );
      dispatch({
        type: FETCH_CREATE_PAYMENT_FULFILLED,
        redirectUrl: res.data.results.redirectUrl,
        idConcours,
      });
    })
    .catch((err) => {
      cbError(err.response?.data?.message?.message);
      dispatch(returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'CREATE_PAYMENT_FAIL'));
      dispatch({
        type: FETCH_CREATE_PAYMENT_ERROR,
      });
    });
};

export const confirmationPayment = (
  { idConcours, transactionId },
  cbSuccess = () => {},
  cbError = () => {},
) => (dispatch, getState) => {
  // dispatch(clearErrors());
  dispatch({ type: FETCH_CONFIRMATION_PAYMENT });

  axios.get(`${apiKey}/payment/confirmation/web/payin?idConcours=${idConcours}&transactionId=${transactionId}`, tokenConfig(getState))
    .then((res) => {
      cbSuccess(res.data.message);
      dispatch(
        returnSuccess(res.data?.message, 'CONFIRMATION_PAYMENT'),
      );
      dispatch({
        type: FETCH_CONFIRMATION_PAYMENT_FULFILLED,
        idConcours: res.data.results.idConcours,
      });
    })
    .catch((err) => {
      cbError(err.response);
      console.log(err.response, 'err');
      dispatch(returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'CREATE_PAYMENT_FAIL'));
      dispatch({
        type: FETCH_CONFIRMATION_PAYMENT_ERROR,
      });
    });
};
