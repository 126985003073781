import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import { Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import IconConcours from '../../_atoms/IconConcours/icon-concours';
import Button from '../../_atoms/Button/button';

import withCardInformationConcours from './card-information-concours.style';

const CardInformationConcours = ({
  classes,
  className,
  category,
  gain,
  time,
  message,
  end,
  onClick,
  nbProposition,
  format,
  isParticipate,
  isConcoureur,
}) => {
  let propo;
  if (nbProposition > 1 || parseInt(nbProposition, 10) > 1) {
    propo = 'propositions';
  } else {
    propo = 'proposition';
  }
  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.wrapperIcon}>
        {
          isParticipate && (
            <CheckIcon className={classes.checkIcon} color="secondary" fontSize="large" titleAccess="Vous avez participé" />
          )
        }
        <IconConcours className={classes.icon} color="secondary" fontSize="large" format={format} />
      </div>
      <div className={classes.wrapperCategoryTime}>
        <Typography variant="body1" className={classes.category}>{category}</Typography>
        <Typography variant="body1" className={classes.dateCreation}>{time}</Typography>
      </div>
      <Typography variant="body2" className={classes.message}>{message}</Typography>
      <Typography variant="h3" className={classes.titleGain}>Vainqueur : <span className={classes.valueGain}>{`${gain} €`}</span></Typography>
      <Button width="20rem" className={classes.btnInformation} onClick={onClick}>{isConcoureur ? 'Les participations' : 'Participer'}</Button>
      <div className={classes.moreDetails}>
        <Typography variant="body1" className={classes.end}>{`Fin ${end}`}</Typography>
        <Typography variant="body1" className={classes.numberProposition}>{`${nbProposition} ${propo}`}</Typography>
      </div>
    </div>
  );
};

CardInformationConcours.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  className: Proptypes.string,
  category: Proptypes.string,
  gain: Proptypes.oneOfType([
    Proptypes.string,
    Proptypes.number,
  ]),
  time: Proptypes.string,
  message: Proptypes.string,
  end: Proptypes.string, // Fin dans 4 jours
  nbProposition: Proptypes.oneOfType([
    Proptypes.string,
    Proptypes.number,
  ]),
  format: Proptypes.oneOfType([
    Proptypes.string,
    Proptypes.number,
  ]),
  isParticipate: Proptypes.bool,
  isConcoureur: Proptypes.bool,
  onClick: Proptypes.func,
};

CardInformationConcours.defaultProps = {
  onClick: () => {},
  isParticipate: false,
  isConcoureur: false,
};

export default withCardInformationConcours(CardInformationConcours);
