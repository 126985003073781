import { connect } from 'react-redux';
import { registerUser, clearSuccessMsg } from '../../../actions/user';
import Inscription from './inscription';

const mapStateToProps = ({ user, error }) => ({
  isAuthenticated: user.isAuthenticated,
  error,
});

export default connect(
  mapStateToProps,
  { registerUser, clearSuccessMsg },
)(Inscription);
