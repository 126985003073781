import { withStyles } from '@material-ui/styles';

export default withStyles(({ palette, breakpoints }) => ({
  root: {
    boxShadow: '0 11px 55px 10px rgba(0, 0, 0, 0.07), 0 13px 18px -8px rgba(0, 0, 0, 0.15)',
  },
  containerTabs: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '5rem',
  },
  containerTabs2: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 2.4rem',
    [breakpoints.down(800)]: {
      width: '90%',
      margin: '0 auto',
    },
  },
  titleTabs: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: palette.grey[800],
  },
  containerBtn: {

  },
  btnTab: {
    height: '3rem',
    width: '13rem',
    '@global': {
      span: {
        fontSize: '1.2rem',
      },
    },
  },
}));
