import React from 'react';
import Proptypes from 'prop-types';

import { Typography } from '@material-ui/core';
import CarousselCategoryConcours from '../../_organisms/CarousselCategoryOrganiserConcours/caroussel-category-organiser-concours';

import { categoriesConcours } from '../../../constants/categories';

import Header from '../../_organisms/Header/header.connect';
import WrapperPageUser from '../../../containers/WrapperPageUser/wrapper-page-user.connect';
import withStep1Concours from './oragniserConcours.style';

const Step1Concours = ({ classes, history }) => {
  const handleClick = (category) => () => {
    history.push({
      pathname: '/organiser-concours/etape2',
      state: {
        category: category.title,
        costMini: category.price,
        idSubCategory: category.id,
      },
    });
  };

  return (
    <WrapperPageUser>
      <Header title="Etape 1: Séléctionner une catégorie" history={history} />
      <section className={classes.wrapBodyStep1}>
        {
          categoriesConcours.map((categorie) => (
            <article className={classes.category} key={categorie.id}>
              <Typography variant="h3" display="block" className={classes.title}>{categorie.title}</Typography>
              <CarousselCategoryConcours list={categorie.subCategories} classNameRoot={classes.caroussel} onClick={handleClick} history={history} />
            </article>
          ))
        }
      </section>
    </WrapperPageUser>
  );
};

Step1Concours.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  history: Proptypes.objectOf(
    Proptypes.oneOfType([
      Proptypes.number,
      Proptypes.func,
      Proptypes.object,
      Proptypes.string,
    ]),
  ),
};

export default withStep1Concours(Step1Concours);
