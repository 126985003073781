export const loadState = (attribute = 'token', bearee = true) => {
  try {
    let serializedState;
    if (bearee) {
      serializedState = `Bearer ${localStorage.getItem(attribute)}`.replace('"', '');
      serializedState = `"${serializedState}`;
    } else {
      serializedState = localStorage.getItem(attribute);
    }

    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (attribute, state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(attribute, serializedState);
  } catch (err) {
    // ignore
  }
};
