import React from 'react';
import Proptypes from 'prop-types';
import cx from 'classnames';
import SendIcon from '@material-ui/icons/Send';import { IconButton, InputBase, Paper } from '@material-ui/core';
import { categoriesConcours } from '../../../constants/categories';
import withSearchCategories from './search-categories.style';

const SearchCategories = ({
  classes, onSubmit, className, classNameInput, classNameInputRoot,
}) => {
  const [suggestions, setSuggestions] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState('');

  const onSearchChanged = (e) => {
    const { value } = e.target;
    let suggestionFilter = [];
    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, 'i');
      suggestionFilter = categoriesConcours.map((categorie) => ({
        id: categorie.id,
        title: categorie.title,
        subCategories: categorie.subCategories.filter((subCategorie) => regex.test(categorie.title) || regex.test(subCategorie.title)),
      }))
        .filter((categorie) => regex.test(categorie.title) || categorie.subCategories.length !== 0);
    }
    setSuggestions(suggestionFilter);
    setSearchValue(value);
  };

  const suggestionSelected = (value) => {
    setSearchValue(value);
    setSuggestions([]);
  };

  const handleClick = (e) => {
    onSubmit(searchValue);
  };

  const renderSuggestions = () => {
    if (suggestions.length === 0) return null;

    return (
      <ul className={classes.listSuggestions}>
        {suggestions.map((categorie) => (
          <li key={categorie.id} className={classes.liCategorie}>
            {categorie.title}
            <ul className={classes.listSubCategorie}>
              {
                categorie.subCategories.map((subCategorie) => (
                  <li key={subCategorie.id} className={classes.liSubCategorie}>
                    <button type="button" className={classes.btnSubCategory} onClick={() => suggestionSelected(subCategorie.title)}>
                      {subCategorie.title}
                    </button>
                  </li>
                ))
}
            </ul>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className={classes.root}>
      <Paper component="form" className={cx(classes.paperSearch, className)} square={false}>
        <InputBase
          id="search"
          name="search"
          className={cx(classes.inputSearch, classNameInput)}
          placeholder="Rechercher une catégorie"
          onChange={onSearchChanged}
          value={searchValue}
          inputProps={{ 'aria-label': 'search categorie for concours' }}
          type="text"
          classes={{ input: cx(classes.inputSearchRoot, classNameInputRoot) }}
          autoComplete="off"
        />
        <IconButton type="button" className={classes.iconButtonSearch} aria-label="search" onClick={handleClick}>
          <SendIcon fontSize="large" color="primary" />
        </IconButton>
      </Paper>
      {renderSuggestions()}
    </div>
  );
};

SearchCategories.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  className: Proptypes.string,
  classNameInput: Proptypes.string,
  classNameInputRoot: Proptypes.string,
  onSubmit: Proptypes.func,
  width: Proptypes.string,
};

SearchCategories.defaultProps = {
  width: '40rem',
};

export default withSearchCategories(SearchCategories);
