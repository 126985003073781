import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import { IconButton, Typography } from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';

import PaperCustomize from '../PaperCustomize/paper-customize';
import Button from '../../_atoms/Button/button';
import withInfoConcours from './info-concours.style';

const InfoConcours = ({
  classes,
  className,
  classNameBtnSubmit,
  classNameMessage,
  category,
  endDate,
  gain,
  format,
  resultatAttendu,
  textSubmit,
  sendMessage,
  onClickSubmit,
  onClickMessage,
}) => (
  <PaperCustomize className={cx(classes.root, className)} title="Information sur le concours" marginTopText="-12rem">
    <Typography variant="h3" className={classes.title} display="block">{category}</Typography>
    <div className={classes.details}>
      <Typography variant="body1" className={classes.subTitle}>Date de fin du concours:
        <span className={classes.detailContent}>{` ${endDate}`}</span>
      </Typography>
      <Typography variant="body1" className={classes.subTitle}>Gain pour le vainqueur:
        <span className={classes.detailContent}>{` ${gain} €`}</span>
      </Typography>
      <Typography variant="body1" className={classes.subTitle}>Format:
        <span className={classes.detailContent}>{` ${format}`}</span>
      </Typography>
      <Typography variant="body1" className={classes.subTitle}>Resultat attendu:
        <span className={classes.detailContent}>{` ${resultatAttendu}`}</span>
      </Typography>
    </div>
    <div className={classes.wrapperBtn}>
      <Button className={cx(classes.btnParticiper, classNameBtnSubmit)} onClick={onClickSubmit}>{textSubmit}</Button>
      {
        sendMessage && (
          <IconButton aria-label="Message to concoureur" className={cx(classes.btnMailIcon, classNameMessage)} onClick={onClickMessage}>
            <MailIcon fontSize="large" />
          </IconButton>
        )
      }
    </div>
  </PaperCustomize>
);

InfoConcours.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  className: Proptypes.string,
  classNameBtnSubmit: Proptypes.oneOfType([Proptypes.string, Proptypes.bool]),
  classNameMessage: Proptypes.oneOfType([Proptypes.string, Proptypes.bool]),
  category: Proptypes.string,
  gain: Proptypes.oneOfType([
    Proptypes.string,
    Proptypes.number,
  ]),
  endDate: Proptypes.string,
  format: Proptypes.string,
  resultatAttendu: Proptypes.string,
  textSubmit: Proptypes.string,
  sendMessage: Proptypes.bool,
  onClickSubmit: Proptypes.func,
  onClickMessage: Proptypes.func,
};

InfoConcours.defaultProps = {
  onClickSubmit: () => {},
  onClickMessage: () => {},
  sendMessage: true,
};

export default withInfoConcours(InfoConcours);
