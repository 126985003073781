import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import withListCategoryConcours from './list-category-concours.style';
import CardCategoryConcours from '../../_molecules/CardCategoryConcours/card-category-concours';

const ListCategoryConcours = ({
  classes, classNameRoot, list, onClick,
}) => (
  <div className={cx(classes.root, classNameRoot)}>
    <ul className={cx(classes.list, classNameRoot)}>
      {
        list.map((item) => (
          <li key={item.id}>
            <CardCategoryConcours
              key={item.id}
              title={item.title}
              price={item.price}
              background={item.image}
              className={classes.card}
              onClick={(onClick(item))}
            />
          </li>
        ))
      }
    </ul>
  </div>
);

ListCategoryConcours.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  classNameRoot: Proptypes.string,
  list: Proptypes.arrayOf(Proptypes.object),
  onClick: Proptypes.func,
};

ListCategoryConcours.defaultProps = {
  onClick: () => {},
};
export default withListCategoryConcours(ListCategoryConcours);
