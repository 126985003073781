import { withStyles } from '@material-ui/core';

export default withStyles(({
  palette, spacing,
}) => ({
  root: {
    height: 'inherit',
  },
  containerDetails: {
    flexDirection: 'column',
  },
  wrapperImg: {
    width: '10rem',
    height: '8.5rem',
    marginTop: '2rem',
  },
  img: {
    width: '100%',
    height: '100%',
  },
  details: {
    margin: '2rem 0',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: palette.grey[500],
    textAlign: 'center',
    paddingLeft: spacing(4),
    paddingRight: spacing(4),
  }
}));
