import { withStyles } from '@material-ui/styles';

export default withStyles(({ palette }) => ({
  root: {
    display: 'flex',
    width: '40vw',
    maxWidth: '15rem',
    alignItems: 'center',
    padding: '1rem',
  },
  infos: {
    width: '10rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  number: {
    fontSize: '2.4rem',
    fontWeight: 'bold',
    color: palette.grey[800],
  },
  textInfo: {
    fontSize: '1.2rem',
    alignSelf: 'flex-end',
  },
}));
