import { withStyles } from '@material-ui/core';

export default withStyles(({ breakpoints, palette, spacing }) => ({
  root: {

  },
  header: {
    background: palette.common.white,
    boxShadow: '0 14px 23px 4px rgba(0, 0, 0, 0.03)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem',
    width: '100%',
    [breakpoints.down(700)]: {
      flexDirection: 'column',
      height: '17rem',
    },
  },
  content: {
    height: '70vh',
    margin: '0 auto',
    marginTop: '2rem',
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
  },
  toggleButtons: {
    marginBottom: '2rem',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  wrapperMessages: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'flex-end',
  },
  messages: {
    overflow: 'scroll',
    scrollBehavior: 'smooth',
    top: 0,
    padding: '1rem',
  },
  wrapperFiles: {
    overflow: 'scroll',
    scrollBehavior: 'smooth',
    top: 0,
  },
  containerFiles: {
    display: 'flex',
    margin: '2rem 0',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  wrapperCardMessage: {
    margin: '3rem',
    minWidth: '20rem',
    minHeight: '20rem',
  },
  aboutConcours: {
    display: 'flex',
  },
  avatarImg: {
    width: '110%',
  },
  aboutConcoursText: {
    marginLeft: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  titleConcours: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: palette.grey[800],
  },
  propositionConcours: {
    fontSize: '0.8rem',
    fontWeight: 'bold',
    color: palette.grey[400],
  },
  infoConcours: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    textAlign: 'center',
    [breakpoints.down(700)]: {
      fontSize: '1.2rem',
    },
    [breakpoints.down(580)]: {
      fontSize: '1rem',
    },
    color: palette.grey[800],
  },
  btnIcon: {
    transition: '0.2s opacity ease-in-out',
    background: palette.gradient.main,
    '&:hover': {
      background: palette.gradient.main,
      opacity: 0.8,
    },
    color: palette.common.white,
    marginRight: '1rem',
  },
  pseudo: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    textAlign: 'center',
    color: palette.grey[800],
    [breakpoints.down(580)]: {
      fontSize: '1rem',
    },
  },
  wrapperBtn: {
    [breakpoints.down(700)]: {
      padding: '1rem',
    },
  },
  btnValider: {

  },
  textBtn: {
    fontSize: '1.2rem',
  },
  wrapperDate: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '3rem',
  },
  /* divider: {
    margin: '1rem auto',
    display: 'block',
    width: '40%',
    height: '1px',
    background: palette.grey[200],
    [breakpoints.down(700)]: {
      width: '30%',
    },
  },
   */
  date: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    color: palette.secondary.main,
  },
  wrapperTitleFile: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleFile: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '1rem',
    flex: 1,
    color: palette.grey[800],
    textTransform: 'uppercase',
  },
  divider: {
    flex: 2,
    height: '1px',
    background: palette.grey[300],
    [breakpoints.down(700)]: {
      flex: 1,
    },
  },
  wrapperInputMessage: {
    width: '100%',
    margin: '3rem auto',
    borderTop: `2px solid ${palette.secondary.main}`,
  },
  paperMessage: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  inputMessage: {
    marginLeft: spacing(1),
    flex: 1,
  },
  inputMessageRoot: {
    fontSize: '1.4rem',
    '&::placeholder': {
      fontWeight: 'bold',
      opacity: 0.6,
    },
  },
  btnNoPointerEvent: {
    pointerEvents: 'none',
    background: palette.grey[500],
  },
}));
