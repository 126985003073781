import { withStyles } from '@material-ui/core';

export default withStyles(({ spacing, breakpoints }) => ({
  root: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    position: 'relative',
  },
  motionContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '400px',
    width: '80vw',
    margin: 'auto',
    position: 'relative',
    overflow: 'hidden',
  },
  motionInner: {
    width: '80vw',
    position: 'absolute',
    margin: 'auto',
  },
  dots: {
    marginTop: '2rem',
  },
  arrows: {
    display: 'flex',
    pointerEvents: 'none',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'absolute',
    width: '100%',
    top: '45%',
    left: 0,
  },
  arrow: {

  },
  arrowLeft: {

  },
  btnArrow: {
    margin: 0,
    padding: 0,
    background: 'none',
    border: 'none',
    pointerEvents: (props) => (props.numberOfCards <= 1 ? 'none' : 'auto'),
    display: (props) => (props.numberOfCards <= 1 && 'none'),
    transition: '0.2s opacity ease-in-out',
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  arrowIcon: {
    width: '5rem',
  },
  btnArrowRight: {

  },
}));
