import { withStyles } from '@material-ui/core';

export default withStyles(({ breakpoints, palette }) => ({
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    height: 'fit-content',
  },
  card: {
    margin: '2rem',
  },
}));
