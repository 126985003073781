import React from 'react';
import Proptypes from 'prop-types';

import {
  Typography,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

import TextareaForm from '../../_atoms/TextareaForm/textarea-form';

import WrapperPageUser from '../../../containers/WrapperPageUser/wrapper-page-user.connect';
import Header from '../../_organisms/Header/header.connect';
import ModalSendToConcoureur from '../../_atoms/ModalSendToConcoureur/modal-send-to-concoureur';
import SnackBarBottom from '../../_atoms/SnackBarBottom/snack-bar-bottom';
import DropzoneDialogPerso from '../../_organisms/DropzoneDialog/dropzone-dialog';

import iconeFichier from '../../../assets/Image/Icon Fichier/download.svg';
import {
  parseAcceptedFiles,
  parseFormatConcours,
} from '../../../helpers/parse';

import ModalMessageToParticipant from '../../_atoms/ModalMessageToParticipant/modal-message-to-participant';
import { convertBytesToMbsOrKbs, FilesLimit } from '../../../helpers/file';
import ModalInformation from '../../_atoms/ModalInformation/modal-information';

import { sortByNameOptions } from '../../../helpers/sort';
import { engagementConcoureur } from '../../../constants/text';
import CardParticipant from '../../_atoms/CardParticipant/card-participant';
import ProgressPerso from '../../_atoms/LinearProgress/linearProgress';

import { maxFilesInTotalForParticipation } from '../../../constants/file';

import DetailsConcours from '../../_organisms/DetailsConcours/details-concours';
import { statusConcoursPaid } from '../../../constants/concours';

import withConcoursDetailsParticipant from './details-concours-participant.style';

class DetailsConcoursParticipant extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openEngagParticipant: false,
      openEngagConcoureur: false,
      openParticipation: false,
      radioEngagParticipant: false,
      loadingFiles: false,
      openDropzone: false,
      concours: undefined,
      openSnackError: false,
      openSnackSuccess: false,
      textParticipation: '',
      popupMessage: false,
      valueMessage: '',
      options: {
        prerequis: [],
        options: [],
      },
      errorText: "Une erreur c'est produite, votre participation n'a pas eu lieu !",
      messageSuccess: 'Votre participation a bien été ajouté au concours !',
    };
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    const { getConcoursById, match } = this.props;
    const { id } = match.params;

    getConcoursById(id, (data) => {
      const concours = data;

      // TODO: En back ne pas mettre les participations en null s'ils nous appartiennent pas mais les enlever directement
      concours.participations = concours.participations.filter((element) => element !== null);
      concours.participationsParticipate = concours.participations.filter((element) => element.isParticipating);
      concours.participationsNoParticipate = concours.participations.filter((element) => element.isParticipating === false);

      console.log(concours, 'concours');
      const prerequis = concours.subCategory.options.filter((elem) => elem.isPrerequis === true).sort(sortByNameOptions);
      let optionsGratuit = concours.subCategory.options.filter((elem) => elem.isPrerequis === false && elem.isPayant === false);
      const optionsPayant = concours.optionHasConcours.map((elem) => elem.option);

      let thereIsRetouch = false;
      let thereIsFast = false;

      optionsPayant.forEach((optionPayant) => {
        if (optionPayant.nom.toLowerCase().indexOf('retouche') !== -1) thereIsRetouch = true;
        if (optionPayant.nom.toLowerCase().indexOf('livr') !== -1) thereIsFast = true;
      });

      if (thereIsRetouch) {
        optionsGratuit = optionsGratuit.filter((elem) => elem.nom.toLowerCase().indexOf('retouche') === -1);
      }

      if (thereIsFast) {
        optionsGratuit = optionsGratuit.filter((elem) => elem.nom.toLowerCase().indexOf('livr') === -1);
      }

      const options = optionsGratuit.concat(optionsPayant).sort(sortByNameOptions);

      console.log(options, 'options', prerequis, 'prerequis');

      this.setState({
        concours,
        options: {
          prerequis,
          options,
        },
      });
    });
  }

  handleChangeTextParticipation = (event) => {
    this.setState({
      textParticipation: event.target.value,
    });
  }

  handleRadioEngagParticipant = () => {
    const { radioEngagParticipant } = this.state;
    if (radioEngagParticipant) {
      this.setState({
        radioEngagParticipant: false,
      });
    } else {
      this.setState({
        radioEngagParticipant: true,
      });
    }
  }

  /*
  isParticipate = (callback) => {
    let isParticipateBool = false;
    let hisParticipation = null;
    const { user, getConcoursById, match } = this.props;
    const { id } = match.params;

    getConcoursById(id, (data) => { //
      console.log(data, 'concours');
      const participations = data.participations.filter((element) => element !== null);
      if (participations) {
        for (const participation of participations) {
          if (participation.idParticipant === user.id) {
            if (participation.isParticipating) isParticipateBool = true;
            hisParticipation = participation;
            break;
          }
        }
      }
      return callback({
        isParticipate: isParticipateBool,
        hisParticipation,
      });
    });
  }
*/
  handleOpenEngagParticipant = () => {
    const { user, concours, history } = this.props;
    if (!user.ownerId || !user.payerId) {
      history.push({
        pathname: '/mon-profil/statut',
        state: {
          variant: 'warning',
          text: 'Merci de remplir les informations pour participer à un concours !',
        },
      });
    } else if (concours.participationsParticipate.length > 0) {
      this.setState({
        errorText: 'Vous avez déjà participé à ce concours !',
        openSnackError: true,
      });
    } else {
      this.setState({
        openEngagParticipant: true,
        openEngagConcoureur: false,
        openParticipation: false,
      });
    }
  }

  handleCloseParticipation = () => {
    this.setState({
      openEngagParticipant: false,
      openEngagConcoureur: false,
      openParticipation: false,
      radioEngagParticipant: false,
    });
  }

  handleOpenEngagConcoureur = () => {
    const { radioEngagParticipant } = this.state;
    if (radioEngagParticipant) {
      this.setState({
        openEngagParticipant: false,
        openEngagConcoureur: true,
        openParticipation: false,
      });
    } else {
      this.setState({
        openSnackError: true,
        errorText: 'Vous devez accepté les conditions pour continuer !',
      });
    }
  }

  handleOpenParticipation = () => {
    this.setState({
      openEngagParticipant: false,
      openEngagConcoureur: false,
      openParticipation: true,
    });
  }

  handleOpenDropzone = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.setState({
      openDropzone: true,
      openParticipation: false,
      openEngagParticipant: false,
      openEngagConcoureur: false,
    });
  }

  handleCloseDropzone = () => {
    this.setState({
      openDropzone: false,
    });
  }

  handleOpenSuccessSnack = () => {
    this.setState({
      openSnackSuccess: true,
      loadingFiles: false,
      openSnackError: false,
    });
  };

  handleCloseSnack = () => {
    this.setState({
      openSnackSuccess: false,
      loadingFiles: false,
      openSnackError: false,
    });
  };

  handleOpenErrorSnack = () => {
    this.setState({
      openSnackSuccess: false,
      loadingFiles: false,
      openSnackError: true,
    });
  };

  handleOpenPopupMessage = () => {
    this.setState({
      popupMessage: true,
    });
  }

  handleClosePopupMessage = () => {
    this.setState({
      popupMessage: false,
    });
  }

  handleChangeMessage = (event) => {
    this.setState({
      valueMessage: event.target.value,
    });
  }

  handleSubmitMessage = () => {
    const { addParticipant, createMessage } = this.props;
    const { concours, valueMessage } = this.state;
    // TODO: Créer une participation puis créer un message à partir de cette participation
    if (concours.participations.length > 0) {
      createMessage({
        idParticipation: concours.participations[0].id,
        message: valueMessage,
      }, () => {
        this.handleClosePopupMessage();
      });
    } else {
      addParticipant(concours.id, null, (res) => {
        createMessage({
          idParticipation: res.participation.id,
          message: valueMessage,
        }, () => {
          this.handleClosePopupMessage();
        });
      });
    }
  }

  addFile = (files, idParticipation) => {
    const { addFileToParticipation, match, getConcoursById } = this.props;
    const { id } = match.params;

    files.forEach((file) => {
      console.log(file, 'file');
      if (file.type.indexOf('image') !== -1) { // Si c'est une image alors faire attention que ce soit un carré
        const reader = new FileReader();

        reader.onload = (event) => {
          const img = new Image();

          img.onload = () => {
            // const widthImg = img.width;
            // const heightImg = img.height;

            // if (widthImg === heightImg) {
            addFileToParticipation(idParticipation, file, () => {
              getConcoursById(id, (data) => {
                const concoursTmp = data;

                concoursTmp.participations = concoursTmp.participations.filter((element) => element !== null);
                concoursTmp.participationsParticipate = concoursTmp.participations.filter((element) => element.isParticipating);
                concoursTmp.participationsNoParticipate = concoursTmp.participations.filter((element) => element.isParticipating === false);
                this.setState({
                  concours: concoursTmp,
                });
              });
            });
            // } else {
            //  this.setState({
            //    errorText: `La largeur et la hauteur de l'image ${file.name} doient être égaux !`,
            //    openSnackError: true,
            //  });
            // }
          };

          img.src = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        files.forEach((fileNotImage) => {
          addFileToParticipation(idParticipation, fileNotImage, () => {
            getConcoursById(id, (data) => {
              const concoursTmp = data;
              concoursTmp.participations = concoursTmp.participations.filter((element) => element !== null);
              concoursTmp.participationsParticipate = concoursTmp.participations.filter((element) => element.isParticipating);
              concoursTmp.participationsNoParticipate = concoursTmp.participations.filter((element) => element.isParticipating === false);
              this.setState({
                concours: concoursTmp,
                loadingFiles: false,
              });
            });
          });
        });
      }
    });
  }

  // TODO: A modifier les conditions de send

  handleSave= async (files) => {
    const { addParticipant } = this.props;
    const { concours } = this.state;

    const sizeOfAllFiles = files.reduce((a, b) => a + b.size, 0);
    if (sizeOfAllFiles > maxFilesInTotalForParticipation) {
      this.setState({
        errorText: `La taille de vos fichiers sont de ${convertBytesToMbsOrKbs(sizeOfAllFiles)}, alors que le maximum autorisé est de ${convertBytesToMbsOrKbs(maxFilesInTotalForParticipation)}`,
        openSnackError: true,
      });
    } else {
      // Saving files to state for further use and closing Modal.
      this.setState({
        openDropzone: false,
        openParticipation: false,
        openEngagParticipant: false,
        openEngagConcoureur: false,
        loadingFiles: true,
      });

      if (concours.participations.length > 0) {
        await this.addFile(files, concours.participations[0].id);
        this.setState({ loadingFiles: false });
      } else {
        addParticipant(concours.id, null, async (res) => {
          await this.addFile(files, res.participation.id);
          this.setState({ loadingFiles: false });
        });
      }
    }
  }

  handleSaveText = () => {
    const { textParticipation, concours } = this.state;
    const { addParticipant } = this.props;
    this.setState({
      openParticipation: false,
      openEngagParticipant: false,
      openEngagConcoureur: false,
    });
    addParticipant(concours.id, textParticipation, () => {
      this.setState({
        messageSuccess: 'Votre participation a bien été ajouté au concours !',
      });
      this.handleOpenSuccessSnack();
    }, () => this.handleOpenErrorSnack());
  }

  render() {
    const {
      classes, history,
    } = this.props;
    const {
      radioEngagParticipant,
      concours,
      openParticipation,
      openEngagParticipant,
      openEngagConcoureur,
      openDropzone,
      openSnackSuccess,
      openSnackError,
      textParticipation,
      errorText,
      messageSuccess,
      popupMessage,
      valueMessage,
      options,
      loadingFiles,
    } = this.state;

    return (
      <>
        {
          !!openSnackSuccess && <SnackBarBottom variant="success" open={openSnackSuccess} onClose={this.handleCloseSnack} text={messageSuccess} />
        }
        {
          !!openSnackError && <SnackBarBottom variant="error" open={openSnackError} onClose={this.handleCloseSnack} text={errorText} />
        }
        {
          loadingFiles ? (
            <ProgressPerso linear={false} />
          ) : (
            <>
              <Header title="Prêt à relever le défi ?" history={history} />
              <WrapperPageUser>
                {
          concours ? (
            <>
              <DetailsConcours
                concours={concours}
                sendMessage
                textSubmit="Participer"
                onClickMessage={this.handleOpenPopupMessage}
                onClickSubmit={this.handleOpenEngagParticipant}
                classNameMessage={concours.status !== statusConcoursPaid && classes.btnNoPointerEvent}
                classNameBtnSubmit={concours.status !== statusConcoursPaid && classes.btnNoPointerEvent} // Si le concours n'est pas en statut 2, personne peut participer
                valueCompanyName={concours.companyName}
                valueCompanySkills={concours.companySkills}
                valueTarget={concours.target}
                valueCursorModern={concours.cursorModern}
                valueCursorYoung={concours.cursorYoung}
                valueCursorFun={concours.cursorFun}
                valueCursorLuxury={concours.cursorLuxury}
                lockedInput
              />
              <div className={classes.wrapperHisParticipation}>
                {
          concours.participationsParticipate.length > 0 && (
          <>
            <Typography variant="h3" display="block" className={classes.title}>Votre participation</Typography>
            {
          concours.subCategory.format === 5 ? (
            <CardParticipant
              format={5}
              text={concours.participationsParticipate[0].inputText} // Chaque participant peut participer qu'une fois
              allowOnlyZooomImage
              className={classes.cardFormatText}
              limitText={220}
            />
          ) : (
            <div className={classes.listParticipations}>
              {
          concours.participationsParticipate[0].fileParticipants.filter((elem) => elem.isFinalFile !== true).map((item) => (
            <div className={classes.participation} key={item.id}>
              <CardParticipant
                background={item.filepath}
                format={concours.subCategory.format}
                allowOnlyZooomImage
              />
              <Typography variant="body2" style={{ padding: '1rem', textAlign: 'center', fontSize: '1.2rem' }}>{item.filename}</Typography>
            </div>
          ))
        }
            </div>
          )
        }
          </>
          )
        }
              </div>
              <ModalMessageToParticipant
                onClose={this.handleClosePopupMessage}
                open={popupMessage}
                onClickSubmit={this.handleSubmitMessage}
                valueMessage={valueMessage}
                onChangeMessage={this.handleChangeMessage}
                title="Envoyez un message au concoureur"
              />
              <ModalInformation
                open={openEngagParticipant}
                title="Engagement du participant"
                textButton="Suivant"
                onClickSubmit={this.handleOpenEngagConcoureur}
                onClose={this.handleCloseParticipation}
                classNameContainerDetails={classes.containerDetailsEngagParticipant}
              >
                <div className={classes.wrapperTextEngagParticipant}>
                  {
                    options.options.map((element) => (
                      <Typography key={element.id} variant="body1" className={classes.textEngagParticipantValue}>{element.nom}</Typography>
                    ))
                  }
                  <FormControlLabel
                    control={<Checkbox icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} />}
                    label="Vous vous engagez à réaliser toutes les demandes ci-dessus"
                    value={radioEngagParticipant}
                    onChange={this.handleRadioEngagParticipant}
                    name="Engagement"
                    classes={{ label: classes.radioLabel }}
                    className={classes.formControlLabel}
                  />
                </div>
              </ModalInformation>
              <ModalInformation
                open={openEngagConcoureur}
                title="Engagement du concoureur"
                textButton="Suivant"
                onClickSubmit={this.handleOpenParticipation}
                onClose={this.handleCloseParticipation}
              >
                <Typography variant="body1" className={classes.textInfoEngagConcoureur}>{engagementConcoureur}</Typography>
                <div className={classes.wrapperTextEngagConcoureur}>
                  {
          options.prerequis.map((element) => (
            <Typography key={element.id} variant="body2" className={classes.textEngagConcoureurValue}>{element.nom}</Typography>
          ))
        }
                </div>
              </ModalInformation>
              {
          concours.subCategory.format !== 5 ? (
            <>
              <ModalSendToConcoureur
                open={openParticipation}
                img={iconeFichier}
                informations={`Cliquez sur l'icône pour pouvoir insérer votre fichier, le format ${parseFormatConcours(concours.subCategory.format)} est demandé pour cette catégorie`}
                title={`"${concours.subCategory.name}" pour ${concours.companyName}`}
                onClose={this.handleCloseParticipation}
                onClickImg={this.handleOpenDropzone}
              />
              <DropzoneDialogPerso
                filesLimit={FilesLimit(concours.subCategory.format)}
                open={openDropzone}
                onChange={this.handleAddFile}
                onSave={this.handleSave}
                acceptedFiles={[parseAcceptedFiles(concours.subCategory.format)]}
                onClose={this.handleCloseDropzone}
              />
            </>
          ) : (
            <ModalInformation
              onClose={this.handleCloseParticipation}
              open={openParticipation}
              onClickSubmit={this.handleSaveText}
              title={`"${concours.subCategory.name}" pour ${concours.companyName}`}
              textButton="Enregistrer"
              width="80vw"
            >
              <TextareaForm
                name="participation"
                className={classes.textAreaField}
                classNameArea={classes.textArea}
                onChange={this.handleChangeTextParticipation}
                value={textParticipation}
                noOnChange={false}
                width="75vw"
              />
            </ModalInformation>
          )
        }
            </>
          ) : (
            <ProgressPerso />
          )
        }
              </WrapperPageUser>
            </>
          )
        }
      </>
    );
  }
}

DetailsConcoursParticipant.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  className: Proptypes.string,
  concours: Proptypes.object,
  match: Proptypes.shape({
    path: Proptypes.string,
    url: Proptypes.string,
    isExact: Proptypes.bool,
    params: Proptypes.object,
  }),
  user: Proptypes.object,
  isAuthenticated: Proptypes.bool,
  getConcoursById: Proptypes.func,
  clearSuccessMsg: Proptypes.func,
  addParticipant: Proptypes.func,
  addFileToParticipation: Proptypes.func,
  history: Proptypes.objectOf(
    Proptypes.oneOfType([
      Proptypes.number,
      Proptypes.func,
      Proptypes.object,
      Proptypes.string,
    ]),
  ),
  messageSuccess: Proptypes.string,
  createMessage: Proptypes.func,
  updateUser: Proptypes.func,
};

export default withConcoursDetailsParticipant(DetailsConcoursParticipant);
