import React from 'react';
import Proptypes from 'prop-types';

import { Typography, ButtonBase } from '@material-ui/core';
import ModalInformation from '../ModalInformation/modal-information';

import withModalSendToConoureur from './modal-send-to-concoureur.style';

const ModalSendToConcoureur = ({
  classes,
  classNameBtnSubmit,
  open,
  onClose,
  title,
  onClickSubmit,
  onClickImg,
  textButton,
  img,
  button,
  informations,
  children,
  imgDisabled,
}) => (
  <ModalInformation
    open={open}
    onClose={onClose}
    title={title}
    classNameBtnSubmit={classNameBtnSubmit}
    onClickSubmit={onClickSubmit}
    textButton={textButton}
    button={button}
    classNamePaper={classes.root}
    classNameContainerDetails={classes.containerDetails}
    widthButton="70%"
  >
    <ButtonBase className={classes.wrapperImg} onClick={onClickImg} disabled={imgDisabled}>
      <img src={img} alt="participant" className={classes.img} />
    </ButtonBase>
    {
      informations && (
        <Typography variant="body2" className={classes.details}>{informations}</Typography>
      )
    }
    {
      children && (
        <>
          {children}
        </>
      )
    }
  </ModalInformation>
);

ModalSendToConcoureur.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  classNameBtnSubmit: Proptypes.oneOfType([Proptypes.string, Proptypes.bool]),
  open: Proptypes.bool,
  onClose: Proptypes.func,
  title: Proptypes.string,
  textButton: Proptypes.string,
  onClickSubmit: Proptypes.func,
  img: Proptypes.node,
  informations: Proptypes.string,
  onClickImg: Proptypes.func,
  children: Proptypes.node,
  button: Proptypes.bool,
  imgDisabled: Proptypes.bool,
};

ModalSendToConcoureur.defaultProps = {
  onClose: () => {},
  onClickSubmit: () => {},
  onClickImg: () => {},
  imgDisabled: false,
  button: false,
};

export default withModalSendToConoureur(ModalSendToConcoureur);
