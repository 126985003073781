// Design
import logo from '../assets/Image/Organiser un concours/1_Logo.png';
import packIdentite from '../assets/Image/Organiser un concours/2_Pack_identite.png';
import carteVisite from '../assets/Image/Organiser un concours/3_Carte_visite.png';
import flyer from '../assets/Image/Organiser un concours/4_Flyer.png';
import logoVetement from '../assets/Image/Organiser un concours/5_Logo_de_Vetement.png';
import brochure from '../assets/Image/Organiser un concours/6_Brochure_depliant.png';
import menu from '../assets/Image/Organiser un concours/7_Menu_restaurant.png';
import catalogue from '../assets/Image/Organiser un concours/8_Catalogue.png';
import infographie from '../assets/Image/Organiser un concours/9_Infographie.png';
import carteInvitation from '../assets/Image/Organiser un concours/10_Carte_Invitation.png';
import illustration from '../assets/Image/Organiser un concours/11_Illustration.png';
import poster from '../assets/Image/Organiser un concours/12_Poster.png';
import troisD from '../assets/Image/Organiser un concours/13_3D.png';
import newlestters from '../assets/Image/Organiser un concours/14_Newsletter.png';
import autocollants from '../assets/Image/Organiser un concours/15_Autocollants.png';
import packaging from '../assets/Image/Organiser un concours/16_Packaging.png';
import couvertureLivre from '../assets/Image/Organiser un concours/17_Couverture_de_livre.png';
import pochetteCD from '../assets/Image/Organiser un concours/18_Pochette_CD.png';
import kakemono from '../assets/Image/Organiser un concours/19_Kakemono.png';

// Site web / App mobile
import designSite from '../assets/Image/Organiser un concours/20_DesignSiteWeb.png';
import designApp from '../assets/Image/Organiser un concours/21_Design_app_mobile.png';
import icone from '../assets/Image/Organiser un concours/22_Icon.png';
import eCommerce from '../assets/Image/Organiser un concours/23_Ecommerce.png';
import landingPage from '../assets/Image/Organiser un concours/24_Landing_page.png';
import siteVitrine from '../assets/Image/Organiser un concours/25_Site_Vitrine.png';

// Reseaux sociaux
import publicationImage from '../assets/Image/Organiser un concours/26_PublicationImage.png';
import publicationVideo from '../assets/Image/Organiser un concours/27_PublicationVideo.png';
import storiesImage from '../assets/Image/Organiser un concours/28_StoriesImage.png';
import storiesVideo from '../assets/Image/Organiser un concours/29_StoriesVideo.png';
import banniereImage from '../assets/Image/Organiser un concours/30_BanniereImage.png';
import banniereVideo from '../assets/Image/Organiser un concours/31_BanniereVideo.png';
// TODO Pack reseaux

// Business
import nomBoite from '../assets/Image/Organiser un concours/32_Nom_de_boite.png';
import slogan from '../assets/Image/Organiser un concours/33_Slogan.png';
import presentation from '../assets/Image/Organiser un concours/34_Presentation.png';
import cv from '../assets/Image/Organiser un concours/35_CV.png';

// Ecriture
import articleBlog from '../assets/Image/Organiser un concours/36_Article_de_blog.png';
import traduction from '../assets/Image/Organiser un concours/37_Traduction.png';

// Video image
import animation2D from '../assets/Image/Organiser un concours/38_Animation2D.png';
import animation3D from '../assets/Image/Organiser un concours/39_Animation3D.png';
import animationTexte from '../assets/Image/Organiser un concours/40_Animation_Texte.png';
import whiteBoard from '../assets/Image/Organiser un concours/41_Whiteboard.png';
import videoPublicitaire from '../assets/Image/Organiser un concours/42_Video_publicitaire_Presentation.png';
import montagePhotoVideo from '../assets/Image/Organiser un concours/43_Montage_photo_video.png';
import animationLogo from '../assets/Image/Organiser un concours/44_Animation_de_logo.png';
import gif from '../assets/Image/Organiser un concours/45_GIF.png';
import motionDesign from '../assets/Image/Organiser un concours/46_MotionDesign-AnimationIllustration.png';
import generique from '../assets/Image/Organiser un concours/47_Generique.png';

// Audio
import voixOff from '../assets/Image/Organiser un concours/48_Voix_off.png';
import pubRadio from '../assets/Image/Organiser un concours/49_Pub_radio.png';
import livreAudio from '../assets/Image/Organiser un concours/50_Livre_audio.png';
import jingle from '../assets/Image/Organiser un concours/51_Jingle.png';
import effetSonore from '../assets/Image/Organiser un concours/52_Effet_sonore.png';

// format: 1 --> photo donc png puis PSD
// format: 2 --> video donc format avi
// format: 3 --> AUDIO
// format: 4 --> Gif
// format: 5 --> texte donc directement sur le site
export const categoriesConcours = [
  {
    id: 1,
    title: 'Design',
    to: 'design',
    subCategories: [
      {
        id: 1,
        title: 'Logo',
        to: 'design/logo',
        image: logo,
        price: 240,
        format: 1,
        type: 'png',
      },
      {
        id: 2,
        title: 'Pack Identité',
        to: 'design/pack-identite',
        image: packIdentite,
        price: 480,
        format: 1,
        type: 'png',
      },
      {
        id: 3,
        title: 'Carte de visite',
        to: 'design/carte-visite',
        image: carteVisite,
        price: 144,
        format: 1,
        type: 'png',
      },
      {
        id: 4,
        title: 'Flyer',
        to: 'design/flyer',
        image: flyer,
        price: 156,
        format: 1,
        type: 'png',
      },
      {
        id: 5,
        title: 'Logo de vêtement',
        to: 'design/logo-vetement',
        image: logoVetement,
        price: 204,
        format: 1,
        type: 'png',
      },
      {
        id: 6,
        title: 'Brochure / dépliant',
        to: 'design/brochure',
        image: brochure,
        price: 204,
        format: 1,
        type: 'png',
      },
      {
        id: 7,
        title: 'Menu restaurant',
        to: 'design/menu',
        image: menu,
        price: 228,
        format: 1,
        type: 'png',
      },
      {
        id: 8,
        title: 'Catalogue / plaquette',
        to: 'design/catalogue',
        image: catalogue,
        price: 264,
        format: 1,
        type: 'png',
      },
      {
        id: 9,
        title: 'Infographie',
        to: 'design/infographie',
        image: infographie,
        price: 204,
        format: 1,
        type: 'png',
      },
      {
        id: 10,
        title: 'Carte d\'invitation',
        to: 'design/carte-invitation',
        image: carteInvitation,
        price: 120,
        format: 1,
        type: 'png',
      },
      {
        id: 11,
        title: 'Illustration',
        to: 'design/illustration',
        image: illustration,
        price: 240,
        format: 1,
        type: 'png',
      },
      {
        id: 12,
        title: 'Affiche / poster',
        to: 'design/poster',
        image: poster,
        price: 180,
        format: 1,
        type: 'png',
      },
      {
        id: 13,
        title: '3D',
        to: 'design/3d',
        image: troisD,
        price: 312,
        format: 1,
        type: 'png',
      },
      {
        id: 14,
        title: 'Newlestters',
        to: 'design/newlestters',
        image: newlestters,
        price: 216,
        format: 1,
        type: 'png',
      },
      {
        id: 15,
        title: 'Autocollants',
        to: 'design/autocollant',
        image: autocollants,
        price: 120,
        format: 1,
        type: 'png',
      },
      {
        id: 16,
        title: 'Packaging',
        to: 'design/packaging',
        image: packaging,
        price: 312,
        format: 1,
        type: 'png',
      },
      {
        id: 17,
        title: 'Couverture de livre',
        to: 'design/couverture-livre',
        image: couvertureLivre,
        price: 204,
        format: 1,
        type: 'png',
      },
      {
        id: 18,
        title: 'Pochette CD',
        to: 'design/pochette-cd',
        image: pochetteCD,
        price: 204,
        format: 1,
        type: 'png',
      },
      {
        id: 19,
        title: 'Kakemono',
        to: 'design/kakemono',
        image: kakemono,
        price: 204,
        format: 1,
        type: 'png',
      },
    ],
  },
  {
    id: 2,
    title: 'Site / App',
    to: 'site-app',
    subCategories: [
      {
        id: 20,
        title: 'Design site web',
        to: 'site-app/design-site-web',
        image: designSite,
        price: 240,
        format: 1,
        type: 'png',
      },
      {
        id: 21,
        title: 'Design app mobile',
        to: 'site-app/design-app-mobile',
        image: designApp,
        price: 216,
        format: 1,
        type: 'png',
      },
      {
        id: 22,
        title: 'Icone',
        to: 'site-app/icone',
        image: icone,
        price: 120,
        format: 1,
        type: 'png',
      },
      {
        id: 23,
        title: 'Site e-commerce',
        to: 'site-app/site-ecommerce',
        image: eCommerce,
        price: 360,
        format: 1,
        type: 'png',
      },
      {
        id: 24,
        title: 'Landing page',
        to: 'site-app/landing-page',
        image: landingPage,
        price: 240,
        format: 1,
        type: 'png',
      },
      {
        id: 25,
        title: 'Site vitrine',
        to: 'site-app/site-vitrine',
        image: siteVitrine,
        price: 180,
        format: 1,
        type: 'png',
      },
    ],
  },
  {
    id: 3,
    title: 'Réseaux sociaux',
    to: 'reseaux-sociaux',
    subCategories: [
      {
        id: 26,
        title: 'Publication (image)',
        to: 'reseaux-sociaux/publication-image',
        image: publicationImage,
        price: 84,
        format: 1,
        type: 'png',
      },
      {
        id: 27,
        title: 'Publication (vidéo)',
        to: 'reseaux-sociaux/publication-video',
        image: publicationVideo,
        price: 156,
        format: 1,
        type: 'png',
      },
      {
        id: 28,
        title: 'Stories (image)',
        to: 'reseaux-sociaux/stories-image',
        image: storiesImage,
        price: 84,
        format: 1,
        type: 'png',
      },
      {
        id: 29,
        title: 'Stories (video)',
        to: 'reseaux-sociaux/stories-video',
        image: storiesVideo,
        price: 156,
        format: 2,
        type: 'png',
      },
      {
        id: 30,
        title: 'Banniere (image)',
        to: 'reseaux-sociaux/banniere-image',
        price: 108,
        image: banniereImage,
        format: 1,
        type: 'png',
      },
      {
        id: 31,
        title: 'Banniere (video)',
        to: 'reseaux-sociaux/banniere-video',
        price: 180,
        image: banniereVideo,
        format: 1,
        type: 'png',
      },
      {
        id: 32,
        title: 'Pack réseaux sociaux',
        to: 'reseaux-sociaux/pack-reseaux-sociaux',
        // TODO: PRICE A MODIFIER
        price: 540,
        image: publicationVideo,
        format: 1,
        type: 'png',
      },
    ],
  },
  {
    id: 4,
    title: 'Business',
    to: 'business',
    subCategories: [
      {
        id: 33,
        title: 'Nom de boite',
        to: 'business/nom-boite',
        image: nomBoite,
        price: 60,
        format: 5,
        type: 'text',
      },
      {
        id: 34,
        title: 'Slogan',
        to: 'business/slogan',
        image: slogan,
        price: 84,
        format: 5,
        type: 'text',
      },
      {
        id: 35,
        title: 'Présentation PowerPoint',
        to: 'business/presentation-powerPoint',
        price: 156,
        image: presentation,
        format: 1,
        type: 'png',
      },
      {
        id: 36,
        title: 'CV',
        to: 'business/cv',
        price: 120,
        image: cv,
        format: 1,
        type: 'png',
      },
    ],
  },
  {
    id: 5,
    title: 'Ecriture',
    to: 'ecriture',
    subCategories: [
      {
        id: 37,
        title: 'Article de blog',
        to: 'ecriture/article-blog',
        price: 72,
        image: articleBlog,
        format: 5,
        type: 'text',
      },
      {
        id: 38,
        title: 'Traduction',
        to: 'ecriture/traduction',
        price: 60,
        image: traduction,
        format: 5,
        type: 'text',
      },
    ],
  },
  {
    id: 6,
    title: 'Video / Image',
    to: 'video-image',
    subCategories: [
      {
        id: 39,
        title: 'Animation 2D',
        to: 'video-image/animation-2D',
        price: 312,
        image: animation2D,
        format: 2,
      },
      {
        id: 40,
        title: 'Animation 3D',
        to: 'video-image/animation-3D',
        price: 480,
        image: animation3D,
        format: 2,
      },
      {
        id: 41,
        title: 'Animation texte',
        to: 'video-image/animation-texte',
        price: 240,
        image: animationTexte,
        format: 2,
      },
      {
        id: 42,
        title: 'Whiteboard',
        to: 'video-image/whiteboard',
        price: 240,
        image: whiteBoard,
        format: 2,
      },
      {
        id: 43,
        title: 'Video publicitaire',
        to: 'video-image/video-publicitaire',
        price: 300,
        image: videoPublicitaire,
        format: 2,
      },
      {
        id: 44,
        title: 'Montage photo / video',
        to: 'video-image/montage-photo-video',
        price: 120,
        image: montagePhotoVideo,
        format: 2,
      },
      {
        id: 45,
        title: 'Animation de logo',
        to: 'video-image/animation-logo',
        price: 156,
        image: animationLogo,
        format: 2,
      },
      {
        id: 46,
        title: 'GIF',
        to: 'video-image/gif',
        price: 120,
        image: gif,
        format: 4,
      },
      {
        id: 47,
        title: 'Motion design / Animation',
        to: 'video-image/motion-design-animation-illustration',
        price: 216,
        image: motionDesign,
        format: 2,
      },
      {
        id: 48,
        title: 'Générique',
        to: 'video-image/generique',
        price: 300,
        image: generique,
        format: 2,
      },
    ],
  },
  {
    id: 7,
    title: 'Audio',
    to: 'audio',
    subCategories: [
      {
        id: 49,
        title: 'Voix off',
        to: 'audio/voix-off',
        price: 60,
        image: voixOff,
        format: 3,
      },
      {
        id: 50,
        title: 'Pub radio',
        to: 'audio/pub-radio',
        price: 60,
        image: pubRadio,
        format: 3,
      },
      {
        id: 51,
        title: 'Livre audio',
        to: 'audio/livre-audio',
        price: 156,
        image: livreAudio,
        format: 3,
      },
      {
        id: 52,
        title: 'Jingle',
        to: 'audio/jingle',
        price: 120,
        image: jingle,
        format: 3,
      },
      {
        id: 53,
        title: 'Effets sonores',
        to: 'audio/effets-sonores',
        price: 60,
        image: effetSonore,
        format: 3,
      },
    ],
  },
];
