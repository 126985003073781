export const resultatAttendu = [
  /* Values of checkbox resultatAttendu
    * 1 --> Fini
    * 2 --> Semi-fini
    * 3 --> Preuve
   */
  // TODO au lieu de parser la value en 1, 2, 3 par le helper par la suite, permettre directement de mettre value 1, 2, 3
  {
    title: 'Fini: Le résultat est adapté à votre projet et prêt à être utilisé.',
    value: 'Fini',
    key: 1,
  },
  {
    title: 'Semi fini: Le résultat est partiellement adapté, il le sera dans un second temps, une fois le concours fini.',
    value: 'Semi-fini',
    key: 2,
  },
  {
    title: 'Preuves: Le résultat n\'est pas adapté à votre projet mais permet de se rendre compte du talent des candidats.',
    value: 'Preuves',
    key: 3,
  },
];

export const rules = {
  dateConcours: 'Le concours sera en ligne pendant 7 jours sur la plateforme. \n'
    + 'Il est possible de stopper à tout moment le concours si une proposition vous convient.',
  choice: 'Pour vous aider à choisir durant la phase de sélection,  les talents devront vous envoyer : ',
};

export const statusConcoursNotPaid = 1;
export const statusConcoursPaid = 2;
export const statusConcoursWaitWinner = 3;
export const statusConcoursWaitFinalFiles = 4;
export const statusConcoursFinish = 5;
export const statusConcoursProblem = -1;

export const triConcours = [
  {
    key: 0,
    text: 'Plus récent',
    selector: 'createdAt',
    orderedBy: 'DESC',
  }, {
    key: 1,
    text: 'Moins récent',
    selector: 'createdAt',
    orderedBy: 'ASC',
  }, {
    key: 2,
    text: 'Plus de gains',
    selector: 'cost',
    orderedBy: 'DESC',
  }, {
    key: 3,
    text: 'Moins de gains',
    selector: 'cost',
    orderedBy: 'ASC',
  }, {
    key: 4,
    text: 'Plus de participations',
    selector: null,
    orderedBy: 'DESC',
  }, {
    key: 5,
    text: 'Moins de participations',
    selector: null,
    orderedBy: 'ASC',
  },
];
