import { connect } from 'react-redux';
import {
  getConcoursById, addWinner, prefinalWinner, getAllWinnerByConcours, deleteWinner,
} from '../../../actions/concours';
import { updateStars, getParticipationById, reportParticipation } from '../../../actions/participation';
import Participations from './participations';

const mapStateToProps = ({ concours, participation }) => ({
  concours: concours.singleConcours,
  messageSuccess: concours.message || participation.msg,
  allWinners: concours.allWinners,
});

export default connect(
  mapStateToProps,
  {
    getConcoursById, updateStars, addWinner, prefinalWinner, getParticipationById, getAllWinnerByConcours, deleteWinner, reportParticipation,
  },
)(Participations);
