import { withStyles } from '@material-ui/core';

export default withStyles(({ breakpoints, palette, shadows }) => ({
  root: {
    display: 'flex',
  },
  wrapperLeft: {
    minHeight: '100%',
    height: '60vh',
    background: '#f1f1f3',
    padding: '2rem 1rem',
    flex: 0.5,
    transition: 'all .3s ease-in-out',
  },
  invisible: {
    transition: 'all .3s ease-in-out',
    display: 'none',
  },
  visible: {
    display: 'block',
    transition: 'all .3s ease-in-out',
  },
  visibleFlex: {
    display: 'flex',
  },
  littleWidth: {
    flex: 'inherit',
    transition: 'all .3s ease-in-out',
  },
  wrapperSearch: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  fieldsetInput: {
    background: palette.grey[300],
    borderRadius: '3rem',
    transition: '0.1s all ease-in-out',
    '@global': {
      label: {
        color: `${palette.grey[500]} !important`,
        fontWeight: 'bold',
      },
    },
    '&:hover': {
      opacity: 0.8,
    },
  },
  inputSearch: {
    background: `${palette.grey[300]} !important`,
    '&::placeholder': {
      color: palette.grey[500],
      fontWeight: 'bold',
    },
  },
  iconButtonSearch: {
    marginLeft: '1rem',
  },
  wrapperUser: {

  },
  avatarImg: {
    width: '110%',
  },
  fullWidth: {
    width: '100%',
  },
  elementWeight: {
    fontWeight: 'bold',
  },
  dateUser: {
    alignSelf: 'end',
    marginTop: '0.6rem',
  },
  dateUserText: {
    fontWeight: 'bold',
    color: palette.grey[400],
  },
  wrapperRight: {
    margin: '2rem',
    flex: 3,
  },
  headerRight: {
    boxShadow: '0 14px 23px 4px rgba(0, 0, 0, 0.03)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem',
    width: '100%',
  },
  concours: {
    display: 'flex',
  },
  concoursText: {
    marginLeft: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  titleConcours: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
  propositionConcours: {
    fontSize: '0.8rem',
    fontWeight: 'bold',
    color: palette.grey[400],
  },
  infoConcours: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
  },
  messages: {
    boxShadow: '0 14px 23px 4px rgba(0, 0, 0, 0.03)',
    height: '90%',
    padding: '2rem',
    marginTop: '1rem',
  },
}));
