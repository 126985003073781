import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';
import NavBar from '../../_molecules/NavBarGuest/nav-bar-guest';
import Connexion from '../../_pages/Connexion/connection.connect';
import withHeader from './header-guest.style';

const HeaderGuest = ({
  classes, className, onConnexion, open, onClose, allLinks, sticky, position,
}) => (
  <div className={cx(classes.header, className)}>
    <NavBar sticky={sticky} onConnexion={onConnexion} position={position} allLinks={allLinks} />
    <Connexion open={open} onClose={onClose} />
  </div>
);

HeaderGuest.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  className: Proptypes.string,
  onConnexion: Proptypes.func,
  onClose: Proptypes.func,
  open: Proptypes.bool,
  sticky: Proptypes.bool,
  allLinks: Proptypes.bool,
  position: Proptypes.oneOf([
    'static', 'fixed', 'relative', 'sticky',
  ]),
};

HeaderGuest.defaultProps = {
  allLinks: true,
  sticky: false,
};

export default withHeader(HeaderGuest);
