import axios from 'axios';
import { returnErrors } from './errorActions';
import { apiKey } from '../constants/api';

import {
  FETCH_CREATECONCOURS,
  FETCH_CREATECONCOURS_FULFILLED,
  FETCH_CREATECONCOURS_ERROR,
  FETCH_CONCOURSID,
  FETCH_CONCOURSID_FULFILLED,
  FETCH_CONCOURSID_ERROR,
  FETCH_ALL_CONCOURS,
  FETCH_ALL_CONCOURS_FULFILLED,
  FETCH_ALL_CONCOURS_ERROR,
  FETCH_CONCOURS_SUBCATEGORY,
  FETCH_CONCOURS_SUBCATEGORY_ERROR,
  FETCH_CONCOURS_SUBCATEGORY_FULFILLED,
  FETCH_CONCOURS_FULFILLED_NOCONTENT,
  FETCH_CONCOURS_CATEGORY,
  FETCH_CONCOURS_CATEGORY_FULFILLED,
  FETCH_CONCOURS_CATEGORY_ERROR,
  FETCH_CONCOURS_CLEAR,
  FETCH_ADD_PARTICIPANT,
  FETCH_ADD_PARTICIPANT_FULFILLED,
  FETCH_ADD_PARTICIPANT_ERROR,
  CLEAR_SUCCESS,
  FETCH_ADD_WINNER,
  FETCH_ADD_WINNER_FULFILLED,
  FETCH_ADD_WINNER_ERROR,
  FETCH_PREFINAL_WINNER,
  FETCH_PREFINAL_WINNER_FULFILLED,
  FETCH_PREFINAL_WINNER_ERROR,
  FETCH_CHOOSE_FINAL_WINNER,
  FETCH_CHOOSE_FINAL_WINNER_FULFILLED,
  FETCH_CHOOSE_FINAL_WINNER_ERROR,
  FETCH_GET_ALL_WINNER_BY_CONCOURS,
  FETCH_GET_ALL_WINNER_BY_CONCOURS_FULFILLED,
  FETCH_DELETE_WINNER_FULFILLED,
  FETCH_DELETE_WINNER_ERROR,
  FETCH_DELETE_WINNER,
  FETCH_GET_ALL_WINNER_BY_CONCOURS_ERROR,
  FETCH_GET_CONCOURS_CREATED_BY_USER,
  FETCH_GET_CONCOURS_CREATED_BY_USER_FULFILLED,
  FETCH_GET_CONCOURS_CREATED_BY_USER_ERROR,
  FETCH_GET_CONCOURS_PARTICIPED_BY_USER,
  FETCH_GET_CONCOURS_PARTICIPED_BY_USER_ERROR,
  FETCH_GET_CONCOURS_PARTICIPED_BY_USER_FULFILLED,
  FETCH_GET_CONCOURS_CREATED_BY_USER_NO_CONTENT,
  FETCH_GET_CONCOURS_PARTICIPED_BY_USER_NO_CONTENT,
  FETCH_ADD_FINAL_FILE,
  FETCH_ADD_FINAL_FILE_FULFILLED,
  FETCH_ADD_FINAL_FILE_ERROR,
  FETCH_CONCOURS_WITH_FILTER,
  FETCH_CONCOURS_WITH_FILTER_FULFILLED,
  FETCH_CONCOURS_WITH_FILTER_ERROR,
  FETCH_MODIFY_CONCOURS,
  FETCH_MODIFY_CONCOURS_FULFILLED,
  FETCH_MODIFY_CONCOURS_ERROR,
} from '../constants/actionTypes';
import { tokenConfig } from '../helpers/tokenConfig';
import { returnSuccess } from './successActions';

// Une fois que le concours est créé il manque la partie paiement pour que le lightener fasse le paiement
export const createConcours = ({
  companyName,
  companySkills,
  target,
  cursorModern,
  idSubCategory,
  cursorYoung,
  cursorFun,
  cursorLuxury,
  message,
  endDate,
  cost,
  step,
  options,
}, cbSuccess = () => {}, cbError = () => {}) => (dispatch, getState) => {
  // //dispatch(clearErrors());

  dispatch({ type: FETCH_CREATECONCOURS });

  // Request body
  const body = JSON.stringify({
    companyName,
    companySkills,
    target,
    cursorModern,
    idSubCategory,
    cursorYoung,
    cursorFun,
    cursorLuxury,
    message,
    endDate,
    cost,
    step,
    arrayOptions: options,
  });

  axios.post(`${apiKey}/concours/create`, body, tokenConfig(getState))
    .then((res) => {
      cbSuccess(res.data.results.newConcours);
      dispatch({
        type: FETCH_CREATECONCOURS_FULFILLED,
        payload: res.data.results.newConcours,
      });
    })
    .catch((err) => {
      cbError(err.response?.data?.message?.message);
      dispatch(
        returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'CREATE_CONCOURS_FAIL'),
      );
      dispatch({
        type: FETCH_CREATECONCOURS_ERROR,
      });
    });
};

export const getConcoursById = (id, cbSuccess = () => {}, cbError = () => {}) => (dispatch, getState) => {
  // dispatch(clearErrors());
  dispatch({ type: FETCH_CONCOURSID });

  axios.get(`${apiKey}/concours/read/${id}`, tokenConfig(getState))
    .then((res) => {
      cbSuccess(res.data.results.concours);
      dispatch({ type: FETCH_CONCOURSID_FULFILLED, payload: res.data.results.concours });
    })
    .catch((err) => {
      cbError(err.response?.data?.message?.message);
      dispatch(
        returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'GET_CONCOURS_ID_FAIL'),
      );
      dispatch({ type: FETCH_CONCOURSID_ERROR });
    });
};

export const getConcoursWithFilter = (param, cbSuccess = () => {}, cbError = () => {}) => (dispatch, getState) => {
  // dispatch(clearErrors());
  dispatch({ type: FETCH_CONCOURS_WITH_FILTER });

  const body = JSON.stringify({
    selector: param.selector,
    orderedBy: param.orderedBy,
    idCategory: param.idCategory,
    idSubCategory: param.idSubCategory,
  });

  axios.post(`${apiKey}/concours/orderBy/any`, body, tokenConfig(getState))
    .then((res) => {
      cbSuccess(res.data.results.concours);
      dispatch({
        type: FETCH_CONCOURS_WITH_FILTER_FULFILLED,
        payload: res.data.results.concours,
      });
    })
    .catch((err) => {
      cbError(err.response?.data?.message?.message);
      dispatch(
        returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'CONCOURS_WITH_FILTER_ERROR'),
      );

      dispatch({
        type: FETCH_CONCOURS_WITH_FILTER_ERROR,
      });
    });
};

export const getConcoursByCategory = (idCategory, cbSuccess = () => {}, cbError = () => {}) => (dispatch, getState) => {
  // dispatch(clearErrors());
  dispatch({ type: FETCH_CONCOURS_CATEGORY });

  axios.get(`${apiKey}/concours/read/category/${idCategory}`, tokenConfig(getState))
    .then((res) => {
      cbSuccess(res.data.results.concours);
      dispatch({ type: FETCH_CONCOURS_CATEGORY_FULFILLED, payload: res.data.results.concours });
    })
    .catch((err) => {
      if (err.response?.data?.message?.code === 400) { // Le serveur a compris mais pas de content
        cbError(err.response?.data?.message?.message);
        dispatch({ type: FETCH_CONCOURS_FULFILLED_NOCONTENT });
      } else {
        cbError(err.response?.data?.message?.message);
        dispatch(
          returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'GET_CONCOURS_CATEGORY_FAIL'),
        );
        dispatch({ type: FETCH_CONCOURS_CATEGORY_ERROR });
      }
    });
};

export const getConcoursBySubCategory = (idSubCategory, cbSuccess = () => {}, cbError = () => {}) => (dispatch, getState) => {
  // dispatch(clearErrors());
  dispatch({ type: FETCH_CONCOURS_SUBCATEGORY });

  axios.get(`${apiKey}/concours/read/subcategory/${idSubCategory}`, tokenConfig(getState))
    .then((res) => {
      cbSuccess(res.data.results.concours);
      dispatch({ type: FETCH_CONCOURS_SUBCATEGORY_FULFILLED, payload: res.data.results.concours });
    })
    .catch((err) => {
      if (err.response?.data?.message?.code === 400) { // Le serveur a compris mais pas de content
        cbError(err.response?.data?.message?.message);
        dispatch({ type: FETCH_CONCOURS_FULFILLED_NOCONTENT });
      } else {
        cbError(err.response?.data?.message?.message);
        dispatch(
          returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'GET_CONCOURS_SUBCATEGORY_FAIL'),
        );
        dispatch({ type: FETCH_CONCOURS_SUBCATEGORY_ERROR });
      }
    });
};

export const getAllConcours = (cbSuccess = () => {}, cbError = () => {}) => (dispatch, getState) => {
  // dispatch(clearErrors());
  dispatch({ type: FETCH_ALL_CONCOURS });

  axios.get(`${apiKey}/concours/readAll/created`, tokenConfig(getState))
    .then((res) => {
      cbSuccess(res.data.results.concours);
      dispatch({
        type: FETCH_ALL_CONCOURS_FULFILLED,
        payload: res.data.results.concours,
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_ALL_CONCOURS_ERROR,
      });
      cbError(err.response?.data?.message?.message);
      dispatch(
        returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'GET_ALL_CONCOURS_FAIL'),
      );
    });
};

export const getAllConcoursCreatedbyUser = (cbSuccess = () => {}, cbError = () => {}) => (dispatch, getState) => {
  // dispatch(clearErrors());
  dispatch({ type: FETCH_GET_CONCOURS_CREATED_BY_USER });

  axios.get(`${apiKey}/concours/readAll/user`, tokenConfig(getState))
    .then((res) => {
      cbSuccess(res.data.results.concours);
      dispatch({
        type: FETCH_GET_CONCOURS_CREATED_BY_USER_FULFILLED,
        payload: res.data.results.concours,
      });
    })
    .catch((err) => {
      if (err.response?.data?.message?.code === 400) { // Le serveur a compris mais pas de content
        cbError(err.response?.data?.message?.message);
        dispatch({ type: FETCH_GET_CONCOURS_CREATED_BY_USER_NO_CONTENT });
        dispatch(
          returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'GET_CONCOURS_CREATED_BY_USER_FAIL'),
        );
      }
      cbError(err.response?.data?.message?.message);
      dispatch({
        type: FETCH_GET_CONCOURS_CREATED_BY_USER_ERROR,
      });
      dispatch(
        returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'GET_CONCOURS_CREATED_BY_USER_NO_CONTENT'),
      );
    });
};

export const getAllConcoursParticipedbyUser = (cbSuccess = () => {}, cbError = () => {}) => (dispatch, getState) => {
  // dispatch(clearErrors());
  dispatch({ type: FETCH_GET_CONCOURS_PARTICIPED_BY_USER });

  axios.get(`${apiKey}/concours/readAll/byParticipant`, tokenConfig(getState))
    .then((res) => {
      cbSuccess(res.data.results.concours);
      dispatch({
        type: FETCH_GET_CONCOURS_PARTICIPED_BY_USER_FULFILLED,
        payload: res.data.results.concours,
      });
    })
    .catch((err) => {
      if (err.response?.data?.message?.code === 400) { // Le serveur a compris mais pas de content
        cbError(err.response?.data?.message?.message);
        dispatch({ type: FETCH_GET_CONCOURS_PARTICIPED_BY_USER_NO_CONTENT });
        dispatch(
          returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'GET_CONCOURS_PARTICIPED_BY_USER_NO_CONTENT'),
        );
      }
      cbError(err.response?.data?.message?.message);
      dispatch({
        type: FETCH_GET_CONCOURS_PARTICIPED_BY_USER_ERROR,
      });
      dispatch(
        returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'GET_CONCOURS_PARTICIPED_BY_USER_FAIL'),
      );
    });
};

export const getAllWinnerByConcours = (idConcours, cbSuccess = () => {}, cbError = () => {}) => (dispatch, getState) => {
  // dispatch(clearErrors());
  dispatch({ type: FETCH_GET_ALL_WINNER_BY_CONCOURS });
  axios.get(`${apiKey}/concours/readAll/winner/${idConcours}`, tokenConfig(getState))
    .then((res) => {
      cbSuccess(res.data.results.concours);
      dispatch({
        type: FETCH_GET_ALL_WINNER_BY_CONCOURS_FULFILLED,
        payload: res.data.results.concours, // Je récupère seulement les participations
      });
    })
    .catch((err) => {
      cbError(err.response?.data?.message?.message);
      dispatch({
        type: FETCH_GET_ALL_WINNER_BY_CONCOURS_ERROR,
      });
      dispatch(
        returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'GET_ALL_WINNER_BY_CONCOURS_FAIL'),
      );
    });
};

export const modifyConcours = ({
  companyName,
  companySkills,
  target,
  cursorModern,
  cursorYoung,
  cursorFun,
  cursorLuxury,
  message,
  idConcours,
}, cbSuccess = () => {}, cbError = () => {}) => (dispatch, getState) => {
  // dispatch(clearErrors());
  dispatch({ type: FETCH_MODIFY_CONCOURS });

  const body = JSON.stringify({
    companyName,
    companySkills,
    target,
    cursorModern,
    cursorYoung,
    cursorFun,
    cursorLuxury,
    message,
    idConcours,
  });

  axios.post(`${apiKey}/concours/update`, body, tokenConfig(getState))
    .then((res) => {
      cbSuccess(res.data);
      dispatch(
        returnSuccess(res.data?.message, 'MODIFY_CONCOURS'),
      );
      dispatch({
        type: FETCH_MODIFY_CONCOURS_FULFILLED,
      });
    })
    .catch((err) => {
      cbError(err.response?.data?.message?.message);
      dispatch(returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'ADD_WINNER_FAIL'));
      dispatch({
        type: FETCH_MODIFY_CONCOURS_ERROR,
      });
    });
};

export const addWinner = (idParticipationWinner, idConcours, cbSuccess = () => {}, cbError = () => {}) => (dispatch, getState) => {
  // dispatch(clearErrors());
  dispatch({ type: FETCH_ADD_WINNER });

  const body = JSON.stringify({
    idParticipationWinner,
    idConcours,
  });

  axios.post(`${apiKey}/concours/winner/add`, body, tokenConfig(getState))
    .then((res) => {
      cbSuccess(res.data);
      dispatch(
        returnSuccess(res.data?.message, 'ADD_WINNER'),
      );
      dispatch({
        type: FETCH_ADD_WINNER_FULFILLED,
      });
    })
    .catch((err) => {
      cbError(err.response?.data?.message?.message);
      dispatch(returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'ADD_WINNER_FAIL'));
      dispatch({
        type: FETCH_ADD_WINNER_ERROR,
      });
    });
};

export const deleteWinner = (idParticipationWinner, idConcours, cbSuccess = () => {}, cbError = () => {}) => (dispatch, getState) => {
  // dispatch(clearErrors());
  dispatch({ type: FETCH_DELETE_WINNER });

  const body = JSON.stringify({
    idParticipationWinner,
    idConcours,
  });

  axios.post(`${apiKey}/concours/winner/delete`, body, tokenConfig(getState))
    .then((res) => {
      cbSuccess(res.data);
      dispatch(
        returnSuccess(res.data?.message, 'DELETE_WINNER'),
      );
      dispatch({
        type: FETCH_DELETE_WINNER_FULFILLED,
      });
    })
    .catch((err) => {
      cbError(err.response?.data?.message?.message);
      dispatch(returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'DELETE_WINNER_FAIL'));
      dispatch({
        type: FETCH_DELETE_WINNER_ERROR,
      });
    });
};

export const prefinalWinner = (idConcours, cbSuccess = () => {}, cbError = () => {}) => (dispatch, getState) => {
  // dispatch(clearErrors());
  dispatch({ type: FETCH_PREFINAL_WINNER });

  const body = JSON.stringify({
    idConcours,
  });

  axios.post(`${apiKey}/concours/winner/prefinal`, body, tokenConfig(getState))
    .then((res) => {
      cbSuccess(res.data);
      dispatch(
        returnSuccess(res.data?.message, 'PREFINAL_WINNER'),
      );
      dispatch({
        type: FETCH_PREFINAL_WINNER_FULFILLED,
      });
    })
    .catch((err) => {
      cbError(err.response?.data?.message?.message);
      dispatch(returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'PREFINAL_WINNER_FAIL'));
      dispatch({
        type: FETCH_PREFINAL_WINNER_ERROR,
      });
    });
};

export const chooseFinalWinner = (idConcours, idParticipation, cbSuccess = () => {}, cbError = () => {}) => (dispatch, getState) => {
  // dispatch(clearErrors());
  dispatch({ type: FETCH_CHOOSE_FINAL_WINNER });

  const body = JSON.stringify({
    idConcours,
    idParticipation,
  });

  axios.post(`${apiKey}/concours/winner/final`, body, tokenConfig(getState))
    .then((res) => {
      cbSuccess(res.data);
      dispatch(
        returnSuccess(res.data?.message, 'CHOOSE_FINAL_WINNER'),
      );
      dispatch({
        type: FETCH_CHOOSE_FINAL_WINNER_FULFILLED,
      });
    })
    .catch((err) => {
      cbError(err.response?.data?.message?.message);
      dispatch(returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'CHOOSE_WINNER_FAIL'));
      dispatch({
        type: FETCH_CHOOSE_FINAL_WINNER_ERROR,
      });
    });
};

export const addParticipant = (idConcours, inputText = null, cbSuccess = () => {}, cbError = () => {}) => (dispatch, getState) => {
  // dispatch(clearErrors());
  dispatch({ type: FETCH_ADD_PARTICIPANT });
  let body;

  if (inputText) {
    body = JSON.stringify({
      idConcours,
      inputText,
    });
  } else {
    body = JSON.stringify({
      idConcours,
    });
  }

  axios.post(`${apiKey}/concours/add/participation`, body, tokenConfig(getState))
    .then((res) => {
      cbSuccess(res.data.results);
      dispatch({
        type: FETCH_ADD_PARTICIPANT_FULFILLED,
        payload: res.data.results,
      });
    })
    .catch((err) => {
      cbError(err.response?.data?.message?.message);
      dispatch({
        type: FETCH_ADD_PARTICIPANT_ERROR,
      });
      dispatch(
        returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'ADD_PARTICIPATION_FAIL'),
      );
    });
};

export const addFinalFile = (idParticipation, file = null, cbSuccess = () => {}, cbError = () => {}) => (dispatch, getState) => {
  // dispatch(clearErrors());
  dispatch({ type: FETCH_ADD_FINAL_FILE });

  const body = new FormData();
  body.append('fileFinale', file);
  body.append('idParticipation', idParticipation);

  axios.post(`${apiKey}/concours/participant/upload/finalFile`, body, tokenConfig(getState))
    .then((res) => {
      cbSuccess(res.data);
      dispatch(
        returnSuccess(res.data?.message, 'ADD_FINAL_FILE'),
      );
      dispatch({
        type: FETCH_ADD_FINAL_FILE_FULFILLED,
      });
    })
    .catch((err) => {
      cbError(err.response?.data?.message?.message);
      dispatch({
        type: FETCH_ADD_FINAL_FILE_ERROR,
      });
      dispatch(
        returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'ADD_FINAL_FILE'),
      );
    });
};

export const clearConcours = () => ({
  type: FETCH_CONCOURS_CLEAR,
});

export const clearSuccessMsg = () => ({
  type: CLEAR_SUCCESS,
});
