import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import { Typography } from '@material-ui/core';
import InputForm from '../../_atoms/InputForm/input-form';
import TextareaForm from '../../_atoms/TextareaForm/textarea-form';
import SliderWithInfo from '../../_molecules/SliderWithInfo/slider-with-info';
import { ucFirst } from '../../../helpers/string';
import { parseDateConcoursAgo, parseFormatConcours } from '../../../helpers/parse';
import InfoConcours from '../../_molecules/InfoConcours/info-concours';
import { gainForWinner } from '../../../helpers/concours';

import withDetailsConcours from './details-concours.style';

const DetailsConcours = ({
  classes,
  lockedInput,
  valueCompanyName,
  valueCompanySkills,
  valueTarget,
  valueCursorModern,
  valueCursorYoung,
  valueCursorFun,
  valueCursorLuxury,
  onChangeCompanyName,
  onChangeCompanySkills,
  onChangeTarget,
  onChangeCursorModern,
  onChangeCursorYoung,
  onChangeFun,
  onChangeLuxury,
  concours,
  sendMessage,
  textSubmit,
  className,
  classNameField,
  classNameInput,
  classNameBtnSubmit,
  classNameMessage,
  onClickMessage,
  onClickSubmit,
}) => {
  const today = new Date();
  return (
    <section className={cx(classes.root, className)}>
      <div className={classes.wrapperInfosConcours}>
        <div className={classes.left}>
          <div className={classes.block}>
            <Typography variant="h3" display="block" className={classes.title}>Nom de votre entreprise ou projet</Typography>
            <InputForm
              name="companyName"
              id="companyName"
              autoComplete="off"
              type="text"
              className={cx(classes.fieldsetInput, classNameField)}
              classNameInput={cx(classes.input, classNameInput)}
              value={valueCompanyName}
              locked={lockedInput}
              onChange={onChangeCompanyName}
              noOnChange={lockedInput}
            />
          </div>
          <div className={classes.block}>
            <Typography variant="h3" display="block" className={classes.title}>Vous êtes réputé pour</Typography>
            <TextareaForm
              name="companySkills"
              id="companySkills"
              autoComplete="off"
              type="text"
              className={cx(classes.fieldsetInput, classNameField)}
              classNameArea={cx(classes.input, classNameInput)}
              value={valueCompanySkills}
              noMinHeight
              locked={lockedInput}
              onChange={onChangeCompanySkills}
              width="90%"
              noOnChange={lockedInput}
            />
          </div>
          <div className={classes.block}>
            <Typography variant="h3" display="block" className={classes.title}>Elle cible</Typography>
            <TextareaForm
              name="target"
              id="target"
              autoComplete="off"
              type="text"
              className={cx(classes.fieldsetInput, classNameField)}
              classNameArea={cx(classes.input, classNameInput)}
              value={valueTarget}
              onChange={onChangeTarget}
              noMinHeight
              locked={lockedInput}
              width="90%"
              noOnChange={lockedInput}
            />
          </div>
          <div className={classes.sliders}>
            <SliderWithInfo onChange={onChangeCursorModern} name="cursorModern" value={valueCursorModern} infoLeft="Classique" infoRight="Moderne" className={classes.slider} />
            <SliderWithInfo onChange={onChangeCursorYoung} name="cusorYoung" value={valueCursorYoung} infoLeft="Jeune" infoRight="Mature" className={classes.slider} />
            <SliderWithInfo onChange={onChangeFun} name="cusorFun" value={valueCursorFun} infoLeft="Drôle" infoRight="Sérieuse" className={classes.slider} />
            <SliderWithInfo onChange={onChangeLuxury} name="cusorLuxury" value={valueCursorLuxury} infoLeft="Discount" infoRight="Luxueux" className={classes.slider} />
          </div>
        </div>
        <div className={classes.right}>
          <div className={classes.paperMessage}>
            <div className={classes.topPaperMessage}>
              <div className={classes.infoUser}>
                <Typography variant="body1" className={classes.pseaudo}>{concours.User.pseudo ? ucFirst(concours.User.pseudo) : 'Anonyme'}</Typography>
                <Typography variant="body1" className={classes.dateCreation}>{parseDateConcoursAgo(concours.createdAt, today)}</Typography>
              </div>
              <div className={classes.motOrganisateur}>
                <Typography variant="body1" className={classes.textMotOrganisateur}>Le mot de l&apos;organisateur</Typography>
              </div>
            </div>
            <Typography variant="body2" className={classes.message}>
              {concours.message}
            </Typography>
          </div>
          { // TODO: REVOIR LE classNameBtnParticipate And classNameMessage
          }
          <InfoConcours
            className={classes.infoConcours}
            classNameBtnSubmit={classNameBtnSubmit}
            classNameMessage={classNameMessage}
            onClickMessage={onClickMessage}
            onClickSubmit={onClickSubmit}
            textSubmit={textSubmit}
            sendMessage={sendMessage}
            category={concours.subCategory.name}
            endDate={concours.endDate}
            format={`${parseFormatConcours(concours.subCategory.format)} puis fichier source`}
            gain={gainForWinner(concours.cost)}
            resultatAttendu="Fini"
          />
        </div>
      </div>
    </section>
  );
};

DetailsConcours.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  concours: Proptypes.object,
  valueCompanyName: Proptypes.string,
  valueCompanySkills: Proptypes.string,
  valueTarget: Proptypes.string,
  valueCursorModern: Proptypes.oneOfType([
    Proptypes.number, Proptypes.string,
  ]),
  valueCursorYoung: Proptypes.oneOfType([
    Proptypes.number, Proptypes.string,
  ]),
  valueCursorFun: Proptypes.oneOfType([
    Proptypes.number, Proptypes.string,
  ]),
  valueCursorLuxury: Proptypes.oneOfType([
    Proptypes.number, Proptypes.string,
  ]),
  className: Proptypes.string,
  classNameField: Proptypes.string,
  classNameInput: Proptypes.string,
  classNameMessage: Proptypes.oneOfType([
    Proptypes.bool, Proptypes.string,
  ]),
  classNameBtnSubmit: Proptypes.oneOfType([Proptypes.string, Proptypes.bool]),
  onClickMessage: Proptypes.func,
  onClickSubmit: Proptypes.func,
  onChangeCompanyName: Proptypes.func,
  onChangeCompanySkills: Proptypes.func,
  onChangeTarget: Proptypes.func,
  onChangeCursorModern: Proptypes.func,
  onChangeCursorYoung: Proptypes.func,
  onChangeFun: Proptypes.func,
  onChangeLuxury: Proptypes.func,
  sendMessage: Proptypes.bool,
  textSubmit: Proptypes.string,
  lockedInput: Proptypes.bool,
};

DetailsConcours.defaultProps = {
  lockedInput: false,
};

export default withDetailsConcours(DetailsConcours);
