import React from 'react';
import Proptypes from 'prop-types';
import cx from 'classnames';

import { Typography } from '@material-ui/core';
import Slider from '../../_atoms/Slider/slider';
import withSliderInfo from './slider-with-info.style';

const SliderWithInfo = ({
  classes,
  infoLeft,
  infoRight,
  value,
  onChange,
  className,
  marks,
  min,
  max,
  defaultValue,
  name,
  ...rest
}) => (
  <div className={cx(classes.root, className)}>
    <Typography variant="h4" display="block" className={classes.text}>{infoLeft}</Typography>
    <Slider
      className={classes.margin}
      value={value}
      onChange={onChange}
      name={name}
      marks={marks}
      max={max}
      min={min}
      defaultValue={defaultValue}
      {...rest}
    />
    <Typography variant="h4" display="block" className={cx(classes.text, classes.textLeft)}>{infoRight}</Typography>
  </div>
);

SliderWithInfo.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  className: Proptypes.string,
  infoLeft: Proptypes.string,
  infoRight: Proptypes.string,
  value: Proptypes.oneOfType([
    Proptypes.string,
    Proptypes.number,
  ]),
  min: Proptypes.number,
  max: Proptypes.number,
  marks: Proptypes.arrayOf(Proptypes.object),
  defaultValue: Proptypes.oneOfType([
    Proptypes.string,
    Proptypes.number,
  ]),
  name: Proptypes.string,
  onChange: Proptypes.func,
};

SliderWithInfo.defaultProps = {
  marks: [
    {
      value: 0,
    },
    {
      value: 1,
    },
    {
      value: 2,
    },
    {
      value: 3,
    },
    {
      value: 4,
    },
    {
      value: 5,
    },
  ],
  defaultValue: 2,
  min: 0,
  max: 5,
};

export default withSliderInfo(SliderWithInfo);
