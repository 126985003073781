import { withStyles } from '@material-ui/core';

export default withStyles(({ breakpoints, palette, shadows }) => ({
  categories: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0',
  },
  sliderCategories: {
    marginTop: '4em',
    paddingTop: '2rem',
  },
  arrowsCategories: {
    top: '40%',
  },
  listCard: {
    marginTop: '1rem',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1.8rem',
    color: palette.grey[800],
    textAlign: 'center',
  },
  sectionConcours: {
    display: 'flex',
    marginTop: '2rem',
    justifyContent: 'space-around',
    [breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
    },
  },
  articleConcours: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: '2rem 0',
  },
  filter: {
    marginBottom: '2rem',
    justifyContent: 'flex-end',
    width: '90%',
    [breakpoints.down('sm')]: {
      width: '90%',
      justifyContent: 'center',
    },
  },
  concours: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  cardInformationConcours: {
    margin: '2rem',
  },
  asideHowDid: {
    margin: '2rem',
    marginTop: '6rem',
    [breakpoints.down('sm')]: {
      marginTop: '2rem',
    },
  },
  btnVoirPlus: {
    transition: '0.2s opacity ease-in-out',
    '&:hover': {
      opacity: 0.8,
    },
  },
  titleSlider: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '2rem',
    color: palette.grey[800],
  },
  wrapperNoConcoursPaper: {
    display: 'flex',
    height: '50vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noConcoursPaper: {
    width: '30vw',
    minWidth: '28rem',
    height: '20vw',
    minHeight: '28rem',
  },
  noConcoursTitle: {
    textAlign: 'center',
    fontSize: '2rem',
    color: palette.secondary.main,
    fontWeight: 'bold',
    padding: '2rem',
  },
}));
