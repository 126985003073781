import {
  FETCH_SIGNUP,
  FETCH_SIGNUP_FULFILLED,
  FETCH_SIGNUP_ERROR,
  FETCH_LOGIN,
  FETCH_LOGIN_FULFILLED,
  FETCH_LOGIN_ERROR,
  USER_LOADING,
  USER_LOADED,
  AUTH_ERROR,
  LOGOUT_SUCCESS,
  CLEAR_SUCCESS,
  FETCH_USER_UPDATE,
  FETCH_USER_UPDATE_FULFILLED,
  FETCH_ADD_STATUT_TO_USER_FULFILLED,
  FETCH_ADD_STATUT_TO_USER,
  FETCH_GET_STATUT_USER,
  FETCH_GET_STATUT_USER_FULFILLED,
  FETCH_GET_STATUT_USER_ERROR,
  FETCH_LOGIN_GOOGLE_FULFILLED,
  FETCH_LOGIN_GOOGLE,
  FETCH_LOGIN_GOOGLE_ERROR,
  FETCH_LOGIN_FACEBOOK,
  FETCH_LOGIN_FACEBOOK_ERROR,
  FETCH_LOGIN_FACEBOOK_FULFILLED,
  FETCH_VERIFY_USER,
  FETCH_VERIFY_USER_FULFILLED,
  FETCH_VERIFY_USER_ERROR,
  FETCH_RESET_PASSWORD,
  FETCH_RECOVER_PASSWORD,
  FETCH_RECOVER_PASSWORD_FULFILLED,
  FETCH_RESET_PASSWORD_FULFILLED,
  FETCH_RECOVER_PASSWORD_ERROR,
  FETCH_RESET_PASSWORD_ERROR,
  FETCH_FINISH_TUTORIAL,
  FETCH_FINISH_TUTORIAL_FULFILLED,
  FETCH_FINISH_TUTORIAL_ERROR,
} from '../constants/actionTypes';
import { saveState, loadState } from '../store/localStorage';

const initialState = {
  token: loadState('token'),
  fetching: false,
  fetched: false,
  isLoading: false,
  isAuthenticated: null,
  isAdmin: false,
  user: null,
  userId: null,
  statutEntreprise: null,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case USER_LOADING:
    case FETCH_LOGIN:
    case FETCH_LOGIN_GOOGLE:
    case FETCH_LOGIN_FACEBOOK:
    case FETCH_SIGNUP:
    case FETCH_USER_UPDATE:
    case FETCH_ADD_STATUT_TO_USER:
    case FETCH_GET_STATUT_USER:
    case FETCH_VERIFY_USER:
    case FETCH_RESET_PASSWORD:
    case FETCH_RECOVER_PASSWORD:
    case FETCH_FINISH_TUTORIAL:
      return {
        ...state,
        fetching: true,
        isLoading: true,
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        fetching: false,
        fetched: true,
        user: action.payload,
        userId: action.payload.id,
        isAdmin: action.payload.isAdmin,
      };
    case FETCH_USER_UPDATE_FULFILLED:
    case FETCH_ADD_STATUT_TO_USER_FULFILLED:
      return {
        ...state,
        isLoading: false,
        fetching: false,
        fetched: true,
        user: action.payload,
      };
    case FETCH_LOGIN_FULFILLED:
    case FETCH_LOGIN_GOOGLE_FULFILLED:
    case FETCH_LOGIN_FACEBOOK_FULFILLED:
      saveState('token', action.payload.token);
      return {
        ...state,
        token: loadState('token'),
        fetching: false,
        fetched: true,
        isAuthenticated: true,
        isAdmin: action.payload.isAdmin,
        userId: action.payload.userId,
        isLoading: false,
      };
    case FETCH_GET_STATUT_USER_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        statutEntreprise: action.payload,
        isLoading: false,
      };
    case FETCH_SIGNUP_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        userId: action.payload.userId,
        isLoading: false,
      };
    case FETCH_VERIFY_USER_FULFILLED:
    case FETCH_RESET_PASSWORD_FULFILLED:
    case FETCH_RECOVER_PASSWORD_FULFILLED:
    case FETCH_FINISH_TUTORIAL_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        isLoading: false,
      };
    case FETCH_GET_STATUT_USER_ERROR:
      return {
        ...state,
        statutEntreprise: null,
        fetched: true,
      };
    case FETCH_SIGNUP_ERROR:
    case AUTH_ERROR:
    case FETCH_LOGIN_ERROR:
    case FETCH_LOGIN_GOOGLE_ERROR:
    case FETCH_LOGIN_FACEBOOK_ERROR:
    case LOGOUT_SUCCESS:
      localStorage.removeItem('token');
      return {
        ...state,
        fetching: false,
        fetched: true,
        token: null,
        user: null,
        isAuthenticated: false,
        isAdmin: false,
        isLoading: false,
        statutEntreprise: null,
        userId: null,
      };
    case CLEAR_SUCCESS:
    case FETCH_VERIFY_USER_ERROR:
    case FETCH_RECOVER_PASSWORD_ERROR:
    case FETCH_RESET_PASSWORD_ERROR:
    case FETCH_FINISH_TUTORIAL_ERROR:
      return {
        ...state,
      };
    case 'test':
      return {
        ...state,
        isAuthenticated: null,
      };
    default:
      return state;
  }
}
