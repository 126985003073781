import React from 'react';
import Proptypes from 'prop-types';
import cx from 'classnames';
import {
  Backdrop, Fade, IconButton, Modal,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import withModalCenter from './modal-center.style';

const ModalCenter = ({
  classes, open, className, onClose, children, classNameCloseBtn,
}) => (
  <Modal
    aria-labelledby="connexion"
    aria-describedby="connexion-concoureur"
    disablePortal
    className={cx(classes.modal, className)}
    open={open}
    onClose={onClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
  >
    <Fade in={open}>
      <div className={classes.paper}>
        <IconButton aria-label="close" className={cx(classes.closeButton, classNameCloseBtn)} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <div className={classes.content}>
          {children}
        </div>
      </div>
    </Fade>
  </Modal>
);

ModalCenter.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  open: Proptypes.bool,
  onClose: Proptypes.func,
  children: Proptypes.node,
  className: Proptypes.string,
  classNameCloseBtn: Proptypes.string,
};

ModalCenter.defaultProps = {
  open: false,
  onClose: () => {},
};

export default withModalCenter(ModalCenter);
