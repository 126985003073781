export const Categories = [
  {
    title: 'Design',
    to: 'design',
    key: 0,
  },
  {
    title: 'Ecriture',
    to: 'ecriture',
    key: 1,
  },
  {
    title: 'Video & Image',
    to: 'video-image',
    key: 2,
  },
  {
    title: 'Audio',
    to: 'audio',
    key: 3,
  },
  {
    title: 'Site Web / App',
    to: 'site-app',
    key: 4,
  },
  {
    title: 'Reseaux sociaux',
    to: 'reseaux-sociaux',
    key: 5,
  },
  {
    title: 'Business',
    to: 'business',
    key: 6,
  },
];

export const APropos = [
  {
    title: 'F.A.Q',
    to: '/faq',
    externalLink: false,
    key: 0,
  },
  {
    title: 'Blog',
    to: 'https://blog.concoureur.com',
    externalLink: true,
    key: 1,
  },
  {
    title: 'C.G.U',
    to: '/cgu',
    externalLink: false,
    key: 2,
  },
  {
    title: 'Politique de confidentialité',
    to: '/politique-confidentialite',
    externalLink: false,
    key: 3,
  },
];
