import { withStyles } from '@material-ui/styles';
import { responsiveHome } from '../../../constants/size';

export default withStyles(({ breakpoints, palette }) => ({
  root: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '50vh',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    position: 'relative',
  },
  title: {
    marginTop: '2rem',
    fontSize: '3rem',
    fontWeight: 'bold',
    color: palette.grey[800],
    [breakpoints.down(responsiveHome)]: {
      fontSize: '2rem',
      padding: '0 2.5rem',
    },
  },
  source: {
    fontSize: '1.4rem',
    color: palette.grey[500],
    fontWeight: 'bold',
    [breakpoints.down(responsiveHome)]: {
      fontSize: '1.2rem',
    },
  },
}));
