import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';
import Button from '@material-ui/core/Button';

import withButtonCon from './button.style';

const ButtonCon = ({
  classes,
  width,
  height,
  background,
  color,
  children,
  className,
  ...rest
}) => (
  <Button
    width={width}
    background={background}
    color={color}
    height={height}
    className={cx(
      classes.root,
      className,
    )}
    classes={classes}
    {...rest}
  >
    {children}
  </Button>
);

ButtonCon.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  className: Proptypes.string,
  width: Proptypes.string,
  background: Proptypes.string,
  color: Proptypes.string,
  height: Proptypes.string,
  children: Proptypes.node,
};

ButtonCon.defaultProps = {
  width: '15rem',
  height: '4rem',
  children: null,
  background: 'linear-gradient(82deg, #FE5266 0%, #FF8177 100%)', // linear-gradient(256deg, rgba(254,82,102,1) 70%, rgba(253,127,84,1) 100%)
};

export default withButtonCon(ButtonCon);
