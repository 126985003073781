import { connect } from 'react-redux';
import ConcoursBySub from './concours-by-sub';
import {
  getConcoursWithFilter, clearConcours, getConcoursByCategory,
} from '../../../actions/concours';

const mapStateToProps = ({ concours, user }) => ({
  concours: concours.concours,
  user: user.user,
});

export default connect(
  mapStateToProps,
  {
    getConcoursWithFilter, clearConcours, getConcoursByCategory,
  },
)(ConcoursBySub);
