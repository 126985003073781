import React from 'react';
import Proptypes from 'prop-types';
import cx from 'classnames';
import GoogleLogin from 'react-google-login';
import { Typography } from '@material-ui/core';
import Logo from '../../_atoms/Logo/logo';
import Button from '../../_atoms/Button/button';
import withConnexionTop from './connexion.style';

const ConnexionTop = ({
  classes, onSuccessGoogle, onFaillureGoogle, isForgetPassword,
}) => (
  <>
    {
      isForgetPassword ? (
        <div className={classes.wrapperTextForget}>
          <Typography variant="h3" className={classes.titleForgetPassword}>
            Réinistialiser le mot de passe
          </Typography>
          <Typography variant="body2" className={classes.bodyForgetPassword}>
            Veuillez entrer votre adresse e-mail et nous vous enverrons un lien pour réinitialiser votre mot de passe.
          </Typography>
        </div>
      ) : (
        <>
          <Logo color1="#606060" color2="#fd655e" />
          <div className={classes.connexionReseau}>
            {
              /*
            <FacebookLogin
              appId="222293129842717"
              autoLoad
              fields="name,email,picture"
              callback={onSuccessFacebook}
            />
            <Button
              width="25rem"
              className={cx(
                classes.btn,
                classes.btnFb,
              )}
            >
              Connexion avec Facebook
            </Button>
            */
            }
            <GoogleLogin
              clientId={process.env.REACT_APP_CLIENTID_GOOGLE}
              buttonText="Connexion avec Google"
              render={(renderProps) => (
                <Button
                  width="25rem"
                  className={cx(
                    classes.btn,
                    classes.btnGo,
                  )}
                  onClick={renderProps.onClick}
                >
                  Connexion avec Google
                </Button>
              )}
              onSuccess={onSuccessGoogle}
              onFaillure={onFaillureGoogle}
              cookiePolicy="single_host_origin"
            />
          </div>
        </>

      )
    }
  </>
);

ConnexionTop.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  onSuccessGoogle: Proptypes.func,
  onFaillureGoogle: Proptypes.func,
  isForgetPassword: Proptypes.bool,
};

ConnexionTop.defaultProps = {
  isForgetPassword: false,
};

export default withConnexionTop(ConnexionTop);
