import React from 'react';
import Proptypes from 'prop-types';
import cx from 'classnames';
import { Typography } from '@material-ui/core';
import withLogo from './logo.style';

const Logo = ({
  classes, classNameWrapper, classNameLogo1, classNameLogo2,
}) => (
  <div className={cx(classes.title, classNameWrapper)}>
    <Typography variant="h3" display="inline" className={cx(classes.logo1, classNameLogo1)}>Concoureur</Typography>
    <Typography variant="h3" display="inline" className={cx(classes.logo2, classNameLogo2)}>.com</Typography>
  </div>
);

Logo.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  classNameLogo1: Proptypes.string,
  classNameLogo2: Proptypes.string,
  classNameWrapper: Proptypes.string,
  color1: Proptypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  color2: Proptypes.string,
  responsive: Proptypes.bool,
};

Logo.defaultProps = {
  responsive: false,
};

export default withLogo(Logo);
