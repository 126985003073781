import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Redirect } from 'react-router-dom';
import Proptypes from 'prop-types';

import {
  Checkbox, Divider, FormControlLabel, Typography,
} from '@material-ui/core';

import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import Button from '../../_atoms/Button/button';
import WrapperPageUser from '../../../containers/WrapperPageUser/wrapper-page-user.connect';
import Header from '../../_organisms/Header/header.connect';

import { sortByNameOptions } from '../../../helpers/sort';

import withStep3 from './oragniserConcours.style';

const Step3 = ({
  classes, location, history, getSubCategoryById,
}) => {
  const [optionsBase, setOptionsBase] = useState([]);
  const [optionsPay, setOptionsPaye] = useState([]);
  const [optionsPrerequis, setOptionsPrerequis] = useState([]);
  const [costMini, setCostMini] = useState(0);
  useEffect(() => {
    getSubCategoryById(location.state.concours.idSubCategory,
      ((data) => {
        console.log(data, 'data');
        const optionsBaseTemp = [];
        const optionsPayTemp = [];
        const optionsPrerequisTemp = [];
        data.options.forEach((element) => {
          if (element.isPrerequis) {
            optionsPrerequisTemp.push(element);
          } else if (element.isPayant) {
            optionsPayTemp.push(
              {
                id: element.id,
                nom: element.nom,
                prix: element.prix,
                active: false,
              },
            );
          } else {
            optionsBaseTemp.push(element);
          }
        });
        setOptionsBase(optionsBaseTemp.sort(sortByNameOptions));
        setOptionsPaye(optionsPayTemp.sort(sortByNameOptions));
        setOptionsPrerequis(optionsPrerequisTemp.sort(sortByNameOptions));
      }));
    setCostMini(location.state.concours.costMini);
  }, [getSubCategoryById, location.state.concours.idSubCategory, location.state.concours.costMini]);


  const handleRadioOptions = (option) => (e) => {
    console.log(location.state);
    const temp = option;
    let newCost = costMini;
    console.log(option, 'optionSelected');
    let optionsPayTmp = optionsPay.filter((element) => element.id !== option.id);
    if (temp.active) {
      temp.active = false;
      newCost -= option.prix;
    } else {
      temp.active = true;
      newCost += option.prix;
      if (temp.nom.toLowerCase().indexOf('retouche') !== -1) {
        optionsPayTmp = optionsPayTmp.map((element) => {
          if (element.active) {
            if (element.nom.toLowerCase().indexOf('retouche') !== -1) {
              newCost -= element.prix;
              return { ...element, active: false };
            }
            return element;
          }
          return element;
        });
      }
      if (temp.nom.toLowerCase().indexOf('livr') !== -1) {
        optionsPayTmp = optionsPayTmp.map((element) => {
          if (element.active) {
            if (element.nom.toLowerCase().indexOf('livr') !== -1) {
              newCost -= element.prix;
              return { ...element, active: false };
            }
            return element;
          }
          return element;
        });
      }
    }
    optionsPayTmp = [...optionsPayTmp, temp].sort(sortByNameOptions);
    console.log(optionsPayTmp, 'optionsPayTmp');
    setCostMini(newCost);
    setOptionsPaye(optionsPayTmp);
  };

  const handleSubmit = () => {
    const arrayOptionsPay = [];

    optionsPay.forEach((optionPay) => {
      if (optionPay.active) {
        arrayOptionsPay.push(optionPay);
      }
    });
    history.push({
      pathname: '/organiser-concours/etape4',
      state: {
        concours: {
          ...location.state.concours,
          costMini,
          options: arrayOptionsPay,
        },
      },
    });
  };
  return (
    <>
      {
        location.state && optionsBase ? (
          <WrapperPageUser>
            <Header title="Etape 3 : De quoi avez-vous besoin ? " history={history} />
            <div className={cx(classes.wrapBodyStep2, classes.wrapBodyStep3)}>
              <div className={classes.step3Left}>
                <div className={classes.wrapperOffreBase}>
                  <Typography variant="h3" display="block" className={cx(classes.title, classes.titleStep3Left)}>Offre de base</Typography>
                  <ul className={classes.listOffre}>
                    {
                      optionsBase.map((element) => (
                        <li className={classes.itemOffre} key={element.id}>{element.nom}</li>
                      ))
                    }
                  </ul>
                </div>
                <div className={classes.preRequis}>
                  <Typography variant="h3" display="block" className={cx(classes.title, classes.titleStep3Left)}>Prérequis à fournir</Typography>
                  <ul className={classes.listOffre}>
                    {
                      optionsPrerequis.map((element) => (
                        <li key={element.id} className={classes.itemOffre}>{element.nom}</li>
                      ))
                    }
                  </ul>
                </div>
              </div>
              <div className={classes.wrapperDivider}>
                <Divider className={classes.divider} orientation="vertical" />
              </div>
              <div className={classes.step3Right}>
                <Typography variant="h3" display="block" className={classes.title}>Options</Typography>
                <Typography variant="body2" display="block" className={cx(classes.details, classes.detailsRight)} style={{ marginBottom: '1rem' }}>
                  En souscrivant des options vous clarifier votre demande et vous permettez aux participants de connaitre vos besoin en amont.
                </Typography>
                <div className={classes.options}>
                  {
                    optionsPay.map((element) => (
                      <FormControlLabel
                        control={(
                          <Checkbox
                            onChange={handleRadioOptions(element)}
                            value={element.active}
                            checked={element.active}
                            classes={{ checked: classes.radioChecked }}
                            icon={<RadioButtonUncheckedIcon fontSize="large" />}
                            checkedIcon={<RadioButtonCheckedIcon fontSize="large" />}
                          />
)}
                        label={`${element.nom}: ${element.prix}€`}
                        key={element.id}
                        value={element.active}
                        name={element.nom}
                        classes={{ label: classes.radioLabel }}
                      />
                    ))
                  }
                </div>
                <Typography variant="body2" className={classes.detailPrice}>
                  Le prix minimum du concours est de <span className={classes.priceStep3}>{`${costMini}€`}</span>
                </Typography>
              </div>
            </div>
            <div className={classes.formBottom} style={{ marginTop: '2rem' }}>
              <Button className={classes.btnStep} onClick={handleSubmit}>Continuez</Button>
            </div>
          </WrapperPageUser>
        ) : (
          <Redirect to="/organiser-concours/etape1" />
        )
      }
    </>
  );
};


Step3.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  location: Proptypes.objectOf(
    Proptypes.oneOfType([
      Proptypes.string, Proptypes.object,
    ]),
  ),
  history: Proptypes.objectOf(
    Proptypes.oneOfType([
      Proptypes.number,
      Proptypes.func,
      Proptypes.object,
      Proptypes.string,
    ]),
  ),
  getSubCategoryById: Proptypes.func,
};

Step3.defaultProps = {
  getSubCategoryById: () => {},
};

export default withStep3(Step3);
