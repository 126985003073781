import { withStyles } from '@material-ui/styles';

export default withStyles(({ breakpoints }) => ({
  title: {
    textAlign: 'center',
  },
  logo1: {
    textAlign: 'center',
    color: (props) => props.color1,
    [breakpoints.down(700)]: {
      fontSize: (props) => props.responsive && '1.8rem',
    },
  },
  logo2: {
    textAlign: 'center',
    color: (props) => props.color2,
    [breakpoints.down(700)]: {
      fontSize: (props) => props.responsive && '1.8rem',
    },
  },
}));
