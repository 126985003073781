import React from 'react';
import Proptypes from 'prop-types';
import cx from 'classnames';
import { Paper, Typography } from '@material-ui/core';
import headerImg from '../../../assets/Fond/Element1_modif.png';

import withPaperCustomize from './paper-customize.style';

const PaperCustomize = ({
  classes, className, title, headerBackground, classNameChildren, children, classNameTitle, marginTopText, centerTitle,
}) => (
  <Paper className={cx(classes.root, className)}>
    <div className={classes.header}>
      <img src={headerBackground} alt="" className={classes.img} />
      <div className={classes.wrapperTitle}>
        <Typography variant="h3" className={cx(classes.title, classNameTitle)}>{title}</Typography>
      </div>
    </div>
    <div className={cx(classes.children, classNameChildren)}>
      {children}
    </div>
  </Paper>
);

PaperCustomize.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  className: Proptypes.string,
  classNameTitle: Proptypes.string,
  width: Proptypes.string,
  height: Proptypes.string,
  title: Proptypes.string,
  classNameChildren: Proptypes.string,
  children: Proptypes.node,
  headerBackground: Proptypes.node,
  marginTopText: Proptypes.string,
  centerTitle: Proptypes.bool,
};

PaperCustomize.defaultProps = {
  headerBackground: headerImg,
  marginTopText: '-10rem',
  centerTitle: true,
};

export default withPaperCustomize(PaperCustomize);
