import { connect } from 'react-redux';

import MyConcours from './my-concours';

const mapStateToProps = ({ concours }) => ({
  concoursByUser: concours.concoursByUser,
  concoursParticiped: concours.concoursParticiped,
});

export default connect(
  mapStateToProps,
  null,
)(MyConcours);
