import React from 'react';
import Proptypes from 'prop-types';


// Heroes
import { Typography } from '@material-ui/core';
import top1 from '../assets/SVG/Top1.svg';
import top2 from '../assets/SVG/Top2.svg';

// CardSubCategory
import Logo from '../assets/Image/Mini pres/SubCategories/1_Logo.png';
import Brochure from '../assets/Image/Mini pres/SubCategories/2_Brochure-depliant.png';
import ECommerce from '../assets/Image/Mini pres/SubCategories/3_Ecommerce.png';
import Publication from '../assets/Image/Mini pres/SubCategories/4_Publication.png';
import NomBoite from '../assets/Image/Mini pres/SubCategories/5_Nom_de_boite.png';
import Presentation from '../assets/Image/Mini pres/SubCategories/6_Presentation.png';
import Traduction from '../assets/Image/Mini pres/SubCategories/7_Traduction.png';
import BanniereFB from '../assets/Image/Mini pres/SubCategories/8_Banniere_fb.png';
import VoixOff from '../assets/Image/Mini pres/SubCategories/9_Voix_off.png';
import Mixage from '../assets/Image/Mini pres/SubCategories/10_Mixage.png';

// CardSubCategories
import Design from '../assets/Image/Mini pres/Categories/1_Design.png';
import SiteApp from '../assets/Image/Mini pres/Categories/2_SiteWebApp.png';
import Reseaux from '../assets/Image/Mini pres/Categories/3_ReseauxSociaux.png';
import Business from '../assets/Image/Mini pres/Categories/4_Business.png';
import Ecriture from '../assets/Image/Mini pres/Categories/5_Ecriture.png';
import ImageVideo from '../assets/Image/Mini pres/Categories/6_ImageVideo.png';
import Audio from '../assets/Image/Mini pres/Categories/7_Son.png';

// CardInformationProject
import idee1 from '../assets/SVG/Mid11.svg';
import idee2 from '../assets/SVG/Mid12.svg';
import concours1 from '../assets/SVG/Mid21.svg';
import concours2 from '../assets/SVG/Mid22.svg';
import choix1 from '../assets/SVG/Mid31.svg';
import choix2 from '../assets/SVG/Mid32.svg';
import engagement1 from '../assets/SVG/Mid41.svg';
import engagement2 from '../assets/SVG/Mid42.svg';

// format
import {
  formatAudio, formatGif, formatImage, formatTexte, formatVideo,
} from './formatCategory';

import videoGenerique from '../assets/Image/Icon Fichier/files_icon-video.png';
import audioGenerique from '../assets/Image/Icon Fichier/files_icon-music.png';
import texteGenerique from '../assets/Image/Icon Fichier/files_icon-DOCX.png';
import imageGenerique from '../assets/Image/Icon Fichier/files icon-picture.png';
import otherGenerique from '../assets/Image/Icon Fichier/files_icon-other.png';

export const topHome = [
  {
    title: 'Satisfaction garantie !',
    text: 'Invitez notre communauté de créatifs à participer à vos projets. Les talents proposent des idées et vous choisissez votre préférée !',
    picture: top2,
    key: 1,
  },
  {
    title: "Vous avez l'âme d'un compétiteur ? ",
    text: "Que ce soit pour s'amuser, répondre à un besoin, valoriser ses compétences ou gagner en expériences, chacun y trouve son compte !",
    picture: top1,
    key: 2,
  },
];

export const SubCardCategories = [
  {
    title: 'Logo',
    price: 240,
    image: Logo,
    id: 1,
    idCategory: 1,
  },
  {
    title: 'Brochure dépliant',
    price: 204,
    image: Brochure,
    id: 6,
    idCategory: 1,
  },
  {
    title: 'Site E-commerce',
    price: 360,
    image: ECommerce,
    id: 23,
    idCategory: 2,
  },
  {
    title: 'Publication réseaux',
    price: 84,
    image: Publication,
    id: 26,
    idCategory: 3,
  },
  {
    title: 'Nom de boite',
    price: 60,
    image: NomBoite,
    id: 33,
    idCategory: 4,
  },
  {
    title: 'Présentation',
    price: 156,
    image: Presentation,
    id: 35,
    idCategory: 4,
  },
  {
    title: 'Traduction',
    price: 60,
    image: Traduction,
    id: 38,
    idCategory: 5,
  },
  {
    title: 'Banniere Facebook',
    price: 108,
    image: BanniereFB,
    id: 30,
    idCategory: 3,
  },
  {
    title: 'Voix off',
    price: 60,
    image: VoixOff,
    id: 49,
    idCategory: 7,
  },
  // A MODIFIER
  {
    title: 'Mixage',
    price: 125,
    image: Mixage,
    id: 53,
    idCategory: 7,
  },
];


export const CardCategories = [
  {
    title: 'Design',
    image: Design,
    id: 1,
  },
  {
    title: 'Site Web / App',
    image: SiteApp,
    id: 2,
  },
  {
    title: 'Réseaux sociaux',
    image: Reseaux,
    id: 3,
  },
  {
    title: 'Business',
    image: Business,
    id: 4,
  },
  {
    title: 'Ecriture',
    image: Ecriture,
    id: 5,
  },
  {
    title: 'Image / Vidéo',
    image: ImageVideo,
    id: 6,
  },
  {
    title: 'Audio',
    image: Audio,
    id: 7,
  },
];

const TypographyPerso = ({ children }) => (
  <Typography
    variant="body1"
    style={{
      fontSize: '1.2rem',
      color: '#555555',
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
    }}
  >
    {children}
  </Typography>
);

TypographyPerso.propTypes = {
  children: Proptypes.node,
};
export const CardInformations = [
  {
    key: 1,
    title: 'Une idée',
    picture: idee1,
    pictureActive: idee2,
    text:
  <>
    Concrétisez votre idée en moins de
    <span style={{ color: 'red', fontWeight: 'bold', whiteSpace: 'pre-wrap' }}> 5 minutes</span> :
    Parlez nous de ce que vous souhaitez, fixez le prix pour les vainqueurs ainsi que la date de fin du concours
  </>,
  },
  {
    key: 2,
    title: 'Un concours',
    picture: concours1,
    pictureActive: concours2,
    text:
  <>
    Une fois vos besoins définis, nos talents n&apos;auront qu&apos;un seul objectif :
    <span style={{ color: 'red', fontWeight: 'bold', whiteSpace: 'pre-wrap' }}> remporter la mise</span> en répondant à vos attentes.
  </>,
  },
  {
    key: 3,
    title: 'Un choix',
    picture: choix1,
    pictureActive: choix2,
    text:
  <>
    <span style={{ color: 'red', fontWeight: 'bold', whiteSpace: 'pre-wrap' }}> Choisissez-le ou les gagnants</span> à partir des propositions obtenues.
    Les propositions sont adaptées à votre demande initiale.
  </>,
  },
  {
    key: 4,
    title: 'Un engagement',
    picture: engagement1,
    pictureActive: engagement2,
    text:
  <>
    Finalisez le concours
    <span style={{ color: 'red', fontWeight: 'bold', whiteSpace: 'pre-wrap' }}> après avoir validé</span> l&apos;ensemble des éléments.
    Le talent reçoit alors le gain promis au vainqueur.
  </>,
  },
];

export const CardInformationsOld = [
  {
    title: 'Une idée',
    picture: idee1,
    pictureActive: idee2,
    text:
  <>
    <TypographyPerso>
      Concrétisez votre idée en moins de
      <span style={{ color: 'red', whiteSpace: 'pre-wrap' }}> 5 minutes</span> :
    </TypographyPerso>
    <TypographyPerso>
      Parlez nous de ce que vous souhaitez, fixez le prix pour les vainqueurs ainsi que la date de fin du concours
    </TypographyPerso>
  </>,
    key: 1,
  },
  {
    title: 'Un concours',
    picture: concours1,
    pictureActive: concours2,
    text:
  <>
    <TypographyPerso>
      Une fois vos besoins définis, nos talents n&apos;auront qu&apos;un seul objectif :
      <span style={{ color: 'red', whiteSpace: 'pre-wrap' }}> remporter la mise</span> en répondant à vos attentes.
    </TypographyPerso>
  </>,
    key: 2,
  },
  {
    title: 'Un choix',
    picture: choix1,
    pictureActive: choix2,
    text:
  <>
    <TypographyPerso>
      <span style={{ color: 'red', whiteSpace: 'pre-wrap' }}> Choisissez-le ou les gagnants</span> à partir des propositions obtenues.
    </TypographyPerso>
    <TypographyPerso>
      Les propositions sont adaptées à votre demande initiale.
    </TypographyPerso>
  </>,
    key: 3,
  },
  {
    title: 'Un engagement',
    picture: engagement1,
    pictureActive: engagement2,
    text:
  <>
    <TypographyPerso>
      Finalisez le concours
      <span style={{ color: 'red', whiteSpace: 'pre-wrap' }}> après avoir validé</span> l&apos;ensemble des éléments.
    </TypographyPerso>
    <TypographyPerso>
      Le talent reçoit alors le gain promis au vainqueur.
    </TypographyPerso>
  </>,
    key: 4,
  },
];

export const imgAvatar = (format, participation, isChild = false, background = null) => {
  if (format === formatImage) {
    if (isChild) return background;
    if ('fileParticipants' in participation) {
      if (participation.fileParticipants.length === 0) return imageGenerique;
      return `${participation.fileParticipants[0].filepath}`;
    }
  }
  if (format === (formatVideo || formatGif)) return videoGenerique;
  if (format === formatAudio) return audioGenerique;
  if (format === formatTexte) return texteGenerique;
  return otherGenerique;
};
