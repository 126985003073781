import { withStyles } from '@material-ui/core';

export default withStyles(({
  palette, spacing, shadows,
}) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'initial',
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: (props) => props.width || '30rem',
    height: 'auto',
    background: palette.common.white,
    borderRadius: '1rem',
    padding: spacing(2, 0, 3),
    '&:focus': {
      outline: 'none',
    },
  },
  top: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    color: palette.grey[800],
    textAlign: 'center',
    paddingLeft: spacing(4),
    margin: '0 auto',
  },
  closeButton: {
    position: 'relative',
    top: '-1.5rem',
  },
  containerDetails: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  details: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    color: palette.grey[400],
    textAlign: 'center',
    paddingLeft: spacing(4),
    paddingRight: spacing(4),
    marginTop: '1rem',
    whiteSpace: 'break-spaces',
  },
  wrapperImg: {
    boxShadow: shadows[2],
    borderRadius: '1rem',
    marginTop: '2rem',
    width: '9rem',
    height: '9rem',
  },
  img: {
    width: '100%',
    height: '100%',
    padding: '1rem',
  },
  btn: {
    marginTop: '2rem',
  },
}));
