import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Home from './components/_pages/Home/home.connect';

import App from './containers/App/App';
import Inscription from './components/_pages/Inscription/inscription.connect';
import ValidateAuth from './components/_pages/ValidateAuth/validate-auth';
import OrganiserUnConcoursStep1 from './components/_pages/OrganiserConcours/step1';
import OrganiserUnConcoursStep1Old from './components/_pages/OrganiserConcours/step1-old';
import OrganiserUnConcoursStep2 from './components/_pages/OrganiserConcours/step2';
import OrganiserUnConcoursStep3 from './components/_pages/OrganiserConcours/step3.connect';
import OrganiserUnConcoursStep4 from './components/_pages/OrganiserConcours/step4';
import OrganiserUnConcoursStep5 from './components/_pages/OrganiserConcours/step5.connect';
import DetailsConcours from './components/_pages/DetailsConcoursParticipant/details-concours-participant.connect';
import Participations from './components/_pages/Participations/participations.connect';
import ConcoursBySub from './components/_pages/ConcoursBySub/concours-by-sub.connect';
import InformationsPersos from './components/_pages/MonProfil/InformationsPerso/informations-perso.connect';
import Statut from './components/_pages/MonProfil/Statut/statut.connect';
import MyConcours from './components/_pages/MonProfil/MyConcours/my-concours.connect';
import MessageFirstStep from './components/_pages/Message/FirstPage/message-first-step.connect';
import MessageSecondStep from './components/_pages/Message/SecondPage/message-second-step.connect';
import MessageOld from './components/_pages/MessageOLD/message_old';
import FAQ from './components/_pages/FAQ/faq';
import ModifyConcours from './components/_pages/ModifyConcours/modify-concours.connect';
import UserVerified from './components/_pages/UserVerified/user-verified.connect';
import ResetPassword from './components/_pages/ResetPassword/reset-password.connect';
import ConfirmationPayment from './components/_pages/ConfirmationPayment/confirmationPayment';

const AppRoutes = (props) => (
  <App {...props}>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/home" component={Home} />
      <Route path="/confirmation" component={ConfirmationPayment} />
      <Route exact path="/signup" component={Inscription} />
      <Route exact path="/organiser-concours/etape1" component={OrganiserUnConcoursStep1} />
      <Route exact path="/organiser-concours/etape1-old" component={OrganiserUnConcoursStep1Old} />
      <Route exact path="/organiser-concours/etape2" component={OrganiserUnConcoursStep2} />
      <Route exact path="/organiser-concours/etape3" component={OrganiserUnConcoursStep3} />
      <Route exact path="/organiser-concours/etape4" component={OrganiserUnConcoursStep4} />
      <Route exact path="/organiser-concours/etape5" component={OrganiserUnConcoursStep5} />
      <Route exact path="/details-concours/:id" component={DetailsConcours} />
      <Route exact path="/modifier-concours/:id" component={ModifyConcours} />
      <Route exact path="/concours/:category" component={ConcoursBySub} />
      <Route exact path="/messages" component={MessageFirstStep} />
      <Route exact path="/messages/:id" component={MessageSecondStep} />
      <Route exact path="/messages_old" component={MessageOld} />
      <Route exact path="/mon-profil/informations-personnelles" component={InformationsPersos} />
      <Route exact path="/mon-profil/mes-concours" component={MyConcours} />
      <Route exact path="/mon-profil/statut" component={Statut} />
      <Route exact path="/concours/:category/:subCategory" component={ConcoursBySub} />
      <Route exact path="/participations/:id" component={Participations} />
      <Route exact path="/signup/success" component={ValidateAuth} />
      <Route exact path="/verify/:emailToken" component={UserVerified} />
      <Route exact path="/recover-password/:emailToken" component={ResetPassword} />
      <Route exact path="/login/success" component={Home} />
      <Route exact path="/faq" component={FAQ} />
      <Route component={Home} />
    </Switch>
  </App>
);

export default AppRoutes;
