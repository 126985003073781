import { withStyles } from '@material-ui/core';
import footerbg from '../../../assets/Fond/bg-gradient-footer.svg';

export default withStyles(({ breakpoints, palette }) => ({
  footer: {
    backgroundImage: `url(${footerbg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
    backgroundSize: 'cover',
    marginTop: '2rem',
    width: '100%',
  },
  noMargin: {
    margin: 0,
  },
  shape: {
    position: 'relative',
    width: '100%',
  },
  imgShape: {
    width: '100%',
    position: 'absolute',
    maxWidth: 'none',
    height: 'auto',
  },
  container: {
    backgroundImage: `url(${footerbg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
    backgroundSize: 'cover',
  },
  contentFooter: {
    paddingTop: '3rem', // 8rem si y a le shape svg au dessus
    paddingBottom: '3rem',
    width: '100%',
  },
  wrapperContentFooter: {
    display: 'flex',
    justifyContent: 'center',
    [breakpoints.down(400)]: {
      flexWrap: 'wrap',
    },
  },
  contentLeft: {
    display: 'flex',
    justifyContent: 'space-around',
    [breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  categories: {
    marginTop: '3rem',

    [breakpoints.down(400)]: {
      marginTop: '1rem',
    },
  },
  title: {
    color: palette.common.white,
    fontSize: '1.8rem',
    fontWeight: 'bold',
  },
  titleContact: {
    textAlign: 'center',
  },
  ulList: {
    paddingLeft: '2rem',
  },
  item: {
    listStyle: 'none',
    fontSize: '1.2rem',
    lineHeight: 2,
    fontWeight: 'bold',
    color: palette.common.white,
  },
  aPropos: {
    marginTop: '3rem',

    [breakpoints.down(400)]: {
      marginTop: 0,
    },
  },
  footerLeft: {
    width: '50%',
    marginLeft: '2rem',
  },
  footerRight: {
    width: '50%',
    marginTop: '3rem',
    display: 'flex',

    flexDirection: 'column',
    alignItems: 'center',

    [breakpoints.down('xs')]: {
      width: '80%',
    },

    [breakpoints.down(400)]: {
      marginTop: 0,
    },
  },
  formContact: {
    marginTop: '3rem',
    [breakpoints.down(400)]: {
      marginTop: '1rem',
    },
  },
  link: {
    textDecoration: 'none',
    color: palette.common.white,
    '&:hover': {
      opacity: 0.8,
    },
  },
}));
