import { withStyles } from '@material-ui/core';

export default withStyles(({ palette, spacing }) => ({
  root: {
    width: (props) => props.width || '40rem',
  },
  paperSearch: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: (props) => props.width,
  },
  inputSearch: {
    marginLeft: spacing(1),
    flex: 1,
  },
  inputSearchRoot: {
    fontSize: '1.8rem',
    '&::placeholder': {
      fontWeight: 'bold',
      opacity: 0.6,
      color: palette.secondary.main,
    },
  },
  iconButtonSearch: {
    padding: '1rem',
  },
  listSuggestions: {
    zIndex: 999,
    width: (props) => props.width || '40rem',
    background: palette.common.white,
    position: 'absolute',
    padding: 0,
    margin: 0,
    borderBottom: '1px solid grey',
    boxShadow: '0 0 0 1px rgba(0,0,0,0.1), 0 2px 4px 1px rgba(0,0,0,0.18)',
    textAlign: 'left',
    '@global': {
      li: {
        padding: '10px 13px',
        fontSize: '1.4rem',
        color: palette.grey[500],
      },
    },
  },
  liCategorie: {
    fontWeight: 'bold',
  },
  listSubCategorie: {
    padding: '0 1rem',
  },
  liSubCategorie: {
    fontWeight: 'normal',
    fontSize: '1.2rem',
    '&:hover': {
      cursor: 'pointer',
      background: palette.grey[200],
    },
  },
  btnSubCategory: {
    background: 'none',
    border: 'none',
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      cursor: 'pointer',
    },
    color: palette.grey[500],
    fontFamily: 'Futura, Helvetica, sans-serif',
    width: '100%',
    textAlign: 'left',
  },
}));
