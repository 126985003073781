import React from 'react';
import Proptypes from 'prop-types';

import CarousselWrapper from '../../_molecules/CarousselWrapper/caroussel-wrapper';
import CardParticipant from '../../_atoms/CardParticipant/card-participant';

class CarousselCardParticipant extends React.Component {
  constructor(props) {
    super(props);
    this.card = React.createRef();
  }

  render() {
    const {
      list,
      classNameRoot,
      classNameUl,
      classNameArrows,
      classNameCard,
      subCategory,
      onClickSubmitMessage,
      onClickReport,
      isWinner,
      statusConcours,
      onClickAddWinner,
    } = this.props;

    return (
      <CarousselWrapper
        classNameRoot={classNameRoot}
        classNameUl={classNameUl}
        classNameArrows={classNameArrows}
        onlyOneCard
        cardRef={this.card}
        numberOfCards={list.length}
      >
        {
          list.map((item) => (
            <li key={item.id}>
              <CardParticipant
                background={subCategory.format !== 5 && `${item.filepath}`}
                className={classNameCard}
                format={subCategory.format}
                onClickSubmitMessage={onClickSubmitMessage}
                onClickReport={onClickReport}
                onClickAddWinner={onClickAddWinner}
                ref={this.card}
                isWinner={isWinner}
                statusConcours={statusConcours}
              />
            </li>
          ))
        }
      </CarousselWrapper>
    );
  }
}

CarousselCardParticipant.propTypes = {
  list: Proptypes.array,
  subCategory: Proptypes.object,
  classNameRoot: Proptypes.string,
  classNameArrows: Proptypes.string,
  classNameUl: Proptypes.string,
  background: Proptypes.node,
  classNameCard: Proptypes.string,
  onClickSubmitMessage: Proptypes.func,
  onClickReport: Proptypes.func,
  onClickAddWinner: Proptypes.func,
  statusConcours: Proptypes.number,
  isWinner: Proptypes.oneOfType([
    Proptypes.bool, Proptypes.number,
  ]),
};

CarousselCardParticipant.defaultProps = {
  isWinner: false,
  statusConcours: 2,
  onClickSubmitMessage: () => {},
  onClickReport: () => {},
  onClickAddWinner: () => {},
}

export default CarousselCardParticipant;
