import { withStyles } from '@material-ui/core';

export default withStyles(({
  palette, shadows, spacing, breakpoints,
}) => ({
  root: {
    minHeight: '35rem',
    height: '100%',
  },
  text: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    color: palette.grey[800],
    textAlign: 'center',
    marginTop: '1rem',
  },
  noWin: {
    width: '50%',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: (props) => (props.noWin ? '5rem' : '2rem'),
    alignItems: 'center',
  },
  wrapperContentWinner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  wrapperWin: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '2rem',
  },
  bulleNumber: {
    marginRight: '2rem',
  },
  cardParticipant: {
    width: '10rem',
    height: '10rem',
  },
  closeButton: {
    marginLeft: '0.4rem',
  },
  buttonFinal: {
    width: '18rem',
    marginBottom: '2rem',
  },
  btnNoPointerEvent: {
    display: 'none',
    pointerEvents: 'none',
  },
}));
