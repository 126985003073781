import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import { makeStyles, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Header from '../Header/header';
import PaperCustomize from '../../_molecules/PaperCustomize/paper-customize';
import ButtonCon from '../../_atoms/Button/button';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  wrapperHeaderGuest: {
    background: palette.gradient.main,
    height: '12rem',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);',
    [breakpoints.down('xs')]: {
      height: '8rem',
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    margin: '5rem 0',
  },
  paperChildren: {
    padding: '0 2rem',
    paddingBottom: '5rem',
    justifyContent: 'center',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  title: {
    color: palette.secondary.main,
    fontWeight: 'bold',
    [breakpoints.down(620)]: {
      fontSize: '2.5rem',
    },
  },
  text: {
    fontSize: '1.5rem',
    marginTop: '3rem',
  },
  btn: {
    marginTop: '2rem',
    transition: '0.2s opacity ease-in-out',
    '&:hover': {
      opacity: '0.8',
    },
  },
}));

const PageInfoSuccess = ({
  open,
  onClose,
  text,
  title,
  classNameTitle,
  classNameText,
  user,
  onConnexion,
}) => {
  const classes = useStyles();
  return (
    <>
      {
        user.isAuthenticated ? (
          <Header position="static" />
        )
          : (
            <Header needContainerHeader sticky={false} onConnexion={onConnexion} open={open} onClose={onClose} />
          )
      }
      <section className={classes.content}>
        <PaperCustomize width="60vw" className={classes.paper} classNameChildren={classes.paperChildren}>
          <Typography variant="h1" className={cx(classes.title, classNameTitle)}>{title}</Typography>
          <Typography variant="body1" className={cx(classes.text, classNameText)}>{text}</Typography>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <ButtonCon className={classes.btn}>Page d&apos;acceuil</ButtonCon>
          </Link>
        </PaperCustomize>
      </section>
    </>
  );
};

PageInfoSuccess.propTypes = {
  user: Proptypes.shape({
    token: Proptypes.string,
    fetching: Proptypes.bool,
    fetched: Proptypes.bool,
    isLoading: Proptypes.bool,
    isAuthenticated: Proptypes.bool,
    isAdmin: Proptypes.oneOfType([
      Proptypes.bool,
      Proptypes.number,
    ]),
    user: Proptypes.object,
    msg: Proptypes.string,
  }),
  open: Proptypes.bool,
  onClose: Proptypes.func,
  onConnexion: Proptypes.func,
  text: Proptypes.string,
  title: Proptypes.string,
  classNameText: Proptypes.string,
  classNameTitle: Proptypes.string,
};

export default PageInfoSuccess;
