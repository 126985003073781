import { withStyles } from '@material-ui/core';

export default withStyles(({ breakpoints }) => ({
  wrapperNavBarUser: {
    width: '100%',
    boxShadow: '0 0 42px 0 rgba(0, 0, 0, 0.07)',
  },
  navBarUser: {
    width: '90%',
  },
  searchMobile: {
    marginTop: '2rem',
    width: '40rem',
    [breakpoints.down(500)]: {
      width: '30rem',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
}));
