import React from 'react';
import Proptypes from 'prop-types';

import { TextField, Typography } from '@material-ui/core';
import cx from 'classnames';
import WrapperPageGuest from '../../../containers/WrapperPageGuest/wrapper-page-guest.connect';
import Header from '../../_organisms/Header/header';
import Button from '../../_atoms/Button/button';

import withResetPassword from './reset-password.style';
import PaperCustomize from '../../_molecules/PaperCustomize/paper-customize';

const ResetPassword = ({
  classes, recoverPassword, match,
}) => {
  const [openConnexion, setOpenConnexion] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');

  const handleOpenConnexion = () => {
    setOpenConnexion(true);
  };

  const handleCloseConnexion = () => {
    setOpenConnexion(false);
  };

  const handleChangePassword = (e) => {
    const { value } = e.target;
    setPassword(value);
  };

  const handleChangeConfirmPassword = (e) => {
    const { value } = e.target;
    setConfirmPassword(value);
  };

  const handleSubmit = () => {
    const { emailToken } = match.params;
    recoverPassword(
      emailToken, { newPassword: password, confirmPassword },
      () => {
        handleOpenConnexion();
      },
    );
  };

  return (
    <>
      <WrapperPageGuest>
        <Header needContainerHeader sticky={false} onConnexion={handleOpenConnexion} open={openConnexion} onClose={handleCloseConnexion} />
        <section className={classes.content}>
          <PaperCustomize width="60vw" className={classes.paper} classNameChildren={classes.paperChildren}>
            <Typography variant="h1" className={cx(classes.title)}>Réinistialiser votre mot de passe</Typography>
            <div className={classes.form}>
              <TextField
                name="password"
                value={password}
                onChange={handleChangePassword}
                label="Mot de passe"
                type="password"
                className={classes.textField}
              />
              <TextField
                name="confirmPassword"
                value={confirmPassword}
                onChange={handleChangeConfirmPassword}
                label="Confirmation Mot de passe"
                type="password"
                className={classes.textField}
              />
              <Button className={classes.btn} onClick={handleSubmit}>Réinitialiser</Button>
            </div>
          </PaperCustomize>
        </section>
      </WrapperPageGuest>
    </>

  );
};

ResetPassword.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  recoverPassword: Proptypes.func,
  match: Proptypes.shape({
    path: Proptypes.string,
    url: Proptypes.string,
    isExact: Proptypes.bool,
    params: Proptypes.object,
  }),
};

export default withResetPassword(ResetPassword);
