import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import chevronLeft from '../../../assets/SVG/btn-back.svg';
import chevronRight from '../../../assets/SVG/btn-right.svg';
import Button from '../../_atoms/Button/button';

import withCarousselWrapper from './caroussel-wrapper.style';

class CarousselWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.time = null;
    this.slider = React.createRef();
    this.sliderContent = React.createRef();
    this.sliderContentInner = React.createRef();
    this.deltaX = 0;
  }

  lastSlide = () => {
    const { onlyOneCard, numberOfCards } = this.props;
    if (onlyOneCard) return numberOfCards;
    const { innerWidth } = window;
    console.log(innerWidth, 'innerwidth');
    if (innerWidth > 1280) return numberOfCards - 4;
    if (innerWidth < 1280 && innerWidth > 1024) return numberOfCards - 3;
    if (innerWidth < 1024 && innerWidth > 800) return numberOfCards - 2;
    return numberOfCards - 1;
  }

  displayPicture = (event) => {
    const { cardRef, onlyOneCard } = this.props;
    if (onlyOneCard) {
      if (event.target.alt.includes('left')) {
        const { current } = cardRef;
        this.deltaX -= current.offsetWidth;

        this.deltaX %= this.lastSlide() * current.offsetWidth;

        if (this.deltaX < 0) {
          this.deltaX = 0;
        }
      } else if (event.target.alt.includes('right')) {
        const { current } = cardRef;

        this.deltaX += current.offsetWidth;

        this.deltaX %= this.lastSlide() * (current.offsetWidth);
      }

      this.sliderContentInner.current.style.marginLeft = `-${this.deltaX}px`;
    } else {
      if (event.target.alt.includes('left')) {
        const { current } = cardRef;
        this.deltaX -= current.offsetWidth + 20;

        this.deltaX %= this.lastSlide() * current.offsetWidth;

        if (this.deltaX < 0) {
          this.deltaX = 0;
        }
      } else if (event.target.alt.includes('right')) {
        const { current } = cardRef;

        this.deltaX += current.offsetWidth + 20;

        this.deltaX %= this.lastSlide() * (current.offsetWidth + 20);
      }

      this.sliderContentInner.current.style.marginLeft = `-${this.deltaX}px`;
    }
  };

  handleMouseLeave = () => {
    this.time = setTimeout(() => {
      this.deltaX = 0;
      if (this.sliderContentInner.current) this.sliderContentInner.current.style.marginLeft = 0;
    }, 2000);
  };

  render() {
    const {
      classes,
      classNameRoot,
      classNameUl,
      onlyTitle,
      onClickForUser,
      classNameArrows,
      categoryUser,
      children,
    } = this.props;
    return (
      <div className={cx(classes.root, classNameRoot)} onMouseLeave={this.handleMouseLeave} onMouseEnter={() => clearInterval(this.time)}>
        <div ref={this.slider} className={cx(classes.slider, classNameRoot)}>
          <div ref={this.sliderContent} className={classes.sliderContent}>
            <ul ref={this.sliderContentInner} className={cx(classes.list, classNameUl)}>
              {children}
            </ul>
          </div>
          <div className={cx(classes.arrows, classNameArrows)}>
            <figure className={cx(classes.arrow, classes.arrowLeft)}>
              <button type="button" onClick={this.displayPicture} className={classes.btnArrow}>
                <img src={chevronLeft} className={classes.arrowIcon} alt="chevron-left" />
              </button>
            </figure>
            <figure className={classes.arrow}>
              <button type="button" onClick={this.displayPicture} className={cx(classes.btnArrow, classes.btnArrowRight)}>
                <img src={chevronRight} className={classes.arrowIcon} alt="chevron-right" />
              </button>
            </figure>
          </div>
        </div>
        {
          onlyTitle && categoryUser.length > 0 && (
            <Button className={classes.btnValider} onClick={onClickForUser(categoryUser)}>Valider</Button>
          )
        }
      </div>
    );
  }
}

CarousselWrapper.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  onlyTitle: Proptypes.bool,
  classNameRoot: Proptypes.string,
  classNameArrows: Proptypes.string,
  classNameUl: Proptypes.string,
  onClickForUser: Proptypes.func,
  onlyOneCard: Proptypes.bool,
  children: Proptypes.node,
  categoryUser: Proptypes.array,
  cardRef: Proptypes.object,
  numberOfCards: Proptypes.number,
};

CarousselWrapper.defaultProps = {
  onlyOneCard: false,
  categoryUser: [],
};

export default withCarousselWrapper(CarousselWrapper);
