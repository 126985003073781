import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import withListParticipations from './list-participations.style';
import CardParticipantRating from '../../_molecules/CardParticipantRating/card-participant-rating';

const ListParticipations = ({
  classes,
  className,
  concours,
  onChangeStars,
  createMessage,
  onClickAddWinner,
  onClickReport,
}) => {
  const handleClickSubmitMessage = (id) => (messageValue) => {
    createMessage({
      idParticipation: id,
      message: messageValue,
    });
  };

  const handleClickAddWinner = (participation) => (e) => {
    onClickAddWinner(participation);
  };

  return (
    <>
      <ul className={cx(classes.list, className)}>
        {
          concours.participations && concours.participations.map((participation, index) => (
            <CardParticipantRating
              statusConcours={concours.status}
              isWinner={participation.isWinner}
              subCategory={concours.subCategory}
              participation={participation}
              list={participation.fileParticipants}
              className={classes.card}
              number={index + 1}
              nameStars={participation.id.toString()}
              valueStars={participation.stars || 0}
              key={participation.id}
              onChangeStars={onChangeStars(participation)}
              onClickReport={onClickReport(participation)}
              onClickSubmitMessage={handleClickSubmitMessage(participation.id)}
              onClickAddWinner={handleClickAddWinner(participation)}
            />
          ))
        }
      </ul>
    </>
  );
};

ListParticipations.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  className: Proptypes.string,
  concours: Proptypes.object,
  onChangeStars: Proptypes.func,
  createMessage: Proptypes.func,
  onClickAddWinner: Proptypes.func,
  onClickReport: Proptypes.func,
};

ListParticipations.defaultProps = {
  onChangeStars: () => {},
  onClickAddWinner: () => {},
  onClickReport: () => {},
};

export default withListParticipations(ListParticipations);
