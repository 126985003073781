import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  Toolbar,
  Typography,
  AppBar,
  IconButton,
  Badge,
  Drawer,
  Divider,
  useTheme,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from '@material-ui/core';

import NotificationsIcon from '@material-ui/icons/Notifications';
import MailIcon from '@material-ui/icons/Mail';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExitToAppIcon from '@material-ui/icons/ExitToApp'; import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Logo from '../../_atoms/Logo/logo';
import Button from '../../_atoms/Button/button';

import { classicListDrawerHeaderUser } from '../../../constants/header';
import { categoriesConcours } from '../../../constants/categories';

import withHeaderUser from './header-user.style';

const HeaderUser = ({
  classes, title, history, badgeNotification, badgeMessage, logout, position,
}) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClickLogout = () => {
    logout();
  };

  const handleClickSubCategory = (subcategory) => () => {
    history.push({
      pathname: `/concours/${subcategory.to}`,
      state: { idSubCategory: subcategory.id },
    });
  };

  const handleClickSubProfil = (profil) => () => {
    history.push({
      pathname: `/mon-profil/${profil}`,
    });
  };

  const handleOver = (e) => {
    const oldClassName = e.currentTarget.className;
    if (oldClassName.indexOf(classes.listOver) === -1) {
      e.currentTarget.className = `${oldClassName} ${classes.listOver}`;
    }
  };

  const handleLeave = (e) => {
    const { className } = e.currentTarget;
    if (className.indexOf(classes.listOver) !== -1) {
      e.currentTarget.className = className.replace(classes.listOver, '');
    }
  };

  return (
    <AppBar position={position} className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Logo
            color1="#ffffff"
            color2="#fab9af"
            classNameWrapper={classes.logo}
            responsive
          />
        </Link>
        <Typography variant="h3" className={classes.title}>{title}</Typography>
        <div className={classes.sectionDesktop}>
          <IconButton aria-label={`show ${badgeNotification} new notifications`} color="inherit">
            <Badge badgeContent={badgeNotification} color="primary">
              <NotificationsIcon fontSize="large" />
            </Badge>
          </IconButton>
          <Link
            style={{ color: 'white' }}
            to={{ pathname: '/messages' }}
          >
            <IconButton aria-label={`show ${badgeMessage} new messages`} color="inherit">
              <Badge badgeContent={badgeMessage} color="primary">
                <MailIcon fontSize="large" />
              </Badge>
            </IconButton>
          </Link>
          <IconButton
            aria-label="account of current user"
            color="inherit"
            onMouseOver={handleOver}
            onMouseLeave={handleLeave}
          >
            <AccountCircle fontSize="large" />
            <div className={classes.wrapperList}>
              <ul className={classes.subSettingsList}>
                <Link
                  className={classes.subSettingsLink}
                  to={{
                    pathname: '/mon-profil/mes-concours',
                  }}
                >
                  <li className={classes.subSettings}>
                    Mes concours
                  </li>
                </Link>
                <Link
                  className={classes.subSettingsLink}
                  to={{
                    pathname: '/mon-profil/informations-personnelles',
                  }}
                >
                  <li className={classes.subSettings}>
                    Informations personnelles
                  </li>
                </Link>
                <Link
                  className={classes.subSettingsLink}
                  to={{
                    pathname: '/mon-profil/statut',
                  }}
                >
                  <li className={classes.subSettings}>
                    Statut
                  </li>
                </Link>
              </ul>
            </div>
          </IconButton>
          <Link to="/organiser-concours/etape1" className={classes.link}>
            <Button
              background={theme.palette.common.white}
              color="secondary"
              className={classes.btnConcours}
              classes={{ label: classes.btnConcoursText }}
            >Organiser un concours
            </Button>
          </Link>
          <IconButton
            aria-label="account of current user"
            color="inherit"
            edge="end"
            onClick={handleClickLogout}
          >
            <ExitToAppIcon fontSize="large" />
          </IconButton>
        </div>
        <div className={classes.mobileSection}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
          >
            <MenuIcon fontSize="large" />
          </IconButton>
          <Drawer
            className={classes.drawer}
            anchor="right"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
            onClose={handleDrawerClose}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={handleDrawerClose}>
                <ChevronRightIcon fontSize="large" />
              </IconButton>
            </div>
            <Divider />
            <List>

              {
                classicListDrawerHeaderUser.map((element) => (
                  <Link to={element.to} className={classes.link} key={`classicListDrawer${element.key}`}>
                    <ListItem button>
                      <ListItemIcon>{element.icon}</ListItemIcon>
                      <ListItemText primary={element.text} classes={{ primary: classes.listText }} />
                    </ListItem>
                  </Link>
                ))
              }

              <ExpansionPanel className={cx(classes.expansionPanel, classes.expansionPanelProfil)} classes={{ root: classes.expansionPanelProfilRoot, expanded: classes.expansionPanelProfilExpanded }}>
                <ExpansionPanelSummary
                  expandIcon={(
                    <IconButton aria-label="expandIcon" color="inherit">
                      <ExpandMoreIcon fontSize="large" color="secondary" />
                    </IconButton>
                  )}
                  style={{ padding: '0 17px', height: 0, minHeight: '42px' }}
                  aria-controls="panel-profil"
                  id="panel-profil"
                >
                  <ListItemIcon>
                    <AccountCircle fontSize="large" />
                  </ListItemIcon>
                  <ListItemText primary="Mon profil" classes={{ primary: classes.listText }} />
                </ExpansionPanelSummary>
                <List className={classes.listSubcategorie}>
                  <ListItem button onClick={handleClickSubProfil('mes-concours')}>
                    <ExpansionPanelDetails className={classes.expansionDetails}>
                      <ListItemText primary="Mes concours" classes={{ primary: classes.listText }} />
                    </ExpansionPanelDetails>
                  </ListItem>

                  <ListItem button onClick={handleClickSubProfil('informations-personnelles')}>
                    <ExpansionPanelDetails className={classes.expansionDetails}>
                      <ListItemText primary="Informations personnelles" classes={{ primary: classes.listText }} />
                    </ExpansionPanelDetails>
                  </ListItem>

                  <ListItem button onClick={handleClickSubProfil('statut')}>
                    <ExpansionPanelDetails className={classes.expansionDetails}>
                      <ListItemText primary="Statut" classes={{ primary: classes.listText }} />
                    </ExpansionPanelDetails>
                  </ListItem>
                </List>
              </ExpansionPanel>

              <Link to="/notifications" className={classes.link}>
                <ListItem button key="notifications">
                  <ListItemIcon>
                    <Badge badgeContent={badgeNotification} color="primary">
                      <NotificationsIcon fontSize="large" />
                    </Badge>
                  </ListItemIcon>
                  <ListItemText primary="Notifications" classes={{ primary: classes.listText }} />
                </ListItem>
              </Link>

              <Link to="/messages" className={classes.link}>
                <ListItem button key="messages">
                  <ListItemIcon>
                    <Badge badgeContent={badgeMessage} color="primary">
                      <MailIcon fontSize="large" />
                    </Badge>
                  </ListItemIcon>
                  <ListItemText primary="Messages" classes={{ primary: classes.listText }} />
                </ListItem>
              </Link>

              <Link to="/" className={classes.link}>
                <ListItem button key="logout" onClick={handleClickLogout}>
                  <ListItemIcon><ExitToAppIcon fontSize="large" /></ListItemIcon>
                  <ListItemText primary="Se déconnecter" classes={{ primary: classes.listText }} />
                </ListItem>
              </Link>
            </List>
            <Divider />
            <List>
              {
              categoriesConcours.map((category) => (
                <ListItem key={`categorie${category.id}`}>
                  <ExpansionPanel className={classes.expansionPanel}>
                    <ExpansionPanelSummary
                      expandIcon={(
                        <IconButton aria-label="expandIcon" color="inherit">
                          <ExpandMoreIcon fontSize="large" color="secondary" />
                        </IconButton>
                      )}
                      style={{ padding: '0 0 0 24px' }}
                      aria-controls={`panel-categories${category.id}`}
                      id={`panel-categories${category.id}`}
                    >
                      <Link
                        to={{
                          pathname: `/concours/${category.to}`,
                        }}
                        className={classes.link}
                      >
                        <ListItemText primary={category.title} classes={{ primary: classes.listText }} />
                      </Link>
                    </ExpansionPanelSummary>
                    <List className={classes.listSubcategorie}>
                      {
                    category.subCategories.map((subCategorie) => (
                      <ListItem button key={`subCategorie${subCategorie.id}`} onClick={handleClickSubCategory(subCategorie)}>
                        <ExpansionPanelDetails className={classes.expansionDetails}>
                          <ListItemText primary={subCategorie.title} classes={{ primary: classes.listText }} />
                        </ExpansionPanelDetails>
                      </ListItem>
                    ))
                  }
                    </List>
                  </ExpansionPanel>
                </ListItem>
              ))
            }
            </List>
          </Drawer>
        </div>
      </Toolbar>
    </AppBar>
  );
};

HeaderUser.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  title: Proptypes.string,
  badgeNotification: Proptypes.number,
  badgeMessage: Proptypes.number,
  logout: Proptypes.func,
  position: Proptypes.oneOf([
    'static', 'fixed', 'relative', 'sticky',
  ]),
  history: Proptypes.objectOf(
    Proptypes.oneOfType([
      Proptypes.number,
      Proptypes.func,
      Proptypes.object,
      Proptypes.string,
    ]),
  ),
};

HeaderUser.defaultProps = {
  badgeNotification: 0,
  badgeMessage: 0,
  position: 'sticky',
};

export default withHeaderUser(HeaderUser);
