import React from 'react';
import Proptypes from 'prop-types';
import cx from 'classnames';
import useForm from 'react-hook-form';

import withFormContact from './form-contact.style';
import InputForm from '../../_atoms/InputForm/input-form';
import TextareaForm from '../../_atoms/TextareaForm/textarea-form';
import Button from '../../_atoms/Button/button';

const FormContact = ({ classes, className }) => {
  const {
    register, handleSubmit,
  } = useForm();
  const onSubmit = (data) => { console.log(data); };
  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <div className={cx(classes.root, className)}>
        <div>
          <InputForm name="name" id="name-contact" type="text" label="Nom" className={classes.fieldsetInput} refInput={register({ required: true })} />
          <InputForm name="email" id="email-contact" type="email" label="Email" className={classes.fieldsetInput} refInput={register({ required: true })} />
          <InputForm name="objet" id="objet-contact" type="text" label="Objet" className={classes.fieldsetInput} refInput={register({ required: true })} />
        </div>
        <div className={classes.textArea}>
          <TextareaForm name="message" id="message-contact" label="Message" className={classes.textArea} refTextarea={register({ required: true })} />
        </div>
      </div>
      <Button className={classes.btnEnvoyer} type="submit">Envoyer</Button>
    </form>
  );
};

FormContact.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  className: Proptypes.string,
};

export default withFormContact(FormContact);
