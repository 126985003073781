import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';
import {
  AppBar, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Toolbar,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import AssignmentIcon from '@material-ui/icons/Assignment';

import Button from '../../_atoms/Button/button';
import Logo from '../../_atoms/Logo/logo';
import withNavBarStyle from './nav-bar-guest.style';
import { classicListDrawerHeaderGuest } from '../../../constants/header';

const NavBarGuest = ({
  classes, onConnexion, onInscription, position, allLinks, sticky,
}) => {
  const [openDrawer, setOpenDrawer] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handleClickConnexionMobile = () => {
    handleDrawerClose();
    onConnexion();
  };

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  return (
    <AppBar position={position} className={sticky ? classes.appBarSticky : classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <HashLink smooth to="/#" style={{ textDecoration: 'none' }}>
          <Logo
            color1="#ffffff"
            color2="#fab9af"
            classNameLogo1={classes.logo1}
            classNameLogo2={classes.logo2}
            classNameWrapper={classes.logoWrapper}
            responsive={false}
          />
        </HashLink>
        {
          allLinks && (
            <>
              <div className={classes.sectionDesktop}>
                <div className={classes.navRef}>
                  <HashLink
                    smooth
                    to="/#concept"
                    className={cx(classes.link, classes.textNavBar)}
                    scroll={(el) => scrollWithOffset(el)}
                  >Concept
                  </HashLink>
                  <HashLink
                    smooth
                    to="/#engagements"
                    className={cx(classes.link, classes.textNavBar)}
                    scroll={(el) => scrollWithOffset(el)}
                  >Engagements
                  </HashLink>
                  <HashLink
                    smooth
                    to="/#temoignages"
                    className={cx(classes.link, classes.textNavBar)}
                    scroll={(el) => scrollWithOffset(el)}
                  >Témoignages
                  </HashLink>
                </div>
                <div className={classes.auth}>
                  <Button
                    variant="text"
                    disableRipple
                    width="auto"
                    className={cx(
                      classes.btn,
                      classes.btnConnexion,
                    )}
                    classes={{
                      label: classes.labelConnexion,
                    }}
                    onClick={onConnexion}
                  >
                    Connexion
                  </Button>
                  <Link to="/signup" className={classes.link}>
                    <Button
                      background="#ffffff"
                      variant="text"
                      width="18rem"
                      className={cx(
                        classes.btn,
                        classes.btnInscription,
                      )}
                      onClick={onInscription}
                    >
                      S&apos;inscrire
                    </Button>
                  </Link>
                </div>
              </div>
              <div className={classes.mobileSection}>
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color={sticky ? 'secondary' : 'inherit'}
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                >
                  <MenuIcon fontSize="large" />
                </IconButton>
                <Drawer
                  className={classes.drawer}
                  anchor="right"
                  open={openDrawer}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  onClose={handleDrawerClose}
                >
                  <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                      <ChevronRightIcon fontSize="large" />
                    </IconButton>
                  </div>
                  <Divider />
                  <List>
                    {
                      classicListDrawerHeaderGuest.map((element) => (
                        <HashLink scroll={(el) => scrollWithOffset(el)} to={element.to} className={classes.linkMobile} key={`link${element.key}`}>
                          <ListItem onClick={handleDrawerClose} button key={`classicListDrawer${element.key}`}>
                            <ListItemIcon>{element.icon}</ListItemIcon>
                            <ListItemText primary={element.text} classes={{ primary: classes.listText }} />
                          </ListItem>
                        </HashLink>
                      ))
                    }
                  </List>
                  <Divider />
                  <List>
                    <HashLink className={classes.linkMobile}>
                      <ListItem button onClick={handleClickConnexionMobile}>
                        <ListItemIcon><PowerSettingsNewIcon fontSize="large" /></ListItemIcon>
                        <ListItemText primary="Connexion" classes={{ primary: classes.listText }} />
                      </ListItem>
                    </HashLink>
                    <HashLink to="/signup" className={classes.linkMobile}>
                      <ListItem button onClick={handleDrawerClose}>
                        <ListItemIcon><AssignmentIcon fontSize="large" /></ListItemIcon>
                        <ListItemText primary="Inscription" classes={{ primary: classes.listText }} />
                      </ListItem>
                    </HashLink>
                  </List>
                </Drawer>
              </div>
            </>
          )
        }
      </Toolbar>
    </AppBar>
  );
};

NavBarGuest.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  onConnexion: Proptypes.func,
  onInscription: Proptypes.func,
  position: Proptypes.oneOf([
    'static', 'fixed', 'relative', 'sticky',
  ]),
  allLinks: Proptypes.bool,
  sticky: Proptypes.bool,
};

NavBarGuest.defaultProps = {
  onConnexion: () => {},
  onInscription: () => {},
  position: 'static',
  allLinks: true,
  sticky: false,
};

export default withNavBarStyle(NavBarGuest);
