import { connect } from 'react-redux';
import Footer from './footer';

const mapStateToProps = ({ user }) => ({
  isAuthenticated: user.isAuthenticated,
});

export default connect(
  mapStateToProps,
  null,
)(Footer);
