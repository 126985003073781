import React, { useState } from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import { IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PaperCustomize from '../../_molecules/PaperCustomize/paper-customize';

import CardParticipant from '../../_atoms/CardParticipant/card-participant';
import BulleNumber from '../../_atoms/BulleNumber/bulle-number';
import Button from '../../_atoms/Button/button';
import withWinners from './winners.style';
import ModalInformation from '../../_atoms/ModalInformation/modal-information';
import { formatTexte } from '../../../constants/formatCategory';

const Winners = ({
  classes,
  className,
  classNameTitlePaper,
  classNameCardParticipant,
  participations,
  format,
  status,
  onClickDeleteWinner,
  onClickPrefinalWinners,
}) => {
  const [popupFinalizeWinners, setPopupFinalizeWinners] = useState(false);

  const handleOpenPopupFinalizeWinner = (e) => {
    setPopupFinalizeWinners(true);
  };

  const handleClosePopupFinalizeWinner = (e) => {
    setPopupFinalizeWinners(false);
  };

  const handleClickDeleteWinners = (participation) => (e) => {
    onClickDeleteWinner(participation);
  };

  const handleClickPrefinalWinners = (idConcours) => (e) => {
    onClickPrefinalWinners(idConcours);
    handleClosePopupFinalizeWinner();
  };

  return (
    <PaperCustomize title="Vos gagnants" width="28rem" className={cx(classes.root, className)} classNameTitle={classNameTitlePaper}>
      <Typography variant="body2" className={classes.text}>
        Organisez vos gagnants
      </Typography>
      <div className={classes.content}>
        {
          participations.length === 0 ? (
            <Typography variant="body2" className={cx(classes.text, classes.noWin)}>
              Aucun gagnant pour le moment
            </Typography>
          ) : (
            <div className={classes.wrapperContentWinner}>
              {
                participations.map((participation, index) => (
                  <div className={classes.wrapperWin} key={participation.id}>
                    <BulleNumber
                      className={classes.bulleNumber}
                      text={index + 1}
                    />
                    <CardParticipant
                      allowMouseOver={false}
                      background={format !== formatTexte && `${participation.fileParticipants[0].filepath}`}
                      text={participation.inputText}
                      format={format}
                      className={cx(classes.cardParticipant, classNameCardParticipant)}
                    />
                    <IconButton aria-label="close" className={cx(classes.closeButton, (status === 4 || status === 5) && classes.btnNoPointerEvent)} onClick={handleClickDeleteWinners(participation)}>
                      <CloseIcon fontSize="large" />
                    </IconButton>
                  </div>
                ))
              }
              <>
                <Button className={cx(classes.buttonFinal, (status === 4 || status === 5) && classes.btnNoPointerEvent)} onClick={handleOpenPopupFinalizeWinner}>
                  <span style={{ fontSize: '1.2rem' }}> Finaliser le concours</span>
                </Button>
                <ModalInformation
                  open={popupFinalizeWinners}
                  onClose={handleClosePopupFinalizeWinner}
                  title="Êtes-vous sûr de vouloir valider la ou les propositions selectionnés ?"
                  details={
                    'Cela aura pour effet de suspendre le concours, vous ne recevez aucune nouvelle proposition.'
                    + '\n\nVous pouvez dialoguer et échanger des fichiers avec le talent selectionné.'
                    + "\n\nLe paiement ne se fera qu'après votre validation, une fois que tout sera conforme à votre demande"
                  }
                  onClickSubmit={handleClickPrefinalWinners(participations[0].idConcours)}
                />
              </>
            </div>
          )
        }
      </div>
    </PaperCustomize>
  );
};


Winners.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  win: Proptypes.number,
  className: Proptypes.string,
  classNameTitlePaper: Proptypes.string,
  classNameCardParticipant: Proptypes.string,
  participations: Proptypes.array,
  format: Proptypes.oneOfType([
    Proptypes.string,
    Proptypes.number,
  ]),
  status: Proptypes.number,
  onClickPrefinalWinners: Proptypes.func,
  onClickDeleteWinner: Proptypes.func,
};

Winners.defaultProps = {
  participations: [],
  onClickPrefinalWinners: () => {},
  onClickDeleteWinner: () => {},
};

export default withWinners(Winners);
