import { withStyles } from '@material-ui/core';

export default withStyles(({
  shadows,
}) => ({
  root: {
    height: 'inherit',
  },
  containerDetails: {
    flexDirection: 'column',
  },
  wrapperImg: {
    boxShadow: shadows[2],
    borderRadius: '1rem',
    marginTop: '2rem',
    width: '9rem',
    height: '9rem',
  },
  img: {
    width: '100%',
    height: '100%',
    padding: '1rem',
  },
  textAreaField: {
    marginTop: '2rem',
    minHeight: '10rem',
    border: 'solid 2px #c9cdd5',
  },
  textAreaMessage: {
    '&::placeholder': {
      color: '#bbb7b7 !important',
      fontSize: '1.2rem',
      fontWeight: 'bold',
    },
  },
}));
