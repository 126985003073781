import { connect } from 'react-redux';
import { readMessageByIdParticipation, createMessage, addFileMessage } from '../../../../actions/message';
import { chooseFinalWinner, addFinalFile } from '../../../../actions/concours';
import MessageFirstStep from './message-second-step';

const mapStateToProps = ({ messages, user }) => ({
  messages,
  user: user.user,
});

export default connect(
  mapStateToProps,
  {
    readMessageByIdParticipation, createMessage, addFileMessage, chooseFinalWinner, addFinalFile,
  },
)(MessageFirstStep);
