import React from 'react';
import Proptypes from 'prop-types';

import ModalInformation from '../ModalInformation/modal-information';
import withMessageModalParticipation from './modal-message-to-participant..style';
import TextareaForm from '../TextareaForm/textarea-form';

const ModalMessageToParticipant = ({
  classes, open, onClose, title, onClickSubmit, textButton, imgParticipant, refMessage, valueMessage, onChangeMessage,
}) => (
  <ModalInformation
    open={open}
    onClose={onClose}
    button
    onClickSubmit={onClickSubmit}
    title={title}
    textButton={textButton}
    classNamePaper={classes.root}
    classNameContainerDetails={classes.containerDetails}
    widthButton="70%"
    imgParticipant={imgParticipant}
  >
    <TextareaForm
      name="message"
      id="message-to-participant"
      placeholder="Rédigez votre message..."
      label="Message"
      className={classes.textAreaField}
      classNameArea={classes.textAreaMessage}
      refTextarea={refMessage}
      value={valueMessage}
      onChange={onChangeMessage}
      noOnChange={false}
    />
  </ModalInformation>
);

ModalMessageToParticipant.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  open: Proptypes.bool,
  onClose: Proptypes.func,
  title: Proptypes.string,
  details: Proptypes.string,
  textButton: Proptypes.string,
  onClickSubmit: Proptypes.func,
  imgParticipant: Proptypes.node,
  refMessage: Proptypes.func,
  valueMessage: Proptypes.string,
  onChangeMessage: Proptypes.func,
};

ModalMessageToParticipant.defaultProps = {
  title: "Envoyez un message à l'auteur de cette proposition",
  textButton: 'Envoyez',
  onClose: () => {},
  onClickSubmit: () => {},
  onChangeMessage: () => {},
};

export default withMessageModalParticipation(ModalMessageToParticipant);
