import React from 'react';
import Proptypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';

import {
  Divider,
} from '@material-ui/core';

import ConnexionTop from './connexion.top';
import ConnexionForm from './connexion.form';
import withConnexion from './connexion.style';
import ModalCenter from '../../_molecules/ModalCenter/modal-center';

class Connexion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isForgetPassword: false,
    };
  }

  componentDidMount() {
    const { clearSuccessMsg } = this.props;
    clearSuccessMsg();
  }

  handleSubmit = async (data) => {
    const { login, resetPassword } = this.props;
    const { isForgetPassword } = this.state;

    if (isForgetPassword) {
      const { email } = data;
      resetPassword(email);
    } else {
      login({ ...data });
    }
  };

  handleSuccessGoogle = (response) => {
    const { loginGoogle } = this.props;
    loginGoogle(response.tokenId, () => {}, (err) => {
      // TODO: Comprendre pourquoi à la première connexion google au début y a une erreur
      loginGoogle(response.tokenId);
      console.log(err);
    });
  }

  handleFaillureGoogle = (err) => {
    console.log(err);
  }

  handleClickForget = () => {
    this.setState({
      isForgetPassword: true,
    });
  }

  handleClickClose = () => {
    const { onClose } = this.props;
    this.setState({
      isForgetPassword: false,
    });

    onClose();
  }

  /*

  handleSuccessFacebook = (response) => {
    const { loginFacebook } = this.props;
    loginFacebook({
      accessToken: response.accessToken,
      userId: response.userID,
    }, (res) => console.log(res, 'res'), (err) => console.log(err, 'err'));
    console.log(response);
  }

   */

  render() {
    const {
      open, classes, user,
    } = this.props;
    const { isForgetPassword } = this.state;
    return (
      <>
        {
          user.isAuthenticated && (
            <Redirect to="/" />
          )
        }
        <ModalCenter onClose={this.handleClickClose} open={open}>
          <ConnexionTop
            onSuccessGoogle={this.handleSuccessGoogle}
            onFaillureGoogle={this.handleFaillureGoogle}
            isForgetPassword={isForgetPassword}
          />
          <Divider className={classes.divider} />
          <ConnexionForm
            onSubmit={this.handleSubmit}
            msgSuccess={user.msg}
            onClose={this.handleClickClose}
            onClickForget={this.handleClickForget}
            isForgetPassword={isForgetPassword}
          />
        </ModalCenter>
      </>
    );
  }
}

Connexion.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  open: Proptypes.bool,
  onClose: Proptypes.func,
  login: Proptypes.func,
  loginGoogle: Proptypes.func,
  loginFacebook: Proptypes.func,
  user: Proptypes.shape({
    token: Proptypes.string,
    fetching: Proptypes.bool,
    fetched: Proptypes.bool,
    isLoading: Proptypes.bool,
    isAuthenticated: Proptypes.bool,
    isAdmin: Proptypes.oneOfType([
      Proptypes.bool,
      Proptypes.number,
    ]),
    user: Proptypes.object,
    msg: Proptypes.string,
  }),
  clearSuccessMsg: Proptypes.func,
  resetPassword: Proptypes.func,
};

Connexion.defaultProps = {
  open: false,
  loginGoogle: () => {},
  loginFacebook: () => {},
  onClose: () => {},
};

export default withRouter(withConnexion(Connexion));
