import {
  FETCH_CREATECONCOURS,
  FETCH_CREATECONCOURS_ERROR,
  FETCH_CREATECONCOURS_FULFILLED,
  FETCH_CONCOURSID,
  FETCH_CONCOURSID_FULFILLED,
  FETCH_CONCOURSID_ERROR,
  FETCH_ALL_CONCOURS,
  FETCH_ALL_CONCOURS_FULFILLED,
  FETCH_ALL_CONCOURS_ERROR,
  FETCH_GET_CONCOURS_CREATED_BY_USER,
  FETCH_GET_CONCOURS_CREATED_BY_USER_FULFILLED,
  FETCH_GET_CONCOURS_CREATED_BY_USER_ERROR,
  FETCH_GET_CONCOURS_PARTICIPED_BY_USER,
  FETCH_GET_CONCOURS_PARTICIPED_BY_USER_ERROR,
  FETCH_GET_CONCOURS_PARTICIPED_BY_USER_FULFILLED,
  FETCH_CONCOURS_SUBCATEGORY,
  FETCH_CONCOURS_SUBCATEGORY_FULFILLED,
  FETCH_CONCOURS_SUBCATEGORY_ERROR,
  FETCH_CONCOURS_FULFILLED_NOCONTENT,
  FETCH_CONCOURS_CATEGORY,
  FETCH_CONCOURS_CATEGORY_FULFILLED,
  FETCH_CONCOURS_CATEGORY_ERROR,
  FETCH_CONCOURS_CLEAR,
  FETCH_ADD_PARTICIPANT,
  FETCH_ADD_PARTICIPANT_FULFILLED,
  FETCH_ADD_PARTICIPANT_ERROR,
  FETCH_ADD_FINAL_FILE,
  FETCH_ADD_FINAL_FILE_FULFILLED,
  FETCH_ADD_FINAL_FILE_ERROR,
  FETCH_ADD_WINNER,
  FETCH_ADD_WINNER_FULFILLED,
  FETCH_ADD_WINNER_ERROR,
  FETCH_PREFINAL_WINNER,
  FETCH_PREFINAL_WINNER_FULFILLED,
  FETCH_PREFINAL_WINNER_ERROR,
  FETCH_CHOOSE_FINAL_WINNER,
  FETCH_CHOOSE_FINAL_WINNER_FULFILLED,
  FETCH_CHOOSE_FINAL_WINNER_ERROR,
  CLEAR_SUCCESS,
  FETCH_GET_ALL_WINNER_BY_CONCOURS,
  FETCH_GET_ALL_WINNER_BY_CONCOURS_FULFILLED,
  FETCH_GET_ALL_WINNER_BY_CONCOURS_ERROR,
  FETCH_DELETE_WINNER,
  FETCH_DELETE_WINNER_FULFILLED,
  FETCH_DELETE_WINNER_ERROR,
  FETCH_GET_CONCOURS_CREATED_BY_USER_NO_CONTENT,
  FETCH_GET_CONCOURS_PARTICIPED_BY_USER_NO_CONTENT,
  FETCH_CONCOURS_WITH_FILTER,
  FETCH_CONCOURS_WITH_FILTER_FULFILLED,
  FETCH_CONCOURS_WITH_FILTER_ERROR,
  FETCH_MODIFY_CONCOURS, FETCH_MODIFY_CONCOURS_FULFILLED, FETCH_MODIFY_CONCOURS_ERROR,
} from '../constants/actionTypes';

const initialState = {
  fetching: false,
  fetched: false,
  isLoading: false,
  singleConcours: null,
  concours: null,
  concoursParticiped: null,
  concoursByUser: null,
  allWinners: null,
  participationCreated: null,
};

export default function concoursReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CREATECONCOURS:
    case FETCH_CONCOURSID:
    case FETCH_ALL_CONCOURS:
    case FETCH_CONCOURS_SUBCATEGORY:
    case FETCH_CONCOURS_CATEGORY:
    case FETCH_ADD_PARTICIPANT:
    case FETCH_ADD_FINAL_FILE:
    case FETCH_ADD_WINNER:
    case FETCH_PREFINAL_WINNER:
    case FETCH_MODIFY_CONCOURS:
    case FETCH_CHOOSE_FINAL_WINNER:
    case FETCH_GET_ALL_WINNER_BY_CONCOURS:
    case FETCH_DELETE_WINNER:
    case FETCH_GET_CONCOURS_CREATED_BY_USER:
    case FETCH_GET_CONCOURS_PARTICIPED_BY_USER:
    case FETCH_CONCOURS_WITH_FILTER:
      return {
        ...state,
        fetching: true,
        isLoading: true,
      };
    case FETCH_ALL_CONCOURS_FULFILLED:
    case FETCH_CONCOURS_SUBCATEGORY_FULFILLED:
    case FETCH_CONCOURS_CATEGORY_FULFILLED:
    case FETCH_CONCOURS_WITH_FILTER_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        concours: action.payload,
        isLoading: false,
      };
    case FETCH_GET_CONCOURS_CREATED_BY_USER_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        concoursByUser: action.payload,
        isLoading: false,
      };
    case FETCH_GET_CONCOURS_PARTICIPED_BY_USER_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        concoursParticiped: action.payload,
        isLoading: false,
      };
    case FETCH_GET_ALL_WINNER_BY_CONCOURS_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        allWinners: action.payload,
        isLoading: false,
      };
    case FETCH_CREATECONCOURS_FULFILLED:
    case FETCH_CONCOURSID_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        singleConcours: action.payload,
        isLoading: false,
      };
    case FETCH_CONCOURS_FULFILLED_NOCONTENT:
      return {
        ...state,
        fetching: false,
        fetched: true,
        concours: [],
        singleConcours: null,
        isLoading: false,
      };
    case FETCH_GET_CONCOURS_CREATED_BY_USER_NO_CONTENT:
      return {
        ...state,
        fetching: false,
        fetched: true,
        concours: [],
        singleConcours: null,
        isLoading: false,
      };
    case FETCH_GET_CONCOURS_PARTICIPED_BY_USER_NO_CONTENT:
      return {
        ...state,
        fetching: false,
        fetched: true,
        concoursParticiped: [],
        singleConcours: null,
        isLoading: false,
      };
    case FETCH_ADD_PARTICIPANT_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        participationCreated: action.payload.participation,
      };
    case FETCH_ADD_FINAL_FILE_FULFILLED:
    case FETCH_ADD_WINNER_FULFILLED:
    case FETCH_DELETE_WINNER_FULFILLED:
    case FETCH_PREFINAL_WINNER_FULFILLED:
    case FETCH_CHOOSE_FINAL_WINNER_FULFILLED:
    case FETCH_MODIFY_CONCOURS_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
      };
    case CLEAR_SUCCESS:
      return {
        ...state,
      };
    case FETCH_GET_CONCOURS_CREATED_BY_USER_ERROR:
      return {
        ...state,
        fetching: false,
        concoursByUser: null,
      };
    case FETCH_GET_CONCOURS_PARTICIPED_BY_USER_ERROR:
      return {
        ...state,
        fetching: false,
        concoursParticiped: null,
      };
    case FETCH_CREATECONCOURS_ERROR:
    case FETCH_CONCOURS_CLEAR:
    case FETCH_CONCOURSID_ERROR:
    case FETCH_ALL_CONCOURS_ERROR:
    case FETCH_CONCOURS_SUBCATEGORY_ERROR:
    case FETCH_CONCOURS_CATEGORY_ERROR:
    case FETCH_GET_ALL_WINNER_BY_CONCOURS_ERROR:
    case FETCH_ADD_PARTICIPANT_ERROR:
    case FETCH_ADD_FINAL_FILE_ERROR:
    case FETCH_ADD_WINNER_ERROR:
    case FETCH_DELETE_WINNER_ERROR:
    case FETCH_PREFINAL_WINNER_ERROR:
    case FETCH_CHOOSE_FINAL_WINNER_ERROR:
    case FETCH_MODIFY_CONCOURS_ERROR:
    case FETCH_CONCOURS_WITH_FILTER_ERROR:
      return {
        ...state,
        fetching: false,
        concours: null,
        singleConcours: null,
        allWinners: {
          participations: [],
        },
      };
    default:
      return state;
  }
}
