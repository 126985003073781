import { withStyles } from '@material-ui/styles';

export default withStyles(({ palette, spacing, breakpoints }) => ({
  root: {
    display: 'flex',
  },
  text: {
    width: '15rem',
    textAlign: 'right',
    fontWeight: 'bold',
    color: palette.grey[600],
    [breakpoints.down(600)]: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      fontSize: '1.4rem',
      width: '10rem',
    },
    [breakpoints.down(400)]: {
      fontSize: '1.2rem',
    },
  },
  textLeft: {
    textAlign: 'left',
    [breakpoints.down(600)]: {
      justifyContent: 'flex-start',
    },
  },
  margin: {
    marginLeft: spacing(3),
    marginRight: spacing(3),
    [breakpoints.down(600)]: {
      marginLeft: spacing(2),
      marginRight: spacing(2),
    },
  },
}));
