import { formatTexte } from '../constants/formatCategory';

export const convertBytesToMbsOrKbs = (filesize) => {
  let size = '';
  // I know, not technically correct...
  if (filesize >= 1000000) {
    size = `${(filesize / 1000000).toFixed(2)} megabytes`;
  } else if (filesize >= 1000) {
    size = `${(filesize / 1000).toFixed(2)} kilobytes`;
  } else {
    size = `${filesize} bytes`;
  }
  return size;
};

export const FilesLimit = (format) => { // Pour le moment on accepte pour tous 5
  if (format === formatTexte) return 1;
  return 5;
};
