import { withStyles } from '@material-ui/styles';

export default withStyles(({
  palette,
}) => ({
  root: {
    width: '100%',
    overflow: 'hidden',
    transition: 'all .3s ease-in-out',
  },
  btn: {
    background: 'none',
    border: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  categories: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  search: {
    width: '22rem',
  },
  searchInput: {
    fontSize: '1.2rem',
  },
  wrapperList: {
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  listCategorie: {
    position: 'relative',
  },
  link: {
    textDecoration: 'none',
    color: palette.grey[800],
  },
  subCategoryLink: {
    color: palette.grey[500],
  },
  listMenu: {
    width: '16rem',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  titleCategorie: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    padding: '1rem',
    '&:hover': {
      background: palette.grey[200],
    },
  },
  subCategorieList: {
    overflow: 'hidden',
    width: '16rem',
    visibility: 'hidden',
    opacity: 0,
    padding: 0,
    zIndex: 10,
    position: 'absolute',
    transition: '0.2s all ease-in-out',
    pointerEvents: 'none',
    borderRadius: '2rem',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5), 5px 2px 4px 0 rgba(0, 0, 0, 0.5);',
    backgroundColor: palette.common.white,
  },
  subCategorie: {
    margin: '0 auto',
    zIndex: 10,
    padding: '1rem',
    textAlign: 'center',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: palette.grey[500],
    '&:hover': {
      cursor: 'pointer',
      background: palette.grey[200],
    },
  },
  listOver: {
    '@global': {
      ul: {
        pointerEvents: 'auto',
        visibility: 'visible',
        opacity: 1,
      },
    },
  },
  listLeave: {
    '@global': {
      ul: {
        visibility: 'hidden',
        opacity: 0,
        pointerEvents: 'none',
      },
    },
  },
}));
