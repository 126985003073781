import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import { LinearProgress } from '@material-ui/core';
import WrapperPageInfo from '../../_organisms/PageInfoSuccess/page-info-success.connect';
import WrapperPageGuest from '../../../containers/WrapperPageGuest/wrapper-page-guest.connect';

const UserVerified = ({
  verifyUser, match,
}) => {
  const [msgLocal, setMsgLocal] = React.useState('');
  const [title, setTitle] = React.useState('');
  useEffect(() => {
    const { emailToken } = match.params;
    verifyUser(emailToken, (res) => {
      setTitle('Vérification réussi !');
      setMsgLocal(res.message);
    }, () => {
      setTitle('Vérification echoué !');
      setMsgLocal('Le lien utilisé est mauvais ou expiré');
    });
  }, [match.params, verifyUser]);

  const [openConnexion, setOpenConnexion] = useState(false);

  const handleOpenConnexion = () => {
    setOpenConnexion(true);
  };

  const handleCloseHeader = () => {
    setOpenConnexion(false);
  };

  return (
    <>
      {
        msgLocal ? (
          <WrapperPageGuest>
            <WrapperPageInfo text={msgLocal} title={title} open={openConnexion} onClose={handleCloseHeader} onConnexion={handleOpenConnexion} />
          </WrapperPageGuest>
        ) : (
          <LinearProgress />
        )
      }
    </>
  );
};

UserVerified.propTypes = {
  location: Proptypes.objectOf(
    Proptypes.oneOfType([
      Proptypes.string, Proptypes.object,
    ]),
  ),
  match: Proptypes.shape({
    path: Proptypes.string,
    url: Proptypes.string,
    isExact: Proptypes.bool,
    params: Proptypes.object,
  }),
  verifyUser: Proptypes.func,
};

export default UserVerified;
