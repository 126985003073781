import React  from 'react';
import Proptypes from 'prop-types';
import { LinearProgress, CircularProgress } from '@material-ui/core';

const ProgressPerso = ({ linear }) => (
  <div style={{
    width: '80vw',
    margin: '50vh auto',
    display: linear === false && 'flex',
    justifyContent: 'center',
  }}
  >
    {
        linear ? (
          <LinearProgress
            color="secondary"
            style={{ height: '2rem' }}
          />
        ) : (
          <CircularProgress
            color="secondary"
          />
        )
      }
  </div>
);

ProgressPerso.propTypes = {
  linear: Proptypes.bool,
};

ProgressPerso.defaultProps = {
  linear: true,
};

export default ProgressPerso;
