export const organisateurFAQ = [
  {
    title: "Comment se passe l'organisation d'un concours ? ",
    details: "Comment se passe l'organisation d'un concours ? \n"
      + "L'organisation est composée de trois étapes \n"
      + '\t - Le brief :  Vous exprimez votre besoins, communiquez les informations clé et définissez les règles du concours.\n'
      + '\t - Le concours : A partir des informations renseignées vous recevrez des propositions, à vous de choisir vos préférés. \n'
      + '\t - Le résultat : une fois que le fichier source est en votre possession vous validez le concours. \n',
    id: 1,
  },
  {
    title: 'Comment sont définis les prix des concours ? ',
    details: 'Les prix des concours sont librement fixés par les organisateurs. \n'
      + 'Des montants minimum sont néanmoins demandés en fonction des catégories et peuvent être majoré selon les options souscrites.',
    id: 2,
  },
  {
    title: 'Puis-je communiquer avec le talent ?',
    details: 'Oui, dès que vous recevrez des propositions il vous sera possible de communiquer avec les talents.',
    id: 3,
  },
  {
    title: 'Aucune des propositions ne me convient, que faire ?',
    details: 'Si aucune des propositions apportées durant le concours ne vous convient, nous vous proposons de prolonger le concours en renouvelant la période.\n'
      + 'Si même après ce laps de temps vous n’êtes pas satisfait, nous vous remboursons votre concours.*\n\n\n'
      + '*Aucun remboursement possible pour les catégories slogan et nom d’entreprise.',
    id: 4,
  },
  {
    title: 'Puis-je choisir plusieurs gagnants ?',
    details: 'Oui, il vous est possible de sélectionner plusieurs gagnants. Dans ce cas, chaque gagnant remporte la mise de départ.',
    id: 5,
  },
  {
    title: 'Le talent ne me répond pas à mes demandes ? ',
    details: 'Une fois votre gagnant désigné celui-ci à 48 heures pour accepter la mission et vos éventuelles demandes. Après validation, les talents disposeront d’un délai impartis pour fournir les fichiers finaux. \n'
      + 'Dans les deux cas, si le délai est dépassé notre support se réserve le droit d’intervenir afin d’apporter une solution.\n',
    id: 6,
  },
];

export const participantFAQ = [
  {
    title: 'Comment se passe la participation à un concours ? ',
    details: 'Votre participation à un concours débute au moment où vous proposez une solution à un concours. \n'
      + 'Si votre solution est sélectionnée par l’organisateur vous recevrez une notification vous informant de cette nouvelle et vous demandant de valider les informations. \n'
      + 'Vous pourrez alors communiqué librement avec l’organisateur afin de fournir les fichier finaux qui clôtureront le concours une fois validé.',
    id: 1,
  },
  {
    title: "Puis-je communiquer avec l'organisateur ? ",
    details: "Oui, vous avez la possibilité de communiquer avec l'organisateur à tout moment d'un concours",
    id: 2,
  },
  {
    title: 'Quel travail est attendu lors de la participation ?',
    details: 'En fonction des concours, le travail attendu pour la participation à un concours ne sera pas la même. Vous retrouverez dans le brief une indication à ce sujet.',
    id: 3,
  },
  {
    title: 'Dois-je accepter toutes les demandes de retouches de la part de l’organisateur ? ',
    details: 'L’organisateur souscrit dès le départ un nombre possible de retouches. En proposant leurs services, les participants s’engagent à accepter ces demandes. \n'
      + 'Dans la mesure où l’organisateur dépasse le nombre de retouche prédéfini, notre équipe se réserve le droit d’intervenir et pourra être en mesure de réclamer une compensation adaptée à la situation.',
    id: 4,
  },
  {
    title: 'A quel moment l’argent est transféré sur mon porte monnaie ? ',
    details: 'L’argent est transféré sur votre compte dès que l’organisateur valide les fichiers envoyés. \n'
      + 'Sans retour de ce dernier, le concours est automatiquement clôturé 48heures après l’envoi des fichiers finaux.',
    id: 5,
  },
];
