import { withStyles } from '@material-ui/core';

export default withStyles(({ palette }) => ({
  root: {
    width: (props) => props.width,
    height: (props) => props.height,
  },
  header: {
    width: '100%',
  },
  img: {
    width: '100%',
  },
  wrapperTitle: {
    display: 'flex',
  },
  title: {
    textAlign: 'center',
    color: palette.common.white,
    fontSize: '2.4rem',
    marginLeft: (props) => props.centerTitle && 'auto',
    marginRight: (props) => props.centerTitle && 'auto',
    marginTop: (props) => props.marginTopText,
    width: '70%',
  },
  children: {

  },
}));
