import { withStyles } from '@material-ui/core';

export default withStyles(({
  loading: {
    width: '80vw',
    margin: '50vh auto',
  },
  linearProgress: {
    height: '2rem',
  },
}));
