import React from 'react';
import Proptypes from 'prop-types';
import cx from 'classnames';

import {
  TextField, Typography, Paper, IconButton,
} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';

import Header from '../../../_organisms/Header/header.connect';
import WrapperPageUser from '../../../../containers/WrapperPageUser/wrapper-page-user.connect';
import PaperCustomize from '../../../_molecules/PaperCustomize/paper-customize';
import Button from '../../../_atoms/Button/button';
import SnackBarBottom from '../../../_atoms/SnackBarBottom/snack-bar-bottom';

import { ucFirst } from '../../../../helpers/string';

import headerBackgroundPaper from '../../../../assets/Fond/Element2_modif.png';

import withMonProfil from './informations-perso.style';
import ModalRib from './ModalRib/modal-rib';

class InformationsPerso extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      companyName: '',
      pseudo: '',
      email: '',
      phone: '',
      openWarningSnack: true,
      openErrorSnack: false,
      openSuccessSnack: false,
    };
  }

  componentDidMount() {
    const { loadUser, location } = this.props;
    loadUser((data) => {
      this.setState({
        firstName: data.firstName || '',
        lastName: data.lastName || '',
        pseudo: data.pseudo || '',
        email: data.email || '',
        phone: data.phone || '',
        companyName: data.companyName || '',
      });
    });

    window.setTimeout(() => {
      location.state = null;
    }, 500);
  }

  handleCloseSnack = () => {
    this.setState({
      openWarningSnack: false,
      openSuccessSnack: false,
      openErrorSnack: false,
    });
  }

  handleChangeFirstName = (e) => {
    this.setState({
      firstName: e.target.value,
    });
  }

  handleChangeLastName = (e) => {
    this.setState({
      lastName: e.target.value,
    });
  }

  handleChangeCompanyName = (e) => {
    this.setState({
      companyName: e.target.value,
    });
  }

  handleChangePseudo = (e) => {
    this.setState({
      pseudo: e.target.value,
    });
  }

  handleChangeEmail = (e) => {
    this.setState({
      email: e.target.value,
    });
  }

  handleChangePhone = (e) => {
    this.setState({
      phone: e.target.value,
    });
  }

  handleSettingsWallet = () => {
    this.setState({
      openRib: true,
    });
  }

  handleCloseModalRib = () => {
    this.setState({
      openRib: false,
    });
  }

  handleLogout = () => {
    const { logout } = this.props;
    logout();
  }

  onSave = () => {
    const {
      firstName, lastName, phone, pseudo, email, companyName,
    } = this.state;
    const { updateUser, location } = this.props;
    if (pseudo && email) {
      updateUser({
        firstName: ucFirst(firstName),
        lastName: ucFirst(lastName),
        pseudo,
        email,
        phone,
        socialReason: companyName,
      }, () => {
        location.state = undefined;
      }, (err) => console.log(err));
    } else {
      this.setState({
        openErrorSnack: true,
      });
    }
  }

  render() {
    const {
      classes, history, user, location,
    } = this.props;
    const {
      firstName, lastName, pseudo, email, phone, openRib, companyName, openWarningSnack, openErrorSnack,
    } = this.state;
    return (
      <>
        <Header title="Gérer votre profil" history={history} />
        <WrapperPageUser>
          {
            location.state && location.state.noNameOrCompanyNameOrPseudo && (
              <SnackBarBottom variant="warning" open={openWarningSnack} onClose={this.handleCloseSnack} text="Pour pouvoir créer ou participer à un concours il faut rentrer les informations essentielles" />
            )
          }
          {
            openErrorSnack && (
              <SnackBarBottom variant="error" open={openErrorSnack} onClose={this.handleCloseSnack} text="Veuillez remplir tout les champs requis pour mettre à jour votre profil !" />
            )
          }
          {
            user && (
              <>
                <div className={classes.root}>
                  <PaperCustomize
                    className={classes.paperInfoPerso}
                    classNameTitle={classes.paperTitle}
                    headerBackground={headerBackgroundPaper}
                    title="Informations personnelles"
                    centerTitle={false}
                  >
                    <div className={classes.informations}>
                      <TextField className={classes.textField} value={firstName} label="Prénom" onChange={this.handleChangeFirstName} />
                      <TextField className={classes.textField} value={lastName} label="Nom" onChange={this.handleChangeLastName} />
                      <TextField className={classes.textField} value={companyName} label="Raison sociale" onChange={this.handleChangeCompanyName} />
                    </div>
                    <div className={classes.wrapperPwd}>
                      <TextField className={cx(classes.textField)} value={pseudo} label="Pseudo" onChange={this.handleChangePseudo} />
                      <TextField className={classes.textField} defaultValue="*******" disabled label="Mot de passe" />
                      <Button className={classes.btnResetPass}><span className={classes.textBtn}>Réinitialiser</span></Button>
                    </div>
                    <div className={classes.informations}>
                      <TextField className={classes.textField} value={email} label="E-mail" onChange={this.handleChangeEmail} />
                      <TextField className={classes.textField} value={phone} label="Téléphone" onChange={this.handleChangePhone} />
                    </div>
                    <div className={classes.buttons}>
                      <Button className={classes.btnSave} onClick={this.onSave}>
                        <span className={classes.textBtn}>Enregistrer</span>
                      </Button>
                      <Button className={classes.btnLogout} onClick={this.handleLogout}>
                        <span className={classes.textBtn}>Déconnexion</span>
                      </Button>
                    </div>
                  </PaperCustomize>
                  <Paper className={classes.paperWallet}>
                    <div className={classes.wrapperTitleWallet}>
                      <Typography variant="h4" className={classes.titleWallet}>Mon porte monnaie</Typography>
                      <IconButton className={classes.iconButtonSettings} aria-label="search" onClick={this.handleSettingsWallet}>
                        <SettingsIcon fontSize="large" className={classes.iconSettings} />
                      </IconButton>
                      <ModalRib open={openRib} onClose={this.handleCloseModalRib} />
                    </div>
                    <div className={classes.wrapperInfoWallet}>
                      <div className={classes.wrapperTitleInfoWallet}>
                        <Typography variant="body1" className={classes.titleInfoWallet}>Montant disponible : </Typography>
                        <Typography variant="body1" className={classes.amountWallet}>{`${user.wallet || 0}€`}</Typography>
                      </div>
                      <Button className={classes.btnTransfert}>
                        <span className={classes.textBtn}>Transférer</span>
                      </Button>
                    </div>
                  </Paper>
                </div>
              </>
            )
          }

        </WrapperPageUser>
      </>
    );
  }
}

InformationsPerso.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  history: Proptypes.objectOf(
    Proptypes.oneOfType([
      Proptypes.number,
      Proptypes.func,
      Proptypes.object,
      Proptypes.string,
    ]),
  ),
  user: Proptypes.object,
  updateUser: Proptypes.func,
  logout: Proptypes.func,
  loadUser: Proptypes.func,
  location: Proptypes.objectOf(
    Proptypes.oneOfType([
      Proptypes.string, Proptypes.object,
    ]),
  ),
};

export default withMonProfil(InformationsPerso);
