import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';
import { Paper, Typography } from '@material-ui/core';

import withCardCategory from './card-category.style';

const CardCategory = React.forwardRef(({
  classes, className, title, id, onlyTitle, price, callBackClick, active, onlyBackground, onClick,
}, ref) => {
  const handleClick = (event) => {
    if (onlyTitle) {
      callBackClick(id, event);
    }
  };

  const classeNameContainerContent = classes.containerContent + (active ? ` ${classes.containerContentActive}` : '');

  return (
    <Paper
      className={cx(classes.root, className)}
      ref={ref}
    >
      <button disabled={!onlyTitle && !onlyBackground} className={classes.btn} type="button" onClick={onlyBackground ? onClick : handleClick} id={id}>
        <div className={classeNameContainerContent}>
          <div className={classes.wrapperContent}>
            <div className={classes.content}>
              <Typography variant="h4" className={cx(classes.h4)}>
                {title}
              </Typography>
              <Typography variant="body1" className={classes.display}>
                à partir de
              </Typography>
              <Typography variant="h4" className={cx(classes.h4, classes.display)}>
                {`${price}€`}
              </Typography>
            </div>
          </div>
        </div>
      </button>
    </Paper>
  );
});

CardCategory.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  background: Proptypes.string,
  className: Proptypes.string,
  title: Proptypes.string,
  id: Proptypes.oneOfType([
    Proptypes.string,
    Proptypes.number,
  ]),
  price: Proptypes.oneOfType([
    Proptypes.string,
    Proptypes.number,
  ]),
  onlyTitle: Proptypes.bool,
  onlyBackground: Proptypes.bool,
  callBackClick: Proptypes.func,
  active: Proptypes.bool,
  onClick: Proptypes.func,
};

CardCategory.defaultProps = {
  onlyTitle: false,
  onlyBackground: false,
  callBackClick: () => {},
  onClick: () => {},
  active: false,
};

export default withCardCategory(CardCategory);
