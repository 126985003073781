// Pas besoion mais on sait jamais

export const sqlToJsDate = (sqlDate, withHour = false) => {
  // sqlDate in SQL DATETIME format ("yyyy-mm-dd hh:mm:ss.ms")
  const sqlDateArr1 = sqlDate.split('-');
  // format of sqlDateArr1[] = ['yyyy','mm','dd hh:mm:ms']
  const sYear = sqlDateArr1[0];
  const sMonth = (Number(sqlDateArr1[1]) - 1).toString();
  let sqlDateArr2;
  if (withHour) {
    sqlDateArr2 = sqlDateArr1[2].split('T');
    // format of sqlDateArr2[] = ['dd', 'hh:mm:ss.ms']
  } else {
    sqlDateArr2 = sqlDateArr1[2].split(' ');
  }
  const sDay = sqlDateArr2[0];

  return new Date(sYear, sMonth, sDay);
};

export const dateSQLDiff = (date1, date2) => {
  const diff = {}; // Initialisation du retour
  const date1Tmp = new Date(date1);
  const date2Tmp = new Date(date2);

  let tmp = date2Tmp - date1Tmp;
  tmp = Math.floor(tmp / 1000); // Nombre de secondes entre les 2 dates
  diff.sec = tmp % 60; // Extraction du nombre de secondes

  tmp = Math.floor((tmp - diff.sec) / 60); // Nombre de minutes (partie entière)
  diff.min = tmp % 60; // Extraction du nombre de minutes

  tmp = Math.floor((tmp - diff.min) / 60); // Nombre d'heures (entières)
  diff.hour = tmp % 24; // Extraction du nombre d'heures

  tmp = Math.floor((tmp - diff.hour) / 24); // Nombre de jours restants
  diff.day = tmp;

  return diff;
};

export const dateAfterXDays = (day) => {
  const date = new Date();
  date.setDate(date.getDate() + day);
  return date;
};

export const sortByCreatedAt = (a, b) => {
  const da = new Date(a.createdAt);
  const db = new Date(b.createdAt);
  return (da > db) ? 1 : -1;
};

export const parseMinutes = (minutes) => {
  if (minutes < 10) return `0${minutes}`;
  return minutes;
};

export const parseHours = (hours) => hours - 1; // Dans une date, l'heure est de 0 à 23

export const isSameDate = (date1, date2) => {
  if (date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth()) return true;
  return false;
};
