import { withStyles } from '@material-ui/styles';

export default withStyles(({ palette, breakpoints }) => ({
  root: {
    height: '100%',
    whiteSpace: 'pre-wrap',
    boxShadow: 'none',
    border: '1px solid rgba(0, 0, 0, .125)',
    '&$expanded': {
      margin: 'auto',
    },
  },
  expansionSummaryExpanded: {
    borderBottom: '0.5px solid rgba(0, 0, 0, .125)',
  },
  titleText: {
    color: palette.grey[600],
    fontSize: '1.4rem',
    fontWeight: 'bold',
    [breakpoints.down('xs')]: {
      fontSize: '1.2rem',
    },
    [breakpoints.down(500)]: {
      fontSize: '1rem',
    },
  },
  expansionDetails: {
    paddingBottom: '0.8rem',
  },
  detailsText: {
    fontSize: '1.4rem',
    color: '#858585',
    fontWeight: 'normal',
    [breakpoints.down('xs')]: {
      fontSize: '1.2rem',
    },
    [breakpoints.down(500)]: {
      fontSize: '1rem',
    },
  },
  '@global': {
    '.MuiExpansionPanel-root:before': {
      height: 0,
    },
  },
}));
