import { withStyles } from '@material-ui/styles';

export default withStyles(({ palette }) => ({
  field: {
    background: palette.grey[200],
  },
  btnNoPointerEvent: {
    pointerEvents: 'none',
    width: '80%',
    background: palette.grey[500],
  },
}));
