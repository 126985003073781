import axios from 'axios';
import { returnErrors } from './errorActions';
import { tokenConfig } from '../helpers/tokenConfig';
import { apiKey } from '../constants/api';

import {
  FETCH_SIGNUP,
  FETCH_SIGNUP_FULFILLED,
  FETCH_SIGNUP_ERROR,
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  FETCH_LOGIN_FULFILLED,
  FETCH_LOGIN_ERROR,
  FETCH_LOGIN,
  LOGOUT_SUCCESS,
  CLEAR_SUCCESS,
  FETCH_USER_UPDATE,
  FETCH_USER_UPDATE_FULFILLED,
  FETCH_USER_UPDATE_ERROR,
  FETCH_FINISH_TUTORIAL,
  FETCH_FINISH_TUTORIAL_FULFILLED,
  FETCH_FINISH_TUTORIAL_ERROR,
  FETCH_ADD_STATUT_TO_USER,
  FETCH_ADD_STATUT_TO_USER_FULFILLED,
  FETCH_ADD_STATUT_TO_USER_ERROR,
  FETCH_GET_STATUT_USER,
  FETCH_GET_STATUT_USER_FULFILLED,
  FETCH_GET_STATUT_USER_ERROR,
  FETCH_LOGIN_GOOGLE,
  FETCH_LOGIN_GOOGLE_FULFILLED,
  FETCH_LOGIN_GOOGLE_ERROR,
  FETCH_LOGIN_FACEBOOK,
  FETCH_LOGIN_FACEBOOK_FULFILLED,
  FETCH_LOGIN_FACEBOOK_ERROR,
  FETCH_VERIFY_USER,
  FETCH_VERIFY_USER_FULFILLED,
  FETCH_VERIFY_USER_ERROR,
  FETCH_RECOVER_PASSWORD,
  FETCH_RESET_PASSWORD,
  FETCH_RESET_PASSWORD_FULFILLED,
  FETCH_RESET_PASSWORD_ERROR,
  FETCH_RECOVER_PASSWORD_FULFILLED,
  FETCH_RECOVER_PASSWORD_ERROR,
} from '../constants/actionTypes';
import { returnSuccess } from './successActions';

// Check token & load user
export const loadUser = (cbSuccess = () => {}, cbError = () => {}) => async (dispatch, getState) => {
  // dispatch(clearErrors());
  // User loading
  dispatch({ type: USER_LOADING });

  try {
    const getUser = await axios
      .get(`${apiKey}/user/me`, tokenConfig(getState));
    cbSuccess(getUser.data.results.user);
    dispatch({
      type: USER_LOADED,
      payload: getUser.data.results.user,
    });
  } catch (e) {
    cbError(e.response?.data?.message?.message);
    dispatch(returnErrors(e.response?.data?.message?.message, e.response?.data?.message?.code, 'USER_LOADED_FAIL'));
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Update user
// TODO: A REVOIR

export const updateUser = (
  {
    firstName,
    lastName,
    email,
    phone,
    pseudo,
    birthDate,
    country,
    lang,
    socialReason,
  },
  cbSuccess = () => {},
  cbError = () => {},
) => (dispatch, getState) => {
  // dispatch(clearErrors());
  // User loading
  dispatch({ type: FETCH_USER_UPDATE });

  // Request body
  const body = JSON.stringify({
    firstName,
    lastName,
    pseudo,
    email,
    phone,
    birthDate,
    country,
    lang,
    socialReason,
  });

  axios
    .post(`${apiKey}/user/update`, body, tokenConfig(getState))
    .then((res) => {
      // dispatch(clearErrors());
      cbSuccess(res.data?.message);
      dispatch(
        returnSuccess(res.data?.message, 'USER_UPDATE'),
      );
      dispatch({
        type: FETCH_USER_UPDATE_FULFILLED,
        payload: res.data.results.user,
      });
    })
    .catch((err) => {
      cbError(err.response?.data?.message?.message);
      dispatch(returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'USER_UPDATE_FAIL'));
      dispatch({
        type: FETCH_USER_UPDATE_ERROR,
      });
    });
};

// Register User
export const registerUser = (
  {
    firstName, lastName, pseudo, email, password, confirmPassword,
  },
  cbSuccess = () => {},
  cbError = () => {},
) => (dispatch) => {
  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // dispatch(clearErrors());

  dispatch({ type: FETCH_SIGNUP });

  // Request body
  const body = JSON.stringify({
    firstName,
    lastName,
    pseudo,
    email,
    password,
    confirmPassword,
  });
  axios
    .post(`${apiKey}/auth/register`, body, config)
    .then((res) => {
      cbSuccess(res.data?.message);
      /* Pas besoin ici car on renvoie l'user dans une page success lorsqu'il a pu s'inscrire
       dispatch(
        returnSuccess(res.data?.message, 'REGISTER_USER'),
      ); */
      dispatch({
        type: FETCH_SIGNUP_FULFILLED,
        payload: res.data.results,
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch(returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'REGISTER_FAIL'));
      cbError(err.response?.data?.message?.message);
      dispatch({
        type: FETCH_SIGNUP_ERROR,
      });
    });
};

export const verifyUser = (emailToken, cbSuccess = () => {}, cbError = () => {}) => (dispatch) => {
  // dispatch(clearErrors());
  dispatch({ type: FETCH_VERIFY_USER });

  axios
    .get(`${apiKey}/auth/confirmation/${emailToken}`)
    .then((res) => {
      cbSuccess(res.data);
      dispatch(
        returnSuccess(res.data?.message, 'VERIFY_USER'),
      );
      dispatch({
        type: FETCH_VERIFY_USER_FULFILLED,
      });
    })
    .catch((err) => {
      cbError(err.response?.data?.message?.message);
      dispatch(returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'VERIFY_USER_FAIL'));
      dispatch({ type: FETCH_VERIFY_USER_ERROR });
    });
};

// Login User
export const login = ({ email, password }) => (dispatch) => {
  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // dispatch(clearErrors());

  dispatch({ type: FETCH_LOGIN });

  // Request body
  const body = JSON.stringify({ email, password });

  axios
    .post(`${apiKey}/auth/login`, body, config)
    .then((res) => {
      /* Pas besoin ici car on renvoie l'user dans une nouvelle page home lorsqu'il a pu se connecter
      dispatch(
        returnSuccess(res.data?.message, 'LOGIN'),
      ); */
      dispatch({
        type: FETCH_LOGIN_FULFILLED,
        payload: res.data.results,
      });
    })
    .catch((err) => {
      dispatch(
        returnErrors(err.response?.data?.message?.message, err.response?.message?.code, 'LOGIN_FAIL'),
      );
      dispatch({
        type: FETCH_LOGIN_ERROR,
      });
    });
};

export const loginGoogle = (tokenId, cbSuccess = () => {}, cbError = () => {}) => (dispatch) => {
  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // dispatch(clearErrors());

  dispatch({ type: FETCH_LOGIN_GOOGLE });

  // Request body
  const body = JSON.stringify({ tokenId });

  axios
    .post(`${apiKey}/auth/login/google`, body, config)
    .then((res) => {
      cbSuccess(res.data.results);
      /* Pas besoin ici car on renvoie l'user dans une nouvelle page home lorsqu'il a pu se connecter
      dispatch(
        returnSuccess(res.data?.message, 'LOGIN_GOOGLE'),
      ); */
      dispatch({
        type: FETCH_LOGIN_GOOGLE_FULFILLED,
        payload: res.data.results,
      });
    })
    .catch((err) => {
      cbError(err.response?.data?.message?.message);
      dispatch(returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'LOGIN_GOOGLE_FAIL'));
      dispatch({
        type: FETCH_LOGIN_GOOGLE_ERROR,
      });
    });
};

export const loginFacebook = ({ accessToken, userID }, cbSuccess = () => { }, cbError = () => {}) => (dispatch) => {
  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // dispatch(clearErrors());

  dispatch({ type: FETCH_LOGIN_FACEBOOK });

  // Request body
  const body = JSON.stringify({ accessToken, userID });

  axios
    .post(`${apiKey}/auth/login/facebook`, body, config)
    .then((res) => {
      cbSuccess(res.data.results);
      /* Pas besoin ici car on renvoie l'user dans une nouvelle page home lorsqu'il a pu se connecter
      dispatch(
        returnSuccess(res.data?.message, 'LOGIN_FACEBOOK'),
      ); */
      dispatch({
        type: FETCH_LOGIN_FACEBOOK_FULFILLED,
        payload: res.data.results,
      });
    })
    .catch((err) => {
      cbError(err.response?.data?.message?.message);
      dispatch(returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'LOGIN_FACEBOOK_FAIL'));
      dispatch({
        type: FETCH_LOGIN_FACEBOOK_ERROR,
      });
    });
};

export const resetPassword = (email, cbSuccess = () => {}, cbError = () => {}) => (dispatch) => {
  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // dispatch(clearErrors());

  dispatch({ type: FETCH_RESET_PASSWORD });

  // Request body
  const body = JSON.stringify({ email });

  axios
    .post(`${apiKey}/auth/pswd/lost`, body, config)
    .then((res) => {
      cbSuccess(res.data);
      dispatch(
        returnSuccess(res.data?.message, 'RESET_PASSWORD'),
      );
      dispatch({
        type: FETCH_RESET_PASSWORD_FULFILLED,
      });
    })
    .catch((err) => {
      cbError(err.response?.data?.message?.message);
      dispatch(returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'RESET_PASSWORD_FAIL'));
      dispatch({
        type: FETCH_RESET_PASSWORD_ERROR,
      });
    });
};

// Reset password
export const recoverPassword = (emailToken, { newPassword, confirmPassword }, cbSuccess = () => {}, cbError = () => {}) => (dispatch) => {
  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // dispatch(clearErrors());

  dispatch({ type: FETCH_RECOVER_PASSWORD });

  // Request body
  const body = JSON.stringify({ newPassword, confirmPassword });

  axios
    .post(`${apiKey}/auth/pswd/recover/${emailToken}`, body, config)
    .then((res) => {
      cbSuccess(res.data);
      dispatch(
        returnSuccess(res.data?.message, 'RECOVER_PASSWORD'),
      );
      dispatch({
        type: FETCH_RECOVER_PASSWORD_FULFILLED,
      });
    })
    .catch((err) => {
      cbError(err.response?.data?.message?.message);
      dispatch(returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'RECOVER_PASSWORD_FAIL'));
      dispatch({
        type: FETCH_RECOVER_PASSWORD_ERROR,
      });
    });
};
export const finishTutorial = (cbSuccess = () => {}, cbError = () => {}) => (dispatch, getState) => {
  dispatch({ type: FETCH_FINISH_TUTORIAL });

  axios
    .get(`${apiKey}/user/finish/tutorial`, tokenConfig(getState))
    .then((res) => {
      cbSuccess(res.data);
      dispatch({
        type: FETCH_FINISH_TUTORIAL_FULFILLED,
      });
    })
    .catch((err) => {
      cbError(err.response?.data?.message?.message);
      dispatch(returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'FINISH_TUTORIAL_FAIL'));
      dispatch({
        type: FETCH_FINISH_TUTORIAL_ERROR,
      });
    });
};

export const addStatut = (
  {
    socialReason,
    legalStatus,
    address,
    statut,
    numSiret,
    numTVA,
    regimeTVA,
    city,
    zip,
    country,
    beneficiaires,
    statutBeneficiaire,
  },
  cbSuccess = () => {},
  cbError = () => {},
) => (dispatch, getState) => {
  // dispatch(clearErrors());
  // User loading
  dispatch({ type: FETCH_ADD_STATUT_TO_USER });

  const body = JSON.stringify({
    socialReason,
    legalStatus,
    address,
    statut,
    numSiret,
    numTVA,
    regimeTVA,
    city,
    zip,
    country,
    beneficiaires,
    statutBeneficiaire,
  });

  axios
    .post(`${apiKey}/user/add/statut/`, body, tokenConfig(getState))
    .then((res) => {
      // dispatch(clearErrors());
      cbSuccess(res.data);
      dispatch(
        returnSuccess(res.data?.message, 'ADD_STATUS'),
      );
      dispatch({
        type: FETCH_ADD_STATUT_TO_USER_FULFILLED,
        payload: res.data.results.user,
      });
    })
    .catch((err) => {
      cbError(err.response?.data?.message?.message);
      dispatch(returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'ADD_STATUT_FAIL'));
      dispatch({
        type: FETCH_ADD_STATUT_TO_USER_ERROR,
      });
    });
};

export const getStatutUser = (cbSuccess = () => {}, cbError = () => {}) => (dispatch, getState) => {
  // dispatch(clearErrors());
  // User loading
  dispatch({ type: FETCH_GET_STATUT_USER });

  axios
    .get(`${apiKey}/user/get/statut`, tokenConfig(getState))
    .then((res) => {
      // dispatch(clearErrors());
      cbSuccess(res.data.results.statut);
      dispatch({
        type: FETCH_GET_STATUT_USER_FULFILLED,
        payload: res.data.results.statut,
      });
    })
    .catch((err) => {
      cbError(err.response?.data?.message?.message);
      dispatch(returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'GET_STATUT_FAIL'));
      dispatch({
        type: FETCH_GET_STATUT_USER_ERROR,
      });
    });
};

/* TO DO
 * Forget password
 */

// Logout User
export const logout = () => ({
  type: LOGOUT_SUCCESS,
});

export const clearSuccessMsg = () => ({
  type: CLEAR_SUCCESS,
});
