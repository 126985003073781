export const sortById = (a, b) => {
  if (a.id > b.id) return 1;
  if (a.id < b.id) return -1;
  return 0;
};

export const sortByIdForParticipationObject = (a, b) => {
  if (a.idConcours > b.idConcours) return 1;
  if (a.idConcours < b.idConcours) return -1;
  return 0;
};

export const sortByNameOptions = (a, b) => {
  if (a.nom > b.nom) return 1;
  if (a.nom < b.nom) return -1;
  return 0;
};
