import React from 'react';

import AssignmentIcon from '@material-ui/icons/Assignment';
import HomeIcon from '@material-ui/icons/Home';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import ContactlessIcon from '@material-ui/icons/Contactless';

export const classicListDrawerHeaderUser = [
  {
    key: 'home',
    icon: <HomeIcon fontSize="large" />,
    text: 'Acceuil',
    onClick: () => {},
    to: '/',
  },
  {
    key: 'concours',
    icon: <AssignmentIcon fontSize="large" />,
    text: 'Organiser un concours',
    onClick: () => {},
    to: '/organiser-concours/etape1',
  },
];

export const classicListDrawerHeaderGuest = [
  {
    key: 'home',
    icon: <HomeIcon fontSize="large" />,
    text: 'Acceuil',
    onClick: () => {},
    to: '/#',
  },
  {
    key: 'concept',
    icon: <ContactSupportIcon fontSize="large" />,
    text: 'Concept',
    onClick: () => {},
    to: '/#concept',
  },
  {
    key: 'engagemgents',
    icon: <SentimentSatisfiedAltIcon fontSize="large" />,
    text: 'Engagements',
    onClick: () => {},
    to: '/#engagements',
  },
  {
    key: 'temoignages',
    icon: <ContactlessIcon fontSize="large" />,
    text: 'Témoignages',
    onClick: () => {},
    to: '/#temoignages',
  },
];
