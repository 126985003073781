import React from 'react';
import Proptypes from 'prop-types';

import DateFnsUtils from '@date-io/date-fns';
import frLocale from 'date-fns/locale/fr';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { dateAfterXDays } from '../../../helpers/date';

const DatePickerCon = ({
  className, value, onChange, label, minDate, maxDate,
}) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
    <KeyboardDatePicker
      className={className}
      variant="dialog"
      inputVariant="outlined"
      label={label}
      cancelLabel="Annuler"
      okLabel="Valider"
      todayLabel="Aujourd'hui"
      showTodayButton
      minDate={minDate}
      maxDate={maxDate}
      format="MM/dd/yyyy"
      value={value}
      InputAdornmentProps={{ position: 'start' }}
      onChange={onChange}
      keyboardIcon={<CalendarTodayIcon style={{ position: 'relative', top: '-0.5rem' }} />}
    />
  </MuiPickersUtilsProvider>
);

DatePickerCon.propTypes = {
  className: Proptypes.string,
  value: Proptypes.object,
  onChange: Proptypes.func,
  label: Proptypes.string,
  minDate: Proptypes.object,
  maxDate: Proptypes.object,
};

DatePickerCon.defaultProps = {
  onChange: () => {},
  minDate: dateAfterXDays(5),
  maxDate: dateAfterXDays(10),
};

export default DatePickerCon;

// onChange={(date) => handleDateChange(date)}
