import React from 'react';
import cx from 'classnames';
import { Redirect } from 'react-router-dom';
import Proptypes from 'prop-types';

import { Divider, Typography } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { parseDate, parseFormatConcours } from '../../../helpers/parse';

import Header from '../../_organisms/Header/header.connect';
import WrapperPageUser from '../../../containers/WrapperPageUser/wrapper-page-user.connect';
import PaperPaiement from '../../_atoms/PaperPaiement/paper-paiement';

import withStep5 from './oragniserConcours.style';
import TextfieldIconButton from '../../_molecules/TextFieldIconButton/textfield-icon-button';
import { typeCards } from '../../../constants/payment';

const Step5 = ({
  classes, location, createConcours, history, createPayment,
}) => {
  const [codePromo, setCodePromo] = React.useState('');

  if (location.state) {
    const {
      endDate, category, cost, format,
    } = location.state.concours;

    const handleChangeCodePromo = (e) => {
      setCodePromo(e.target.value);
    };

    // TODO
    const handleClickCodePromo = () => {
      console.log(location.state);
      console.log(codePromo);
    };

    const handleCreateConcours = (cardType) => () => {
      const {
        companyName,
        companySkills,
        target,
        cursorModern,
        idSubCategory,
        cursorYoung,
        cursorFun,
        cursorLuxury,
        message,
        options,
      } = location.state.concours;

      createConcours({
        companyName,
        companySkills,
        target,
        cursorModern,
        idSubCategory,
        cursorYoung,
        cursorFun,
        cursorLuxury,
        message,
        endDate,
        cost,
        options,
      }, (concours) => {
        console.log(concours, 'newConcours');

        createPayment({
          idConcours: concours.id,
          cardType,
        }, (redirectUrl) => {
          console.log(concours, 'newConcours');
          console.log(redirectUrl, 'redirectUrl');
          window.open(redirectUrl, '_blank');
        });
      }, (error) => console.log(error));
    };

    return (
      <>
        <WrapperPageUser>
          <Header title="Etape 4 : Paiement" history={history} />
          <div className={cx(classes.wrapBodyStep2, classes.wrapBodyStep3)}>
            <div className={classes.step5Left}>
              <Typography variant="h3" display="block" className={classes.title}>Détails du concours</Typography>
              <div className={classes.wrapperStep5Details}>
                <Typography variant="body1" display="block" className={classes.informationTitleStep5}>
                  Catégorie : <span className={classes.informationStep5}>{category}</span>
                </Typography>
                <Typography variant="body1" display="block" className={classes.informationTitleStep5}>
                  Format : <span className={classes.informationStep5}>{`${parseFormatConcours(format)} puis fichier source`}</span>
                </Typography>
                <Typography variant="body1" display="block" className={classes.informationTitleStep5}>
                  {// TODO: Modifier logo partiellement adapté
                  }
                  Résultat attendu : <span className={classes.informationStep5}>Un logo partiellement adapté à l&apos;annonce</span>
                </Typography>
                <Typography variant="body1" display="block" className={classes.informationTitleStep5}>
                  Date de fin du concours : <span className={classes.informationStep5}>{parseDate(endDate)}</span>
                </Typography>
                <Typography variant="body1" display="block" className={classes.informationTitleStep5}>
                  Coût du concours : <span className={classes.informationStep5}>{`${cost} €`}</span>
                </Typography>
              </div>
            </div>
            <div className={classes.wrapperDivider}>
              <Divider className={classes.divider} orientation="vertical" />
            </div>
            <div className={classes.step3Right}>
              <Typography variant="h3" display="block" className={classes.title}>Moyen de paiement</Typography>
              <Typography variant="body2" display="block" className={cx(classes.details, classes.detailsRight)}>
                <span style={{ color: 'red' }}>Choisissez un moyen de paiement pour régler votre concours.</span>
                Vous pouvez également régler avec votre compte concoureur.com
              </Typography>
              <div className={classes.paiement}>
                {
                  typeCards.map((typeCard) => (
                    <PaperPaiement icon={typeCard.icon} text={typeCard.text} onClick={handleCreateConcours(typeCard.value)} key={typeCard.key} />
                  ))
                }
              </div>
              <Typography variant="h3" display="block" className={classes.title}>Code promotionnel</Typography>
              <TextfieldIconButton
                id="codePromo"
                name="codePromo"
                icon={<SendIcon fontSize="large" color="secondary" />}
                value={codePromo}
                onChange={handleChangeCodePromo}
                onClickIcon={handleClickCodePromo}
                ariaLabelIcon="Code promo"
                label="Code promotionnel"
              />
            </div>
          </div>
        </WrapperPageUser>
      </>
    );
  }

  return <Redirect to="/organiser-concours/etape1" />;
};

Step5.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  location: Proptypes.objectOf(
    Proptypes.oneOfType([
      Proptypes.string, Proptypes.object,
    ]),
  ),
  createConcours: Proptypes.func,
  createPayment: Proptypes.func,
  history: Proptypes.objectOf(
    Proptypes.oneOfType([
      Proptypes.number,
      Proptypes.func,
      Proptypes.object,
      Proptypes.string,
    ]),
  ),
};

Step5.defaultProps = {
  createConcours: () => {},
  createPayment: () => {},
};

export default withStep5(Step5);
