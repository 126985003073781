import { withStyles } from '@material-ui/styles';

export default withStyles(({ palette }) => ({
  containerBtn: {

  },
  btnTab: {
    height: '3rem',
    width: '13rem',
    '@global': {
      span: {
        fontSize: '1.2rem',
      },
    },
  },
}));
