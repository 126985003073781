import { dateSQLDiff } from './date';
import {
  formatImage, formatVideo, formatAudio, formatGif, formatTexte,
} from '../constants/formatCategory';

export const radioGroupStepParse = (resultat) => {
  if (resultat === 'Fini') return 1;
  if (resultat === 'Semi-fini') return 2;
  return 3;
};

export const resultatAttenduToString = (resultat) => {
  if (resultat === 1) return 'Fini';
  if (resultat === 2) return 'Semi-fini';
  return 'Preuves';
};

export const parseDateConcoursEnd = (date1, date2) => {
  const nbrJour = dateSQLDiff(date1, date2).day;
  if (nbrJour === 0) return "Aujourd'hui";
  if (nbrJour === 1) return `dans ${nbrJour} jour`;

  return `dans ${nbrJour} jours`;
};

export const parseDateConcoursAgo = (date1, date2) => {
  const nbrJour = dateSQLDiff(date1, date2, true).day;
  if (nbrJour === 0) return "Aujourd'hui";
  if (nbrJour === 1) return `Il y a ${nbrJour} jour`;
  return `Il y a ${nbrJour} jours`;
};

export const parseDate = (date) => {
  let day = date.getDate();
  let month = date.getMonth() + 1;
  const year = date.getFullYear();

  if (day < 10) day = `0${day}`;
  if (month < 10) month = `0${month}`;

  return `${day}/${month}/${year}`;
};

export const parseDateSQLToHour = (date) => {
  const dateJs = new Date(date);

  let hour = dateJs.getHours();

  if (hour < 10) hour = `0${hour}`;
  let minutes = dateJs.getMinutes();

  if (minutes < 10) minutes = `0${minutes}`;

  return `${hour}:${minutes}`;
};

export const parseFormatConcours = (format) => {
  // TODO: Faire un format special pour les sites
  if (format === formatImage) return 'PNG ou JPEG';
  if (format === formatVideo) return 'AVI ou MP4';
  if (format === formatAudio) return 'MP3';
  if (format === formatGif) return 'GIF';
  if (format === formatTexte) return 'Ecrire directement sur le site puis fichier texte';
  return 'format non connu';
};

export const parseFormatConcoursFinal = (format) => {
  // TODO: Faire un format special pour les sites
  if (format === formatImage) return 'PSD';
  if (format === formatVideo) return 'AVI ou MP4';
  if (format === formatAudio) return 'TODO';
  if (format === formatGif) return 'TODO';
  if (format === formatTexte) return 'PDF ou Word';
  return 'format non connu';
};

export const parseAcceptedFiles = (format, finalFile = false) => {
  // TODO: Faire un format special pour les sites
  if (finalFile) return '.zip, .rar. .7zip';
  if (format === formatImage) return 'image/png, image/jpeg';
  if (format === formatVideo) return 'video/*';
  if (format === formatAudio) return 'audio/*';
  if (format === formatGif) return 'image/gif';
  return '.pdf';
};

export const parseTextMessage = (text) => {
  if (text.length > 25) {
    return `${text.substring(0, 25)}...`;
  }
  return text;
};

/*
  Statut : 1 = Créer pas encore payé
  Statut : 2 = Créer et payé
  Statut 3 : EN attente de gagnant (plus de participation possible)
  Statut : 4 = Winner selectionné
  Statut : 5 = Concours terminée (Il a reçu les fichiers finaux
  Statut : -1 = Temps de fin depassé
 */

export const parseStatutConcours = (number) => {
  if (number === 2) return 'En cours';
  if (number === 3) return 'En attente du gagnant';
  if (number === 4) return 'En attente des fichiers finaux';
  if (number === 5) return 'Terminé';
  return `Statut ${number} non connu`;
};
