import { combineReducers } from 'redux';

import errorReducer from './errorReducer';
import userReducer from './userReducer';
import concoursReducer from './concoursReducer';
import messageReducer from './messageReducer';
import participationReducer from './participationReducer';
import subCategoryReducer from './subCategoryReducer';
import paymentReducer from './paymentReducer';
import successReducer from './successReducer';

const rootReducer = combineReducers({
  error: errorReducer,
  success: successReducer,
  user: userReducer,
  concours: concoursReducer,
  messages: messageReducer,
  participation: participationReducer,
  subCategory: subCategoryReducer,
  paymentReducer,
});

export default rootReducer;
