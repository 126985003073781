import { withStyles } from '@material-ui/core';

export default withStyles(({ palette }) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '4rem',
    height: '4rem',
    backgroundColor: palette.common.white,
    border: `2px solid ${palette.secondary.main}`,
    borderRadius: '50%',
  },
  text: {
    fontSize: '2.5rem',
    position: 'relative',
    top: '-1px',
    left: '1px',
  },
}));
