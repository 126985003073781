import React from 'react';
import Proptypes from 'prop-types';
import useForm from 'react-hook-form';

import TextField from '@material-ui/core/TextField';
import ModalCenter from '../../../../_molecules/ModalCenter/modal-center';
import Button from '../../../../_atoms/Button/button';

import withModalRib from './modal-rib.style';

const ModalRib = ({
  classes, open, onClose, onSubmit,
}) => {
  const {
    register, handleSubmit, errors,
  } = useForm();
  return (
    <ModalCenter open={open} onClose={onClose}>
      <form
        autoComplete="off"
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextField
          id="name"
          name="name"
          type="text"
          className={classes.textField}
          label="Nom & prénom du titulaire"
          margin="normal"
          variant="outlined"
          fullWidth
          inputRef={register({ required: true })}
          error={!!errors.name}
        />
        <TextField
          id="iban"
          name="iban"
          type="text"
          className={classes.textField}
          label="IBAN"
          margin="normal"
          variant="outlined"
          fullWidth
          inputRef={register({ required: true })}
          error={!!errors.iban}
        />
        <Button onClick={onSubmit} className={classes.btn}>Valider</Button>
      </form>
    </ModalCenter>
  );
};

ModalRib.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  open: Proptypes.bool,
  onClose: Proptypes.func,
  onSubmit: Proptypes.func,
};

ModalRib.defaultProps = {
  open: false,
  onClose: () => {},
};

export default withModalRib(ModalRib);
