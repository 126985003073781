import { connect } from 'react-redux';
import { logout, loadUser, updateUser } from '../../../../actions/user';
import InformationsPerso from './informations-perso';

const mapStateToProps = ({ user }) => ({
  user: user.user,
  msgSuccess: user.msg,
});

export default connect(
  mapStateToProps,
  { logout, loadUser, updateUser },
)(InformationsPerso);
