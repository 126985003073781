import { connect } from 'react-redux';
import WrapperPageUser from './wrapper-page-guest';

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(
  mapStateToProps,
  null,
)(WrapperPageUser);
