import React, { useState } from 'react';
import cx from 'classnames';
import { Redirect } from 'react-router-dom';
import Proptypes from 'prop-types';

import {
  Checkbox,
  Divider, FormControlLabel, Typography,
} from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

import TextField from '@material-ui/core/TextField';
import WrapperPageUser from '../../../containers/WrapperPageUser/wrapper-page-user.connect';

import { rules } from '../../../constants/concours';

import Header from '../../_organisms/Header/header.connect';
import ButtonCon from '../../_atoms/Button/button';
import SnackBarBottom from '../../_atoms/SnackBarBottom/snack-bar-bottom';
import { dateAfterXDays } from '../../../helpers/date';
import { parseDate } from '../../../helpers/parse';


import withStep4 from './oragniserConcours.style';

const Step4 = ({ classes, location, history }) => {
  const [openSnackCost, setOpenSnackCost] = useState(false);
  const [openSnackFields, setOpenSnackFields] = useState(false);
  const [cost, setCost] = useState('');
  const [radioCGU, setRadioCGU] = useState(false);

  const endDate = dateAfterXDays(7);

  const handleOpenSnackFields = () => {
    setOpenSnackFields(true);
  };

  const handleCloseSnackFields = () => {
    setOpenSnackFields(false);
  };

  const handleOpenSnackCost = () => {
    setOpenSnackCost(true);
  };

  const handleCloseSnackCost = () => {
    setOpenSnackCost(false);
  };

  const handleCostChange = (e) => {
    const newCost = parseInt(e.target.value, 10);
    setCost(newCost);
  };

  const handleRadioCGUChange = () => {
    console.log(location.state);
    if (radioCGU) setRadioCGU(false);
    else setRadioCGU(true);
  };

  const handleSubmit = () => {
    if (cost && radioCGU === true) {
      if (cost < location.state.concours.costMini) {
        handleOpenSnackCost();
      } else {
        history.push({
          pathname: '/organiser-concours/etape5',
          state: {
            concours: {
              ...location.state.concours,
              cost,
              endDate,
              radioCGU,
            },
          },
        });
      }
    } else {
      handleOpenSnackFields();
    }
  };

  return (
    <>
      {
        !!openSnackFields && (
          <SnackBarBottom variant="error" open={openSnackFields} onClose={handleCloseSnackFields} text="Tout les champs doivent être remplis pour continuer !" />
        )
      }
      {
        !!openSnackCost && (
          <SnackBarBottom variant="error" open={openSnackCost} onClose={handleCloseSnackCost} text={`Le coût du concours doit être supérieur à ${location.state.concours.costMini} € !`} />
        )
      }
      {
        location.state ? (
          <WrapperPageUser>
            <Header title="Etape 4 : Définissez les règles" history={history} />
            <div className={cx(classes.wrapBodyStep2, classes.wrapBodyStep3)}>
              <div className={classes.step3Left}>
                <div className={classes.wrapperDatePicker}>
                  <Typography variant="h3" display="block" className={classes.title}>Date de fin du concours</Typography>
                  <Typography variant="body1" display="block" className={classes.date}>{parseDate(endDate)}</Typography>
                  <Typography variant="body2" display="block" className={classes.details}>{rules.dateConcours}</Typography>
                </div>
                <div className={classes.wrapperCost}>
                  <Typography variant="h3" display="block" className={classes.title}>Coût du concours</Typography>
                  <TextField
                    id="cost"
                    name="cost"
                    type="number"
                    className={classes.textField}
                    label="Coût du concours"
                    margin="normal"
                    variant="outlined"
                    value={cost}
                    onChange={handleCostChange}
                  />
                  <Typography variant="body2" display="block" className={classes.details}>
                    Le montant minimum pour le vainqueur dans la catégorie
                    <span style={{ color: 'red' }}>{` ${location.state.concours.category} `}</span>
                    est de <span style={{ color: 'red' }}>{` ${location.state.concours.costMini} €`}</span>
                  </Typography>
                </div>
              </div>
              <div className={classes.wrapperDivider}>
                <Divider className={classes.divider} orientation="vertical" />
              </div>
              <div className={classes.step3Right}>
                <Typography variant="h3" display="block" className={classes.title}>Comment faire votre choix ?</Typography>
                <div className={classes.wrapperRulesChoice}>
                  <Typography variant="body2" display="block" className={cx(classes.details, classes.detailsRight)}>{rules.choice}</Typography>
                  {// TODO: Modifier logo partiellement adapté
                  }
                  <Typography variant="body2" display="block" className={cx(classes.details, classes.colorPrimary)}>Un logo partiellement adapté à votre annonce</Typography>
                </div>
              </div>
            </div>
            <div className={classes.formBottom}>
              <FormControlLabel
                control={<Checkbox classes={{ checked: classes.radioChecked }} icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} />}
                label="Vous acceptez les C.G.U de notre site"
                value={radioCGU}
                onChange={handleRadioCGUChange}
                name="CGUValidate"
                classes={{ label: classes.radioLabel }}
                className={classes.formControlLabel}
              />
              <ButtonCon className={classes.btnStep} onClick={handleSubmit}>Paiement</ButtonCon>
            </div>
          </WrapperPageUser>
        ) : (
          <Redirect to="/organiser-concours/etape1" />
        )
      }
    </>
  );
};

Step4.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  location: Proptypes.objectOf(
    Proptypes.oneOfType([
      Proptypes.string, Proptypes.object,
    ]),
  ),
  history: Proptypes.objectOf(
    Proptypes.oneOfType([
      Proptypes.number,
      Proptypes.func,
      Proptypes.object,
      Proptypes.string,
    ]),
  ),
};

export default withStep4(Step4);
