import axios from 'axios';
import { returnErrors } from './errorActions';
import { apiKey } from '../constants/api';

import {
  FETCH_CREATE_MESSAGE,
  FETCH_CREATE_MESSAGE_FULFILLED,
  FETCH_CREATE_MESSAGE_ERROR,
  FETCH_READ_MESSAGES_BY_USER,
  FETCH_READ_MESSAGES_BY_USER_FULFILLED,
  FETCH_READ_MESSAGES_BY_USER_ERROR,
  FETCH_READ_MESSAGES_BY_CONCOURS,
  FETCH_READ_MESSAGES_BY_CONCOURS_FULFILLED,
  FETCH_READ_MESSAGES_BY_CONCOURS_ERROR,
  FETCH_READ_MESSAGES_BY_PARTICIPATIONS_ERROR,
  FETCH_READ_MESSAGES_BY_PARTICIPATIONS_FULFILLED,
  FETCH_READ_MESSAGES_BY_PARTICIPATIONS,
  FETCH_READ_MESSAGES_BY_ID_PARTICIPATION,
  FETCH_READ_MESSAGES_BY_ID_PARTICIPATION_FULFILLED,
  FETCH_READ_MESSAGES_BY_ID_PARTICIPATION_ERROR, FETCH_ADD_FILE_MESSAGE,
  FETCH_ADD_FILE_MESSAGE_FULFILLED,
  FETCH_ADD_FILE_MESSAGE_ERROR,
} from '../constants/actionTypes';
import { tokenConfig } from '../helpers/tokenConfig';
import { returnSuccess } from './successActions';

export const createMessage = ({
  idParticipation,
  message,
}, cbSuccess = () => {}, cbError = () => {}) => (dispatch, getState) => {
  // dispatch(clearErrors());
  dispatch({ type: FETCH_CREATE_MESSAGE });

  const body = JSON.stringify({
    idParticipation,
    message,
  });

  axios.post(`${apiKey}/message/create`, body, tokenConfig(getState))
    .then((res) => {
      dispatch(
        returnSuccess(res.data?.message, 'CREATE_MESSAGE'),
      );
      cbSuccess(res.data.message);
      dispatch({
        type: FETCH_CREATE_MESSAGE_FULFILLED,
      });
    })
    .catch((err) => {
      cbError(err.response?.data?.message?.message);
      dispatch(returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'CREATE_MESSAGE_FAIL'));
      dispatch({
        type: FETCH_CREATE_MESSAGE_ERROR,
      });
    });
};

export const readMessageByUser = (cbSuccess = () => {}, cbError = () => {}) => (dispatch, getState) => {
  // dispatch(clearErrors());
  dispatch({ type: FETCH_READ_MESSAGES_BY_USER });

  axios.get(`${apiKey}/message/read/byUser`, tokenConfig(getState))
    .then((res) => {
      cbSuccess(res.data.results.concours);
      dispatch({ type: FETCH_READ_MESSAGES_BY_USER_FULFILLED, payload: res.data.results.concours });
    })
    .catch((err) => {
      cbError(err.response?.data?.message?.message);
      dispatch(
        returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'GET_MESSAGE_BY_USER_FAIL'),
      );
      dispatch({ type: FETCH_READ_MESSAGES_BY_USER_ERROR });
    });
};

export const readMessageByConcours = (cbSuccess = () => {}, cbError = () => {}) => (dispatch, getState) => {
  // dispatch(clearErrors());
  dispatch({ type: FETCH_READ_MESSAGES_BY_CONCOURS });

  axios.get(`${apiKey}/message/read/byConcours`, tokenConfig(getState))
    .then((res) => {
      cbSuccess(res.data.results.concours);
      dispatch({ type: FETCH_READ_MESSAGES_BY_CONCOURS_FULFILLED, payload: res.data.results.concours });
    })
    .catch((err) => {
      cbError(err.response?.data?.message?.message);
      dispatch(
        returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'GET_MESSAGE_BY_CONCOURS_FAIL'),
      );
      dispatch({ type: FETCH_READ_MESSAGES_BY_CONCOURS_ERROR });
    });
};

export const readMessageByParticipations = (cbSuccess = () => {}, cbError = () => {}) => (dispatch, getState) => {
  // dispatch(clearErrors());
  dispatch({ type: FETCH_READ_MESSAGES_BY_PARTICIPATIONS });

  axios.get(`${apiKey}/message/read/byParticipation`, tokenConfig(getState))
    .then((res) => {
      cbSuccess(res.data.results.concours);
      dispatch({ type: FETCH_READ_MESSAGES_BY_PARTICIPATIONS_FULFILLED, payload: res.data.results.concours });
    })
    .catch((err) => {
      cbError(err.response?.data?.message?.message);
      dispatch(
        returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'GET_MESSAGE_BY_PARTICIPATIONS_FAIL'),
      );
      dispatch({ type: FETCH_READ_MESSAGES_BY_PARTICIPATIONS_ERROR });
    });
};

export const readMessageByIdParticipation = (idParticipation, cbSuccess = () => {}, cbError = () => {}) => (dispatch, getState) => {
  // dispatch(clearErrors());
  dispatch({ type: FETCH_READ_MESSAGES_BY_ID_PARTICIPATION });

  axios.get(`${apiKey}/message/readByParticipant/${idParticipation}`, tokenConfig(getState))
    .then((res) => {
      cbSuccess(res.data.results.participation);
      dispatch({ type: FETCH_READ_MESSAGES_BY_ID_PARTICIPATION_FULFILLED, payload: res.data.results.participation });
    })
    .catch((err) => {
      cbError(err.response?.data?.message?.message);
      dispatch(
        returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'GET_MESSAGE_BY_ID_PARTICIPATION_FAIL'),
      );
      dispatch({ type: FETCH_READ_MESSAGES_BY_ID_PARTICIPATION_ERROR });
    });
};

export const addFileMessage = (idParticipation, file, cbSuccess = () => {}, cbError = () => {}) => (dispatch, getState) => {
  // dispatch(clearErrors());
  dispatch({ type: FETCH_ADD_FILE_MESSAGE });

  const body = new FormData();
  body.append('fileMessage', file);

  axios.post(`${apiKey}/message/add/file/${idParticipation}`, body, tokenConfig(getState))
    .then((res) => {
      cbSuccess(res.data);
      dispatch(
        returnSuccess(res.data?.message, 'ADD_FILE_MESSAGE'),
      );
      dispatch({ type: FETCH_ADD_FILE_MESSAGE_FULFILLED, payload: res.data });
    })
    .catch((err) => {
      cbError(err.response?.data?.message?.message);
      dispatch(
        returnErrors(err.response?.data?.message?.message, err.response?.data?.message?.code, 'ADD_FILE_MESSAGE_FAIL'),
      );
      dispatch({ type: FETCH_ADD_FILE_MESSAGE_ERROR });
    });
};
