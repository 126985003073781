import React from 'react';

import cx from 'classnames';
import Proptypes from 'prop-types';

import {
  FormControl, InputLabel, MenuItem, TextField, Select,
} from '@material-ui/core';
import SelectCountry from '../../../../_atoms/SelectCountry/select-country';

import withCoord from '../statut.style';

const Coord = ({
  classes,
  infoPerso,
  infoCompany,
  isAddressPerso,
  isAddressCompany,
  needFormJuri,
  firstName,
  onChangeFirstName,
  lastName,
  onChangeLastName,
  nationality,
  onChangeNationality,
  address,
  onChangeAddress,
  zip,
  onChangeZip,
  country,
  onChangeCountry,
  city,
  onChangeCity,
  socialReason,
  onChangeSocialReason,
  legalStatus,
  onChangeLegalStatus,
  countryCompany,
  onChangeCountryCompany,
  addressCompany,
  onChangeAddressCompany,
  zipCompany,
  onChangeZipCompany,
  cityCompany,
  onChangeCityCompany,
}) => (
  <>
    {
      infoPerso && (
        <>
          <TextField name="firstName" className={cx(classes.textField, classes.width30)} value={firstName} label="Prénom" onChange={onChangeFirstName} />
          <TextField name="lastName" className={cx(classes.textField, classes.width30)} value={lastName} label="Nom" onChange={onChangeLastName} />
          <SelectCountry classNameRoot={cx(classes.textField, classes.formControl, classes.width30)} name="nationality" value={nationality} label="Nationalite" onChange={onChangeNationality} />
        </>
      )
    }
    {
      isAddressPerso && (
        <>
          <div className={classes.twoColumns}>
            <TextField name="address" className={classes.textField} value={address} label="Adresse" onChange={onChangeAddress} />
            <TextField name="zip" className={classes.textField} value={zip} label="Code postal" onChange={onChangeZip} />
          </div>
          <div className={cx(classes.twoColumns, classes.twoColumnsPart2)}>
            <SelectCountry classNameRoot={cx(classes.textField, classes.formControl)} name="country" value={country} label="Pays" onChange={onChangeCountry} />
            <TextField name="city" className={classes.textField} value={city} label="Ville" onChange={onChangeCity} />
          </div>
        </>
      )
    }
    {
      infoCompany && (
        <>
          <TextField className={cx(classes.textField, classes.biggerTextField, !needFormJuri && classes.fullWidthTextField)} name="socialReason" value={socialReason} label="Dénomination" onChange={onChangeSocialReason} />
            {
            needFormJuri && (
              <FormControl className={cx(classes.textField, classes.formControl, classes.biggerTextField)}>
                <InputLabel id="label-forme-juridique">Forme juridique</InputLabel>
                <Select
                  labelId="legalStatus"
                  id="forme-juridique"
                  value={legalStatus}
                  onChange={onChangeLegalStatus}
                  name="legalStatus"
                >
                  <MenuItem value={0}>EURL</MenuItem>
                  <MenuItem value={1}>SARL</MenuItem>
                  <MenuItem value={2}>SA</MenuItem>
                  <MenuItem value={3}>SAS</MenuItem>
                  <MenuItem value={4}>SASU</MenuItem>
                  <MenuItem value={5}>SNC</MenuItem>
                </Select>
              </FormControl>
            )
          }
        </>
      )
    }
    {
      isAddressCompany && (
        <>
          <div className={classes.twoColumns}>
            <TextField name="addressCompany" className={classes.textField} value={addressCompany} label="Adresse" onChange={onChangeAddressCompany} />
            <TextField name="zipCompany" className={classes.textField} value={zipCompany} label="Code postal" onChange={onChangeZipCompany} />
          </div>
          <div className={cx(classes.twoColumns, classes.twoColumnsPart2)}>
            <SelectCountry classNameRoot={cx(classes.textField, classes.formControl)} name="countryCompany" value={countryCompany} label="Pays" onChange={onChangeCountryCompany} />
            <TextField name="cityCompany" className={classes.textField} value={cityCompany} label="Ville" onChange={onChangeCityCompany} />
          </div>
        </>
      )
    }
  </>
);

Coord.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  infoPerso: Proptypes.bool,
  infoCompany: Proptypes.bool,
  isAddressPerso: Proptypes.bool,
  isAddressCompany: Proptypes.bool,
  needFormJuri: Proptypes.bool,
  firstName: Proptypes.string,
  onChangeFirstName: Proptypes.func,
  lastName: Proptypes.string,
  onChangeLastName: Proptypes.func,
  nationality: Proptypes.string,
  onChangeNationality: Proptypes.func,
  address: Proptypes.string,
  onChangeAddress: Proptypes.func,
  city: Proptypes.string,
  onChangeCity: Proptypes.func,
  country: Proptypes.string,
  onChangeCountry: Proptypes.func,
  zip: Proptypes.string,
  onChangeZip: Proptypes.func,
  socialReason: Proptypes.string,
  onChangeSocialReason: Proptypes.func,
  legalStatus: Proptypes.oneOfType([
    Proptypes.number,
    Proptypes.string,
  ]),
  onChangeLegalStatus: Proptypes.func,
  countryCompany: Proptypes.string,
  onChangeCountryCompany: Proptypes.func,
  addressCompany: Proptypes.string,
  onChangeAddressCompany: Proptypes.func,
  zipCompany: Proptypes.string,
  onChangeZipCompany: Proptypes.func,
  cityCompany: Proptypes.string,
  onChangeCityCompany: Proptypes.func,
};

Coord.defaultProps = {
  infoPerso: false,
  infoCompany: false,
  isAddressPerso: false,
  isAddressCompany: false,
  needFormJuri: true,
};

export default withCoord(Coord);
