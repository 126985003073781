import { withStyles } from '@material-ui/styles';

export default withStyles(({ palette }) => ({
  root: {

  },
  title: {
    fontWeight: 'bold',
    color: palette.grey[800],
    textAlign: 'center',
    margin: '2rem 0',
  },
  wrapperPanels: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  pannel: {
    width: '45vw',
    margin: '1rem 2vw',
  },
  pannelExpanded: {
    margin: '1rem 2vw !important',
  },
  '@global': {
    '.MuiExpansionPanel-root.Mui-expanded': {
      margin: '1rem 2vw',
    },
    '.MuiExpansionPanel-root.Mui-expanded:first-child': {
      margin: '1rem 2vw',
    },
  },
}));
