import { withStyles } from '@material-ui/styles';

export default withStyles(({ palette }) => ({
  root: {
    width: '30rem',
  },
  title: {
    fontSize: '2rem',
    color: palette.grey[800],
    textAlign: 'center',
    fontWeight: 'bold',
  },
  details: {
    padding: '2rem',
  },
  subTitle: {
    color: palette.grey[800],
    marginBottom: '2rem',
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '1.2rem',
  },
  detailContent: {
    color: palette.grey[500],
  },
  wrapperBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '3rem',
  },
  btnParticiper: {
    transition: '0.2s opacity ease-in-out',
    '&:hover': {
      opacity: 0.8,
    },
  },
  btnMailIcon: {
    marginLeft: '3rem',
    transition: '0.2s opacity ease-in-out',
    background: palette.gradient.main,
    '&:hover': {
      background: palette.gradient.main,
      opacity: 0.8,
    },
    color: palette.common.white,
  },
}));
