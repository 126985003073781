import { connect } from 'react-redux';
import WrapperPageInfo from './page-info-success';

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(
  mapStateToProps,
  null,
)(WrapperPageInfo);
