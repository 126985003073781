import React from 'react';
import Proptypes from 'prop-types';
import cx from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';

import './style.css';

class TextareaForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      value: props.value || '',
      error: props.error || '',
      label: props.label || '',
    };
    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(event) {
    const { value } = event.target;
    this.setState({ value, error: '' });
  }

  render() {
    const {
      className,
      classNameArea,
      id,
      name,
      refTextarea,
      type,
      locked,
      width,
      maxHeight,
      readOnly,
      noMinHeight,
      placeholder,
      noOnChange,
      onChange,
      disabled,
      ...rest
    } = this.props;

    const {
      active, value, error, label,
    } = this.state;


    const fieldClassName = `fieldArea ${(noMinHeight && 'noMinHeight')} ${(locked ? active : active || value)
    && 'active'} ${(label && 'labelActive')} ${locked && !active && 'locked'}`;

    return (
      <div
        className={cx(fieldClassName, className)}
        style={{
          width,
        }}
      >
        <TextareaAutosize
          id={id}
          name={name}
          value={value}
          style={{
            maxHeight,
            overFlow: 'scroll',
          }}
          placeholder={placeholder || label}
          onChange={noOnChange ? this.changeValue : onChange}
          onFocus={() => !locked && this.setState({ active: true })}
          onBlur={() => !locked && this.setState({ active: false })}
          className={classNameArea}
          readOnly={readOnly}
          inputRef={refTextarea}
          disabled={disabled}
          {...rest}
        />
        <label htmlFor={1} className={error && 'error'}>
          {error || label}
        </label>
      </div>
    );
  }
}

TextareaForm.propTypes = {
  className: Proptypes.string,
  classNameArea: Proptypes.string,
  id: Proptypes.oneOfType([
    Proptypes.string, Proptypes.number,
  ]),
  name: Proptypes.string,
  label: Proptypes.string,
  width: Proptypes.string,
  maxHeight: Proptypes.string,
  refTextarea: Proptypes.func,
  value: Proptypes.string,
  error: Proptypes.string,
  locked: Proptypes.bool,
  type: Proptypes.string,
  readOnly: Proptypes.bool,
  noMinHeight: Proptypes.bool,
  disabled: Proptypes.bool,
  noOnChange: Proptypes.bool,
  placeholder: Proptypes.string,
  onChange: Proptypes.func,
};

TextareaForm.defaultProps = {
  locked: false,
  width: '20rem',
  maxHeight: '75vh',
  label: '',
  readOnly: false,
  noMinHeight: false,
  noOnChange: true,
  disabled: false,
  onChange: () => {},
};


export default TextareaForm;
