import React from 'react';
import Proptypes from 'prop-types';

import { Typography } from '@material-ui/core';
import { organisateurFAQ, participantFAQ } from '../../../constants/faq';
import withFAQ from './faq.style';
import ExpandPannelText from '../../_molecules/ExpandPanelText/expand-pannel-text';
import Header from '../../_organisms/Header/header.connect';

const FAQ = ({ classes }) => {
  const [openConnexion, setOpenConnexion] = React.useState(false);
  const handleOpenConnexion = () => {
    setOpenConnexion(true);
  };
  const handleCloseConnexion = () => {
    setOpenConnexion(false);
  };

  return (
    <div>
      <Header title="FAQ" onClose={handleCloseConnexion} open={openConnexion} onConnexion={handleOpenConnexion} />
      <Typography variant="h2" className={classes.title}>Je suis un organisateur</Typography>
      <div className={classes.wrapperPanels}>
        {
          organisateurFAQ.map((item) => (
            <ExpandPannelText key={`organisateurFAQ${item.id}`} title={item.title} details={item.details} className={classes.pannel} classesRoot={{ expanded: classes.pannelExpanded }} />
          ))
        }
      </div>
      <Typography variant="h2" className={classes.title}>Je suis un participant</Typography>
      <div className={classes.wrapperPanels}>
        {
          participantFAQ.map((item) => (
            <ExpandPannelText key={`participantFAQ${item.id}`} title={item.title} details={item.details} className={classes.pannel} classesRoot={{ expanded: classes.pannelExpanded }} />
          ))
        }
      </div>
    </div>
  );
};

FAQ.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
};

export default withFAQ(FAQ);
