import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => queryString.parse(search));
}

export default useQuery;
