import { connect } from 'react-redux';
import {
  getConcoursWithFilter,
} from '../../../actions/concours';
import Home from './home';
import { finishTutorial } from '../../../actions/user';

const mapStateToProps = ({ user, concours }) => ({
  user: user.user,
  isAuthenticated: user.isAuthenticated,
  concours: concours.concours,
});

export default connect(
  mapStateToProps,
  { getConcoursWithFilter, finishTutorial },
)(Home);
