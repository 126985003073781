import React from 'react';
import Proptypes from 'prop-types';
import cx from 'classnames';

import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { APropos, Categories } from '../../../constants/footer';
// import shapeFooter from '../../../assets/Fond/footer-top-shape.png';

import Logo from '../../_atoms/Logo/logo';
import FormContact from '../../_molecules/FormContact/form-contact';

import withFooter from './footer.style';

const Footer = ({ classes, isAuthenticated }) => (
  <footer className={cx(classes.footer, isAuthenticated === false && window.location.pathname === '/' && classes.noMargin)}>
    {/* }
    <div className={classes.shape}>
      <img
        src={shapeFooter}
        alt="footer shape"
        className={classes.imgShape}
      />
    </div>
    { */}
    <div className={classes.container}>
      <div className={classes.contentFooter}>
        <Logo
          color1="#ffffff"
          color2="rgb(250, 185, 175)"
        />
        <div className={classes.wrapperContentFooter}>
          <div className={classes.footerLeft}>
            <div className={classes.contentLeft}>
              <div className={classes.categories}>
                <Typography variant="h2" className={classes.title} display="inline">
                  Catégories
                </Typography>
                <ul className={classes.ulList}>
                  { Categories.map(({ title, key, to }) => (
                    <li className={classes.item} key={key}>
                      {
                        isAuthenticated ? (
                          <Link to={`/concours/${to}`} className={classes.link}>
                            {title}
                          </Link>
                        ) : <>{title}</>
                      }
                    </li>

                  ))}
                </ul>
              </div>
              <div className={classes.aPropos}>
                <Typography variant="h2" className={classes.title} display="inline">
                  A Propos
                </Typography>
                <ul className={classes.ulList}>
                  { APropos.map(({ title, key, to, externalLink }) => (
                    <li className={classes.item} key={key}>
                      {
                        externalLink ? (
                          <a href={to} className={classes.link}>{title}</a>
                        ) : (
                          <Link to={to} className={classes.link}>
                            {title}
                          </Link>
                        )
                      }
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className={classes.footerRight}>
            <Typography variant="h2" className={cx(classes.title, classes.titleContact)} display="inline">
              Contactez-nous
            </Typography>
            <FormContact className={classes.formContact} />
          </div>
        </div>
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  isAuthenticated: Proptypes.bool,
};

export default withFooter(Footer);
