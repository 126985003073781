import { withStyles } from '@material-ui/core';

export default withStyles(({ palette }) => ({
  root: {
    width: '23vw',
    maxWidth: '40rem',
    minWidth: '25rem',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: '0 0 14px 0 rgba(0, 0, 0, 0.12)',
    borderRadius: '2rem',
    paddingBottom: '2rem',
  },
  wrapperIcon: {
    display: 'flex',
    width: '100%',
    marginBottom: '1rem',
    justifyContent: (props) => (props.isParticipate ? 'space-between' : 'flex-end'),
  },
  checkIcon: {
    fontSize: '4rem',
    margin: '0.5rem 1rem',
    color: '#41a025',
  },
  icon: {
    fontSize: '4rem',
    margin: '1rem 2rem 0 0',
    alignSelf: 'flex-end',
  },
  wrapperCategoryTime: {
    marginTop: '-1rem',
  },
  category: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    color: palette.grey[800],
    textAlign: 'center',
  },
  dateCreation: {
    textAlign: 'center',
    fontWeight: 'bold',
    color: palette.grey[400],
  },
  message: {
    color: palette.grey[600],
    fontSize: '1.2rem',
    textAlign: 'center',
    fontWeight: 'bold',
    padding: '2rem',
  },
  titleGain: {
    fontWeight: 'bold',
    fontSize: '1.4rem',
    color: palette.grey[800],
  },
  valueGain: {
    color: palette.secondary.main,
  },
  btnInformation: {
    margin: '2rem 0',
    transition: '0.2s opacity ease-in-out',
    '&:hover': {
      opacity: 0.8,
    },
  },
  moreDetails: {
    width: '90%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  end: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    color: palette.grey[800],
  },
  numberProposition: {
    color: palette.grey[500],
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
}));
