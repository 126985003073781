import React, { useEffect } from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';
import {
  Avatar, FormControlLabel, List, ListItem, ListItemAvatar, Paper, Switch, Typography,
} from '@material-ui/core';

import Header from '../../../_organisms/Header/header.connect';
import WrapperPageUser from '../../../../containers/WrapperPageUser/wrapper-page-user.connect';

import { parseTextMessage, parseDateSQLToHour } from '../../../../helpers/parse';
import LinearProgressPerso from '../../../_atoms/LinearProgress/linearProgress';
import { imgAvatar } from '../../../../constants/image';
import { concoursInProgress } from '../../../../helpers/concours';
import PaperInformations from '../../../_organisms/PaperInformations/paper-informations';
import ToggleButtons from '../../../_molecules/ToggleButtons/toggle-buttons';

import withMessageFirstStep from './message-frist-step.style';

const MessageFirstStep = ({
  classes, history, readMessageByConcours, readMessageByParticipations,
}) => {
  const [concoursMessages, setConcoursMessages] = React.useState(null);
  const [participationsMessages, setParticipationsMessages] = React.useState(null);
  const [allMessages, setAllMessages] = React.useState(null);
  const [messagesLocal, setMessagesLocal] = React.useState(null);
  const [activeTab, setActiveTab] = React.useState(0);
  const [onlyInProgress, setOnlyInProgress] = React.useState(true);

  useEffect(() => {
    readMessageByConcours((data1) => {
      setConcoursMessages(data1);
      console.log(data1, 'concours');
      readMessageByParticipations((data2) => {
        setParticipationsMessages(data2);
        const all = data1.concat(data2);
        setAllMessages(all);
        setMessagesLocal(all);
        console.log(data2, 'participations');
        console.log(all, 'local');
      });
    });
  }, [readMessageByParticipations, readMessageByConcours]);

  const handleSubmit = (id) => (e) => {
    history.push({
      pathname: `/messages/${id}`,
    });
  };

  const handleClickToogle = (id) => (event) => {
    console.log(concoursMessages);
    console.log(participationsMessages);
    console.log(messagesLocal);
    if (id === 0) {
      setActiveTab(0);
      setMessagesLocal(allMessages);
    } else if (id === 1) {
      setActiveTab(1);
      setMessagesLocal(concoursMessages);
    } else {
      setActiveTab(2);
      setMessagesLocal(participationsMessages);
    }
  };

  const handleChangeSwitch = (e) => {
    setOnlyInProgress(e.target.checked);
  };

  return (
    <>
      <Header title="Messages" history={history} />
      <WrapperPageUser>
        {
          messagesLocal ? (
            <Paper className={classes.root}>
              <List className={classes.list}>
                <li style={{ width: '100%' }}>
                  <div className={classes.wrapperToggle}>
                    <ToggleButtons style={{ height: '3rem' }} onClick={handleClickToogle} activeTab={activeTab} className={classes.toggleButtons} />
                    <FormControlLabel
                      control={<Switch checked={onlyInProgress} onChange={handleChangeSwitch} color="secondary" />}
                      label="Seulement les concours en cours"
                      classes={{ label: classes.labelSwitch }}
                      style={{ height: '3rem' }}
                      className={classes.switch}
                    />
                  </div>
                </li>
                {
                  messagesLocal.length > 0 ? messagesLocal.filter((element) => !onlyInProgress || concoursInProgress(element.status)).map((concours) => (
                    <ListItem className={cx(classes.listItem, classes.rootListItem)} key={concours.id}>
                      <Typography variant="h3" className={classes.titleText}>{`Demande de ${concours.subCategory.name}`}</Typography>
                      {
                        concours.participations.map((participation) => (
                          <List className={classes.list} key={participation.id}>
                            <ListItem button className={classes.listItem} onClick={handleSubmit(participation.id)}>
                              <ListItemAvatar>
                                <Avatar>
                                  <img
                                    src={imgAvatar(concours.subCategory.format, participation)}
                                    alt="avatar"
                                    className={classes.avatarImg}
                                  />
                                </Avatar>
                              </ListItemAvatar>
                              <div className={classes.wrapperInfo}>
                                <div className={classes.wrapperTextLeft}>
                                  <Typography variant="body1" className={cx(classes.titleText, classes.companyNameText)}>
                                    {concours.companyName}
                                  </Typography>
                                  <Typography variant="body1" className={classes.text}>
                                    {parseTextMessage(participation.messages[0].message)} {/* Les Messages sont renvoyés de façon décroissante */}
                                  </Typography>
                                </div>
                                <div>
                                  <Typography variant="body2" className={classes.textRight}>{parseDateSQLToHour(participation.messages[participation.messages.length - 1].createdAt)}</Typography>
                                </div>
                              </div>
                            </ListItem>
                          </List>
                        ))
                      }
                    </ListItem>
                  )) : (
                    <PaperInformations text="Aucun messages" />
                  )
                }
              </List>
            </Paper>
          ) : (
            <LinearProgressPerso />
          )
        }
      </WrapperPageUser>
    </>
  );
};

MessageFirstStep.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  history: Proptypes.objectOf(
    Proptypes.oneOfType([
      Proptypes.number,
      Proptypes.func,
      Proptypes.object,
      Proptypes.string,
    ]),
  ),
  readMessageByUser: Proptypes.func,
  readMessageByConcours: Proptypes.func,
  readMessageByParticipations: Proptypes.func,
  concoursMessages: Proptypes.array,
};

export default withMessageFirstStep(MessageFirstStep);
