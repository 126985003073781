import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import { Typography } from '@material-ui/core';

import Header from '../../_organisms/Header/header.connect';
import WrapperPageUser from '../../../containers/WrapperPageUser/wrapper-page-user.connect';
import ListParticipations from '../../_organisms/ListParticipations/list-participations.connect';
import LinearProgressPerso from '../../_atoms/LinearProgress/linearProgress';
import Button from '../../_atoms/Button/button';
import Winners from '../../_organisms/Winners/winners';
import PaperInformations from '../../_organisms/PaperInformations/paper-informations';

import withParticipations from './participations.style';
import {
  statusConcoursFinish,
  statusConcoursPaid,
  statusConcoursWaitFinalFiles,
  statusConcoursWaitWinner,
} from '../../../constants/concours';

const Participations = ({
  classes,
  history,
  match,
  getConcoursById,
  getAllWinnerByConcours,
  addWinner,
  prefinalWinner,
  deleteWinner,
  concours,
  updateStars,
  reportParticipation,
}) => {
  const [concoursLocal, setConcoursLocal] = useState({});
  const [index1, setIndex1] = useState(true);
  const [index2, setIndex2] = useState(false);
  const [index3, setIndex3] = useState(false);
  const [index4, setIndex4] = useState(false);
  const [index5, setIndex5] = useState(false);
  const [participationsWinners, setParticipationWinners] = useState([]);

  const concoursFiltering = (value) => () => {
    let newConcours = concours;
    if (value === '0') {
      setIndex1(false);
      setIndex2(true);
      setIndex3(false);
      setIndex4(false);
      setIndex5(false);
      newConcours = {
        ...newConcours,
        participations: concours.participations.filter((participation) => (
          participation.stars === 0 || !participation.stars
        )),
      };
    } else if (value === '1-2') {
      setIndex1(false);
      setIndex2(false);
      setIndex3(true);
      setIndex4(false);
      setIndex5(false);
      newConcours = {
        ...newConcours,
        participations: concours.participations.filter((participation) => (
          participation.stars === 1 || participation.stars === 2
        )),
      };
    } else if (value === '3-5') {
      setIndex1(false);
      setIndex2(false);
      setIndex3(false);
      setIndex4(true);
      setIndex5(false);
      newConcours = {
        ...newConcours,
        participations: newConcours.participations.filter((participation) => (
          participation.stars === 3 || participation.stars === 4 || participation.stars === 5
        )),
      };
    } else if (value === 'gagnants') {
      setIndex1(false);
      setIndex2(false);
      setIndex3(false);
      setIndex4(false);
      setIndex5(true);
      newConcours = {
        ...newConcours,
        participations: newConcours.participations.filter((participation) => (
          participation.isWinner
        )),
      };
    } else {
      setIndex1(true);
      setIndex2(false);
      setIndex3(false);
      setIndex4(false);
      setIndex5(false);
      newConcours.participations = concours.participations;
    }
    setConcoursLocal(newConcours);
    console.log(concours, newConcours);
  };

  useEffect(() => {
    document.oncontextmenu = () => false; // Interdire le clique droit

    getConcoursById(match.params.id, (data) => {
      console.log(data, 'concoursById');
      setConcoursLocal(data);
    });
    getAllWinnerByConcours(match.params.id, ((res) => {
      if (res) {
        setParticipationWinners(res.participations);
        if (res.status === statusConcoursFinish || res.status === statusConcoursWaitFinalFiles) {
          setIndex1(false);
          setIndex5(true);
          concoursFiltering('gagnants');
        }
      } else setParticipationWinners([]);
    }));
  }, [getConcoursById, getAllWinnerByConcours, match.params.id]);

  const handleChangeStars = (participation) => (event) => {
    updateStars(participation.id, event.target.value, () => {
      const { id } = match.params;
      getConcoursById(id, (data) => {
        setConcoursLocal(data);
      });
    });
  };

  const handleClickReport = (participation) => () => {
    reportParticipation(participation.id);
  };

  const handleClickAddWinner = (participation) => {
    addWinner(participation.id, participation.idConcours, () => {
      getAllWinnerByConcours(participation.idConcours, ((res) => {
        setParticipationWinners(res.participations);
      }
      ));
    });
  };

  const handleClickDeleteWinner = (participation) => {
    deleteWinner(participation.id, participation.idConcours, () => {
      getAllWinnerByConcours(participation.idConcours, ((res) => {
        if (res) setParticipationWinners(res.participations);
        else setParticipationWinners([]);
      }
      ));
    });
  };

  const handleClickPrefinalWinners = (idConcours) => {
    prefinalWinner(idConcours, () => {
      const { id } = match.params;
      getConcoursById(id, (data) => {
        setConcoursLocal(data);
      });
    });
  };

  const textStatus = (status) => {
    if (status === statusConcoursPaid) return 'Aucune participation validée pour le moment';
    if (status === statusConcoursWaitWinner) return 'Concours terminé, vous pouvez toujours choisir un gagnant';
    if (status === statusConcoursWaitFinalFiles) return 'Vous avez déjà choisi vos gagnants';
    if (status === statusConcoursFinish) return 'Concours terminé !';
    return 'status != 2,3,4,5';
  };

  return (
    <WrapperPageUser>
      <Header title="Mes propositions" history={history} />
      <section className={classes.root}>
        <Typography variant="h2" className={classes.textStatus}>{textStatus(concoursLocal.status)}</Typography>
        {
          concoursLocal.participations ? (
            <>
              <div className={classes.filter}>
                <Button className={cx(classes.btn, index1 && classes.btnActive)} onClick={concoursFiltering('tous')}>
                  <span className={classes.textBtn}>Tous</span>
                </Button>
                <Button className={cx(classes.btn, index2 && classes.btnActive)} onClick={concoursFiltering('0')}>
                  <span className={classes.textBtn}>Non notés</span>
                </Button>
                <Button className={cx(classes.btn, index3 && classes.btnActive)} onClick={concoursFiltering('1-2')}>
                  <span className={classes.textBtn}>1-2 étoiles</span>
                </Button>
                <Button className={cx(classes.btn, index4 && classes.btnActive)} onClick={concoursFiltering('3-5')}>
                  <span className={classes.textBtn}>3-5 étoiles</span>
                </Button>
                {
                  concoursLocal.status !== statusConcoursPaid && (
                    <Button className={cx(classes.btn, index5 && classes.btnActive)} onClick={concoursFiltering('gagnants')}>
                      <span className={classes.textBtn}>Les gagnants</span>
                    </Button>
                  )
                }
              </div>
              {
                concoursLocal.participations.length > 0 ? (
                  <>
                    <div className={classes.wrapperInformations}>
                      <Typography variant="body1" className={classes.text} display="inline">
                        {`${concoursLocal.participations.length} participations`}
                      </Typography>
                    </div>
                    <div className={classes.content}>
                      <ListParticipations
                        concours={concoursLocal}
                        onChangeStars={handleChangeStars}
                        className={classes.left}
                        onClickAddWinner={handleClickAddWinner}
                        onClickReport={handleClickReport}
                      />
                      <Winners
                        className={classes.right}
                        classNameTitlePaper={classes.titlePaper}
                        format={concoursLocal.subCategory.format}
                        participations={participationsWinners}
                        status={concoursLocal.status}
                        onClickDeleteWinner={handleClickDeleteWinner}
                        onClickPrefinalWinners={handleClickPrefinalWinners}
                      />
                    </div>
                  </>
                ) : (
                  <PaperInformations text="Aucune participation pour le moment" />
                )
              }
            </>
          ) : (
            <LinearProgressPerso />
          )
        }
      </section>
    </WrapperPageUser>
  );
};

Participations.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  history: Proptypes.objectOf(
    Proptypes.oneOfType([
      Proptypes.number,
      Proptypes.func,
      Proptypes.object,
      Proptypes.string,
    ]),
  ),
  match: Proptypes.shape({
    path: Proptypes.string,
    url: Proptypes.string,
    isExact: Proptypes.bool,
    params: Proptypes.object,
  }),
  getConcoursById: Proptypes.func,
  getParticipationById: Proptypes.func,
  getAllWinnerByConcours: Proptypes.func,
  addWinner: Proptypes.func,
  deleteWinner: Proptypes.func,
  prefinalWinner: Proptypes.func,
  reportParticipation: Proptypes.func,
  updateStars: Proptypes.func,
  concours: Proptypes.object,
  messageSuccess: Proptypes.string,
};

Participations.defaultProps = {
  getConcoursById: () => {},
  reportParticipation: () => {},
  updateStars: () => {},
  getAllWinnerByConcours: () => {},
  addWinner: () => {},
  deleteWinner: () => {},
  prefinalWinner: () => {},
};

export default withParticipations(Participations);
