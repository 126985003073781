import React, { useEffect } from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import {
  Avatar, Typography, Paper, InputBase, IconButton, Divider,
} from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import SendIcon from '@material-ui/icons/Send';
import ErrorIcon from '@material-ui/icons/Error';
import Header from '../../../_organisms/Header/header.connect';
import WrapperPageUser from '../../../../containers/WrapperPageUser/wrapper-page-user.connect';
import Button from '../../../_atoms/Button/button';

import { parseAcceptedFiles, parseDateConcoursEnd, parseStatutConcours } from '../../../../helpers/parse';
import PaperInformations from '../../../_organisms/PaperInformations/paper-informations';
import { imgAvatar } from '../../../../constants/image';
import { ucFirst } from '../../../../helpers/string';
import TileMessage from '../../../_atoms/TileMessage/tile-message';

import { parseHours, parseMinutes, isSameDate } from '../../../../helpers/date';
import SnackBarBottom from '../../../_atoms/SnackBarBottom/snack-bar-bottom';
import DropzoneDialogPerso from '../../../_organisms/DropzoneDialog/dropzone-dialog';
import ToggleButtons from '../../../_molecules/ToggleButtons/toggle-buttons';
import CardFileMessage from '../../../_molecules/CardFileMessage/card-file-message';

import withMessageSecondStep from './message-second-step.style';
import {
  statusConcoursFinish,
  statusConcoursPaid,
  statusConcoursWaitFinalFiles,
  statusConcoursWaitWinner,
} from '../../../../constants/concours';
import ModalInformation from '../../../_atoms/ModalInformation/modal-information';
import ProgressPerso from '../../../_atoms/LinearProgress/linearProgress';

const MessageSecondStep = ({
  classes,
  history,
  match,
  readMessageByIdParticipation,
  createMessage,
  user,
  addFileMessage,
  chooseFinalWinner,
  addFinalFile,
}) => {
  const [activeTab, setActiveTab] = React.useState(0);
  const [messagesLocal, setMessagesLocal] = React.useState(null);
  const [fileMessagesLocal, setFileMessagesLocal] = React.useState({});
  const [isFinalFile, setIsFinalFile] = React.useState(false);
  const [messageValue, setMessageValue] = React.useState('');
  const [openDropZone, setOpenDropZone] = React.useState(false);
  const [loadingFiles, setLoadingFiles] = React.useState(false);
  const [openSnackError, setOpenSnackError] = React.useState(false);
  const [openSnackSuccess, setOpenSnackSuccess] = React.useState(false);
  const [popupValidateFinalFiles, setPopupValidateFinalFiles] = React.useState(false);
  const [messageSnack, setMessageSnack] = React.useState('');

  const today = new Date();

  useEffect(() => {
    const { params } = match;
    readMessageByIdParticipation(params.id, (data) => {
      console.log(data, 'data');
      setMessagesLocal(data);
      /* pour filtrer les files en fonction de la date
      const array = [];
      tmp.forEach((elem) => {
        const dateValue = new Date(elem.createdAt);
        let compareDate = '';
        if (dateValue.getDate() === today.getDate() && dateValue.getMonth() === today.getMonth()) {
          compareDate = "Aujourd'hui";
        } else {
          compareDate = `${dateValue.getMonth()}/${dateValue.getDate()}`;
        }
        array.push({
          date: compareDate,
          fileMessage: elem,
        });
      });
      const newFileMessages = _.groupBy(array, (fileMessage) => fileMessage.date);
      console.log(newFileMessages, 'newFileMessages');
       */
      const workFile = data.fileMessages;
      const participantFile = data.fileParticipants.filter((elem) => elem.isFinalFile !== true);
      const finalFile = data.fileParticipants.filter((elem) => elem.isFinalFile === true);

      console.log({
        workFile,
        participantFile,
        finalFile,
      });

      setFileMessagesLocal({
        workFile,
        participantFile,
        finalFile,
      });
    });
  }, [match, readMessageByIdParticipation, today.getMinutes]);

  const isConcoureur = () => messagesLocal.concour.idConcoureur === user.id;

  const isMyMessage = (idConcoureur, idUser, isConcoureurMessage) => {
    if (isConcoureurMessage) {
      if (idConcoureur === idUser) return true;
      return false;
    }
    if (idConcoureur !== idUser) return true; // C'est pas un message du concoureur et le user c'est pas le concoureur
    return false;
  };

  const textBtnStatus = (status) => {
    if (status === statusConcoursPaid) return `Fin dans ${parseDateConcoursEnd(today, messagesLocal.concour.endDate)}`;
    if (status === statusConcoursWaitWinner) return 'En attente du gagnant';
    if (status === statusConcoursWaitFinalFiles) {
      if (isConcoureur()) return 'Validation des fichiers finaux';
      return 'Envoie des fichiers finaux';
    }
    if (status === statusConcoursFinish) return 'Concours terminé';
    return 'Status != 2 & 3 & 4 & (';
  };

  const handleOpenSuccessSnackUpdateConcours = () => {
    setMessageSnack('Le concours à bien été mis à jour');
    setOpenSnackSuccess(true);
    setOpenSnackError(false);
    setOpenDropZone(false);
  };

  const handleClickStatut = () => {
    if (isConcoureur()) {
      if (messagesLocal.concour.status === statusConcoursWaitFinalFiles) { // Si le profil est en attente des fichiers finaux
        setPopupValidateFinalFiles(true);
      } else {
        history.push({
          pathname: `/participations/${messagesLocal.concour.id}`,
        });
      }
    } else if (messagesLocal.concour.status === statusConcoursWaitFinalFiles) {
      setIsFinalFile(true);
      setOpenDropZone(true);
    } else {
      history.push({
        pathname: `/details-concours/${messagesLocal.concour.id}`,
      });
    }
  };

  const validateFinalFiles = () => {
    console.log(messagesLocal);
    chooseFinalWinner(messagesLocal.concour.id, messagesLocal.messages[0].idParticipation, () => {
      handleOpenSuccessSnackUpdateConcours();
      const { params } = match;
      readMessageByIdParticipation(params.id, (data) => {
        console.log(data, 'data');
        setPopupValidateFinalFiles(false);
        setMessagesLocal(data);
      });
    }, (err) => {
      console.log(err, 'handleClickStatut');
    });
  };

  const pseudoMessage = (idUser, idParticipant, pseudoConcoureur, pseudoParticipant) => {
    if (idParticipant === idUser) return pseudoConcoureur;
    return pseudoParticipant;
  };

  const handleChangeMessage = (e) => {
    setMessageValue(e.target.value);
  };

  const dateMessage = (index, messages) => {
    if (index === 0) {
      const date = new Date(messages[index].createdAt);
      if (isSameDate(date, new Date())) return "Aujourd'hui";
      return `${date.getMonth()}/${date.getDate()}`;
    }

    const date1 = new Date(messages[index].createdAt);
    const date2 = new Date(messages[index - 1].createdAt);
    const sameDate = isSameDate(date1, date2);

    if (!sameDate) {
      if (isSameDate(date1, new Date())) return "Aujourd'hui";
      return `${date1.getMonth()}/${date1.getDate()}`;
    }

    return null;
  };

  const handleSubmitMessage = () => {
    if (messagesLocal.isWinner === false) {
      if (messagesLocal.concour.status === statusConcoursWaitFinalFiles || messagesLocal.concour.status === statusConcoursFinish) setOpenSnackError(true);
    } else if (messageValue) {
      createMessage({
        idParticipation: messagesLocal.messages[0].idParticipation,
        message: messageValue,
      }, () => {
        const { params } = match;
        setMessageValue('');
        readMessageByIdParticipation(params.id, (data) => {
          console.log(data, 'data');
          setMessagesLocal(data);
        });
      });
    }
  };

  const handleClickToogle = (id) => () => {
    console.log(messagesLocal, 'messagesLocal');
    console.log(id, 'id');
    if (id === 0) {
      setActiveTab(0);
    } else {
      setActiveTab(1);
    }
  };

  const OpenSendFile = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (messagesLocal.isWinner === false) {
      if (messagesLocal.concour.status === statusConcoursWaitFinalFiles || messagesLocal.concour.status === statusConcoursFinish) setOpenSnackError(true);
    } else {
      setMessageSnack('Vous n\'avez pas été sélectionné, vous ne pouvez pas effectuer cette action');
      setOpenDropZone(true);
    }
  };

  const handleOpenSuccessSnack = () => {
    setOpenSnackSuccess(true);
    setOpenSnackError(false);
    setOpenDropZone(false);
    setLoadingFiles(false);
  };

  const handleOpenErrorSnack = () => {
    setOpenSnackSuccess(false);
    setMessageSnack('Le fichier n\'a pas été ajouté, une erreur c\'est produite');
    setOpenSnackError(true);
    setOpenDropZone(false);
    setLoadingFiles(false);
  };

  const handleCloseSnack = () => {
    setOpenSnackError(false);
    setOpenSnackSuccess(false);
    setOpenDropZone(false);
    setLoadingFiles(false);
  };

  const handleCloseDropzone = () => {
    setOpenDropZone(false);
    setIsFinalFile(false);
  };

  const handleClosePopupFinalizeWinner = () => {
    setPopupValidateFinalFiles(false);
  };

  const localAddFile = (files, callback) => {
    const file = files[0];
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setLoadingFiles(true);
    setOpenDropZone(false);
    console.log(files);

    callback(messagesLocal.messages[0].idParticipation, file, () => {
      setMessageSnack('Le fichier à bien été ajouté');
      window.scrollTo({ top: 0, behavior: 'smooth' });
      const { params } = match;
      readMessageByIdParticipation(params.id, (data) => {
        const workFile = data.fileMessages;
        const participantFile = data.fileParticipants.filter((elem) => elem.isFinalFile !== true);
        const finalFile = data.fileParticipants.filter((elem) => elem.isFinalFile === true);

        setFileMessagesLocal({
          workFile,
          participantFile,
          finalFile,
        });
        setLoadingFiles(false);
      });
      handleOpenSuccessSnack();
    }, () => handleOpenErrorSnack());
  };

  const handleAddFile = (files) => {
    if (isFinalFile) {
      localAddFile(files, addFinalFile);
    } else {
      localAddFile(files, addFileMessage);
    }
  };

  const handleReport = () => {
    console.log('report');
  };

  return (
    <>
      {
        !!openSnackSuccess && <SnackBarBottom variant="success" open={openSnackSuccess} onClose={handleCloseSnack} text={messageSnack} />
      }
      {
        !!openSnackError && <SnackBarBottom variant="error" open={openSnackError} onClose={handleCloseSnack} text={messageSnack} />
      }
      {
        loadingFiles ? (
          <ProgressPerso linear={false} />
        ) : (
          <>
            <Header title="Messages" history={history} />
            <WrapperPageUser>
              {
                messagesLocal ? (
                  <div className={classes.root}>
                    {
                      messagesLocal.messages.length > 0 ? (
                        <>
                          <div className={classes.header}>
                            <div className={classes.aboutConcours}>
                              <Avatar>
                                <img src={imgAvatar(messagesLocal.concour.subCategory.format, messagesLocal)} alt="avatar" className={classes.avatarImg} />
                              </Avatar>
                              <div className={classes.aboutConcoursText}>
                                <Typography variant="h5" className={classes.titleConcours}>{`Demande de ${messagesLocal.concour.subCategory.name}`}</Typography>
                              </div>
                            </div>
                            <div>
                              <Typography variant="h3" className={classes.pseudo}>
                                { messagesLocal.concour.User.pseudo ? ucFirst(pseudoMessage(user.id, messagesLocal.User.id, messagesLocal.concour.User.pseudo, messagesLocal.User.pseudo)) : 'Anonyme'}
                              </Typography>
                              <Typography variant="h3" className={classes.infoConcours}>{parseStatutConcours(messagesLocal.concour.status)}</Typography>
                            </div>
                            <div className={classes.wrapperBtn}>
                              <IconButton
                                aria-label="Signaler un participant"
                                className={classes.btnIcon}
                                onClick={handleReport}
                              >
                                <ErrorIcon fontSize="large" />
                              </IconButton>
                              <Button
                                className={cx(
                                  classes.btnValider,
                                  /* isConcoureur() === false && messagesLocal.concour.status !== statusConcoursWaitFinalFiles
            && messagesLocal.concour.status !== statusConcoursWaitWinner
            && messagesLocal.concour.status !== statusConcoursPaid
            && classes.btnNoPointerEvent, */
                                )}
                                onClick={handleClickStatut}
                              >
                                <span className={classes.textBtn}>{textBtnStatus(messagesLocal.concour.status)}</span>
                              </Button>
                            </div>
                          </div>
                          <div className={cx(classes.content, activeTab === 1 && classes.wrapperFiles)}>
                            <ToggleButtons
                              onClick={handleClickToogle}
                              activeTab={activeTab}
                              className={classes.toggleButtons}
                              tabs={[{
                                key: 0,
                                label: 'Messages',
                              },
                              {
                                key: 1,
                                label: 'Fichiers',
                              },
                              ]}
                            />
                            {
                              activeTab === 0 ? (
                                <>
                                  <div className={classes.wrapperMessages}>
                                    <div className={classes.messages}>
                                      {
                                        messagesLocal.messages.map((element, index) => (
                                          <React.Fragment key={element.id}>
                                            {
                                              dateMessage(index, messagesLocal.messages) && (
                                                <div className={classes.wrapperDate}>
                                                  <Typography variant="body2" className={classes.date}>{dateMessage(index, messagesLocal.messages)}</Typography>
                                                </div>
                                              )
                                            }
                                            <TileMessage
                                              text={element.message}
                                              myMessage={isMyMessage(messagesLocal.concour.idConcoureur, user.id, element.isConcoureurMessage)}
                                              className={classes.rootTileMessage}
                                              date={`${parseHours(new Date(element.createdAt).getHours())}:${parseMinutes(new Date(element.createdAt).getMinutes())}`}
                                              typeMessage={element.type}
                                            />
                                          </React.Fragment>
                                        ))
                                      }
                                    </div>
                                    <div className={classes.wrapperInputMessage}>
                                      <Paper component="form" className={classes.paperMessage} square={false}>
                                        <InputBase
                                          id="search"
                                          name="search"
                                          className={classes.inputMessage}
                                          placeholder="Ecrivez un message..."
                                          onChange={handleChangeMessage}
                                          value={messageValue}
                                          classes={{ input: classes.inputMessageRoot }}
                                          inputProps={{ 'aria-label': 'input for send a message' }}
                                          type="text"
                                          autoComplete="off"
                                        />
                                        <IconButton aria-label="upload a file" onClick={OpenSendFile}>
                                          <AttachFileIcon fontSize="large" />
                                        </IconButton>
                                        <IconButton aria-label="send message" onClick={handleSubmitMessage} color="secondary">
                                          <SendIcon fontSize="large" />
                                        </IconButton>
                                      </Paper>
                                    </div>
                                    <DropzoneDialogPerso
                                      open={openDropZone}
                                      onSave={handleAddFile}
                                      onClose={handleCloseDropzone}
                                      acceptedFiles={[parseAcceptedFiles(messagesLocal.concour.subCategory.format, messagesLocal.concour.status === 4 && isFinalFile)]}
                                    />
                                    <ModalInformation
                                      open={popupValidateFinalFiles}
                                      onClose={handleClosePopupFinalizeWinner}
                                      title="Êtes-vous sûr de vouloir valider les fichiers finaux ?"
                                      details="Cela aura pour effet de cloturer le concours, le talent recevra alors la mise de départ !"
                                      onClickSubmit={validateFinalFiles}
                                    />
                                  </div>
                                </>
                              ) : (
                                <>
                                  {
                                    messagesLocal && (
                                      <div>
                                        <div className={classes.wrapperTitleFile}>
                                          <Divider classes={{ root: classes.divider }} />
                                          <Typography variant="body2" className={classes.titleFile}>Fichiers de participation</Typography>
                                          <Divider classes={{ root: classes.divider }} />
                                        </div>
                                        <div className={classes.containerFiles}>
                                          {
                                            fileMessagesLocal.participantFile && fileMessagesLocal.participantFile.map((file) => (
                                              <React.Fragment key={file.id}>
                                                <CardFileMessage
                                                  background={`${file.filepath}`}
                                                  format={messagesLocal.concour.subCategory.format}
                                                  className={classes.wrapperCardMessage}
                                                  fileName={file.filename}
                                                  isWinner={messagesLocal.isWinner}
                                                  status={messagesLocal.concour.status}
                                                />
                                              </React.Fragment>
                                            ))
                                          }
                                        </div>
                                        <div className={classes.wrapperTitleFile} style={{ marginTop: '5rem' }}>
                                          <Divider classes={{ root: classes.divider }} />
                                          <Typography variant="body2" className={classes.titleFile}>Fichiers de travaux</Typography>
                                          <Divider classes={{ root: classes.divider }} />
                                        </div>
                                        <div className={classes.containerFiles}>
                                          {
                                            fileMessagesLocal.workFile && fileMessagesLocal.workFile.map((file) => (
                                              <React.Fragment key={file.id}>
                                                <CardFileMessage
                                                  background={`${file.filepath}`}
                                                  format={messagesLocal.concour.subCategory.format}
                                                  className={classes.wrapperCardMessage}
                                                  fileName={file.filename}
                                                  isWinner={messagesLocal.isWinner}
                                                  status={messagesLocal.concour.status}
                                                />
                                              </React.Fragment>
                                            ))
                                          }
                                        </div>
                                        <div className={classes.wrapperTitleFile} style={{ marginTop: '5rem' }}>
                                          <Divider classes={{ root: classes.divider }} />
                                          <Typography variant="body2" className={classes.titleFile}>Fichiers finaux</Typography>
                                          <Divider classes={{ root: classes.divider }} />
                                        </div>
                                        <div className={classes.containerFiles}>
                                          {
                                            fileMessagesLocal.finalFile && fileMessagesLocal.finalFile.map((file) => (
                                              <React.Fragment key={file.id}>
                                                <CardFileMessage
                                                  background={`${file.filepath}`}
                                                  isFinalFile
                                                  format={messagesLocal.concour.subCategory.format}
                                                  className={classes.wrapperCardMessage}
                                                  fileName={file.filename}
                                                  isWinner={messagesLocal.isWinner}
                                                  status={messagesLocal.concour.status}
                                                />
                                              </React.Fragment>
                                            ))
                                          }
                                        </div>
                                        { /*
              Object.entries(fileMessagesLocal).map((elem) => (
                <React.Fragment key={elem[0]}>
                  <div className={classes.wrapperDate} style={{ marginBottom: 0 }}>
                    <Typography variant="body2" className={classes.date}>{elem[0]}</Typography>
                  </div>
                  <div style={{ display: 'flex', marginBottom: '2rem', flexWrap: 'wrap' }}>
                    {
                      elem[1].map((file) => (
                        <React.Fragment key={file.id}>
                          <CardFileMessage
                            background={`${file.fileMessage.filepath}`}
                            format={messagesLocal.concour.subCategory.format}
                            className={classes.wrapperCardMessage}
                            fileName={file.fileMessage.filename}
                            isWinner={messagesLocal.isWinner}
                            status={messagesLocal.concour.status}
                          />
                        </React.Fragment>
                      ))
                    }
                  </div>
                </React.Fragment>
              ))
            */}
                                      </div>
                                    )
                                  }
                                </>
                              )
                            }
                          </div>
                        </>
                      ) : (
                        <PaperInformations text="Pas de messages" />
                      )
                    }
                  </div>
                ) : (
                  <ProgressPerso />
                )
              }
            </WrapperPageUser>
          </>
        )
      }
    </>
  );
};

MessageSecondStep.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  history: Proptypes.objectOf(
    Proptypes.oneOfType([
      Proptypes.number,
      Proptypes.func,
      Proptypes.object,
      Proptypes.string,
    ]),
  ),
  match: Proptypes.shape({
    path: Proptypes.string,
    url: Proptypes.string,
    isExact: Proptypes.bool,
    params: Proptypes.object,
  }),
  createMessage: Proptypes.func,
  chooseFinalWinner: Proptypes.func,
  readMessageByIdParticipation: Proptypes.func,
  user: Proptypes.object,
  messages: Proptypes.object,
  addFileMessage: Proptypes.func,
  addFinalFile: Proptypes.func,
};

export default withMessageSecondStep(MessageSecondStep);
