import React from 'react';
import Proptypes from 'prop-types';
import { Slider, withStyles } from '@material-ui/core';

const SliderConStyles = withStyles(({ palette }) => ({
  root: {
    color: palette.secondary.main,
    height: 8,
  },
  thumb: {
    height: '4rem',
    width: '4rem',
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -14,
    marginLeft: -20,
    '&:focus,&:hover,&$active': {
      boxShadow: '#ccc 0px 2px 3px 1px',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 2rem)',
    top: '1rem',
    '@global': {
      span: {
        background: 'none',
        color: palette.secondary.main,
        fontSize: '2.5rem',
      },
    },
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
}))(Slider);

const SliderCon = ({
  classes,
  className,
  marks,
  defaultValue,
  min,
  max,
  onChange,
  name,
  value,
  ...rest
}) => (
  <SliderConStyles
    classes={classes}
    className={className}
    marks={marks}
    defaultValue={defaultValue}
    color="secondary"
    min={min}
    max={max}
    onChange={onChange}
    value={value}
    name={name}
    valueLabelDisplay="on"
    {...rest}
  />
);

SliderCon.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ),
  className: Proptypes.string,
  marks: Proptypes.arrayOf(Proptypes.object),
  defaultValue: Proptypes.oneOfType([
    Proptypes.string,
    Proptypes.number,
  ]),
  name: Proptypes.string,
  min: Proptypes.number,
  max: Proptypes.number,
  onChange: Proptypes.func,
  value: Proptypes.oneOfType([
    Proptypes.string,
    Proptypes.number,
  ]),
};

SliderCon.defaultProps = {
  marks: [
    {
      value: 0,
    },
    {
      value: 1,
    },
    {
      value: 2,
    },
    {
      value: 3,
    },
    {
      value: 4,
    },
    {
      value: 5,
    },
  ],
  defaultValue: 2,
  min: 0,
  onChange: () => {},
  value: 2,
  max: 5,
};

export default SliderCon;
