import { withStyles } from '@material-ui/styles';

export default withStyles(({ palette }) => ({
  root: {
    width: '20rem',
    height: '8rem',
  },
  wrapperLogo: {
    paddingTop: '1rem',
    width: '100%',
    height: '100%',
    border: 'none',
    transition: '0.2s opacity ease-in-out',
    background: 'none',
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  logo: {
    height: '3rem',
    backgroundImage: (props) => `url(${props.icon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
    backgroundSize: 'contain',
  },
  text: {
    fontWeight: 'bold',
    color: palette.grey[800],
    fontSize: '1.6rem',
    textAlign: 'center',
  },
}));
