import { withStyles } from '@material-ui/styles';

export default withStyles(({ palette, breakpoints }) => ({
  root: {
    boxShadow: (props) => props.boxShadow && '0 11px 55px 10px rgba(0, 0, 0, 0.07), 0 13px 18px -8px rgba(0, 0, 0, 0.15)',
  },
  textAlign: {
    textAlign: 'center',
  },
  bigScreen: {
    [breakpoints.down(800)]: {
      display: 'none',
    },
    [breakpoints.up(800)]: {
      display: 'block',
      overflow: 'hidden',
    },
  },
  smallScreen: {
    width: '90%',
    padding: '2rem',
    margin: '2rem auto',
    [breakpoints.down(800)]: {
      display: 'block',
    },
    [breakpoints.up(800)]: {
      display: 'none',
    },
  },
  smallScreen_title: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: palette.grey[800],
  },
  smallScreen_wrapperInformations: {
    marginTop: '2rem',
  },
  smallScreen_wrapperRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
  smallScreen_text: {
    fontSize: '1.2rem',
  },
  table: {

  },
  tableHead: {

  },
  row: {
    '&:first-child': {
      textAlign: 'left !important',
    },
  },
  cellHead: {
    '&:first-child': {
      textAlign: (props) => !props.boxShadow && 'left !important',
    },
    fontWeight: 'bold',
    color: palette.grey[800],
    fontSize: '1rem',
    paddingRight: '0.2rem',
    paddingLeft: '0.2rem',
    textAlign: 'center',
    borderBottom: 'none',
  },
  cellBody: {
    '&:first-child': {
      textAlign: (props) => !props.boxShadow && 'left !important',
      paddingLeft: (props) => !props.boxShadow && 0,
      paddingRight: (props) => !props.boxShadow && 0,
    },
    textAlign: 'center',
    paddingRight: '1rem',
    paddingLeft: '1rem',
    color: palette.grey[800],
    fontWeight: 500,
    fontSize: '1rem',
    borderBottom: 'none',
    borderRight: '2px solid rgba(191, 191, 191, 0.2)',
  },
  icon: {
    transition: '0.2s opacity ease-in-out',
    '&:hover': {
      cursor: 'pointer',
      transition: '0.2s opacity ease-in-out',
      opacity: '0.6',
    },
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2rem',
  },
}));
