import React from 'react';
import cx from 'classnames';
import ReactPlayer from 'react-player';
import Proptypes from 'prop-types';
import {
  Paper, Typography, Modal, Fade, Backdrop, IconButton,
} from '@material-ui/core';

import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import MailIcon from '@material-ui/icons/Mail';
import ErrorIcon from '@material-ui/icons/Error';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';

import TextareaForm from '../TextareaForm/textarea-form';
import ModalMessageToParticipant from '../ModalMessageToParticipant/modal-message-to-participant';
import ModalInformation from '../ModalInformation/modal-information';
import { formatImage, formatTexte } from '../../../constants/formatCategory';

import withCardParticipant from './card-participant.style';

import { imgAvatar } from '../../../constants/image';
import useWindowDimensions from '../../../containers/useWindowDimensions/use-window-dimensions';
import { statusConcoursPaid } from '../../../constants/concours';

const CardParticipant = React.forwardRef(({
  classes,
  className,
  format,
  text,
  background,
  onClickSubmitMessage,
  onClickReport,
  onClickAddWinner,
  statusConcours,
  isWinner,
  allowMouseOver,
  allowOnlyZooomImage,
  limitText,
}, ref) => {
  const [zoomImage, setZoomImage] = React.useState(false);
  const [mouseOver, setMouseOver] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState(false);
  const [popupReport, setpopupReport] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const { width } = useWindowDimensions();

  const handleZoomImage = () => {
    setZoomImage(true);
    setMouseOver(false);
  };

  const handleCloseZoomImage = () => {
    setMouseOver(false);
    setZoomImage(false);
  };

  const handleMouseOver = () => {
    if (!zoomImage && !popupMessage && !popupReport && allowMouseOver) {
      setMouseOver(true);
    }
  };

  const handleMouseLeave = () => {
    setMouseOver(false);
  };

  const handlePopupMessage = () => {
    setMouseOver(false);
    setPopupMessage(true);
  };

  const handleClosePopupMessage = () => {
    setMouseOver(false);
    setPopupMessage(false);
  };

  const handleReport = () => {
    setMouseOver(false);
    setpopupReport(true);
  };

  const handleCloseReport = () => {
    setMouseOver(false);
    setpopupReport(false);
  };

  const handleClickReport = () => {
    onClickReport();
    handleCloseReport();
  };

  const handleChangeMessage = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmitMessage = (e) => {
    onClickSubmitMessage(message, e);
    handleClosePopupMessage();
  };

  const limitTextParticipant = (value, limit = 150) => {
    if (width > 1280) return value.length <= limit ? value : `${value.substr(0, limit)}...`;
    if (width < 1280 && width > 1024) return value.length <= (limit - 30) ? value : `${value.substr(0, (limit - 30))}...`;
    if (width < 1024 && width > 800) return value.length <= (limit - 50) ? value : `${value.substr(0, (limit - 50))}...`;

    return text.length <= (limit - 100) ? value : `${value.substr(0, (limit - 100))}...`;
  };

  // format == 5 --> No picture
  return (
    // TODO: ONCLICK sur le bouton envoyer du message --> DONE
    // TODO: ONCLICK sur le bouton signaler
    // TODO: Winner  --> DONE
    <Paper
      className={cx(classes.root, className)}
      ref={ref}
    >
      <div
        className={classes.wrapper}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      >
        {
          format === formatTexte && (
          <Typography variant="body2" className={cx(mouseOver ? classes.hidden : classes.text)}>
            {limitTextParticipant(text, limitText)}
          </Typography>
          )
      }
        <>
          <Modal
            aria-labelledby="Card-participant"
            aria-describedby="Card-participant"
            className={classes.modal}
            open={zoomImage}
            onClose={handleCloseZoomImage}
            closeAfterTransition
            BackdropProps={{
              timeout: 500,
            }}
            BackdropComponent={Backdrop}
          >
            <Fade in={zoomImage}>
              <div className={classes.paper}>
                {
                  // eslint-disable-next-line no-nested-ternary
                    format === formatImage ? (
                      <img src={background} alt="participant" className={classes.imgModal} />
                    )
                      : format === formatTexte ? (
                        <TextareaForm
                          name="participation"
                          disabled
                          value={text}
                          width="75vw"
                        />
                      ) : (
                        <ReactPlayer
                          url={background}
                          className={classes.player}
                          controls
                          config={{
                            file: {
                              attributes: {
                                controlsList: 'nodownload nofullscreen disablePictureInPicture',
                                disablePictureInPicture: true,
                              },
                            },
                          }}
                        />
                      )
                  }
              </div>
            </Fade>
          </Modal>
        </>
        {
          allowMouseOver && (
            <div className={cx((mouseOver && !popupMessage && !zoomImage && !popupReport) ? classes.wrapperBtnIcon : classes.hidden)}>
              {
                allowOnlyZooomImage ? (
                  <IconButton aria-label="Agrandir l'image" className={cx(classes.btnIcon)} onClick={handleZoomImage}>
                    <ZoomOutMapIcon fontSize="large" />
                  </IconButton>
                ) : (
                  <>
                    <div style={{ marginBottom: '1rem' }}>
                      <IconButton aria-label="Agrandir l'image" className={cx(classes.btnIcon)} onClick={handleZoomImage}>
                        <ZoomOutMapIcon fontSize="large" />
                      </IconButton>
                      <IconButton
                        aria-label="Message aux participants"
                        className={cx(classes.btnIcon, classes.marginIcon, !isWinner && statusConcours !== statusConcoursPaid && classes.btnNoPointerEvent)}
                        onClick={handlePopupMessage}
                      >
                        <MailIcon fontSize="large" />
                      </IconButton>
                      <ModalMessageToParticipant
                        onClose={handleClosePopupMessage}
                        open={popupMessage}
                        imgParticipant={imgAvatar(format, null, true, background)}
                        onClickSubmit={handleSubmitMessage}
                        valueMessage={message}
                        onChangeMessage={handleChangeMessage}
                      />
                    </div>
                    <div>
                      <IconButton
                        aria-label="Validé un participant"
                        className={cx(classes.btnIcon, statusConcours !== statusConcoursPaid && classes.btnNoPointerEvent)}
                        onClick={onClickAddWinner}
                      >
                        <EmojiEventsIcon fontSize="large" />
                      </IconButton>
                      <IconButton
                        aria-label="Signaler un participant"
                        className={cx(classes.btnIcon, classes.marginIcon, statusConcours !== statusConcoursPaid && classes.btnNoPointerEvent)}
                        onClick={handleReport}
                      >
                        <ErrorIcon fontSize="large" />
                      </IconButton>
                      <ModalInformation
                        open={popupReport}
                        imgParticipant={imgAvatar(format, null, true, background)}
                        onClose={handleCloseReport}
                        title="Voulez-vous vraiment signaler cette participation ?"
                        details="Vous signalez cette participation car vous admettez que le contenu n'est pas approprié à ce concours"
                        onClickSubmit={handleClickReport}
                      />
                    </div>
                  </>
                )
              }
            </div>
          )
        }
      </div>
    </Paper>
  );
});

CardParticipant.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  className: Proptypes.string,
  format: Proptypes.oneOfType([
    Proptypes.string,
    Proptypes.number,
  ]),
  text: Proptypes.string,
  background: Proptypes.node,
  refMessage: Proptypes.func,
  onClickSubmitMessage: Proptypes.func,
  onClickReport: Proptypes.func,
  onClickAddWinner: Proptypes.func,
  statusConcours: Proptypes.number,
  allowMouseOver: Proptypes.bool,
  isWinner: Proptypes.oneOfType([
    Proptypes.bool, Proptypes.number,
  ]),
  allowOnlyZooomImage: Proptypes.bool,
  limitText: Proptypes.number,
};

CardParticipant.defaultProps = {
  onClickSubmitMessage: () => {},
  onClickReport: () => {},
  onClickAddWinner: () => {},
  allowMouseOver: true,
  statusConcours: statusConcoursPaid,
  isWinner: false,
  allowOnlyZooomImage: false,
  limitText: 150,
};

export default withCardParticipant(CardParticipant);
