import { withStyles } from '@material-ui/core';
import { widthCardCategoryConcours } from '../../../constants/size';


export default withStyles(({ palette, breakpoints }) => ({
  root: {
    width: widthCardCategoryConcours,
    [breakpoints.down('sm')]: {
      width: '30rem',
    },
    [breakpoints.down(650)]: {
      width: '43vw',
    },
  },
  informations: {
    marginTop: '1rem',
    [breakpoints.down(650)]: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      flexDirection: 'column',
    },
  },
  title: {
    display: 'inline',
    fontWeight: 'bold',
    color: palette.grey[700],
    textAlign: 'center',
    overflow: 'auto',
  },
  text: {
    fontSize: '1.2rem',
    color: palette.grey[400],
    fontWeight: 'bold',
    float: 'right',
  },
}));
