import React from 'react';
import Proptypes from 'prop-types';
import cx from 'classnames';

import { Typography } from '@material-ui/core';
import withItemWithIcon from './item-with-icon.style';

const ItemWithIcon = ({
  classes, text, classNameText, classNameRoot,
}) => (
  <div className={cx(classes.root, classNameRoot)}>
    <div>
      <svg className={classes.icon} />
    </div>
    <Typography variant="body2" className={cx(classes.text, classNameText)}>{text}</Typography>
  </div>
);

ItemWithIcon.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  text: Proptypes.string,
  icon: Proptypes.node,
  width: Proptypes.oneOfType([
    Proptypes.number,
    Proptypes.string,
  ]),
  height: Proptypes.oneOfType([
    Proptypes.number,
    Proptypes.string,
  ]),
  classNameRoot: Proptypes.string,
  classNameText: Proptypes.string,
};

export default withItemWithIcon(ItemWithIcon);
