import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import { Typography } from '@material-ui/core';
import PaperCustomize from '../../_molecules/PaperCustomize/paper-customize';
import withPaperInformations from './paper-informations.style';

const PaperInformations = ({ classes, text, className }) => (
  <div className={cx(classes.wrapperPaper, className)}>
    <PaperCustomize
      className={classes.paper}
    >
      <Typography
        variant="h2"
        className={classes.title}
      >
        {text}
      </Typography>
    </PaperCustomize>
  </div>
);

PaperInformations.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  className: Proptypes.string,
  text: Proptypes.string,
};

export default withPaperInformations(PaperInformations);
