import React from 'react';
import Proptypes from 'prop-types';

import {
  FormControl, InputLabel, MenuItem, Select,
} from '@material-ui/core';

import { countryList } from '../../../constants/country';

const SelectCountry = ({ classNameRoot, ...rest }) => (
  <FormControl className={classNameRoot}>
    <InputLabel id={`label-${rest.name}`}>{rest.label}</InputLabel>
    <Select {...rest}>
      {
            countryList.map(({ value, label }) => (
              <MenuItem key={value} value={value}>{label}</MenuItem>
            ))
    }
    </Select>
  </FormControl>
);

SelectCountry.propTypes = {
  classNameRoot: Proptypes.string,
};

SelectCountry.defaultProps = {
  classNameRoot: '',
};

export default SelectCountry;
