import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import withInfoComplementaireEnt from '../statut.style';

const InfoComplementaireEnt = ({
  classes,
  needSiret,
  siret,
  tva,
  onChangeSiret,
  onChangeTva,
  radioBeneTva,
  onChangeRadioBeneTva,
}) => (
  <>
    <div className={classes.center}>
      {
        needSiret && (
          <TextField name="siret" className={cx(classes.textField, classes.biggerTextField)} value={siret} label="N° de SIRET (si applicable)" onChange={onChangeSiret} />
        )
      }
      <TextField name="tva" className={cx(classes.textField, classes.biggerTextField, !needSiret && classes.fullWidthTextField)} value={tva} label="N° de TVA (si applicable)" onChange={onChangeTva} />
    </div>
    {
      needSiret && (
        <FormControlLabel
          control={<Checkbox classes={{ checked: classes.radioChecked }} icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} />}
          label="Bénéficiaire du régime de franchise en base de TVA"
          value={radioBeneTva}
          onChange={onChangeRadioBeneTva}
          name="BeneTva"
          classes={{ label: classes.radioLabel }}
          className={classes.formControlLabel}
        />
      )
    }

  </>
);

InfoComplementaireEnt.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  needSiret: Proptypes.bool,
  siret: Proptypes.string,
  tva: Proptypes.string,
  onChangeSiret: Proptypes.func,
  onChangeTva: Proptypes.func,
  radioBeneTva: Proptypes.bool,
  onChangeRadioBeneTva: Proptypes.func,
};

InfoComplementaireEnt.defaultProps = {
  needSiret: true,
  radioBeneTva: false,
};

export default withInfoComplementaireEnt(InfoComplementaireEnt);
