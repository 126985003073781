import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';
import { Typography } from '@material-ui/core';

import withCardInformation from './card-information-project.style';

const CardInformationProject = ({
  classes, id, picture, title, detailsText, pictureHover, className,
}) => {
  const handleHover = () => {
    const image = document.getElementById(`card-information-${id}`);

    image.setAttribute('src', pictureHover);
  };

  const handleLeave = () => {
    const image = document.getElementById(`card-information-${id}`);

    image.setAttribute('src', picture);
  };

  return (
    <div className={cx(classes.wrapper, className)} onMouseOver={handleHover} onMouseLeave={handleLeave}>
      <img id={`card-information-${id}`} src={picture} alt="card information" className={classes.picture} />
      <Typography id={`title-${id}`} variant="h4" className={classes.title}>{title}</Typography>
      <Typography id={`detailsText-${id}`} variant="body1" className={classes.detailsText}>{detailsText}</Typography>
    </div>
  );
};

CardInformationProject.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  id: Proptypes.oneOfType([
    Proptypes.string, Proptypes.number,
  ]),
  className: Proptypes.string,
  title: Proptypes.string,
  detailsText: Proptypes.oneOfType([
    Proptypes.string, Proptypes.node, Proptypes.object, // Ça peut être un composant ou du texte
  ]),
  picture: Proptypes.node,
  pictureHover: Proptypes.node,
};

export default withCardInformation(CardInformationProject);
