import { withStyles } from '@material-ui/core';

export default withStyles(({ palette, spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'max-content',
  },
  rating: {
    display: 'flex',
    alignItems: 'center',
    marginTop: spacing(1),
  },
  number: {
    fontWeight: 'bold',
    color: palette.grey[500],
    fontSize: '1.6rem',
    paddingRight: spacing(1),
  },
}));
