import { withStyles } from '@material-ui/styles';
import { widthDrawer } from '../../../constants/size';

export default withStyles(({
  breakpoints, palette, transitions, spacing,
}) => ({
  appBar: {
    background: palette.gradient.main,
    transition: transitions.create(['margin', 'width'], {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen,
    }),
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  logo: {
    position: 'relative',
    top: '-2px',
    [breakpoints.down(500)]: {
      display: 'none',
    },
  },
  title: {
    fontSize: '2.2rem',
    textAlign: 'center',
    [breakpoints.down(1100)]: {
      fontSize: '1.6rem',
    },
  },
  btnConcours: {
    marginLeft: spacing(2),
    width: '17rem',
    transition: '0.2s opacity ease-in-out',
    '&:hover': {
      background: palette.common.white,
      opacity: 0.9,
    },
  },
  btnConcoursText: {
    fontSize: '1.2rem',
  },
  sectionDesktop: {
    display: 'none',
    [breakpoints.up('md')]: {
      display: 'block',
      textAlign: 'center',
    },
  },
  mobileSection: {
    display: 'block',
    [breakpoints.up('md')]: {
      display: 'none',
    },
  },
  menuButton: {
    marginRight: spacing(2),
  },
  drawerPaper: {
    width: widthDrawer,
    minHeight: '50rem',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);',
    flexShrink: 0,
    zIndex: 999,
  },
  drawerHeader: {

  },
  link: {
    textDecoration: 'none',
    color: palette.common.black,
  },
  listText: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
  expansionPanel: {
    width: '100%',
    boxShadow: 'none',
    border: '1px solid rgba(0, 0, 0, .125)',
  },
  expansionPanelProfil: {
    border: 'none',
    transition: '0.2s background-color ease-in-out',
    '&:hover': {
      background: palette.grey[100],
      transition: '0.2s background-color ease-in-out',
    },
  },
  expansionPanelProfilRoot: {
    position: 'initial',
  },
  expansionPanelProfilExpanded: {
    margin: '0 !important',
  },
  expansionDetails: {
    padding: '8px 24px 0',
  },
  listSubcategorie: {
    marginTop: '-2rem',
  },
  wrapperList: {
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  subSettingsList: {
    overflow: 'hidden',
    width: '16rem',
    visibility: 'hidden',
    opacity: 0,
    padding: 0,
    zIndex: 10,
    position: 'absolute',
    transition: '0.2s all ease-in-out',
    pointerEvents: 'none',
    borderRadius: '2rem',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5), 5px 2px 4px 0 rgba(0, 0, 0, 0.5);',
    backgroundColor: palette.common.white,
  },
  subSettings: {
    margin: '0 auto',
    zIndex: 10,
    padding: '1rem',
    textAlign: 'center',
    fontSize: '1.2rem',
    color: palette.grey[500],
    '&:hover': {
      cursor: 'pointer',
      background: palette.grey[200],
    },
  },
  subSettingsLink: {
    textDecoration: 'none',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    fontFamily: 'Futura,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    color: palette.grey[800],
  },
  listOver: {
    '@global': {
      ul: {
        pointerEvents: 'auto',
        visibility: 'visible',
        opacity: 1,
      },
    },
  },
}));
