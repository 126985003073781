import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import { TextField, IconButton } from '@material-ui/core';
import withTextfieldIconButton from './textfield-icon-button.style';

const TextfieldIconButton = ({
  classes,
  id,
  name,
  type,
  label,
  margin,
  variant,
  value,
  onChange,
  inputRef,
  ariaLabelIcon,
  classNameTextField,
  onClickIcon,
  icon,
  ...rest
}) => (
  <div className={classes.root}>
    <TextField
      id={id}
      name={name}
      type={type}
      className={cx(classes.textField, classNameTextField)}
      label={label}
      margin={margin}
      variant={variant}
      value={value}
      onChange={onChange}
      inputRef={inputRef}
      {...rest}
    />
    <IconButton aria-label={ariaLabelIcon} onClick={onClickIcon} className={classes.iconButton}>
      {icon}
    </IconButton>
  </div>
);

TextfieldIconButton.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  id: Proptypes.string,
  name: Proptypes.string,
  type: Proptypes.string,
  classNameTextField: Proptypes.string,
  label: Proptypes.string,
  margin: Proptypes.string,
  variant: Proptypes.oneOf(['standard', 'outlined', 'filled']),
  value: Proptypes.any,
  onChange: Proptypes.func,
  inputRef: Proptypes.oneOfType([
    Proptypes.func,
    Proptypes.shape({ current: Proptypes.any }),
  ]),
  ariaLabelIcon: Proptypes.string,
  onClickIcon: Proptypes.func,
  icon: Proptypes.node,
};

TextfieldIconButton.defaultProps = {
  variant: 'outlined',
  margin: 'normal',
  type: 'text',
  onClickIcon: () => {},
  onChange: () => {},
};
export default withTextfieldIconButton(TextfieldIconButton);
